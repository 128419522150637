import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Forms } from "..";
import { api } from "../../../../Services/api";
import { Validators } from "../../../../Utilities/validator";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { useSelector, useDispatch } from "react-redux";

const ResetForm = ({
  setOtpConfirmation,
  setFormValues,
  setPageView,
  fisrtLogin,
  close,
}) => {
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);

  const [invalidUser, setInvalidUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changeInForm, setChangeInForm] = useState(false);

  const SubmitLogin = (form, valid) => {
    if (valid) {
      setLoading(true);
      const obj = {
        emailId: brokerdata.emailId,
        userId: brokerdata.userId,
        newPassword: form.newpassword.trim(),
        administrator: false
      };
      api
        .post("/brokerpayuserauth/forgotpassword", obj)
        .then(function (response) {
          if (response.data.status === true) {
            setFormValues(form);
            setSubmitted(true);
            if (brokerdata.loginStatus) {
              close();
            } else {
              setOtpConfirmation(response.data.status);
            }
            setLoading(false);
          } else {
            setInvalidUser(response.data.message);
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log("Reset Password Error Response", error.response);
          if (error.response.data.status === false) {
            setInvalidUser(error.response.data.message);
            console.log("Error", error.response.data.message);
          }
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="loginFormScreens flex-fill bg-white">
        {invalidUser ? (
          <Alert className="m-2 p-1 text-center" variant="danger">
            {invalidUser}
          </Alert>
        ) : (
          <></>
        )}

        <Forms
          title={""}
          formArr={[
            {
              label: "New Password",
              name: "newpassword",
              type: "password",
              placeholder: "New Password",
              validators: [
                {
                  check: Validators.required,
                  message: "This is a required field",
                },
                {
                  check: Validators.validatePassword,
                  message: "Please enter a strong password",
                },
              ],
              error: false,
              errormsg: "",
              value: "",
              show: true,
              newPassword: true,
            },
            {
              label: "Confirm Password",
              name: "confirmpassword",
              type: "password",
              placeholder: "Confirm Password",
              validators: [
                {
                  check: Validators.required,
                  message: "This is a required field",
                },
              ],
              error: false,
              errormsg: "",
              value: "",
              match: "newpassword",
              show: true,
            },
          ]}
          submitBtn={"Save"}
          onSubmit={SubmitLogin}
          submitted={submitted}
          changeInForm={changeInForm}
          setChangeInForm={setChangeInForm}
          loading={loading}
          Login={fisrtLogin ? false : true}
          setPageView={setPageView}
        />
      </div>
    </>
  );
};

export default ResetForm;
