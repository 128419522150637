import React,{useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginWrap from "./style";
import Rectangle from "../../../../src/assets/images/logo/Rectangle.svg";
import Lamp from "../../../../src/assets/images/logo/lamp.png";
import Doll from "../../../../src/assets/images/logo/doll.png";
import Background from "../../../../src/assets/images/logo/background.png";




let props = {
  initialState: false,
  initialCount: 1
}


const LoginBackground = () => {

  return (
   <React.Fragment>
    
     <LoginWrap>
     <div >
           <img className="img-con" alt="User" src={Background} />
           <img className="img-con3" alt="User" src={Rectangle} />
           <img className="img-con1" alt="User" src={Lamp} />
           {/* <img className="img-con2" alt="User" src={Doll} /> */}
           </div>
     </LoginWrap>
     
    </React.Fragment>
  );
};

export default LoginBackground;
