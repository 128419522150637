import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Card, Button } from "react-bootstrap";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { api } from "../../../../../../Services/api";
import { useSelector } from "react-redux";
import { getCurrentTime } from "../../../../../Reuseable/CurrentDateTime";

const ViewAdministrator = ({ AdministrativeArr, Accountarr, Payments, viewAdministrator, getAdministrator }) => {

    const authToken = useSelector((state) => state.Auth.authToken);
    const permissions = useSelector((state) => state.Tracking.permissions.Administrative);

    const [adminPermissions, setAdminpermissions] = useState(viewAdministrator.permissions.Administrative)
    const [accountPermission, setAccountPermission] = useState(viewAdministrator.permissions.Account)
    const [paymentsPermissions, setPaymentsPermissions] = useState(viewAdministrator.permissions.Payments)

    const [selectallAdmin, setSelectallAdmin] = useState(false)
    const [selectallAccount, setSelectallAccount] = useState(false)
    const [selectallPayments, setSelectallPayments] = useState(false)
    const [update, setUpdate] = useState(false)


    const onChangeAdmin = (id) => {
        setAdminpermissions((prevState) => {

            let temp = {
                ...prevState, ...prevState.adminPermissions
            }

            if (id === 'addnewAdministrator') temp.addnewAdministrator = !temp.addnewAdministrator
            if (id === 'changeAdministratorRoles') temp.changeAdministratorRoles = !temp.changeAdministratorRoles
            if (id === 'viewAdministratorRoles') temp.viewAdministratorRoles = !temp.viewAdministratorRoles
            if (id === 'edituserrfoles') temp.edituserrfoles = !temp.edituserrfoles
            if (id === 'viewActivityLog') temp.viewActivityLog = !temp.viewActivityLog
            if (id === 'viewUserReports') temp.viewUserReports = !temp.viewUserReports
            if (id === 'ViewSettings') temp.ViewSettings = !temp.ViewSettings
            if (id === 'viewDashboard') temp.viewDashboard = !temp.viewDashboard
            if (id === 'viewpaymentsReports') temp.viewpaymentsReports = !temp.viewpaymentsReports

            return temp;
        }
        )
        setUpdate(true)
    }

    const AdminSelectall = (data) => {

        setAdminpermissions(() => {


            let temp = adminPermissions;
            temp.addnewAdministrator = data
            temp.changeAdministratorRoles = data
            temp.viewAdministratorRoles = data
            temp.edituserrfoles = data
            temp.viewActivityLog = data
            temp.viewUserReports = data
            temp.ViewSettings = data
            temp.viewDashboard = data
            temp.viewpaymentsReports = data

            return temp;
        }
        );
        setUpdate(true)
    }
    const onChangeAccount = (id) => {

        setAccountPermission((prevState) => {

            let temp = {
                ...prevState, ...prevState.accountPermission
            }

            if (id === 'viewuserAccount') temp.viewuserAccount = !temp.viewuserAccount
            if (id === 'viewUsercontactDetails') temp.viewUsercontactDetails = !temp.viewUsercontactDetails
            if (id === 'resetUserPassowrd') temp.resetUserPassowrd = !temp.resetUserPassowrd
            if (id === 'approveAccounts') temp.approveAccounts = !temp.approveAccounts
            if (id === 'deleteAccounts') temp.deleteAccounts = !temp.deleteAccounts
            if (id === 'updateuserProfileinformation') temp.updateuserProfileinformation = !temp.updateuserProfileinformation

            return temp;
        }
        );
        setUpdate(true)
    }
    const AccountSelectall = (data) => {

        setAccountPermission(() => {

            let temp = accountPermission;
            temp.viewuserAccount = data
            temp.viewUsercontactDetails = data
            temp.resetUserPassowrd = data
            temp.approveAccounts = data
            temp.deleteAccounts = data
            temp.updateuserProfileinformation = data

            return temp;
        }
        );
        setUpdate(true)
    }


    const onChangePayment = (id) => {

        setPaymentsPermissions((prevState) => {

            let temp = {
                ...prevState, ...prevState.paymentsPermissions
            }

            if (id === 'viewRequests') temp.viewRequests = !temp.viewRequests
            if (id === 'viewRepayemnts') temp.viewRepayemnts = !temp.viewRepayemnts
            if (id === 'setMaxlimit') temp.setMaxlimit = !temp.setMaxlimit
            if (id === 'approveDeclineRequests') temp.approveDeclineRequests = !temp.approveDeclineRequests
            if (id === 'manageuuserPayemntsInformation') temp.manageuuserPayemntsInformation = !temp.manageuuserPayemntsInformation

            return temp;
        }
        );
        setUpdate(true)
    }


    const Selectallpayments = (data) => {

        setPaymentsPermissions(() => {

            let temp = paymentsPermissions;
            temp.viewRequests = data
            temp.viewRepayemnts = data
            temp.setMaxlimit = data
            temp.approveDeclineRequests = data
            temp.manageuuserPayemntsInformation = data

            return temp;
        }
        );
        setUpdate(true)
    }



    const editAdministrator = async () => {
        let obj = {
            emailId: viewAdministrator.emailId,
            userId: viewAdministrator.userId,
            name: 'permissions',
            value: {
                Administrative: adminPermissions,
                Account: accountPermission,
                Payments: paymentsPermissions
            },
            logDateTime: getCurrentTime(),
        }
        await api.patch('/brokerpayuserauth/administrator', obj, {
            headers: {
                'authorizationtoken': `${authToken}`,
            }
        }).then(function (response) {
            if (response.data.status === true) {
                viewAdministrator.permissions = {
                    Administrative: adminPermissions,
                    Account: accountPermission,
                    Payments: paymentsPermissions
                }
                setUpdate(false)
                getAdministrator()
                editMenuList()
            }
        }).catch(function (error) {
            console.log('editUserData [error]-->', error.response);
        });
    }

    const editMenuList = async () => {
        let obj = {
            emailId: viewAdministrator.emailId,
            userId: viewAdministrator.userId,
            name: 'menuList',
            value: [
                {
                    "icon": "/favicons/overview.svg",
                    "name": "Overview",
                    "path": "overview",
                    "activeicon": "/favicons/overview-blue.svg",
                    "id": 1,
                    access: true
                },
                {
                    "icon": "/favicons/users.svg",
                    "name": "Users",
                    "path": "users",
                    "activeicon": "/favicons/users-blue.svg",
                    "id": 2,
                    access: accountPermission.viewuserAccount
                },
                {
                    "icon": "/favicons/request.svg",
                    "name": "Requests",
                    "path": "requests",
                    "activeicon": "/favicons/request-blue.svg",
                    "id": 3,
                    access: paymentsPermissions.viewRequests
                },
                {
                    "icon": "/favicons/repayments.svg",
                    "name": "Repayments",
                    "path": "repayments",
                    "activeicon": "/favicons/repayments-blue.svg",
                    "id": 4,
                    access: paymentsPermissions.viewRepayemnts
                },
                {
                    "icon": "/favicons/report.svg",
                    "name": "Reports",
                    "path": "reports",
                    "activeicon": "/favicons/report-blue.svg",
                    "id": 5,
                    access: adminPermissions.viewUserReports || adminPermissions.viewpaymentsReports
                },
                {
                    "icon": "/favicons/settings.svg",
                    "name": "Settings",
                    "path": "setting",
                    "activeicon": "/favicons/settings-blue.svg",
                    "id": 6,
                    access: adminPermissions.ViewSettings
                },
                {
                    "icon": "/favicons/activitylog.svg",
                    "name": "Activity Log",
                    "path": "activitylog",
                    "activeicon": "/favicons/activitylog-blue.svg",
                    "id": 7,
                    access: adminPermissions.viewActivityLog
                }
            ]
        }
        await api.patch('/brokerpayuserauth/administrator', obj, {
            headers: {
                'authorizationtoken': `${authToken}`,
            }
        }).then(function (response) {
            if (response.data.status === true) {
                getAdministrator()
            }
        }).catch(function (error) {
            console.log('editMenuList [error]-->', error.response);
        });
    }

    if (update) editAdministrator();

    return (
        <React.Fragment>
            <Container className="bg-white p-4">
                <h3>
                    {viewAdministrator.personname}
                </h3>
                <div className="view-data-page ">
                    {/* <div>Ph: 0467897644</div>
                    <div className="divider">|</div> */}
                    <div>Email: {viewAdministrator.emailId}</div>
                </div>
                {/* <div className="view-data-page my-3">
                    <Col sm={6} md={4}>
                        <Form.Group
                            className="mb-3 d-flex"
                            controlId="formBasicEmail"
                        >
                            <Form.Label className="mt-1">Role</Form.Label>
                            <Form.Select
                                className="view-select"
                                aria-label="Default select example"
                            >
                                <option value="1">Solution Architect</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </div> */}
                <div className="primary mt-3">Permissions</div>
                <Row>
                    <Col md={12} lg={12} xl={4}>
                        <div className="my-3">Administrative</div>
                        <Card className='Add-card'>
                            {AdministrativeArr.map((label) => {
                                let value = ''

                                if (label.id === 'addnewAdministrator') value = adminPermissions.addnewAdministrator
                                if (label.id === 'changeAdministratorRoles') value = adminPermissions.changeAdministratorRoles
                                if (label.id === 'viewAdministratorRoles') value = adminPermissions.viewAdministratorRoles
                                if (label.id === 'edituserrfoles') value = adminPermissions.edituserrfoles
                                if (label.id === 'viewActivityLog') value = adminPermissions.viewActivityLog
                                if (label.id === 'viewUserReports') value = adminPermissions.viewUserReports
                                if (label.id === 'ViewSettings') value = adminPermissions.ViewSettings
                                if (label.id === 'viewDashboard') value = adminPermissions.viewDashboard
                                if (label.id === 'viewpaymentsReports') value = adminPermissions.viewpaymentsReports

                                return (
                                    <div>
                                        <div className="d-inline-block label">{label.name}</div>
                                        <div className="d-inline-block float-end">
                                            <FormGroup >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={permissions && permissions.changeAdministratorRoles ? false : true}
                                                            checked={value}
                                                            onChange={() => onChangeAdmin(label.id)}
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="selectall ">
                                <div className="d-inline-flex ">
                                    <span className="mx-2 mt-2"> Select all </span>
                                    <FormGroup >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    disabled={permissions && permissions.changeAdministratorRoles ? false : true}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    onChange={() => {
                                                        setSelectallAdmin(!selectallAdmin);
                                                        AdminSelectall(!selectallAdmin)
                                                    }}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col md={12} lg={12} xl={4}>
                        <div className="my-3">Account</div>
                        <Card className='Add-card'>
                            {Accountarr.map((label) => {
                                let value = ''

                                if (label.id === 'viewuserAccount') value = accountPermission.viewuserAccount
                                if (label.id === 'viewUsercontactDetails') value = accountPermission.viewUsercontactDetails
                                if (label.id === 'resetUserPassowrd') value = accountPermission.resetUserPassowrd
                                if (label.id === 'approveAccounts') value = accountPermission.approveAccounts
                                if (label.id === 'deleteAccounts') value = accountPermission.deleteAccounts
                                if (label.id === 'updateuserProfileinformation') value = accountPermission.updateuserProfileinformation

                                return (
                                    <div>
                                        <div className="d-inline-block label">{label.name}</div>
                                        <div className="d-inline-block float-end">
                                            <FormGroup >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={permissions && permissions.changeAdministratorRoles ? false : true}
                                                            checked={value}
                                                            onChange={() => onChangeAccount(label.id)}
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="selectall ">
                                <div className="d-inline-flex ">
                                    <span className="mx-2 mt-2"> Select all </span>
                                    <FormGroup >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    disabled={permissions && permissions.changeAdministratorRoles ? false : true}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    onChange={() => {
                                                        setSelectallAccount(!selectallAccount);
                                                        AccountSelectall(!selectallAccount)
                                                    }}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col md={12} lg={12} xl={4}>
                        <div className="my-3">Payments</div>
                        <Card className='Add-card'>
                            {Payments.map((label) => {
                                let value = ''

                                if (label.id === 'viewRequests') value = paymentsPermissions.viewRequests
                                if (label.id === 'viewRepayemnts') value = paymentsPermissions.viewRepayemnts
                                if (label.id === 'setMaxlimit') value = paymentsPermissions.setMaxlimit
                                if (label.id === 'approveDeclineRequests') value = paymentsPermissions.approveDeclineRequests
                                if (label.id === 'manageuuserPayemntsInformation') value = paymentsPermissions.manageuuserPayemntsInformation

                                return (
                                    <div>
                                        <div className="d-inline-block label">{label.name}</div>
                                        <div className="d-inline-block float-end">
                                            <FormGroup >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={permissions && permissions.changeAdministratorRoles ? false : true}
                                                            checked={value}
                                                            onChange={() => onChangePayment(label.id)}
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="selectall ">
                                <div className="d-inline-flex ">
                                    <span className="mx-2 mt-2"> Select all </span>
                                    <FormGroup >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    disabled={permissions && permissions.changeAdministratorRoles ? false : true}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                    onChange={() => {
                                                        setSelectallPayments(!selectallPayments);
                                                        Selectallpayments(!selectallPayments)
                                                    }}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <div>
                    </div>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default ViewAdministrator;
