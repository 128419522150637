import React, { useState } from "react";
import { Forms } from "../../../Reuseable/Forms";
import LoginWrap from "../style";
import hashching from "../../../../../src/assets/images/hashchinglogo.svg";
import { Validators } from "../../../../Utilities/validator";
import Styled from "styled-components";
import { api } from "../../../../Services/api";

const HashchingPassword = ({
  setPageView,
  hashchingData,
  email,
  setPassword,
}) => {
  const [submitted, setSubmitted] = useState("");
  const [changeInForm, setChangeInForm] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const setNewPassword = async (form, valid) => {
    if (valid) {
      setLoading(true);
      setErrorMessage(false);
      const obj = {
        emailId: email,
        name: hashchingData.first_name + " " + hashchingData.last_name,
        newPassword: form.newpassword,
        action: "UpdatePassword",
      };
      try {
        let url = "/brokerpayuserauth/hashchinguser/" + email;
        const response = await api.post(url, obj);
        if (response.data.status === true) {
          setLoading(false);
          setSubmitted(true);
          setErrorMessage(false);
          setPassword(form.newpassword);
          setPageView("confirm_account");
        } else {
          setLoading(false);
          setErrorMessage(true);
          setSubmitted(false);
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(true);
        setSubmitted(true);
        console.log("getHashchingBroker [error]-->", error);
      }
    }
  };

  return (
    <React.Fragment>
      <LoginWrap>
        <HashchingWrap>
          <div>
            <h3 className="primary center mt-3">Set password</h3>
            <div>
              <Forms
                title={""}
                formArr={[
                  {
                    label: "Enter password",
                    name: "newpassword",
                    type: "password",
                    placeholder: "New Password",
                    validators: [
                      {
                        check: Validators.required,
                        message: "This is a required field",
                      },
                      {
                        check: Validators.validatePassword,
                        message: "Please enter a strong password",
                      },
                    ],
                    error: false,
                    errormsg: "",
                    value: "",
                    show: true,
                    newPassword: true,
                  },
                  {
                    label: "Confirm password",
                    name: "confirmpassword",
                    type: "password",
                    placeholder: "Confirm Password",
                    validators: [
                      {
                        check: Validators.required,
                        message: "This is a required field",
                      },
                    ],
                    error: false,
                    errormsg: "",
                    value: "",
                    match: "newpassword",
                    show: true,
                  },
                ]}
                submitBtn={"Save"}
                onSubmit={setNewPassword}
                loading={loading}
                submitted={submitted}
                changeInForm={changeInForm}
                setChangeInForm={setChangeInForm}
              />
            </div>
          </div>
        </HashchingWrap>
      </LoginWrap>
    </React.Fragment>
  );
};

const HashchingWrap = Styled.div`

.form-label {
    font-size: 14px !important
}

.col-form {
padding: 0 !important
}


.form-control{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0094FF;
    border-radius: 5px;
}

`;

export default HashchingPassword;
