import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ActivityWrap from "./style";
import Tables from "../../../Reuseable/Tables";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "react-datepicker/dist/react-datepicker.css";
import "antd/dist/antd.css";
import moment from "moment";
import { Paginate } from "../../../Reuseable/Paginate";
import Calender from "../../../../assets/images/Calender.svg";
import { DatePicker } from "antd";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Searchbox from "../../../Reuseable/Searchbox";
import { NoDataFound } from "../../../Reuseable/NoDataFound";
import { InputGroup } from "react-bootstrap";
import CancelIcon from "@mui/icons-material/Cancel";

const { RangePicker } = DatePicker;

const formatDate = (date) =>
  new Date(date.split("/").reverse().join("/")).toISOString();

const ActivityLog = ({ getAllActivityLog }) => {
  useEffect(() => {
    getAllActivityLog();
  }, []);

  const allActivityLog = useSelector((state) => state.Tracking.allActivityLog);
  const user = useSelector((state) => state.Auth.user);
  let allUsers = useSelector((state) => state.Tracking.allUsers);
  let administratorData = useSelector(
    (state) => state.Tracking.allAdministrators
  );

  const [currentPage, setCurrentpage] = useState(1);
  const [date, setDate] = useState("");
  const [searchterm, setSearchterm] = useState("default");
  const [isHovering, setIsHovering] = useState(false);

  const navigate = useNavigate();
  let todosPerPage = 10;

  let ordereddata = allActivityLog
    .filter((val) => {
      if (date) {
        if (
          moment(formatDate(val.datetime.slice(0, 10))).isBetween(
            date[0],
            date[1],
            "day",
            "[]"
          )
        ) {
          return val;
        }
      } else {
        return val;
      }
    })

    .sort((a, b) => {
      if (parseInt(a.logId) > parseInt(b.logId)) return -1;
      else return 1;
    })
    .filter((val) => {
      if (val.viewMode && val.viewMode === "Broker") {
        return false;
      } else {
        return true;
      }
    })
    .filter((val) => {
      if (searchterm === "default") {
        return val;
      } else if (val.userName && val.userName.length) {
        if (val?.userName.toLowerCase().includes(searchterm.toLowerCase())) {
          return val;
        }
      }
    });

  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = ordereddata.slice(indexOfFirstTodo, indexOfLastTodo);

  const columns = ["Date and Time", "Name", "Action", "Notes"];

  const tbody = (
    <tbody>
      {currentTodos.map((data) => {
        if (data.notes === undefined || data.notes === "") {
          data.notes = "-";
        }
        return (
          <tr>
            <td>{data.datetime}</td>
            <td>{data.userName}</td>
            <td className="action">{data.action}</td>
            <td>{data.notes}</td>
          </tr>
        );
      })}
    </tbody>
  );

  const data2 = { columns, tbody };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <React.Fragment>
      <ActivityWrap>
        <Container>
          <div className="tab-row">
            <div className="back-link">Activity Log</div>
          </div>
          <Container>
            <div>
              <Row>
                <Col sm={12} md={3}>
                  <InputGroup
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <KeyboardArrowDownIcon className="down-arrow" />
                    <select
                      className="search-dropdown"
                      value={searchterm}
                      onChange={(e) => setSearchterm(e.target.value)}
                      placeholder="Search Name"
                    >
                      <option disabled value={"default"}>
                        Name
                      </option>
                      <option>{user.personname}</option>
                      {administratorData.map((data) => (
                        <option>{data.personname}</option>
                      ))}
                    </select>
                    {isHovering && searchterm !== "default" && (
                      <div
                        className="reset-dropdown me-3"
                        onClick={() => setSearchterm("default")}
                      >
                        <CancelIcon fontSize="small" className="primary" />
                      </div>
                    )}
                  </InputGroup>
                </Col>
                <Col sm={12} md={3}>
                  <RangePicker
                    className="date-picker mt-2"
                    placeholder={["Start Date", "End Date"]}
                    locale={date}
                    format={"YYYY-MM-DD"}
                    value={date}
                    onChange={(date) => setDate(date)}
                    separator={
                      <CompareArrowsIcon className="primary  calender-arrow" />
                    }
                    suffixIcon={<img src={Calender} />}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <div className="float-end">
                    <Paginate
                      listPerPage={10}
                      totalList={ordereddata.length}
                      setCurrentpage={setCurrentpage}
                      currentPage={currentPage}
                    />
                  </div>
                </Col>
              </Row>
              <div className="my-3">
                {ordereddata && ordereddata.length ? (
                  <Tables props={data2} striped={true} />
                ) : (
                  <NoDataFound body="You don’t have any Activity log at the moment." />
                )}
              </div>
            </div>
          </Container>
        </Container>
      </ActivityWrap>
    </React.Fragment>
  );
};

export default ActivityLog;
