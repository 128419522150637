import React, { useState } from "react";
import ResourceWrap from "./style";
import { Card, Col, Container, Row } from "react-bootstrap";
import ResourceImage1 from "../../../assets/images/ResourceImage1.svg";
import ResourceImage2 from "../../../assets/images/ResourceImage2.svg";
import ResourceImage3 from "../../../assets/images/ResourceImage3.png";
import ResourceImage4 from "../../../assets/images/ResourceImage4.png";
import DownloadIcon from "../../../assets/images/resourcedownloadicon.svg";
import hashching_logo from "../../../assets/images/hashchinglogo.svg";
import UIModal from "../../Reuseable/UIModal";
import standard_pricing from "./standardpricing.pdf";
import hashching_pricing from "./hashchingpricing.pdf";
import how_it_works from "./howitworks.pdf";
import hashching from "./resource_image_4.pdf";
import { saveAs } from "file-saver";
const Resource = () => {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState("Resource_1");

  const handleClick = (pdfUrl, filename) => {
    saveAs(pdfUrl, filename);
  };

  return (
    <ResourceWrap>
      <Container>
        <p className="resource fw-bold px-3">Resources</p>
        <Row>
          <Col className="pb-4" lg={4} md={6}>
            <Card className="p-3">
              <img
                width={150}
                height={150}
                src={ResourceImage1}
                alt="Resource"
              />
              <h5 className="primary">Brokerpay fee structure</h5>
              <p className="resource_sub_title">Standard Pricing</p>
              <div className="resource_content">
                Find out about the standard pricing plans of brokerpay in the
                given document.
              </div>
              <div className="btn_row mt-3">
                <button
                  onClick={() => {
                    setShow(true);
                    setContent("Resource_1");
                  }}
                  className="theme-btn py-1 px-5"
                >
                  View
                </button>
                <span
                  onClick={() =>
                    handleClick(
                      standard_pricing,
                      "Brokerpay Fee Table - Standard Pricing"
                    )
                  }
                >
                  <img
                    width={30}
                    height={30}
                    className="float-end pointer"
                    src={DownloadIcon}
                    alt="download"
                  />
                </span>
              </div>
            </Card>
          </Col>
          <Col className="pb-4" lg={4} md={6}>
            <Card className="p-3">
              <div>
                <img
                  className="card_img"
                  src={hashching_logo}
                  alt="Hashching"
                />
                <img
                  width={150}
                  height={150}
                  src={ResourceImage2}
                  alt="Resource"
                />
              </div>
              <h5 className="primary">Brokerpay fee structure</h5>
              <p className="resource_sub_title">Hashching broker</p>
              <div className="resource_content">
                Find out about the pricing plans of brokerpay for hashching
                broker in the given document.
              </div>
              <div className="btn_row mt-3">
                <button
                  onClick={() => {
                    setShow(true);
                    setContent("Resource_2");
                  }}
                  className="theme-btn py-1 px-5"
                >
                  View
                </button>
                <span
                  onClick={() =>
                    handleClick(
                      hashching_pricing,
                      "Brokerpay Fee Table - Hashching Brokers"
                    )
                  }
                >
                  <img
                    width={30}
                    height={30}
                    className="float-end pointer"
                    src={DownloadIcon}
                    alt="download"
                  />
                </span>
              </div>
            </Card>
          </Col>
          <Col className="pb-4" lg={4} md={6}>
            <Card className="p-3">
              <div className="center">
                <img
                  height={186}
                  width={300}
                  src={ResourceImage3}
                  alt="Resource"
                />
              </div>
              <h5 className="heading_3">Exclusive partner offer</h5>
              <div className="resource_content">
                Access lower fees and higher borrowing limits with hashching
              </div>
              <div className="btn_row mt-3">
                <button
                  onClick={() => {
                    setShow(true);
                    setContent("Resource_3");
                  }}
                  className="theme-btn py-1 px-5"
                >
                  View
                </button>
                <span
                  onClick={() =>
                    handleClick(
                      hashching,
                      "Brokerpay - Exclusive partner offers"
                    )
                  }
                >
                  <img
                    width={30}
                    height={30}
                    className="float-end pointer"
                    src={DownloadIcon}
                    alt="download"
                  />
                </span>
              </div>
            </Card>
          </Col>
          <Col className="pb-4" lg={4} md={6}>
            <Card className="p-3">
              <h5 className="primary text-center">How it works</h5>
              <div className="resource_content center text-center">
                Get access to your advance commission just by following these
                few simple steps with brokerpay.
              </div>
              <div className="mt-3">
                <button
                  onClick={() => {
                    setShow(true);
                    setContent("Resource_4");
                  }}
                  className="theme-btn py-1 px-5"
                >
                  View
                </button>
                <span
                  onClick={() =>
                    handleClick(how_it_works, "How Does it Work  - Brokerpay")
                  }
                >
                  <img
                    width={30}
                    height={30}
                    className="float-end pointer"
                    src={DownloadIcon}
                    alt="download"
                  />
                </span>
              </div>
              <div className="center">
                <img
                  width={220}
                  height={150}
                  src={ResourceImage4}
                  alt="Resource"
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Container>

      <UIModal
        show={show}
        setShow={setShow}
        close={true}
        size={"md"}
        body={
          <ResourceWrap>
            {content === "Resource_1" ? (
              <div className="pdf-container">
                <iframe
                  src={standard_pricing}
                  title="PDF Viewer"
                  className="pdf-iframe"
                />
              </div>
            ) : content === "Resource_2" ? (
              <div className="pdf-container">
                <iframe
                  src={hashching_pricing}
                  title="PDF Viewer"
                  className="pdf-iframe"
                />
              </div>
            ) : content === "Resource_3" ? (
              <div className="pdf-container">
                <iframe
                  src={hashching}
                  title="PDF Viewer"
                  className="pdf-iframe"
                />
              </div>
            ) : (
              <div className="pdf-container">
                <iframe
                  src={how_it_works}
                  title="PDF Viewer"
                  className="pdf-iframe"
                />
              </div>
            )}
          </ResourceWrap>
        }
        padding={false}
      />
    </ResourceWrap>
  );
};

export default Resource;
