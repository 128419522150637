import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { api } from "../../../../Services/api";
import Form from "react-bootstrap/Form";
import { Col, Button } from "react-bootstrap";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import OTPInput from "otp-input-react";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import UIModal from "../../../Reuseable/UIModal";
import LandingPageWrap from "../../LandingPage/style";
import Contact from "../../LandingPage/Conatctus";

const ForgotPasswordOTP = ({ formValues, setPageView, values, setValues }) => {
  const [errors, setErrors] = useState({
    otp: "This is a required field",
  });
  const [show, setShow] = useState(false);

  const errorValidation = (otp) => {
    let errors = {
      otp: "",
    };

    if (otp.length === 6) {
      errors.otp = "No Errors";
    } else {
      errors.otp = "This is a required field";
    }

    setErrors(errors);
  };

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpResponse, setOtpResponse] = useState("");

  const handleChange = async (otp) => {
    setValues({ otp });
    errorValidation(otp);
  };

  const handleFormsubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (errors.otp === "No Errors") {
      setLoading(true);
      const obj = {
        emailId: formValues.email,
        OTP: values.otp,
        administrator: false,
      };

      api
        .post("/brokerpayuserauth/forgotpassword", obj)
        .then(function (response) {
          setLoading(false);
          if (response.data.status === true) {
            setOtpResponse(response.data.message);
            setPageView("set_password");
          } else {
            setOtpResponse(response.data.message);
          }
        })
        .catch(function (error) {
          console.log("Otp Error Response", error.response);
          setLoading(false);
          if (error.response.data.status === false) {
            setOtpResponse(error.response.data.message);
          }
        });
    }
  };

  const resendOtp = () => {
    setOtpResponse("");
    const obj = {
      emailId: formValues.email,
      administrator: false,
    };
    api
      .post("/brokerpayuserauth/forgotpassword", obj)
      .then(function (response) {
        if (response.data.status === true) {
          setOtpResponse('Code re-sent');
        }
      })
      .catch(function (error) {
        if (error.response.data.status === false) {
          console.log("Error", error.response.data.message);
          setOtpResponse(error.response.data.message);
        }
      });
  };

  return (
    <>
      <div className="loginFormScreens flex-fill bg-white">
        <Form.Group controlId="exampleForm.ControlInput1">
          {otpResponse && (
            <Alert
              className="m-2 mb-2 p-1 text-center login-otp-alert"
              variant="info"
            >
              {otpResponse}
            </Alert>
          )}

          <Form.Label className={`center text-center login-content mx-3`}>
            Enter the six-digit code sent to your Email ID
          </Form.Label>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="center reset-otp-heading">
            Enter Verification Code
          </Form.Label>

          <OTPInput
            value={values.otp}
            className="otp-input center bg-white  text-lg focus:outline-none focus:shadow-outline mt-1 mb-1  border-gray-300 rounded-lg  block w-full appearance-none leading-normal"
            onChange={handleChange}
            name="otp"
            otpType="number"
            OTPLength={6}
            separator={<span></span>}
          />
          {errors.otp === "This is a required field" && submitted && (
            <p className="center declined">
              <ReportProblemIcon className="reportIcon-otp" />
              {errors.otp}
            </p>
          )}

          <div className="d-flex justify-content-between">
            <div>
              <Button className="text-btn fw-bold" onClick={resendOtp}>
                Resend Code
              </Button>
            </div>
            <div>
              <Button
                className="text-btn fw-bold"
                onClick={() => setShow(true)}
              >
                Contact Support
              </Button>
            </div>
          </div>

          <Col className="center">
            <Button
              className="create-btn theme-btn mx-0"
              onClick={handleFormsubmit}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </Button>
          </Col>
        </Form.Group>
      </div>
      <UIModal
        show={show}
        setShow={setShow}
        close={true}
        padding={false}
        body={
          <LandingPageWrap>
            <Contact />
          </LandingPageWrap>
        }
      />
    </>
  );
};

export default ForgotPasswordOTP;
