import Styled from 'styled-components';

const HeaderWrap = Styled.div`
.logoimg{
    width:120px;
    height: 70px;
   
  }
  .dropdown-menu{
    padding: 15px;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
  }
  .drop-userid{
    font-size: 16px;
    font-weight: 500 ! important;
    color: #303030;
  }
.drop-license{
    padding: 10px !important;
    font-weight: 500 !important;
}
.dropdown-menu{
    .dropdown-item{
        padding: 0;
    }
}
.avatar{
    border-radius: 50%;
}
.fisrt_nav{
    font-size:18px;
     margin-top:20px;
      color: black;
}
.dropdown-profile{
    height: 64px;
    width: 64px;
    padding-right: 5px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #0094FF;
}
.profile_icon{
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #0094FF;  
}
.profile_icon img{
  height: 100%;
object-fit: cover;  
}
.dropdown-profile img{
height: 100%;
object-fit: cover;
}

@media (min-width:575px) {
    .dropdown-menu{
        width: 455px;
      }
}
@media (max-width:575px) {
.divider{
    display: none;
}
.dropdown-menu{
    width: 285px;
  }
}
            .layout-label {
            margin-left: 45px;
            }
            // .header:active{
            //     color:red;
            // }
            .text-blue {
                color:red;
            }
            .barLoader {
                top: -30px;
                z-index: 4;
            }
            .navbar {
            //  margin-top:10px;
                padding-top:10px;
                // padding-bottom:10px;
            font-weight: 600;
            padding: 0px;
            height: 60px;
            }
            .navbar-brand {
                padding: 2px;
            }
            .z-index-navbar {
                 z-index: 1090;
            }
            #logout-button {
                background-color: #1958a7 !important;
              }              
            .logo-pos {
                position: absolute;
                z-index: 3;
                width: 180px;
                top: 60px;
                left: 20px;
            } 

            .loginSubmitBtn {
                background-color: #1958A7;
                color: white;
            
            }
                 
            .navbar-toggler:focus {
                text-decoration: none; 
                outline: none; 
                box-shadow: 0 0 0 0; 
            }

            .log1 {
                position: absolute;
                right:18px;
                margin-left: 35px; 
            }

            .pos {
                position: relative;
                top: 15px;
                z-index: 2;
                
            }
            // .hide-menu{
            //     display:none;
            // }

            .profile{
                // right:-30px;
            }
            .popup_resource{
                position: relative;
                bottom: 7px;
              }
            .dash {
                border-bottom: 3px solid #0094FF;
                border-radius: 1px;
                transition: 0.1s ease;
                margin: -3px;             
            }
            .head-icon1{
                display:none;
            }
            
            .nav-link.active {
                
                padding-bottom: 7px;
                border-bottom: 3px solid #0094FF;
                border-radius: 1px;
                transition: 0.1s easeinout;
                .head-icon{
                    display:none;
                }
                .head-icon1{
                    display:inline;
                }
                
            }
            .head-icon {
              color:#303030 ;
              &:active,
              &:hover,
              &:focus {
                color: #0094FF;
              }
            }
            .logoutdisplay{
                display:none;
            }

            @media (max-width:829px) {
                .logoutdisplay{
                    display:inline;
                }
                .log {
                    position: relative;

                }
                .logout-btn{
                    display:none;
                }
                .profile{
                    margin-top: -5px;
                    right:10px;
                    // display:none;
                }
                .navbar-nav {
                    display:none;
                    float: right ;
                }
                .pos {
                    position: relative;
                    top: 1px;
                }
                .navbar-collapse {
                    color:red;
                    background-color: rgba(41, 40, 120,0.4);
                    width: 200px;
                    
                  }
                  .container-fluid {
                    padding: 0;
                  }
                }
            a, a:hover, a:visited, a:active {
                color: white;
                text-decoration: none;
               }

               @media (max-width:767px) {
                  .navbar-collapse {
                    display: none;
                  }
                  .navbar-toggler {
                    display: none;
                  }
                  .log1 {
                    margin-right: 20px; 
                    margin-top: -6px;
                }
               
               }
            #close-button {
                width: 70px
            }
            #yes-button {
                background-color: #1958a7 !important;
                color: white;
                margin: 10px;
                width:70px;
                text-align: center;
                padding: 6px;
                margin: 4px;
              }

              #yes-button:hover {
                background-color: #194275 !important;
              }    
            .form-control {
            &:focus {
                box-shadow: inherit !important;
            }
            }
            .imgfirstLetr {
            display: inline-block;
            background-color: ${({ theme }) => theme['orange-color']};
            width: 32px;
            height: 32px;
            border-radius: 50%;
            color: #fff;
            box-shadow: inset 0px 3px 6px #ffffff30, 0px 3px 6px #00000029;
            font-size: 18px;
            padding: 6px 6px;
            margin: 0 auto;
            text-align: center;
            }
            .bgGreyLetr.imgfirstLetr {
            background-color: ${({ theme }) => theme['gray-color']};
            box-shadow: inherit;
            }
            .imgfirstLetrDropdwnTogle {
            padding: 4px !important;
            border-radius: 50% !important;
            width: 40px;
            height: 40px;
            }
            .navbar-brand
            {
                width: 100%;
                margin:0 auto;
            }
            .dropdown-toggle.btn-primary {
                color: #fff;
                background-color: transparent;
                border-color: transparent;
            }
            .dropdown-toggle.btn-primary:hover {
                color: #fff;
                // background-color: #5f63681f;
                // border-color: #5f63681f;
            }
            .dropdown-toggle.imgfirstLetrDropdwnTogle[aria-expanded="true"] {
            background-color: #5f63681f;
            border-radius: 50%;
            padding: 4px;
            width: 40px;
            height: 40px;
            }
            .dropdown-toggle.imgfirstLetrDropdwnTogle {
            &:hover[aria-expanded="true"] {
                background-color: #5f63681f;
                border-radius: 50%;
                padding: 4px;
                width: 40px;
                height: 40px;
            }
            }
            .profInfo {
            .defultImgFirsLetr {
                vertical-align: top;
                height: 60px;
                width: 70px;
                font-size: 40px;
                padding: 10px;
                margin-bottom: 10px;
            }
            h5 {
                font-weight: 600;
                font-size: 18px;
                line-height: normal;
            }
            }

            .singleProfDetail {
            border-bottom: 1px solid #f2f2f2;
            padding: 15px 0;
            &:last-child {
                border-bottom: none;
            }
            }
            .addUserIcon {
            width: 32px;
            height: 32px;
          
            }
            .anothrAcount {
            margin-top: 10px;
            }
            .dpdwnProfDetail {
            h6 {
                font-weight: 400;
            }
            .fisrt_nav{
                font-size:18px;
                 margin-top:20px;
                  color: black;
            }
            .fisrt_nav.active {
                
                padding-bottom: 7px;
                border-radius: 1px;
                transition: 0.1s easeinout;
                .head-icon{
                    display:none;
                }
                .head-icon1{
                    display:inline;
                }
                
            }
            }
            .singleProfDetail.acountList {
            padding: 0;
            h6 {
                font-weight: 500;
                font-size: 15px;
            }
            p {
                font-size: 12px;
                font-weight: 400;
            }
            small {
                font-size: 11px;
                font-weight: 400;
                color: ${({ theme }) => theme['gray-color']};
            }
            }
            .singleProfListng {
            padding: 10px 15px;
            &:hover {
                background-color: transparent;
            }
            }
            .signOutAcount {
            a.btn {
                font-size: 12px;
                border: 1px solid ${({ theme }) => theme['gray-color']};
                padding: 10px 24px;
                letter-spacing: 0.15px;
                text-align: center;
                &:hover {
                background-color: ${({ theme }) => theme['gray-color']};
                }
            }
            }
            .hoverBg {
            padding: 4px 10px 0 0;
            .dropdown-toggle {
                svg {
                width: 35px;
                height: 35px;
                padding: 6px;
                border-radius: 40px;
                path {
                    fill: ${({ theme }) => theme['icon-color']};
                }

                &:hover {
                    background-color: #f2f2f2;
                    path {
                    fill: ${({ theme }) => theme['hover-icon-color']};
                    }
                }
                &:focus {
                    background-color: #f2f2f2;
                    path {
                    fill: ${({ theme }) => theme['hover-icon-color']};
                    }
                }
                &:active {
                    background-color: #f2f2f2;
                    path {
                    fill: ${({ theme }) => theme['hover-icon-color']};
                    }
                }
                }
            }
            }
            .singleOption {
            padding: 10px 0;
            }
            .selectThemepart {
            .form-label {
                font-size: 12px;
                text-transform: uppercase;
                color: #a1a1a1;
                letter-spacing: 1.4px;
                display: block;
                margin-bottom: 15px;
                padding-bottom: 5px;
            }
            .form-check-label {
                margin-left: 5px;
            }
            .feedbckTxt {
                font-size: 12px;
                color: ${({ theme }) => theme['icon-color']};
                margin-left: 5px;
            }
            .defaultTheme {
                .form-check-input {
                cursor: pointer;
                border: 2px solid #0c0c0c40;
                position: absolute;
                top: 9px;
                }
                .themePreviewimg {
                top: 6px;
                }
            }
            .themePreviewimg {
                position: absolute;
                top: -6px;
                right: 0;
                border: 1px solid #d2d2d2;
                padding: 3px;
                border-radius: 3px;
            }
            }
            // .navbar-item{
            //     display:none
            // }

            
            @media (min-width: 992px) {
            .navbar {
                .navbar-menu-wrapper {
                .topNavListing.topNavListing-right {
                    margin-left: 20px;
                }
                }
            }
            }
            @media (max-width: 767px) {
                .profile{
                        right:0px;
                    }
                    .avatar{
                        margin-top:-5px;
                    }
                    .logoimg{
                        margin-top:0px;
                      }
              }
            @media (max-width: 450px) {
                .navbar{
                    padding-bottom:0px;
                    padding-bottom:25px;
                }
            }
          
            
     
`;

export default HeaderWrap;