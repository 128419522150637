import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import { Alert } from "react-bootstrap";
import "slick-carousel/slick/slick-theme.css";
import Form from "react-bootstrap/Form";
import { Row, Col, Button } from "react-bootstrap";
import Contact from "../../../Pages/LandingPage/Conatctus";
import LandingPageWrap from "../../../Pages/LandingPage/style";
import UIModal from "../../../Reuseable/UIModal";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import OTPInput from "otp-input-react";
import { api } from "../../../../Services/api";
import BarLoader from "react-spinners/ClipLoader";

const OTPConfirmation = ({ email, setPageView, hashchingData }) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const errorValidation = (values) => {
    let errors = {};
    if (!values.otp) {
      errors.otp = "This is a required field";
    } else if (values.otp.length < 6) {
      errors.otp = "This is a required field ";
    }
    return errors;
  };

  const [dataIsCorrect, setDataiscorrect] = useState("");
  const [resendotpmessage, setresendotpmessage] = useState("");
  const [values, setValues] = useState("");
  const [errors, setErrors] = useState({});
  const [inValid, setInValid] = useState(false);

  const handleChange = (otp) => {
    setValues({ otp });
  };

  const handleFormsubmit = (event) => {
    event.preventDefault();
    setErrors(errorValidation(values));
    setDataiscorrect("");
    setLoading(true);
    setInValid(false)
    const obj = {
      emailId: email,
      name: hashchingData.first_name+' '+hashchingData.last_name,
      otp: values.otp,
      action : 'VerifyOtp',
    };
    api
      .post("/brokerpayuserauth/hashchinguser/" + email, obj)
      .then(function (response) {
        if (response.data.status === true) {
          setLoading(false);
          setPageView("set_password");
        }else{
          setLoading(false);
          setInValid(true)
          setErrors({...errors, otp: 'Invalid code. Please reenter'});
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.data.status === false) {
          setDataiscorrect(error.response.data.message);
          console.log("Error", error.response.data.message);
          setLoading(false);
        }
      });
  };

  const ResendOTP = (event) => {
    event.preventDefault();
    setDataiscorrect("");
    const obj = {
      emailId: email,
      name: hashchingData.first_name+' '+hashchingData.last_name,
      otp: values.otp,
      action : 'ResendOtp',
    };
    api
    .post("/brokerpayuserauth/hashchinguser/" + email, obj)
      .then(function (response) {
        if (response.data.status === true) {
          setresendotpmessage('Code re-sent');
        }
      })
      .catch(function (error) {
        if (error.response.data.status === false) {
          setDataiscorrect(error.response.data.message);
          console.log("Error", error.response.data.message);
        }
      });
  };

  return (
    <>
      <div>
        <div className="loginFormScreens flex-fill bg-white">
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label
              style={{
                paddingTop: "20px",
                fontSize: "32px",
                color: " #0094FF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <b>Sign Up</b>
            </Form.Label>
            {dataIsCorrect ? (
              <Alert
                className="m-2 mb-2 p-1 text-center login-otp-alert"
                variant="danger"
              >
                {dataIsCorrect}
              </Alert>
            ) : (
              <></>
            )}
            {resendotpmessage ? (
              <Alert
                className="m-2 mb-2 p-1 text-center login-otp-alert"
                variant="success"
              >
                {resendotpmessage}
              </Alert>
            ) : (
              <></>
            )}
            <div className="text-center center login-content login-otp-alert">
              Enter the six-digit code sent to your Email ID
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label
              style={{
                fontSize: "22px",
                fontWeight: "600",
                marginBottom: "0px",
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Enter Verification Code
            </Form.Label>

            <OTPInput
              value={values.otp}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={`${inValid && 'invalid_otp'} otp-input bg-white mx-2 text-lg focus:outline-none focus:shadow-outline  border-gray-300 rounded-lg  block w-full appearance-none leading-normal`}
              name="otp"
              onChange={handleChange}
              otpType="number"
              OTPLength={6}
              separator={<span></span>}
            />
            {errors.otp && (
              <p className="error center"
              >
                {errors.otp}
              </p>
            )}
          </Form.Group>
          <Row>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="pointer p-text" onClick={ResendOTP}>
                  Resend Code
                </Form.Label>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label
                  className="p-text pointer"
                  onClick={() => setShow(true)}
                  style={{
                    float: "right",
                  }}
                >
                  Contact Support
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                background: "#0094FF",
                border: "none",
                width: "110px",
                height: "35px",
                borderRadius: "20px",
                marginTop: "5px",
                marginBottom: "30px",
              }}
              onClick={handleFormsubmit}
            >
              {loading ? (
                <BarLoader
                  as="span"
                  size={14}
                  animation="grow"
                  color="#ffffff"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Col>
        </div>
      </div>

      <UIModal
        show={show}
        setShow={setShow}
        padding={false}
        close={true}
        body={
          <LandingPageWrap>
            <Contact />
          </LandingPageWrap>
        }
      />
    </>
  );
};

export default OTPConfirmation;
