import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Forms } from "..";
import { api } from "../../../../Services/api";
import { Validators } from "../../../../Utilities/validator";
import Form from "react-bootstrap/Form";

const LoginForm = ({
  setIsotpform,
  setpendingUser,
  setPageView,
  setShow,
  ...props
}) => {
  const initially = props.initialState;

  const [invalidUser, setInvalidUser] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(initially);
  const [changeInForm, setChangeInForm] = useState(initially);
  const [invalid, setinvalid] = useState("");

  const SubmitLogin = (form, valid) => {
    seterrormessage("");
    setInvalidUser("");
    if (valid) {
      setLoading(true);
      form.password = form.password.trim();
      const obj = {
        emailId: form.email,
        password: form.password,
      };
      api
        .post("/brokerpayuserauth", obj)
        .then(function (response) {
          console.log("loginResponse", response);
          if (response.data.status === true) {
            setSubmitted(true);
            setLoading(false);
            setIsotpform({
              status: true,
              emailId: form.email,
            });
            setLoading(false);
          }
        })
        .catch(function (error) {
          console.log("loginResponse", error.response);
          if (error.response.status === 402) {
            seterrormessage(error.response.data.message);
            setinvalid("1");
          }
          if (error.response.status === 404) {
            setInvalidUser(error.response.data.message);
            setinvalid("2");
          }
          if (error.response.status === 401) {
            setpendingUser(true);
          }
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="loginFormScreens flex-fill bg-white">
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label
            style={{
              paddingTop: "20px",
              fontSize: "32px",
              color: " #0094FF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <b>Log In</b>
          </Form.Label>
        </Form.Group>

        {errormessage ? (
          <Alert className="m-2 p-1 text-center" variant="danger">
            {errormessage}
          </Alert>
        ) : (
          <></>
        )}
        {invalidUser ? (
          <div className="invaliduser">
            <div className="center text-medium">
              Sorry, We don't recognise that email address.
            </div>
            <div className="center text-medium">
              <div className="text-bold me-1">New here?</div>
              You can
              <div onClick={() => setShow(true)} className="primary pointer ms-2">
                Sign up here.
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <Forms
          title={""}
          formArr={[
            {
              label: "Enter Email",
              name: "email",
              type: "email",
              placeholder: "Enter email",
              maxLength: "",
              validators: [
                {
                  check: Validators.required,
                  message: <p>This is a required field</p>,
                },
                {
                  check: Validators.email,
                  message: <p>Please enter valid Email ID</p>,
                },
              ],
              error: false,
              errormsg: "",
              value: "",
              show: true,
            },
            {
              label: "Enter Password",
              name: "password",
              type: "password",
              maxLength: "50",
              placeholder: "Enter password",
              validators: [
                {
                  check: Validators.required,
                  message: <p>This is a required field</p>,
                },
                {
                  check: Validators.password,
                  message: <p>Password must be more than 8 letters</p>,
                },
              ],
              error: false,
              errormsg: "",
              value: "",
              show: true,
            },
          ]}
          forgotPassword={true}
          submitBtn={"Log in"}
          onSubmit={SubmitLogin}
          submitted={submitted}
          changeInForm={changeInForm}
          setChangeInForm={setChangeInForm}
          loading={loading}
          invalid={invalid}
          setPageView={setPageView}
          signup={true}
          setShow={setShow}
        />
      </div>
    </>
  );
};

export default LoginForm;
