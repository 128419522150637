import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Brokerpay from "../../../assets/images/logo/brokerpay.svg";
import LoginWrap from "../Login/style";
import LoginBackground from "../../Reuseable/LoginBackground";
import ResetPasswordForm from "../../Reuseable/Forms/ResetPasswordForm";
import React from "react";

export const ResetPassword = () => {


  return (
    <LoginWrap>
      <div >
        <LoginBackground />

        <div className="box-pos" >

          <div className=" container loginPageContent">

            <Container className="container-width ">
              <Link to='/'>
                <img className="logo" alt="User" src={Brokerpay} />
              </Link>
              <div className="bg-white reset-password-box">
                <ResetPasswordForm />
              </div>
            </Container>
          </div>
        </div>

      </div>

    </LoginWrap>

  );
};

