import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import Tables from "../../../../../Reuseable/Tables";
import ScrollContainer from "react-indiana-drag-scroll";

const UserPaymentTable = ({ columns, tbody, tableRef, striped }) => {
  const data2 = { columns, tbody };

  const onWheel = (e) => {
    e.preventDefault();
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;

    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
    });
  };

  const scrollRef = useRef(null);

  return (
    <React.Fragment>
      <Container>
        <ScrollContainer
          hideScrollbars={false}
          onWheel={onWheel}
          ref={scrollRef}
          className="scroll-container-view"
          style={{height: '280px'}}
        >
          <div>
            <Tables props={data2} striped={striped} tableRef={tableRef} />
          </div>
        </ScrollContainer>
      </Container>
    </React.Fragment>
  );
};

export default UserPaymentTable;
