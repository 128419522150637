import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import FirstLoginView from "./style";
import { useSelector } from "react-redux";

const FeeStructure = ({ setShow, show }) => {
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);

  return (
    <>
      <Modal
        className="mt-5"
        centered
        size="lg"
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Body>
          <FirstLoginView>
            <div className="fee-structure">
              <h5 className="primary fw-bold">Fee Structure</h5>
              <p>Application Fee: $10 at the time of request</p>
              <p> Late Repayment Fee: $10 per day</p>
              {brokerdata?.hashchingUser === "yes" ? (
                <p>
                  Brokerpay charges a fee equivalent to 4% of the total
                  commission advance amount (for Hashching brokers only). You
                  may use the fee table below as a guide.
                </p>
              ) : (
                <p>
                  Brokerpay charges a fee equivalent to 5% of the total
                  commission advance amount. You may use the fee table below as
                  a guide.
                </p>
              )}
            </div>
            <div>
              <Table bordered>
                {brokerdata?.hashchingUser === "yes" ? (
                  <tbody>
                    <tr>
                      <td className="table-title">Commission Amount</td>
                      <td className="table-title">Fee</td>
                    </tr>
                    <tr>
                      <td>$1000</td>
                      <td>$50</td>
                    </tr>
                    <tr>
                      <td>$4,000</td>
                      <td>$160</td>
                    </tr>
                    <tr>
                      <td>$6,000</td>
                      <td>$240</td>
                    </tr>
                    <tr>
                      <td>$8,000</td>
                      <td>$320</td>
                    </tr>
                    <tr>
                      <td>$10,000</td>
                      <td>$400</td>
                    </tr>
                    <tr>
                      <td>$12,000</td>
                      <td>$480</td>
                    </tr>
                    <tr>
                      <td>$15,000</td>
                      <td>$600</td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="table-title">Commission Amount</td>
                      <td className="table-title">Fee</td>
                    </tr>
                    <tr>
                      <td>$1,000</td>
                      <td>$50</td>
                    </tr>
                    <tr>
                      <td>$4,000</td>
                      <td>$200</td>
                    </tr>
                    <tr>
                      <td>$6,000</td>
                      <td>$300</td>
                    </tr>
                    <tr>
                      <td>$8,000</td>
                      <td>$400</td>
                    </tr>
                    <tr>
                      <td>$10,000</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td>$12,000</td>
                      <td>$600</td>
                    </tr>
                    <tr>
                      <td>$15,000</td>
                      <td>$750</td>
                    </tr>
                  </tbody>
                )}
              </Table>
              <p className="italic-text">Please note this fee table is not exhaustive and is intended to act only as a guide. </p>
            </div>
            <div className="fee-structure">
              {/* <p className="primary fw-bold">
                Please note that this fee table is not exhaustive and is
                intended to act only as a guide.
              </p> */}
              <p>
                <ul className="list-block">
                  <li className="fee-list">
                    The administration fee is payable at the time of
                    application. All other fees are payable on the due date of
                    the commission advance repayment.
                  </li>
                  <li className="fee-list">
                    In the event of delayed settlement or late repayment, you
                    will be charged a late payment fee of $10 per day the
                    repayment is not received after the due date, unless
                    otherwise agreed upon with the Brokerpay team.
                  </li>
                </ul>
              </p>
            </div>
          </FirstLoginView>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FeeStructure;
