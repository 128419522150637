import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import LogoutButton from "../../../../Reuseable/UIButton/LogoutButton";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { useMedia } from "react-use";
import { Link } from "react-router-dom";

const BrokerProfile = ({ Avatar, setdropdownOpen, setShow }) => {
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);
  const user = useSelector((state) => state.Auth.user);

  const resourceBtn = useMedia("(max-width: 900px)");

  return (
    <>
      <div>
        <div className="pb-2">
          <div className="dropdown-profile">
            <img
              src={brokerdata.profile ? brokerdata.profile : Avatar}
              alt="Profile"
            />
          </div>

          <span className="mt-3">{brokerdata.personname}</span>
          {user && resourceBtn && user.userRole === "Broker" ? (
            <Link to={'resource'} className="popup_resource theme-btn float-end py-1 px-4">Resources</Link>
          ) : null}
        </div>
        <Row className="mt-1">
          <Col className="drop-userid float-start" sm={7}>
            {brokerdata.emailId}
          </Col>
          <Col className="drop-userid float-end" sm={5}>
            Ph: {brokerdata.phoneNumber}
          </Col>
        </Row>
        <Container fluid className="drop-license themegradient my-2">
          <div>{brokerdata.businessName}</div>
          <Row>
            <Col sm={7}>
              <div> Business ABN: {brokerdata.ABN} </div>
            </Col>
            <Col sm={1} className="divider px-1">
              |
            </Col>
            <Col sm={4} className="float-end">
              {brokerdata.licenceNumber ? (
                <div>
                  {brokerdata.licenceNumber.ACL == "" ? (
                    <p>CRN:{brokerdata.licenceNumber.CRN}</p>
                  ) : (
                    <p>ACL: {brokerdata.licenceNumber.ACL}</p>
                  )}
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
        <div
          onClick={() => {
            setShow(true);
            setdropdownOpen(false);
          }}
          className="primary mb-2"
        >
          Change password
        </div>
        <DropdownItem>
          <LogoutButton />
        </DropdownItem>
      </div>
    </>
  );
};

export default BrokerProfile;
