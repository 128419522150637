const regex = {
  email: new RegExp(
    "^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$"
  ),
  number: new RegExp("^[0-9]+$"),
};

export class Validators {
  static email(value, message) {
    // if (value) {
    //    const result = regex.email.test(value);
    //     if (value.toString().length <= 2){
    //         return { error: true, message };
    //     }
    // }
    //  return false;
    if (value) {
      const result = regex.email.test(value);
      if (!result) return { error: true, message };
    }
  }

  static name(value, message) {
    const length = value ? value.toString().length : 0;
    if (length < 2) {
      return { error: true, message };
    }
    return false;
  }

  static validateBsb(value, message) {
    const bsbRegex = /^[0-9]{6}$/;
    if (bsbRegex.test(value) === false) {
      return { error: true, message };
    }
    return false;
  }

  static aggregatorLength(value, message) {
    const length = value ? value.toString().length : 0;
    if (length < 3) {
      return { error: true, message };
    }
    return false;
  }

  static licensenumber(value, message) {
    const length = value ? value.toString().length : 0;
    if (length < 4) {
      return { error: true, message };
    }
    return false;
  }

  static abn(value, message) {
    const length = value ? value.toString().length : 0;
    if (length === 11) {
      return false;
    }
    return { error: true, message };
  }

  static required(value, message) {
    if (!value || !value.toString().trim().length) {
      return { error: true, message };
    }
    return false;
  }

  static password(value, message) {
    const length = value ? value.toString().length : 0;
    if (length <= 7) {
      return { error: true, message };
    }
  }

  static number(value, message) {
    const length = value ? value.toString().length : 0;

    if (length > 0) {
      const result = regex.number.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static validatePassword(value, message) {
    const length = value ? value.toString().length : 0;
    if (length) {
      const regexUpperCase = /[A-Z]/;
      const regexLowerCase = /[a-z]/;
      const regexNumeric = /[0-9]/;
      const regexSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

      const isLengthValid = value.length >= 8;
      const isUpperCaseValid = regexUpperCase.test(value);
      const isLowerCaseValid = regexLowerCase.test(value);
      const isNumericValid = regexNumeric.test(value);
      const isSpecialCharValid = regexSpecialChar.test(value);

      let validate =
        isLengthValid &&
        isUpperCaseValid &&
        isLowerCaseValid &&
        isNumericValid &&
        isSpecialCharValid;
      if (validate === false) {
        return { error: true, message };
      }
    }
    return false;
  }

  static phonenumber(value, message) {
    const length = value ? value.toString().length : 0;
    if (value) {
      if (length > 0) {
        if (value[0] + value[1] === "04") {
          return false;
        }
      }
      return { error: true, message };
    }
  }

  static phonenumberLength(value, message) {
    const length = value ? value.toString().length : 0;
    if (value) {
      if (length === 10) {
        return false;
      }
      return { error: true, message };
    }
  }
}

export const validateInput = (validators, value) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i].check(value, validators[i].message);
      if (error) {
        return error;
      }
    }
  }
  return false;
};
