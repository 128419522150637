export const SignupValidation = (value) => {
  let errors = {};
  if (value.licencenumber === "") {
    errors.licencenumber = "This is a required field";
  } else if (value.licencenumber.length < 4) {
    errors.licencenumber = "Please enter a valid license number";
  } else {
    errors.licencenumber = "";
  }
  if (value.address === "") {
    errors.address = "This is a required field";
  } else {
    errors.address = "";
  }
  if (value.aggregator === "") {
    errors.aggregator = "This is a required field";
  } else if (value.aggregator.length < 2) {
    errors.aggregator = "Please enter a valid aggregator name";
  } else {
    errors.aggregator = "";
  }
  if (value.business_abn === "") {
    errors.business_abn = "This is a required field";
  } else if (value.business_abn.length < 11) {
    errors.business_abn = "Please enter a valid ABN";
  } else {
    errors.business_abn = "";
  }
  if (value.businessname === "") {
    errors.businessname = "This is a required field";
  } else if (value.businessname.length < 2) {
    errors.businessname =
      "Your business name should be between 2 and 25 characters.";
  } else {
    errors.businessname = "";
  }

  return errors;
};
