import React, { useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Alerts from "@mui/material/Alert";
import Tables from "../../../../Reuseable/Tables";
import Searchbox from "../../../../Reuseable/Searchbox";
import { useSelector, useDispatch } from "react-redux";
import { api } from "../../../../../Services/api";
import { setAllUsers } from "../../../../../redux/Tracking/actionCreator";
import Snackbar from "@mui/material/Snackbar";
import Notes from "../../../../Reuseable/Notes";
import ConfirmSubmission from "../../../../Reuseable/Notes/ConfirmSubmission";
import { NoDataFound } from "../../../../Reuseable/NoDataFound";
import UIModal from "../../../../Reuseable/UIModal";
import { Money } from "../../../../Reuseable/NumberFormatter";
import { getCurrentTime } from "../../../../Reuseable/CurrentDateTime";

const NewUser = ({ title, getAllActivityLog, setInvisible }) => {
  const dispatch = useDispatch();
  const AllUsers = useSelector((state) => state.Tracking.allUsers);
  const authToken = useSelector((state) => state.Auth.authToken);
  const permissions = useSelector((state) => state.Tracking.permissions);

  const Newusers = AllUsers.filter((val) => {
    if (val.status === "Unverified") {
      return val;
    }
  });

  const [searchterm, setSearchterm] = useState("");
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [content, setcontent] = useState("");
  const [alert, setAlert] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setItem] = useState([]);
  const [ActivityNotes, setActivityNotes] = useState([]);
  const [action, setAction] = useState("");
  const [viewData, setViewData] = useState([]);

  const columns = [
    "Name",
    "User ID",
    "Email",
    "Phone No",
    "User Limit",
    "Action",
  ];


  const ApproveUser = async () => {
    const index = AllUsers.findIndex((data) => data.userId === item.userId);
    if (item.brokerLimit == "" || item.brokerLimit == undefined) {
      setOpen(true);
    } else {
      setloading(true);
      try {
        let obj = {
          userId: item.userId,
          emailId: item.emailId,
          notes: ActivityNotes,
          approvedDate: getCurrentTime(),
          logDateTime: getCurrentTime(),
        };
        let url = "/brokerpayuserauth/approval";
        const response = await api.post(url, obj, {
          headers: {
            authorizationtoken: `${authToken}`,
          },
        });
        if (response.data.status === true) {
          AllUsers[index].status = "verified";
          AllUsers[index].approvedDate = obj.approvedDate;
          dispatch(setAllUsers(AllUsers));
          getAllActivityLog();
          setloading(false);
          setIsModalOpen(false);
          setAlert(false);
          setcontent(
            `New User Request for ${AllUsers[index].personname} has been approved!`
          );
          setConfirmOpen(true);
        }
      } catch (error) {
        setloading(false);
        console.log("SetBrokerLimit [error]-->", error);
      }
    }
  };

  const DeclineNewUser = async () => {
    const index = AllUsers.findIndex((data) => data.userId === item.userId);
    setloading(true);
    try {
      let obj = {
        userId: item.userId,
        emailId: item.emailId,
        notes: ActivityNotes,
        logDateTime: getCurrentTime(),
      };
      let url = "/brokerpayuserauth/decline";
      const response = await api.post(url, obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status === true) {
        AllUsers.splice(index, 1);
        dispatch(setAllUsers(AllUsers));
        getAllActivityLog();
        setIsModalOpen(false);
        setloading(false);
        setAlert(true);
        setcontent(
          `New User Request for ${item.personname} has been declined!`
        );
        setConfirmOpen(true);
      }
    } catch (error) {
      console.log("SetBrokerLimit [error]-->", error);
      setloading(false);
    }
  };



  const tbody = (
    <tbody>
      {Newusers.filter((val) => {
        if (searchterm === "") {
          return val;
        } else if (
          val.firstName.toLowerCase().includes(searchterm.toLowerCase()) ||
          val.lastName.toLowerCase().includes(searchterm.toLowerCase()) ||
          val.userId.toLowerCase().includes(searchterm.toLowerCase())
        ) {
          return val;
        }
      }).map((item) => (
        <tr className="pointer">
          <td
            onClick={() => {
              setShow(true);
              setViewData(item);
            }}
          >
            {item.firstName + " " + item.lastName}
          </td>
          <td
            onClick={() => {
              setShow(true);
              setViewData(item);
            }}
          >
            {item.userId}
          </td>
          <td
            onClick={() => {
              setShow(true);
              setViewData(item);
            }}
          >
            {item.emailId}
          </td>
          <td
            onClick={() => {
              setShow(true);
              setViewData(item);
            }}
          >
            {item.phoneNumber}
          </td>
          <td>{Money(item.brokerLimit)}</td>
          <td>
            <Button
              disabled={
                permissions && permissions.Account.approveAccounts
                  ? false
                  : true
              }
              onClick={() =>
                item.brokerLimit === "" || item.brokerLimit === undefined
                  ? setOpen(true)
                  : (setIsModalOpen(true), setItem(item), setAction("Approve"))
              }
              className="theme-btn mx-1 p-1 px-3"
            >
              Approve
            </Button>
            <Button
              disabled={
                permissions && permissions.Account.approveAccounts
                  ? false
                  : true
              }
              onClick={() => {
                setIsModalOpen(true);
                setItem(item);
                setAction("Decline");
              }}
              className="cancel-btn mx-1 p-1 px-3"
            >
              Decline
            </Button>
          </td>
        </tr>
      ))}
    </tbody>
  );

  let Body = (
    <div className="mt-3">
      <Row>
        <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
          <b>Full Name:</b>
        </Col>
        <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
          <span className="test-wrap">{viewData.personname}</span>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
          <b>E-mail:</b>
        </Col>
        <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
          <span className="test-wrap">{viewData.emailId}</span>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
          <b>Phone No:</b>
        </Col>
        <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
          <span className="test-wrap">{viewData.phoneNumber}</span>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
          <b>Business Name:</b>
        </Col>
        <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
          <span className="test-wrap">{viewData.businessName}</span>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
          <b>ABN:</b>
        </Col>
        <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
          <span className="test-wrap">{viewData.ABN}</span>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
          <b>Aggregator:</b>
        </Col>
        <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
          <span className="test-wrap">
            {viewData.aggregator ? viewData.aggregator : "-"}
          </span>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
          <b>Licence Type:</b>
        </Col>
        <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
          <span className="test-wrap">
            {viewData?.licenceNumber?.ACL.length ? <>ACL</> : <>CRN</>}
          </span>
        </Col>
        <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
          <b>CRN/ACL:</b>
        </Col>
        <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
          <span className="test-wrap">
            {viewData?.licenceNumber?.ACL.length ? (
              <>{viewData.licenceNumber?.ACL}</>
            ) : (
              <>{viewData.licenceNumber?.CRN}</>
            )}
          </span>
        </Col>
      </Row>
      <Row>
        <div className="center">
          <Button
            disabled={
              permissions && permissions.Account.approveAccounts ? false : true
            }
            onClick={() =>
              item.brokerLimit === "" || item.brokerLimit === undefined
                ? setOpen(true)
                : (setIsModalOpen(true),
                  setItem(item),
                  setAction("Approve"),
                  setShow(false))
            }
            className="theme-btn mx-3 p-1 px-3"
          >
            Approve
          </Button>
          <Button
            disabled={
              permissions && permissions.Account.approveAccounts ? false : true
            }
            onClick={() => {
              setIsModalOpen(true);
              setItem(item);
              setAction("Decline");
              setShow(false);
            }}
            className="cancel-btn mx-3 p-1 px-3"
          >
            Decline
          </Button>
        </div>
      </Row>
    </div>
  );

  const data2 = { columns, tbody };
  return (
    <React.Fragment>
      {Newusers == "" || Newusers == undefined ? (
        <NoDataFound body="No new users awaiting approval." />
      ) : (
        <Container className="user-container">
          <Row>
            <div className="search-title">
              {title}
              <div className="search-box ">
                <Searchbox
                  placeholder="Enter name, User ID"
                  handleSearch={setSearchterm}
                />
              </div>
            </div>
          </Row>
          <div>
            <Tables props={data2} striped={false} />
          </div>
        </Container>
      )}
      <Notes
        open={isModalOpen}
        setOpen={setIsModalOpen}
        setNotes={setActivityNotes}
        notes={ActivityNotes}
        Submit={action === "Approve" ? ApproveUser : DeclineNewUser}
        loading={loading}
      />
      <ConfirmSubmission
        open={confirmOpen}
        setOpen={setConfirmOpen}
        content={content}
        alert={alert}
      />
      <UIModal
        show={show}
        setShow={setShow}
        title={"New user request"}
        body={Body}
        size={"lg"}
        close={true}
      />
    </React.Fragment>
  );
};

export default NewUser;
