import Styled from "styled-components";

const NewUsersWrap = Styled.div`

.table>tbody>tr:hover>* { 
  --bs-table-accent-bg: none !important;
  background: none  !important;
}
`;

export default NewUsersWrap;
