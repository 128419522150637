import styled from "styled-components";

export const AccountHistoryWrap = styled.div`

.not-equal {
    color: #FF0000;
    padding-left: 5px;
}

.label {
    font-size: 18px;
    padding-bottom: 10px;
}

.calender-icon {
    color: #0094FF;
    opacity: .4;
}

.date-picker-style {
    background-color: #F6FBFF;
    width: 100%;
    border: 1px solid rgb(88 178 242);
    margin-top: 5px;
    padding: 0px 5px 0px 5px;
    height: 38px;
    border-radius: 5px;
    font: normal normal normal 18px/21px Roboto;
    appearance: none;
}

.prim-col {
    color: #0094FF;
}
.NoData{
   font-weight: 600;
   font-size: 16px;
}
.over-due {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pending {
    color: #FD8800;
    padding-top: 15px;
}

.approved {
    color: #1dbc00;
    padding-top: 15px;
}

.not-approved {
    color: #FF0000;
    padding-top: 15px;
}

.over-due-status {
    padding-top: 15px;
}

.download {
    float: right;
    border-radius: 20px;
    margin-top: 5px;
}

.arrow-down {
    position: absolute;
    right: 5px;
    top: 11px;
    z-index: -1;
    color: #0094FF;
}

.select-style {
    width: 100%;
    opacity: .7;
    font: normal normal normal 14px/21px Roboto;
    color: rgb(150 154 158);
    padding-left: 5px;
    height: 38px;
    border: 1px solid #0094ff59;
    background: #F6FBFF 0% 0% no-repeat padding-box;
    border-radius: 5px !important;
    appearance: none;
}

.searchbar, .date-picker{
    margin-top: 0px !important;
}

.select-style:hover {
    border-color: #40a9ff;
}



`