import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import UIModalView from '../../UIModal/style';

const ConfirmSubmission = ({ open, setOpen, content, alert }) => {


    return (
        <Modal
            show={open}
            onHide={() => setOpen(false)}
            centered
        >
            <UIModalView>
                <Modal.Body>
                    <div className={alert ? 'declined' : 'primary'}>
                        <div className='center fw-bold'>{content}</div>
                    </div>
                </Modal.Body>
            </UIModalView>
        </Modal>
    );
}

export default ConfirmSubmission;