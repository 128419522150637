import Styled from 'styled-components';

const ResourceWrap = Styled.div`

.resource-content{
    color: #303030;
}

.resource_sub_title{
    color: #303030;
opacity: 0.71;
}

.resource{
    font-size: 18px;
}
.card_img{
    position: absolute;
    right: 20px;
}

.heading_3{
    color: #F2BA00
}

.card{
    height: 100%;
}

.btn_row{
    position: relative;
    bottom: 0;
}
.modal_view_title{
    font-size: 25px;
}

table th, table td {
    border: 1px solid #0094FF;
    text-align: center;
    background-color: #DBEFFF;
  }
 td{
    width: 50%;
 }

 .table-title{
    border: none;
    background: transparent;
    box-shadow: none;
 }

 .blue_content{
    background: #0A4374 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000015;
    color: white !important;
 }

 .exclusive{
    margin-bottom: 0px !important;
 }

 .hashching_theme{
    color: #FF9B15;
 }

 .fee-list{
    margin: 20px;
    list-style: disc !important;
 }

 .pdf-container {
   width: 100%;
   height: 100vh;
   overflow: hidden;
 }
 
 .pdf-iframe {
   width: 100%;
   height: 100%;
   border: none;
 }

 .exclusive_content{
    color: white;
 }

 .email{
    color: white !important;
 }

 .row{
    margin: 0 !important;
 }

   
`;

export default ResourceWrap;