import React, { useState, useEffect } from "react";
import { AuBankAccountElement } from "@stripe/react-stripe-js";
import Styled from "styled-components";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import BarLoader from "react-spinners/ClipLoader";
import { Onlytext } from "../../../validators";

const FormWrap = Styled.div`

input,
.StripeElement {
  height: 40px;
  padding: 10px 12px;

  color: #32325d;
  background-color: white;
  border: 1px solid #0094ff52;
  border-radius: 5px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

label{
  width: 100%;
  color: #0094FF;
}
.form-control {
  height: 40px;
  padding: 10px 12px;
  font-size: 15px;
  border:none;
  border:1px solid #0094ff52;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.form-control::placeholder {
 font-size: 16px;
 opacity: 0.6;
}

.feild_error{
  border: 1px solid #fa755a !important;
}

input:focus,
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
`;

const AU_BANK_ACCOUNT_STYLE = {
  base: {
    color: "#32325d",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
    ":-webkit-autofill": {
      color: "#32325d",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    ":-webkit-autofill": {
      color: "#fa755a",
    },
  },
};

const AU_BANK_ACCOUNT_ELEMENT_OPTIONS = {
  style: AU_BANK_ACCOUNT_STYLE,
  disabled: false,
  hideIcon: false,
  iconStyle: "solid", // or "solid"
};

export default function BecsForm({
  onSubmit,
  disabled,
  errors,
  setErrors,
  loading,
  onBoarding,
}) {
  const onChange = (e) => {
    let name = e.target.name;
    if (name === "accountholderName") {
      if (!e.target.value) {
        setErrors({ ...errors, accountholderName: "This is a required field" });
      } else {
        if (e.target.value.length < 3) {
          setErrors({
            ...errors,
            accountholderName:
              "Please enter a name between 3 and 30 characters",
          });
        } else {
          setErrors({ ...errors, accountholderName: "" });
        }
      }
    } else {
      if (name === "bankName") {
        if (!e.target.value) {
          setErrors({ ...errors, bankName: "This is a required field" });
        } else {
          if (e.target.value.length < 3) {
            setErrors({
              ...errors,
              bankName: "Please enter a bank name between 3 and 30 characters",
            });
          } else {
            setErrors({ ...errors, bankName: "" });
          }
        }
      }
    }
  };

  const submitForm = (e) => {
    const accountholderName = e.target["accountholderName"];
    const bankName = e.target["bankName"];

    if (accountholderName.value) {
      setErrors({ ...errors, accountholderName: "This is a required field" });
    } else {
      if (accountholderName.value.length < 3) {
        setErrors({
          ...errors,
          accountholderName: "Please enter a name between 3 and 30 characters",
        });
      } else {
        setErrors({ ...errors, accountholderName: "" });
      }
    }
    if (bankName.value) {
      setErrors({ ...errors, bankName: "This is a required field" });
    } else {
      if (bankName.length < 3) {
        setErrors({
          ...errors,
          bankName: "Please enter a bank name between 3 and 30 characters",
        });
      } else {
        setErrors({ ...errors, bankName: "" });
      }
    }
  };

  return (
    <FormWrap>
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-2">
          <label>Account Name</label>
          <Form.Control
            name="accountholderName"
            className={errors.accountholderName && "feild_error"}
            placeholder="Account Name"
            maxLength={30}
            onKeyDown={(e) => Onlytext(e)}
            onChange={onChange}
          />
          {errors.accountholderName && (
            <p className="error">{errors.accountholderName}</p>
          )}
        </Form.Group>

        <Form.Group className="mb-2">
          <label>Bank Name</label>
          <Form.Control
            className={errors.bankName && "feild_error"}
            name="bankName"
            type="text"
            placeholder="Bank Name"
            maxLength={30}
            onKeyDown={(e) => Onlytext(e)}
            onChange={onChange}
          />
          {errors.bankName && <p className="error">{errors.bankName}</p>}
        </Form.Group>

        <div className="form-row">
          <label>
            Bank Account
            <AuBankAccountElement
              onChange={(event) => {
                if (event.error) {
                  setErrors({
                    ...errors,
                    bsb: event.error.message,
                  });
                } else {
                  setErrors({
                    ...errors,
                    bsb: "",
                  });
                }
              }}
              options={AU_BANK_ACCOUNT_ELEMENT_OPTIONS}
            />
          </label>
          {errors.bsb && <p className="error">{errors.bsb}</p>}
        </div>
        <div id="bank-name"></div>
        <div id="error-message"></div>
        <div className="center">
          <button
            className="theme-btn center px-5 py-1 mt-4"
            type="submit"
            disabled={disabled}
          >
            {loading ? (
              <BarLoader
                as="span"
                size={14}
                animation="grow"
                color="#ffffff"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>{onBoarding ? "Next" : "Save"}</>
            )}
          </button>
        </div>
      </Form>
    </FormWrap>
  );
}
