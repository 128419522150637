import React, { useState } from "react";
import { Row, Col, Card, Modal, Container, Button } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import Form from 'react-bootstrap/Form';
import { Validators } from "../../../../../Utilities/validator";
import UICardView from "../../style";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import OTPInput, { ResendOTP } from "otp-input-react";
import BarLoader from 'react-spinners/ClipLoader';
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../../../redux/Auth/actionCreator";
import { useNavigate } from "react-router-dom";
import { resetTracking } from "../../../../../redux/Tracking/actionCreator";
import { api } from "../../../../../Services/api";
import { Alert } from "react-bootstrap";

const DeleteAccountOTPConfirmation = ({
    deleteShow,
    setDeleteShow,
    ConfirmDeleteAccount,
    setShowContact
}) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const brokerdata = useSelector((state) => state.Tracking.brokerdata);
    const authToken = useSelector((state) => state.Auth.authToken);

    const [values, setValues] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [otpResponse, setOtpResponse] = useState("");

    const errorValidation = (values) => {
        let errors = {};
        if (!values.otp) {
            errors.otp = "This is a required field"
        } else if (values.otp.length < 6) {
            errors.otp = "This is a required field "
        }
        return errors;
    }

    const handleChange = (otp) => {
        setValues({ otp });
    }

    const logoutUser = () => {
        let result = {
            isLoggedIn: false,
            authToken: "",
            user: ""
        }
        dispatch(userLogout(result));
        dispatch(resetTracking());
        navigate("/");
    };

    const SubmitOTP = (event) => {
        event.preventDefault();
        setErrors(errorValidation(values));
        setLoading(true);
        const obj = {
            userId: brokerdata.userId,
            emailId: brokerdata.emailId,
            otp: values.otp,
        };
        api
            .post("/deleteuser", obj, {
                headers: {
                  'authorizationtoken': `${authToken}`,
                }
              })
            .then(function (response) {
                if (response.data.status) {
                    setLoading(false);
                    logoutUser()
                } else {
                    setLoading(false);
                    setOtpResponse(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log('SubmitOTP [error]-->', error)
            });
    };


    return (
        <>
            <Modal show={deleteShow} centered onHide={() => setDeleteShow(false)}>
                <UICardView>
                    <div className="body center">
                        <div className="loginFormScreens flex-fill bg-white" >
                        {otpResponse && (
            <Alert
              className="m-2 mb-2 p-1 text-center login-otp-alert"
              variant="info"
            >
              {otpResponse}
            </Alert>
          )}
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <h4 className="center my-3">
                                    Enter Verification Code
                                </h4>

                                <OTPInput
                                    value={values.otp}
                                    className="otp-input center bg-white mx-2 text-lg focus:outline-none focus:shadow-outline  border-gray-300 rounded-lg  block w-full appearance-none leading-normal"
                                    name="otp"
                                    onChange={handleChange}
                                    otpType="number"
                                    OTPLength={6}
                                    separator={
                                        <span></span>
                                    }
                                />
                                {
                                    errors.otp &&
                                    <p
                                        className="center declined"
                                    >
                                        <ReportProblemIcon
                                            style={{
                                                width: "12px",
                                                marginTop: "-3px"
                                            }}
                                        />
                                        {errors.otp}
                                    </p>
                                }
                            </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label
                                            className="pointer p-text ms-4"
                                            onClick={ConfirmDeleteAccount}
                                        >
                                            Resend Code
                                        </Form.Label>

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label
                                            className="p-text pointer me-46"
                                            onClick={() => {
                                                setShowContact(true);
                                                setDeleteShow(false)
                                            }}
                                            style={{
                                                float: "right"
                                            }}
                                        >Contact Support
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Col
                                className="center"
                            >
                                <Button
                                    style={{
                                        background: '#0094FF',
                                        border: 'none',
                                        width: "110px",
                                        height: '35px',
                                        borderRadius: "20px",
                                        marginTop: "5px",
                                        marginBottom: "30px"
                                    }}
                                    onClick={SubmitOTP}
                                >
                                    {
                                        loading ?
                                            (
                                                <BarLoader
                                                    as="span"
                                                    size={14}
                                                    animation="grow"
                                                    color='#ffffff'
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            )
                                            :
                                            'Proceed'
                                    }
                                </Button>
                            </Col>
                        </div>
                    </div>
                </UICardView>
            </Modal>

        </>
    )
}
export default DeleteAccountOTPConfirmation;
