import React from "react";
import UIGraphView from "./style";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

const UIGraph = ({ data, stroke, title }) => {

  return (
    <>
      <UIGraphView>
        <p className="graph-title">{title}</p>
        <div className="white-bg center">
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" axisLine={{ stroke: '#0094FF', strokeWidth: 2  }} />
            <YAxis tickFormatter={(tick) => `$${tick.toLocaleString()}`} />
            <Tooltip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div
                        style={{
                          backgroundColor: "#95d7f985",
                          padding: "10px",
                          color: "#0094FF",
                        }}
                      >
                        {`$${payload[0].value.toLocaleString()}`}
                      </div>
                    );
                  }
                  return null;
                }}
              />
            <Legend />
            <Line
              dataKey="$"
              stroke={stroke}
              fill={stroke}
              activeDot={{
                r: 8
              }}
              strokeWidth={1}
            />
          </LineChart>
          </ResponsiveContainer>
        </div>
      </UIGraphView>
    </>
  )
}
export default UIGraph;
