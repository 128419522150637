import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CreateAdministrator from "./CreateAdministrator";
import ViewAdministrator from "./ViewAdministrator";
import { api } from "../../../../../Services/api";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import SettingsWrap from "../style";
import BarLoader from "react-spinners/ClipLoader";

const ManageRole = ({ view, setView, getAdministrator, administratorData }) => {
  const authToken = useSelector((state) => state.Auth.authToken);
  const permissions = useSelector(
    (state) => state.Tracking.permissions.Administrative
  );

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [message, setMessage] = useState("");
  const [modalView, setModalView] = useState("reset");

  const [viewAdministrator, setViewAdministrator] = useState("");

  const AdministrativeArr = [
    { name: "Add new administrator", id: "addnewAdministrator" },
    { name: "Change administrator roles", id: "changeAdministratorRoles" },
    { name: "View administrator roles", id: "viewAdministratorRoles" },
    { name: "Edit user roles", id: "edituserrfoles" },
    { name: "View activity log", id: "viewActivityLog" },
    { name: "View User reports", id: "viewUserReports" },
    { name: "View payment reports", id: "viewpaymentsReports" },
    { name: "View settings", id: "ViewSettings" },
    { name: "View dashboard", id: "viewDashboard" },
  ];
  const Accountarr = [
    { name: "View user accounts", id: "viewuserAccount" },
    { name: "View user details", id: "viewUsercontactDetails" },
    { name: "Reset user password", id: "resetUserPassowrd" },
    { name: "Approve accounts", id: "approveAccounts" },
    { name: "Delete accounts", id: "deleteAccounts" },
    {
      name: "Update user profile information",
      id: "updateuserProfileinformation",
    },
  ];
  const Payments = [
    { name: "View Request", id: "viewRequests" },
    { name: "View repayments", id: "viewRepayemnts" },
    { name: "Set max limit", id: "setMaxlimit" },
    { name: "Approve/decline requests", id: "approveDeclineRequests" },
    {
      name: "Manage user payment information",
      id: "manageuuserPayemntsInformation",
    },
  ];

  const Onlytext = (e) => {
    const re = /^[A-Za-z]+$/;
    if (e.key === " " || re.test(e.key)) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const resetpassword = async (data) => {
    setLoading(true);
    setMessage(false);
    let obj = {
      emailId: data.emailId,
      userId: data.userId,
      administrator: true
    };
    await api
      .post("/brokerpayuserauth/forgotpassword", obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          setLoading(false);
          setMessage(
            "Reset passowrd mail has sent to respective administrator"
          );
          setShow(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("resetpassword [error]-->", error.response);
      });
  };

  const deleteAdministrator = async (data) => {
    setLoading(true);
    setMessage(false);
    await api
      .delete("/deleteuser/" + data.userId, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          setLoading(false);
          setMessage(`${data.emailId} has been deleted `);
          setShow(false);
          getAdministrator();
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("resetpassword [error]-->", error.response);
      });
  };

  return (
    <React.Fragment>
      {view === "dataView" ? (
        <Container className="team-data">
          <Row>
            {permissions && permissions.addnewAdministrator ? (
              <Col sm={6} md={6} lg={4} xxl={3}>
                <Card
                  className="add-card center pointer"
                  onClick={() => setView("addNew")}
                >
                  <div className="plus">+</div>
                  <div>Add new</div>
                </Card>
              </Col>
            ) : null}
            {administratorData &&
              administratorData.map((data) => (
                <Col sm={6} md={6} lg={4} xxl={3}>
                  <Card
                    onClick={() =>
                      permissions && permissions.viewAdministratorRoles
                        ? (setView("ViewData"), setViewAdministrator(data))
                        : null
                    }
                    className={
                      permissions && permissions.viewAdministratorRoles
                        ? `pointer data-card`
                        : `data-card`
                    }
                  >
                    <div className="primary">
                      <div className="username">
                        <div
                          className={
                            permissions && permissions.viewAdministratorRoles
                              ? `pointer d-inline-flex`
                              : ` d-inline-flex`
                          }
                        >
                          {data.personname}
                        </div>
                        <Dropdown onClick={(e) => e.stopPropagation()} className="float-end  d-inline-flex">
                          <Dropdown.Toggle
                            className="drop-menu float-end"
                            id="dropdown-basic"
                          >
                            <MoreVertIcon className="primary" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className="primary"
                              onClick={(e) => {
                                setShow(true);
                                setData(data);
                                setModalView("reset");
                                e.stopPropagation();
                              }}
                            >
                              Reset Password
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="primary"
                              onClick={(e) => {
                                setShow(true);
                                setData(data);
                                setModalView("delete");
                                e.stopPropagation();
                              }}
                            >
                              Delete Account
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div>{data.userRole}</div>
                    <div className="team-email">Email: {data.emailId}</div>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      ) : view === "ViewData" ? (
        <ViewAdministrator
          AdministrativeArr={AdministrativeArr}
          Accountarr={Accountarr}
          Payments={Payments}
          viewAdministrator={viewAdministrator}
          getAdministrator={getAdministrator}
          Onlytext={Onlytext}
        />
      ) : (
        <CreateAdministrator
          AdministrativeArr={AdministrativeArr}
          Accountarr={Accountarr}
          Payments={Payments}
          setView={setView}
          getAdministrator={getAdministrator}
          Onlytext={Onlytext}
        />
      )}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="p-2 py-4">
          <SettingsWrap>
            <div className="center">
              {modalView === "delete"
                ? "Are you sure you want to delete this Administrator?"
                : "Are you sure you want to reset password?"}
            </div>
            <div className="center my-3">
              <Button
                className="theme-btn2 me-2 resetpassword-btn"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
              <Button
                className="theme-btn ms-2 resetpassword-btn"
                onClick={() =>
                  modalView === "delete"
                    ? deleteAdministrator(data)
                    : resetpassword(data)
                }
              >
                {loading ? (
                  <BarLoader
                    as="span"
                    size={14}
                    animation="grow"
                    color="#00C6FF"
                    role="status"
                    aria-hidden="true"
                  />
                ) : modalView === "delete" ? (
                  "Delete"
                ) : (
                  "Reset"
                )}
              </Button>
            </div>
          </SettingsWrap>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ManageRole;
