import React, { useState } from "react";
import { Container } from "react-bootstrap";
import RequestWrap from "./style";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import NewRequest from "./Newrequest";
import AllRequest from "./AllRequest";
import { useSelector } from "react-redux";

const Requests = ({ getRequestData, getAllActivityLog, getAllUserData }) => {
  const allRequest = useSelector((state) => state.Tracking.allRequest);
  const NewRequestData = allRequest.filter((val) => {
    if (val.status.toLowerCase() === "pending") {
      return val;
    }
  });

  const [key, setKey] = useState(
    NewRequestData && NewRequestData.length ? "new" : "all"
  );

  console.log()

  return (
    <React.Fragment>
      <RequestWrap>
        <Container>
          <div className="tab-row">
            <div className="back-link">Requests</div>

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="tab-group mb-3"
            >
              <Tab
                className={key === "new" ? "active" : ""}
                eventKey="new"
                title="New requests"
              >
                <NewRequest
                  getRequestData={getRequestData}
                  getAllActivityLog={getAllActivityLog}
                  getAllUserData={getAllUserData}
                />
              </Tab>
              <Tab
                className={key === "all" ? "active" : ""}
                eventKey="all"
                title="All requests"
              >
                <AllRequest />
              </Tab>
            </Tabs>
          </div>
        </Container>
      </RequestWrap>
    </React.Fragment>
  );
};

export default Requests;
