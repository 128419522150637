import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Tables from "../../../../Reuseable/Tables";
import Searchbox from "../../../../Reuseable/Searchbox";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "react-datepicker/dist/react-datepicker.css";
import { InputGroup } from "react-bootstrap";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import { Paginate } from "../../../../Reuseable/Paginate";
import { NoDataFound } from "../../../../Reuseable/NoDataFound";
import { format } from "date-fns";
import CancelIcon from "@mui/icons-material/Cancel";
import { Money } from "../../../../Reuseable/NumberFormatter";
import Calender from "../../../../../assets/images/Calender.svg";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Sort from "../../../../Reuseable/SortIcon";

const AllRequest = ({}) => {
  const allRequest = useSelector((state) => state.Tracking.allRequest);
  const allUsers = useSelector((state) => state.Tracking.allUsers);

  const [searchterm, setSearchterm] = useState("");
  const [searchStatus, setSearchStatus] = useState("default");
  const [sortcolumn, setSortcolumn] = useState("");
  const [amountSort, setAmountSort] = useState(false);
  const [requestStatus, setrequestStatus] = useState(false);
  const [repaymentStatus, setrepaymentStatus] = useState(false);
  const [repaymentDate, setrepaymentDate] = useState(false);
  const [date, setDate] = useState("");
  const [currentPage, setCurrentpage] = useState(1);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  // const formatDate = (date) => new Date(date.split('-').reverse().join('-')).toISOString();
  const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");

  const RequestData = allRequest
    .sort((a, b) => {
      if (
        new moment(a.requestDate).format("YYYYMMDD") <
        new moment(b.requestDate).format("YYYYMMDD")
      )
        return 1;
      else return -1;
    })
    .sort((item1, item2) => {
      if (sortcolumn === "amount") {
        if (amountSort) {
          if (parseInt(item1.commissionAdvance) < parseInt(item2.commissionAdvance))
            return -1;
          else return 1;
        } else {
          if (parseInt(item1.commissionAdvance) < parseInt(item2.commissionAdvance)) return 1;
          else return -1;
        }
      } else if (sortcolumn === "requeststatus") {
        if (requestStatus) {
          if (item1.status < item2.status) return 1;
          else return -1;
        } else {
          if (item1.status < item2.status) return -1;
          else return 1;
        }
      } else if (sortcolumn === "repaymentstatus") {
        if (repaymentStatus) {
          if (item1.repaymentStatus < item2.repaymentStatus) return 1;
          else return -1;
        } else {
          if (item1.repaymentStatus < item2.repaymentStatus) return -1;
          else return 1;
        }
      } else if (sortcolumn === "repaymentdate") {
        if (repaymentDate) {
          if (
            new moment(item1.repaymentDate).format("YYYYMMDD") <
            new moment(item2.repaymentDate).format("YYYYMMDD")
          )
            return 1;
          else return -1;
        } else {
          if (
            new moment(item2.repaymentDate).format("YYYYMMDD") >
            new moment(item1.repaymentDate).format("YYYYMMDD")
          )
            return -1;
          else return 1;
        }
      }
    })
    .filter((val) => {
      if (searchterm === "") {
        return val;
      } else if (
        val.name.toLowerCase().includes(searchterm.toLowerCase()) ||
        val.requestId.toLowerCase().includes(searchterm.toLowerCase())
      ) {
        return val;
      }
    })
    .filter((val) => {
      if (searchStatus === "default") {
        return val;
      } else if (
        val.status.toLowerCase().includes(searchStatus.toLowerCase())
      ) {
        return val;
      }
    })
    .filter((val) => {
      if (date) {
        if (moment(val.requestDate).isBetween(date[0], date[1], "day", "[]")) {
          return val;
        }
      } else {
        return val;
      }
    });

  let todosPerPage = 10;

  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = RequestData.slice(indexOfFirstTodo, indexOfLastTodo);

  const head = (
    <thead>
      <tr>
        <th>Request Date</th>
        <th>Request ID</th>
        <th>User</th>
        <th
          className="pointer"
          onClick={() => {
            setAmountSort(!amountSort);
            setSortcolumn("amount");
          }}
        >
          Amount
          <Sort />
        </th>
        <th
          className="pointer"
          onClick={() => {
            setrequestStatus(!requestStatus);
            setSortcolumn("requeststatus");
          }}
        >
          Request Status
          <Sort />
        </th>
        <th
          className="pointer"
          onClick={() => {
            setrepaymentStatus(!repaymentStatus);
            setSortcolumn("repaymentstatus");
          }}
        >
          Repayment Status
          <Sort />
        </th>
        <th
          className="pointer"
          onClick={() => {
            setrepaymentDate(!repaymentDate);
            setSortcolumn("repaymentdate");
          }}
        >
          Repayment Date
          <Sort />
        </th>
      </tr>
    </thead>
  );

  const tbody = (
    <tbody>
      {currentTodos.map((item) => {
        let userData = allUsers.find((e) => e.userId === item.requestedBy);
        if (userData) item.user = userData.personname;
        else item.user = item.name;

        return (
          <tr>
            <td>{item.dateTime}</td>
            <td>{item.requestId}</td>
            <td>{item.user}</td>
            <td>{Money(item.commissionAdvance)}</td>
            <td>{item.status}</td>
            <td
              className={
                item.repaymentStatus === "Due"
                  ? "pending"
                  : item.repaymentStatus === "Completed"
                  ? "approved"
                  : item.repaymentStatus === "Overdue"
                  ? "declined"
                  : ""
              }
            >
              {item.repaymentStatus}
            </td>
            <td>{formatDate(item.repaymentDate)}</td>
          </tr>
        );
      })}
    </tbody>
  );

  const { RangePicker } = DatePicker;
  const data2 = { head, tbody };

  return (
    <React.Fragment>
      <Container>
        <div>
          <div className="my-4">All Requests:</div>
          <Row>
            <Col sm={4} md={3} lg={3} xl={3}>
              <Searchbox
                placeholder="Enter Name, Request ID"
                handleSearch={setSearchterm}
              />
            </Col>
            <Col sm={4} md={3} lg={3} xl={3}>
              <RangePicker
                className="date-picker"
                placeholder={["Start Date", "End Date"]}
                locale={date}
                format={"YYYY-MM-DD"}
                value={date}
                onChange={(date) => setDate(date)}
                separator={
                  <CompareArrowsIcon className="primary  calender-arrow" />
                }
                suffixIcon={<img src={Calender} />}
              />
            </Col>
            <Col sm={4} md={3} lg={3} xl={2}>
              <InputGroup
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <KeyboardArrowDownIcon className="down-arrow" />
                <select
                  className="search-dropdown"
                  value={searchStatus}
                  onChange={(e) => setSearchStatus(e.target.value)}
                  placeholder="repayment status"
                >
                  <option value={"default"}>Request Status</option>
                  <option>Pending</option>
                  <option>Approved</option>
                  <option>Declined</option>
                </select>
                {isHovering && searchStatus !== "default" && (
                  <div
                    className="reset-dropdown me-3"
                    onClick={() => setSearchStatus("default")}
                  >
                    <CancelIcon fontSize="small" className="primary" />
                  </div>
                )}
              </InputGroup>
            </Col>
            <Col sm={12} md={12} lg={12} xl={4}>
              <div className="float-end">
                <Paginate
                  listPerPage={10}
                  totalList={RequestData.length}
                  setCurrentpage={setCurrentpage}
                  currentPage={currentPage}
                />
              </div>
            </Col>
          </Row>
          <div className="my-3">
            {RequestData && RequestData.length ? (
              <Tables props={data2} striped={true} />
            ) : (
              <NoDataFound key="info" variant="info" body="No Data Found!" />
            )}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default AllRequest;
