import Styled from "styled-components";

const OverviewWrap = Styled.div`

 padding: 10px;

 .center{
    display: flex;
    justify-content: center;
    align-items: center;
 }

 .top-block{
    display: flex;
    justify-content: center;
    align-items: center;
 }

 .active-number{
    display: inline-flex;
    min-width: 218px;
    height: 65px;
    background: transparent linear-gradient(124deg, #0094FF 0%, #26C984 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000015;
    border-radius: 5px;
    color: white;
    padding: 10px;
 }

 .num{
    font-size: 47px;
    padding: 10px;
 }

.cards{
margin-top: 10px !important;
}
 .active-text{
    margin-top: 40px;
 }
 .counting{
   margin-top: 30px;
   padding: 5px;
   background:  linear-gradient(110deg, #0094FF 0%, #20C098 100%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
 }
 .primary{
   color: #0094FF;
   font-weight: 600;
 }
 .graph-row{
   display:inline-flex;
   // margin-top: 10px;
   margin: auto !important;
 }
 .graph-text{
   position: relative;
   width: 100px;
 }

 .white-bg{
   background: #FFFFFF 0% 0% no-repeat padding-box;
   box-shadow: 0px 3px 6px #00000015;
   border-radius: 5px;
   padding: 20px 0px 20px 0px;
}
.first-card{
   width: 100%;
}
.overview-card{
  
   font-weight: 600;
   gap: 12px;
}
.cards{

   height: 103px;
   gap: 20px;
}
.card-row{
   gap: 20px;
  
}
.card-num{
   text-align: center;
   font-size: 43px;
   letter-spacing: 0px;
   color: #0094FF;
}
.card-per{
   color: #26C984;
   font-size: 32px;
}
.card-value{
   font-size: 29px;
}
.request{
   text-align: center;
   position: relative;
   bottom: 10px;
}
@media (min-width: 1400px) {
.end-block{
   float: right;
}
.first-card{
   float: left;
}
}
@media (max-width: 1400px) {
   .card-center{
      display: flex;
      justify-content: center;
      align-items: center;
   }
}
@media (min-width: 1348px) {
   .overdue-graph{
      float: right;
   }
}
@media (max-width: 1348px) {
   .graph-row{
      display: flex;
      justify-content: center;
      align-items: center;
   }
}
@media (min-width: 400px) {
   .cards{
      width: 360px;
   }
}
@media (max-width: 400px) {
.cards{
  padding: 15px;
  width: 280px;
  gap: 5px;
}
.card-per, .card-num{
   font-size: 20px;
}
.num{
   font-size: 30px;
   position: relative;
   bottom: -8px;;
}
.active-number{
   width: 150px;
}
.graph-row{
   margin-top: 10px;
   margin: 0px;
 }
}
`;

export default OverviewWrap;
