import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoginWrap from "./style";
import LoginForm from "../../Reuseable/Forms/LoginForm";
import Rectangle from "../../../../src/assets/images/logo/Rectangle.svg";
import Lamp from "../../../../src/assets/images/logo/lamp.png";
import Background from "../../../../src/assets/images/logo/background.png";
import Brokerpay from "../../../../src/assets/images/logo/brokerpay.svg";
import LoginotpForm from "../../Reuseable/Forms/LoginotpForm";
import ResetForm from "../../Reuseable/Forms/ResetForm";
import ResetOtpVerificationForm from "../../Reuseable/Forms/ResetOtpVerificationForm";
import SignupModal from "../LandingPage/SignupModal";
import { Modal } from "react-bootstrap";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordOTP from "./ForgotPasswordOTP";
import SetPassword from "./SetPassword";

const Login = () => {
  const [pendingUser, setpendingUser] = useState(false);
  const [pageView, setPageView] = useState("login");
  const [show, setShow] = useState(false);

  const [isotpform, setIsotpform] = useState({
    status: false,
    emailId: "",
  });

  const [values, setValues] = useState({
    otp: "",
  });

  const [otpConfirmation, setOtpConfirmation] = useState(false);
  const [formValues, setFormValues] = useState({});

  return (
    <React.Fragment>
      <LoginWrap>
        <div>
          <div>
            <img className="img-con" alt="User" src={Background} />
            <img className="img-con3" alt="User" src={Rectangle} />
            <img className="img-con1" alt="User" src={Lamp} />
          </div>
          <div className="box-pos">
            <div className=" container loginPageContent">
              <Container className="container-width ">
                <Link to="/">
                  <img
                    className="logo pointer"
                    src={Brokerpay}
                    srcSet={Brokerpay}
                    alt="Brokerpay"
                  />
                </Link>
                <div
                  className="bg-white"
                  style={{
                    boxShadow: "0px 3px 10px #00000015",
                  }}
                >
                  {pageView === "login" ? (
                    <div>
                      {!isotpform.status ? (
                        <LoginForm
                          setpendingUser={setpendingUser}
                          setIsotpform={setIsotpform}
                          setPageView={setPageView}
                          setShow={setShow}
                        />
                      ) : (
                        <LoginotpForm isotpform={isotpform} />
                      )}
                    </div>
                  ) : (
                    <div>
                      <div className="center reset-password primary">
                        <b>Reset Password</b>
                      </div>
                      {pageView === "set_password" ? (
                        <SetPassword
                          setFormValues={setFormValues}
                          setPageView={setPageView}
                          formValues={formValues}
                          values={values}
                          setValues={setValues}
                          setOtpConfirmation={setOtpConfirmation}
                        />
                      ) : (
                        <>
                          {!otpConfirmation ? (
                            <ForgotPassword
                              setOtpConfirmation={setOtpConfirmation}
                              setFormValues={setFormValues}
                              setPageView={setPageView}
                            />
                          ) : (
                            <ForgotPasswordOTP
                              setPageView={setPageView}
                              formValues={formValues}
                              setValues={setValues}
                              values={values}
                              setFormValues={setFormValues}
                              setOtpConfirmation={setOtpConfirmation}
                            />
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </Container>
            </div>
          </div>
        </div>
      </LoginWrap>
      <SignupModal setShow={setShow} show={show} />

      <Modal
        size="lg"
        centered
        show={pendingUser}
        onHide={() => setpendingUser(false)}
      >
        <Link className="center pt-4" to="/">
          <img
            className="center pointer"
            src={Brokerpay}
            srcSet={Brokerpay}
            alt="Brokerpay"
          />
        </Link>
        <div className="bg-white px-5 pb-5 pt-4 invaliduser fw-bold">
          <div className="mb-2 justify_text">
            Our team is still reviweing the information you provided at sign up.
            We usually approve new accounts within 24-48 hrs.
          </div>
          <div className="justify_text">
            If the process is taking longer than expected, or you have any
            questions, you can reach out to us at{" "}
            <span>
              <a
                href="mailto: customercare@brokerpay.com.au"
                className="primary fw-bolder"
              >
                customercare@brokerpay.com.au
              </a>
            </span>
            {/* or call us on <b className="primary">1800 444 744</b>. */}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Login;
