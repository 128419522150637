import { createGlobalStyle } from "styled-components";
import "typeface-roboto";

export const GlobalStyles = createGlobalStyle`
       
        body {
          font-family: ${({ theme }) => theme["font-stack"]}!important;  
          background: #FFFFFF 0% 0% no-repeat padding-box;
          color: #303030;
          
        }
         body{
            overflow-x: hidden;

        }

        h1, h2, h3, h4, h5, h6, 
        .h1, .h2, .h3, .h4, .h5, .h6
        {
            font-family: ${({ theme }) => theme["font-stack"]}!important;
            color: ${({ theme }) => theme["heading"]};
        }
        .center{
            display: flex;
            justify-content: center;
            align-items: center;
         }
         .text-medium{
          font-weight: 500 !important;
         }
         .text-bold{
          font-weight: 800 !important;
         }
         .themegradient{
            color: white;
            background: transparent linear-gradient(98deg, #0094FF 0%, #26C984 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000015;
            border-radius: 5px;
            opacity: 0.9;
            padding: 5px;
         }
         .primary{
            color: #0094FF !important;
          }
          .theme-color2{
            color: #387DF0 !important;
          }
          .anticon svg {
            fill: #0094FF !important;
            opacity: 0.6;
          }
          .theme-btn{
            background: #0094FF 0% 0% no-repeat padding-box;
            border-radius: 20px;
            color: white;
            border: none;
          }
          .cancel-btn{
            background: #92c4ee 0% 0% no-repeat padding-box;
            border-radius: 20px;
            border: none;
            color: white;
          }
          .cancel-btn:hover, .cancel-btn:active, .cancel-btn:focus {
            background: #aac8e1 0% 0% no-repeat padding-box;
          }
          .theme-btn2:hover{
            background: #279d6b 0% 0% no-repeat padding-box;
          }
          .theme-btn2{
            background: #26C984 0% 0% no-repeat padding-box;
            border-radius: 20px;
            color: white;
            border: none;
          }
          .text-btn{
            border: none;
            background: transparent;
            font-weight: 500;
            cursor: pointer;
            color: #0094FF !important;
         }
         .text-btn:hover, .text-btn:active, .text-btn:focus{
          border: none;
          background: transparent;
         }
          .approved{
            color: #01D111 !important;
          }
          .pending{
            color: #FF8A02 !important;
          }
          .declined{
            color: #FF0000 !important;
          }
          .nav-tabs {
            border: none !important;
          }
          .date-picker{
            background: transparent;
            width: 100%;
            border: 1px solid rgb(88, 178, 242, 0.4);
            border-radius: 5px;
            height: 38px;
            margin-top: 5px;
            appearance: none;
          }
          .test-wrap{
            word-wrap: break-word;
          }
          .italic-text {
            font-style: italic;
          }
          .calender-arrow{
            opacity: 0.7;
          }
          .reportIcon{
            margin-top: -3px;
            width: 12px !important;
          }
          .calendar-icon{
            opacity: 0.4;
          }
          .reset-dropdown{
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            cursor: pointer;
            opacity: 0.4;
            margin-top: 2px;
          }
          .modal-content{
            margin-bottom: 30px;
          }
          .nodata-alert {
            background: #E2F2FF 0% 0% no-repeat padding-box !important;
            border-radius: 5px !important;
            border: none;
            color: #303030;
          }
          .onboarding_block{
            font-size: 14px !important;
            font-weight: 500;
          }
          .search-dropdown{
            width: 100%;
            opacity: 0.7;
            color: rgb(150 154 158);
            border-radius: 5px;
            height: 38px;
            font-size: 13px;
            background: transparent;
            border: 1px solid rgb(88, 178, 242, 0.5);
            margin-top: 5px;
            appearance: none;
            padding: 5px;
            border-top-left-radius: 5px !important;
            border-bottom-left-radius: 5px !important;
            option{
              font-size: 15px;
              padding: 30px;
            }
          }
          .search-dropdown:focus{
            border: 1px solid rgb(88, 178, 242, 1) !important;
          }
          .ant-picker-focused{
            box-shadow: none !important;
          }
          .pointer{
            cursor: pointer;
          }
          .sorticon{
            font-size: 17px !important;
            stroke-width: 3px;
            transform: rotate(90deg);
          }
          .back-link{
            color: black;
            font-size: 18px !important;
            font-weight: 600;
          }
          .MuiSvgIcon-root {
            /* Replace the value below with your desired stroke width */
            stroke-width: 3px !important;
          }

          .popup_processing{
            color: #FFBA00;
            font-weight: 600;
          }

          .file_list_card{
            background: #ccdfec75 0% 0% no-repeat padding-box;
            border: 1px solid #0094ffd9;
            border-radius: 5px;
            opacity: 0.8;
          }
          .overview_container{
            height: 80vh;
          }
       
          .down-arrow{
            position: absolute;
            color: #0094FF;
            right: 5px;
            top: 11px;
          }
        * {
            &:focus {
                outline: inherit;
            }
            box-sizing: border-box;
        }

     
        .form-select:focus {
          border-color: #ced4da;
          outline: 0;
          box-shadow: inherit;
      }
      .loginformbody .text-center .btn 
      {
        width: 120px;
        /* margin-right: -15px; */
      }
      .loginSubmitBtn
      {
        position:relative;
      }

      .website-card{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 10px #00000015;
        padding: 20px;
      }

      .signup_text{
        font-size: 32px;
        color: #0094FF;
      }

      .broker_btn{
          background: #FFFFFF 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000029;
          border-radius: 5px;
          font-size: 20px;
          padding: 20px;
          width: 100%;
          cursor: pointer;
          color: black !important;
      }
      .broker_btn:hover{
        box-shadow: 0 0 5px #0094FF;
      }
      
      .hashching_link{
        color: black !important;
        width: 100%;
      }
      .error {
        font-size: .8rem;
        color: red;
      }

      .signup_modal_open {
        overflow: hidden !important;
      }

      .justify{
        display: flex; 
        justify-content: space-between !important;
            }

            .justify_text{
              display: block;
              justify-content: center !important; 
              text-align: center !important;
            }

      .close-btn{
          float: right;
          margin-bottom: 10px;
          cursor: pointer;
      }

      .page_text_1{
          font-size: 20px !important;
      }

      .loginSubmitBtn .spinner-grow
      {
        position: absolute;
        left: 10%;
        top: 50%;
        margin-top: -7px;
      }

      .modal-open {
        overflow: inherit;
        overflow-x: hidden !important;
        padding-right: 0px !important;
      }

        
        // .btn 
        // {
        //     &.btn-orange {
        //         color: #fff;
        //         background-color: ${({ theme }) => theme["orange-color"]};
        //         border-color: ${({ theme }) => theme["orange-color"]};
        //         margin: 0 auto;
        //         font-size: 16px;
        //         &:hover {
        //             color: #fff;
        //             background-color: ${({ theme }) =>
          theme["orange-hover"]};
        //         border-color:${({ theme }) => theme["orange-hover"]};
        //         }
        //     }
        // }

        // a {
        //     color: ${({ theme }) => theme["orange-color"]};
        //     text-decoration: none;
        //     &:hover {
        //         color: ${({ theme }) => theme["orange-hover"]};
        //         text-decoration: none;
        //     }
        // }

        .btn-close {
            &:focus {
                box-shadow: inherit !important;
            }
        }

        // .modal {
        //     .modal-content {
        //         border-radius: 10px;
        //     }
        // }

        .cursor
        {
          cursor : pointer;
        }

        .form-control {
            &:focus {
                box-shadow: none !important;
            }
        }

        .btn-check {
            &:focus {
                + {
                    .btn {
                        box-shadow: none !important;
                        outline: 0;
                        box-shadow: none;
                    }
                }
            }
        }

        .btn {
            &:focus {
                box-shadow: none !important;
                outline: 0;
                box-shadow: none;
            }
            // background-color: #ddd;
        }
        
        button:focus {
            outline: inherit;
        }
        
        *{
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        
        a {
            text-decoration: none;
            &:active,
            &:hover,
            &:focus {
            text-decoration: none;
            color: ${({ theme }) => theme["body-text"]};
            }
        }
        
        button,
        html,
        input,
        select,
        textarea {
            font-family: ${({ theme }) => theme["font-stack"]}!important;
        }
        
        input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        
        input::-ms-clear,
        input::-ms-reveal {
            display: none;
        }
        
        input[type="text"]::-ms-clear {
            display: none;
        }
        
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
        input[type="number"] {
            -moz-appearance: textfield;
        }
        
        li {
            list-style: none;
        }
        
        ul {
            padding: 0px;
            margin: 0px;
        }
        
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
        
        .form-control input::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: ${({ theme }) => theme["gray-color"]};
        }
        
        .form-control input::-moz-placeholder {
            /* Firefox 19+ */
            color: ${({ theme }) => theme["gray-color"]};
        }
        
        .form-control input:-ms-input-placeholder {
            /* IE 10+ */

            color: ${({ theme }) => theme["gray-color"]};
        }
        
        .form-control input:-moz-placeholder {
            /* Firefox 18- */
            color: ${({ theme }) => theme["gray-color"]};
        }
        
        input:focus {
            outline: inherit;
        }
        
        select:focus {
            outline: inherit;
        }

        .MuiPopover-root{
          z-index: 99999 !important;
        }

        .btn.focus, .btn:focus {
            outline: inherit;
            box-shadow: inherit;
        }
        .modal{
          z-index: 9999;
        }

        .form-control:focus {
          border-color: #ced4da;
        }
        .navbar-light {

            // .top-menu-links {
            //   color: ${({ theme }) => theme["orange-color"]};
            // }

            .navbar-nav {
                .nav-link {
                    font-size:18px;
                    margin-top:20px;
                  color:#303030 ;
                  &:active,
                  &:hover,
                  &:focus {
                    color: #0094FF;
                  }
                }
            }
            .fisrt_nav{
              font-size:18px;
                    margin-top:20px;
                  color:#303030 !important; ;
            }

          
          // sidebar end
          .nav-tabs {
            border-bottom: inherit;
            .nav-link {
              color: ${({ theme }) => theme["tabLink"]};
              background-color: ${({ theme }) => theme["tabBg"]};
              border-color: transparent transparent ${({ theme }) =>
                theme["tabLink"]} ;
              &.active {
                 color:  ${({ theme }) => theme["tabLink"]};
                 background-color: ${({ theme }) => theme["tabBg"]};
                 border-color: ${({ theme }) => theme["tabLink"]} ${({
  theme,
}) => theme["tabLink"]} transparent;
              }
            }
          }
          .tab-content .tab-pane.active
          {
            color:  ${({ theme }) => theme["panel-heading"]};
          }
          .alert-success{
            background-color : #008000;
            border: 1px solid #008000;
            color : #fff;
          }
          
          @media (max-width: 450px) {
            .navbar-nav {
                .nav-link {
                    margin-top:-15px;
                  
                }
            }
          }   
        }
          @media (max-width: 1000px) {
            // .modal-content {
            //     top: 50px !important;
            // }
      }
`;
