import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import SettingsWrap from "./style";
import ManageRole from "./ManageRoles";
import ManageTeam from "./ManageTeam";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useSelector } from "react-redux";

const Setting = ({ settingsmenu, getAdministrator }) => {
  const authToken = useSelector((state) => state.Auth.authToken);
  const administratorData = useSelector(
    (state) => state.Tracking.allAdministrators
  );

  const [view, setView] = useState("dataView");

  return (
    <React.Fragment>
      <SettingsWrap>
        <Container>
          <div className="tab-row">
            <div
              onClick={() => setView("dataView")}
              className="back-link pointer"
            >
              {view === "dataView" ? (
                ""
              ) : (
                <KeyboardArrowLeftIcon className="primary me-1" />
              )}
              {settingsmenu === "team"
                ? "Manage Team"
                : view === "addNew"
                ? "Create administrator"
                : "Manage Roles and Permissions"}
            </div>
          </div>
          <div className="mt-3">
            {settingsmenu === "team" ? (
              <ManageTeam
                administratorData={administratorData}
                getAdministrator={getAdministrator}
              />
            ) : (
              <ManageRole
                view={view}
                setView={setView}
                administratorData={administratorData}
                getAdministrator={getAdministrator}
              />
            )}
          </div>
        </Container>
      </SettingsWrap>
    </React.Fragment>
  );
};

export default Setting;
