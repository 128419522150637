import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Layout from "./components/Pages/Layout/main";
import { ThemeProvider } from "styled-components";
import config from "./config/config";
import { GlobalStyles } from "./assets/styles/js/GlobalStyles";
import Login from "./components/Pages/Login";
import ProtectedRoute from "./Auth/protectedRoute";
import { ResetPassword } from "./components/Pages/ResetPassword";
import LoginNavigation from "./routes/LoginNavigation";
import PageNotFound from "./components/Pages/PageNotFound";
import Settings from "../src/components/Pages/Settings";
import AccountHistory from "../src/components/Pages/Accounthistory";
import Dashboard from "../src/components/Pages/Portal/Dashboard";
import { useNetworkState } from "react-use";
import NoInternet from "./components/Reuseable/ErrorPage/NoInternet";
import Newrequest from "./components/Pages/Newrequest";
import LandingPage from "./components/Pages/LandingPage";
import Overview from "./components/Pages/Admin/Overview";
import Requests from "./components/Pages/Admin/Requests";
import Repayments from "./components/Pages/Admin/Repayments";
import Users from "./components/Pages/Admin/Users";
import Setting from "./components/Pages/Admin/Setting";
import ActivityLog from "./components/Pages/Admin/ActivityLog";
import Reports from "./components/Pages/Admin/Reports";
import Resource from "./components/Pages/Resource";

const { theme, darktheme } = config;

export const isOnlineContext = React.createContext();

const App = () => {
  const NetworkState = useNetworkState();
  const netState = NetworkState.online;

  const [online, Setonline] = useState(netState);

  useEffect(() => {
    Setonline(netState);
  }, [netState]);

  const isOnlineContextValue = { online };

  const darkmode = useSelector((state) => state.Layout.darkmode);
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);

  return (
    <div className="App">
      <ThemeProvider theme={darkmode ? darktheme : theme}>
        <isOnlineContext.Provider value={isOnlineContextValue}>
          <GlobalStyles />
          <Router basename={process.env.PUBLIC_URL}>
            <Routes>
              <React.Fragment>
                <Route path="" element={<NoInternet />}>
                  {/* ----------Auth protected routes------------ */}

                  <Route exact path="/" element={<LoginNavigation />}>
                    <Route path="/landingpage" element={<LandingPage />} />
                    <Route path="/" element={<LandingPage />} />
                    <Route path="login" element={<Login />} />
                  </Route>

                  {/*-------------- protected routing------------------ */}

                  <Route exact path="/home" element={<ProtectedRoute />}>
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route exact path="/home" element={<Layout />}>
                      {brokerdata.activationStatus === "Onboarding" ? (
                        <>
                          <Route path="dashboard" element={<Dashboard />} />
                          <Route
                            path="dashboard/:id"
                            render={({ match }) => {
                              const token = match.params.id;
                              const queryString = new URLSearchParams(
                                window.location.search
                              );
                              const event = queryString.get("event");

                              if (event === "signing_complete") {
                                return <Dashboard token={token} />;
                              }
                            }}
                            element={<Dashboard redirect={true} />}
                          />
                        </>
                      ) : (
                        <>
                        <Route path="dashboard" element={<Dashboard />} />
                          <Route
                            path="dashboard/:id"
                            render={({ match }) => {
                              const token = match.params.id;
                              const queryString = new URLSearchParams(
                                window.location.search
                              );
                              const event = queryString.get("event");

                              if (event === "signing_complete") {
                                return <Dashboard token={token} />;
                              }
                            }}
                            element={<Dashboard redirect={true} />}
                          />
                          <Route path="settings" element={<Settings />} />
                          <Route
                            path="accounthistory"
                            element={<AccountHistory />}
                          />
                          <Route
                            path="home/dashboard"
                            element={<Dashboard />}
                          />
                          <Route path="newrequest" element={<Newrequest />} />
                          <Route path="resource" element={<Resource />} />
                        </>
                      )}
                      <Route path="overview" element={<Overview />} />
                      <Route path="requests" element={<Requests />} />
                      <Route path="repayments" element={<Repayments />} />
                      <Route path="users" element={<Users />} />
                      <Route path="reports" element={<Reports />} />
                      <Route path="setting" element={<Setting />} />
                      <Route path="activitylog" element={<ActivityLog />} />
                    </Route>
                  </Route>
                </Route>

                <Route path="*" element={<PageNotFound />}></Route>
              </React.Fragment>
            </Routes>
          </Router>
        </isOnlineContext.Provider>
      </ThemeProvider>
    </div>
  );
};
export default App;
