import React, { useState } from "react";
import { Forms } from "../../../Reuseable/Forms";
import { api } from "../../../../Services/api";
import { Validators } from "../../../../Utilities/validator";
import { Alert } from "react-bootstrap";

const ForgotPassword = ({ setOtpConfirmation, setFormValues, setPageView }) => {
  const [invalidUser, setInvalidUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changeInForm, setChangeInForm] = useState(false);

  const SubmitLogin = (form, valid) => {
    if (valid) {
      setLoading(true);
      const obj = {
        emailId: form.email,
        administrator: false
      };
      api
        .post("/brokerpayuserauth/forgotpassword", obj)
        .then(function (response) {
          if (response.data.status === true) {
            setFormValues(form);
            setSubmitted(true);
            setOtpConfirmation(response.data.status);
            setLoading(false);
          } else {
            setInvalidUser(response.data.message);
            setLoading(false);
          }
        })
        .catch(function (error) {
          if (error.response.data.status === false) {
            setInvalidUser(error.response.data.message);
            console.log("Error", error.response.data.message);
          }
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="loginFormScreens flex-fill bg-white">
        {invalidUser ? (
          <Alert className="m-2 p-1 text-center" variant="danger">
            {invalidUser}
          </Alert>
        ) : (
          <></>
        )}

        <Forms
          title={""}
          formArr={[
            {
              label: "Enter Email",
              name: "email",
              type: "email",
              placeholder: "Enter email",
              maxLength: "",
              validators: [
                {
                  check: Validators.required,
                  message: <p>This is a required field</p>,
                },
                {
                  check: Validators.email,
                  message: <p>Please enter valid Email ID</p>,
                },
              ],
              error: false,
              errormsg: "",
              value: "",
              show: true,
            },
          ]}
          submitBtn={"Send OTP"}
          onSubmit={SubmitLogin}
          submitted={submitted}
          changeInForm={changeInForm}
          setChangeInForm={setChangeInForm}
          loading={loading}
          Login={true}
          setPageView={setPageView}
        />
      </div>
    </>
  );
};

export default ForgotPassword;
