import React, { useState, useEffect } from "react";
import hashching from "../../../../../src/assets/images/hashchinglogo.svg";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import { api } from "../../../../Services/api";
import Styled from "styled-components";
import { SignupValidation } from "../SignUpValidation";
import { Alert } from "react-bootstrap";
import { getCurrentTime } from "../../../Reuseable/CurrentDateTime";

const HashchingAccount = ({ hashchingData, email, setPageView, password }) => {
  function isNumeric(value) {
    return /^\d+$/.test(value);
  }

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [value, setvalue] = useState({
    address:
      hashchingData?.broker_postcode +
      ", " +
      hashchingData?.address +
      ", " +
      hashchingData?.state,
    aggregator: isNumeric(hashchingData?.aggregator)
      ? ""
      : hashchingData?.aggregator,
    businessname: hashchingData?.business_name,
    licencenumber: hashchingData?.acl_crn,
    business_abn: hashchingData?.business_abn,
  });
  const [error, setError] = useState({
    address: "",
    aggregator: "",
    businessname: "",
    licencenumber: "",
    business_abn: "",
  });

  const onChangeHandler = (e) => {
    setvalue({ ...value, [e.target.name]: e.target.value });
  };

  const validateField = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "licencenumber") {
      if (value === "") {
        setError({ ...error, licencenumber: "This is a required field" });
      } else if (value.length < 4) {
        error.licencenumber = "Please enter a valid license number";
      } else {
        error.licencenumber = "";
      }
    } else if (name === "address") {
      if (value === "") {
        setError({ ...error, address: "This is a required field" });
      } else {
        error.address = "";
      }
    } else if (name === "aggregator") {
      if (value === "") {
        setError({ ...error, aggregator: "This is a required field" });
      } else if (value.length < 2) {
        error.aggregator = "Please enter a valid aggregator name";
      } else {
        error.aggregator = "";
      }
    } else if (name === "business_abn") {
      if (value === "") {
        setError({ ...error, business_abn: "This is a required field" });
      } else if (value.length < 11) {
        error.business_abn = "Please enter a valid ABN";
      } else {
        error.business_abn = "";
      }
    } else if (name === "businessname") {
      if (value === "") {
        setError({ ...error, businessname: "This is a required field" });
      } else if (value.length < 2) {
        error.businessname =
          "Your business name should be between 2 and 25 characters.";
      } else {
        error.businessname = "";
      }
    }
  };

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  const licensenumber = (e) => {
    const data = e.target.value;
    var key = e.keyCode || e.charCode;
    if (data.length < 8 || key === 8 || key === 46) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const abn = (e) => {
    const data = e.target.value;
    var key = e.keyCode || e.charCode;
    if (data.length < 11 || key === 8 || key === 46) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const Onlytext = (e) => {
    const re = /^[A-Za-z]+$/;
    if (e.key === " " || e.key === "-" || re.test(e.key)) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setIsValid(Object.values(error).every((value) => value === ""));
  }, [error]);

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth();
  let dd = today.getDate();

  let formatDate = dd + "/" + mm + "/" + yyyy;

  const convertDateString = (dateString) => {
    const dateObj = new Date(dateString);
    const day = dateObj.getUTCDate();
    const month = dateObj.getUTCMonth() + 1;
    const year = dateObj.getUTCFullYear();

    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");

    return `${formattedMonth}/${formattedDay}/${year}`;
  };

  const convertDateStringFormat = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getUTCDate()).padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const SubmitForm = () => {
    setLoading(true);
    setErrorMessage(false);
    const obj = {
      firstName: hashchingData.first_name,
      lastName: hashchingData.last_name,
      emailId: email,
      phoneNumber: hashchingData.mobile,
      businessName: value.businessname,
      abn: value.business_abn,
      aggregator: value.aggregator,
      licenceNumber:
        hashchingData.license_type === "CRN"
          ? {
              CRN: value.licencenumber,
              ACL: "",
            }
          : {
              CRN: "",
              ACL: value.licencenumber,
            },
      createddate: formatDate,
      password: password,
      address: value.address,
      DOB:
        hashchingData.date_of_birth === null ||
        hashchingData.date_of_birth === "0000-00-00"
          ? "00/00/0000"
          : convertDateStringFormat(hashchingData.date_of_birth),
      hashchingUser: "yes",
      createdDate: getCurrentTime(),
      profile:
        hashchingData.profile_pic && hashchingData.profile_pic.length
          ? hashchingData.profile_pic
          : "",
      logDateTime: getCurrentTime(),
    };
    api
      .post("/register", obj)
      .then(function (response) {
        if (response.data.status === true) {
          setSubmitted(true);
          setLoading(false);
          setPageView("submissionScreen");
        } else {
          setErrorMessage(response.data.message);
        }
      })
      .catch(function (error) {
        if (error.response.data.status === false) {
          setErrorMessage(error.response.data.message);
          console.log("loginResponse [error]-->", error.response.data.message);
        }
        setLoading(false);
      });
  };

  const handleFormsubmit = (e) => {
    e.preventDefault();
    setError(SignupValidation(value));
    setTimeout(() => {
      if (
        Object.values(value).every((value) => value !== "") === true &&
        Object.values(error).some((value) => value !== "") === false
      ) {
        isValid && SubmitForm();
      }
    }, 1000);
  };
  return (
    <>
      <HashchingWrap>
        {errorMessage ? (
          <Alert className="m-2 p-1 center" variant="danger">
            {errorMessage}
          </Alert>
        ) : (
          <></>
        )}
        <div className="ms-2">
          <img
            className="me-1"
            width={100}
            height={30}
            src={hashching}
            alt="hashching"
          />
          <span className="pt-3">Broker</span>
        </div>
        <Row className="px-4">
          <Col className="" lg={2} md={12}>
            {hashchingData.profile_pic && hashchingData.profile_pic.length ? (
              <div className="hashching_image center me-4 ms-2 mt-2">
                <img
                  className="hashching_image"
                  src={hashchingData.profile_pic}
                  alt="profile"
                />
              </div>
            ) : (
              <div className="hashching_profile center me-4 ms-2 mt-2">
                {hashchingData.first_name[0]}
              </div>
            )}
          </Col>
          <Col className="pt-5" lg={10} md={12}>
            <Row>
              <div className="hashching_name">
                {hashchingData.first_name + " " + hashchingData.last_name}
              </div>
            </Row>
            <div className="data_block">
              <div>{email}</div>
              <span className="data_divider mx-2">|</span>
              <div>Ph:{hashchingData.mobile}</div>
              <span className="data_divider mx-2">|</span>
              <div>
                DOB:{" "}
                {hashchingData.date_of_birth === null ||
                hashchingData.date_of_birth === "0000-00-00"
                  ? "-"
                  : convertDateString(hashchingData.date_of_birth)}
              </div>
            </div>
          </Col>
          <div className="mt-3">
            <Row>
              <Col lg={12}>
                <p className="fw-bold">Address:</p>
                <Form.Control
                  type="text"
                  value={value.address}
                  name="address"
                  onChange={(e) => {
                    onChangeHandler(e);
                    validateField(e);
                  }}
                />
                {error.address && <p className="error">{error.address}</p>}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={6}>
                <p className="fw-bold">Business Name</p>
                <Form.Control
                  type="text"
                  value={value.businessname}
                  name="businessname"
                  maxLength={25}
                  onChange={(e) => {
                    onChangeHandler(e);
                    validateField(e);
                  }}
                />
                {error.businessname && (
                  <p className="error">{error.businessname}</p>
                )}
              </Col>
              <Col lg={6}>
                <p className="fw-bold">Aggregator</p>
                <Form.Control
                  type="text"
                  value={value.aggregator}
                  name="aggregator"
                  onChange={(e) => {
                    onChangeHandler(e);
                    validateField(e);
                  }}
                  onKeyDown={(e) => Onlytext(e)}
                />
                {error.aggregator && (
                  <p className="error">{error.aggregator}</p>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg={6}>
                <p className="fw-bold">ABN</p>
                <Form.Control
                  type="number"
                  value={value.business_abn}
                  name="business_abn"
                  onChange={(e) => {
                    onChangeHandler(e);
                    validateField(e);
                  }}
                  onKeyDown={(e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                    abn(e);
                  }}
                />
                {error.business_abn && (
                  <p className="error">{error.business_abn}</p>
                )}
              </Col>
              <Col lg={6}>
                <p className="fw-bold">CRN/ACL</p>
                <Form.Control
                  type="number"
                  value={value.licencenumber}
                  name="licencenumber"
                  onChange={(e) => {
                    onChangeHandler(e);
                    validateField(e);
                  }}
                  onKeyDown={(e) => {
                    exceptThisSymbols.includes(e.key) && e.preventDefault();
                    licensenumber(e);
                  }}
                />
                {error.licencenumber && (
                  <p className="error">{error.licencenumber}</p>
                )}
              </Col>
            </Row>
            <div className="float-end">
              <button
                onClick={(e) => {
                  handleFormsubmit(e);
                  setError(SignupValidation(value));
                }}
                className="theme-btn px-4 py-1 my-2 mt-4 "
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Confirm"}
              </button>
            </div>
          </div>
        </Row>
      </HashchingWrap>
    </>
  );
};

const HashchingWrap = Styled.div`

.hashching-label {
    font-size: 14px !important;
    font-weight: bold;
}

.text-center {
    display: flex !important;
    justify-content: end !important
}

.form-control{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0094FF;
    border-radius: 5px;
}


`;

export default HashchingAccount;
