import React, { useState, useEffect } from "react";
import AccountCard from "./AccountCard";
import Loading from "../../Reuseable/Loading/Loading";

const Settings = ({loading, getBrokerdata, getBankDetails, }) => {


  return (
    <>
      <React.Fragment>
        {loading ?
          <Loading />
          :
          <AccountCard getBrokerdata={getBrokerdata} getBankDetails={getBankDetails} />
        }

      </React.Fragment>
    </>

  )
}

export default Settings;    