import React, { useState } from "react";
import { Forms } from "../../../Reuseable/Forms";
import { Validators } from "../../../../Utilities/validator";
import Styled from "styled-components";
import { api } from "../../../../Services/api";

const InvalidAccount = ({ setPageView, email }) => {
  const [submitted, setSubmitted] = useState(false);
  const [changeInForm, setChangeInForm] = useState("");
  const [loading, setLoading] = useState(false);

  const contactUs = (form, valid) => {
    if (valid) {
      const obj = {
        name: email,
        emailId: email,
        message:
          "II would like to clarify that I am not associated with this account in any way and have not initiated any sign-up or registration process with the mentioned email address or username. It appears to be a case of mistaken identity or a technical error.",
        phoneNumber: form.phoneNumber,
      };
      setLoading(true);
      api
        .post("/contactus", obj)
        .then(function (response) {
          console.log("contactUs  [response]-->", response);
          if (response.data.status === true) {
            setSubmitted(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log("contactUs [error]-->", error.response);
          setLoading(false);
          setSubmitted(true);
        });
    }
  };

  return (
    <React.Fragment>
      {submitted ? (
        <>
            <div className="center p-4 fw-bold">Thank you. Our team will be in touch with you shortly</div>
        </>
      ) : (
        <>
          <div className="invalid_account_text mt-4">
            We will need some more information from you to verify your account.
            Please provide your mobile number below, or get in touch with our
            support team at <span className="primary">customercare@brokerpay.com.au</span> 
            {/* or
            <span className="primary"> [phone number]</span> */}
          </div>
          <HashchingWrap>
            <div>
              <Forms
                title={""}
                formArr={[
                  {
                    label: "Please provide your mobile phone number",
                    name: "phoneNumber",
                    type: "contactnumber",
                    placeholder: "Enter",
                    maxLength: 10,
                    validators: [
                      {
                        check: Validators.phonenumber,
                        message:
                          "Please enter a valid Australian mobile number beginning with 04.",
                      },
                      {
                        check: Validators.phonenumberLength,
                        message: "Number must be 10 digits long",
                      },
                    ],
                    error: false,
                    errormsg: "",
                    value: "",
                    show: true,
                  },
                ]}
                onSubmit={contactUs}
                submitBtn={"Submit"}
                loading={loading}
                submitted={submitted}
                changeInForm={changeInForm}
                setChangeInForm={setChangeInForm}
                setPageView={setPageView}
              />
            </div>
          </HashchingWrap>
        </>
      )}
    </React.Fragment>
  );
};

const HashchingWrap = Styled.div`

.form-label {
    font-size: 14px !important
}

.col-form {
padding: 0 !important
}

.text-center {
    display: flex !important;
    justify-content: end !important
}

.form-control{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0094FF;
    border-radius: 5px;
}

`;

export default InvalidAccount;
