import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Alert } from "react-bootstrap";
import Tables from "../../../../Reuseable/Tables";
import Searchbox from "../../../../Reuseable/Searchbox";
import UserDataPreview from "../UserDataPreview";
import { api } from "../../../../../Services/api";
import { useSelector } from "react-redux";
import BarLoader from "react-spinners/ClipLoader";
import { NoDataFound } from "../../../../Reuseable/NoDataFound";
import { getCurrentTime } from "../../../../Reuseable/CurrentDateTime";

const DeletedUser = ({
  title,
  setViewUser,
  viewuser,
  getNotes,
  notes,
  setNotes,
  getAllUserData,
  getDeletedUsers,
  deletedUsers,
  getAllActivityLog,
}) => {
  const authToken = useSelector((state) => state.Auth.authToken);
  const permissions = useSelector(
    (state) => state.Tracking.permissions.Account
  );

  const [searchterm, setSearchterm] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setloading] = useState(false);
  const [requestNode, setRequestNode] = useState("");

  const reactivateUser = async (data) => {
    setloading(true);
    let obj = {
      userId: data.userId,
      emailId: data.emailId,
      logDateTime: getCurrentTime(),
    };
    await api
      .post("/deleteuser/reactivate", obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          getDeletedUsers();
          setloading(false);
          setShow(false);
          getAllUserData();
          getAllActivityLog();
        }
      })
      .catch(function (error) {
        setloading(false);
        console.log("getDeletedUsers [error]-->", error.response);
      });
  };

  const columns = ["Name", "User ID", "Email", "Phone No", "Status", "Action"];

  const tbody = (
    <tbody>
      {deletedUsers
        .filter((val) => {
          if (searchterm === "") {
            return val;
          } else if (
            val.personname.toLowerCase().includes(searchterm.toLowerCase()) ||
            val.userId.toLowerCase().includes(searchterm.toLowerCase())
          ) {
            return val;
          }
        })
        .map((row) => (
          <tr
            className={
              permissions && permissions.viewUsercontactDetails
                ? "user-data pointer"
                : "user-data"
            }
          >
            <td
              onClick={() =>
                permissions && permissions.viewUsercontactDetails
                  ? (setShow(true), setViewUser(row))
                  : null
              }
            >
              {row.personname}
            </td>
            <td
              onClick={() =>
                permissions && permissions.viewUsercontactDetails
                  ? (setShow(true), setViewUser(row))
                  : null
              }
            >
              {row.userId}
            </td>
            <td
              onClick={() =>
                permissions && permissions.viewUsercontactDetails
                  ? (setShow(true), setViewUser(row))
                  : null
              }
            >
              {row.emailId}
            </td>
            <td
              onClick={() =>
                permissions && permissions.viewUsercontactDetails
                  ? (setShow(true), setViewUser(row))
                  : null
              }
            >
              {row.phoneNumber}
            </td>
            <td
              onClick={() =>
                permissions && permissions.viewUsercontactDetails
                  ? (setShow(true), setViewUser(row))
                  : null
              }
              className="deleted-user"
            >
              {row.status}
            </td>
            <td>
              <button
                className="theme-btn p-1 px-2"
                onClick={() => {
                  reactivateUser(row);
                  setRequestNode(row.userId);
                }}
              >
                {loading && requestNode === row.userId ? (
                  <div className="mx-4">
                    <BarLoader
                      as="span"
                      size={14}
                      animation="grow"
                      color="#00C6FF"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  "Reactivate"
                )}
              </button>
            </td>
          </tr>
        ))}
    </tbody>
  );

  const data2 = { columns, tbody };
  return (
    <React.Fragment>
      {deletedUsers && deletedUsers.length ? (
        <Container className="user-container">
          <Row>
            <div className="search-title">
              {title}
              <div className="search-box ">
                <Searchbox
                  placeholder="Enter name, User ID"
                  handleSearch={setSearchterm}
                />
              </div>
            </div>
          </Row>
          <div className="hover-td">
            <Tables props={data2} striped={false} />
          </div>
        </Container>
      ) : (
        <NoDataFound body="You don’t have any deleted user at the moment." />
      )}
      <UserDataPreview
        show={show}
        setShow={setShow}
        deleteView={true}
        viewuser={viewuser}
        setViewUser={setViewUser}
        notes={notes}
        getNotes={getNotes}
        setNotes={setNotes}
        reactivateUser={reactivateUser}
        loading={loading}
        getAllUserData={getAllUserData}
        getDeletedUsers={getDeletedUsers}
      />
    </React.Fragment>
  );
};

export default DeletedUser;
