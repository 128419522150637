import { useSelector } from 'react-redux';
// import {
//   faWindowMaximize,
//   faArrowCircleRight,
//   faThList,
//   faNetworkWired,
//   faAngleDown,
// } 
// from "@fortawesome/pro-light-svg-icons";
import homeIcon from "../assets/images/icons/home-icon.svg";
import projectIcon from "../assets/images/icons/projects-icon.svg";
import approvalIcon from "../assets/images/icons/approval-icon.svg";




export const AdminSidebar = [
  {
    name: "Dashboard",
    type: null,
    leftIcon: homeIcon,
    rightIcon: "",
    path: "/home",
    child: null,
  },

  {
    name: "Settings",
    type: null,
    leftIcon: homeIcon,
    rightIcon: "",
    path: "/settings",
    child: null,
  },

  {
    name: "Accounthistory",
    type: null,
    leftIcon: homeIcon,
    rightIcon: "",
    path: "/accounthistory",
    child: null,
  },

  // {
  //   name: "Test Menu",
  //   type: null,
  //   leftIcon: projectIcon,
  //   rightIcon: "",
  //   path: "/tracking",
  //   child: null,
  // },
  // {
  //   name: "Approval",
  //   type: null,
  //   leftIcon: approvalIcon,
  //   rightIcon: "",
  //   path: "/approval",
  //   child: null,
  // },

];
