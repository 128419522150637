import {
  Row,
  Col,
  Card,
  Container,
  Button,
  Spinner,
  Table,
} from "react-bootstrap";
import React, { useRef, useState } from "react";
import UIView from "./style";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Form from "react-bootstrap/Form";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import Validation from "../../Reuseable/Validation";
import { Alert, IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useEffect } from "react";
import { api } from "../../../Services/api";
import { useSelector } from "react-redux";
import QuestionIcon from "../../../assets/images/question-icon.svg";
import calendericon from "../../../assets/images/calender-new.svg";
import DcoumentIcon from "../../../assets/images/document-icon.svg";
import { LightTooltip } from "../../Reuseable/Tooltip";
import { Onlytext, percentage, numValue, OnlyNumValue } from "../../validators";
import dayjs from "dayjs";
import { Money } from "../../Reuseable/NumberFormatter";
import UIModal from "../../Reuseable/UIModal";
import { getCurrentTime } from "../../Reuseable/CurrentDateTime";
import CurrencyInput from "../../Reuseable/CurrencyInput";

const Newrequest = ({ getRequestData, getBrokerdata }) => {
  const authToken = useSelector((state) => state.Auth.authToken);
  const user = useSelector((state) => state.Auth.user);
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);

  let navigate = useNavigate();

  const back = () => {
    navigate("/home/dashboard");
  };
  const uploadRef = useRef();
  const [prevFile, setPrevFile] = useState("");
  const [tempFile, setTempFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploadView, setUploadView] = useState(false);
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    fullName: "",
    loanAmount: "",
    settlementDate: "",
    repaymentDate: "",
    settleMentProof: "",
    aggregator: "",
    commissiomnAdvance: "",
    commissionRate: "",
    commissionDate: "",
  });
  const [isValid, setIsValid] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    fullName: "",
    loanAmount: "",
    settlementDate: "",
    repaymentDate: "",
    aggregator: brokerdata.aggregator,
    commissiomnAdvance: "",
    commissionRate: "",
    commissionDate: "",
    settleMentProof: "",
  });

  useEffect(() => {
    setIsValid(Object.values(errors).every((value) => value === ""));
  }, [errors]);

  useEffect(() => {
    setIsValid(false);
  }, []);

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const handleUpdateField = (e, name) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });

    if (name === "fullName") {
      if (!e.target.value) {
        errors.fullName = "This is a required field";
      } else {
        if (e.target.value.length < 3) {
          errors.fullName = "Please enter a name between 3 and 30 characters";
        } else {
          errors.fullName = "";
        }
      }
    } else if (name === "aggregator") {
      if (!e.target.value) {
        errors.aggregator = "This is a required field";
      } else {
        if (e.target.value.length < 3) {
          errors.aggregator = "Please enter a valid aggregator name";
        } else {
          errors.aggregator = "";
        }
      }
    }
  };

  const handleUpdate = (field) => {
    setValues({
      ...values,
      ...field,
    });
  };

  const handleChangenumber = (e, name) => {
    let value = e.target.value.replace(/[$,]/g, "");
    setValues({
      ...values,
      [e.target.name]: value,
    });

    if (name === "loanAmount") {
      if (!value) {
        errors.loanAmount = "This is a required field";
      } else {
        if (value < 9999) {
          errors.loanAmount = "Minimum 10,000";
        } else {
          errors.loanAmount = "";
        }
      }
    } else if (name === "commissiomnAdvance") {
      let availableFunds = Money(brokerdata.availableFunds).props.children;

      if (value) {
        if (value > parseInt(brokerdata.availableFunds) || value < 1000) {
          errors.commissiomnAdvance = `Please enter an amount between $1,000 and ${availableFunds}.`;
        } else {
          errors.commissiomnAdvance = "";
        }
      } else {
        errors.commissiomnAdvance = "";
      }
    }
  };

  const handleRateChange = (e, name) => {
    let value = e.target.value;
    setValues({
      ...values,
      [e.target.name]: value,
    });
    if (name === "commissionRate") {
      if (!value) {
        errors.commissionRate = "Please enter a valid percentage";
      } else {
        if (parseInt(value) > 100) {
          errors.commissionRate = "Please enter a valid percentage";
        } else {
          errors.commissionRate = "";
        }
      }
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadFile = async (requestId) => {
    for (let i = 0; i < prevFile.length; i++) {
      try {
        let processedData = {
          requestId: requestId,
          fileName: prevFile[i].name,
          codeValue: await toBase64(prevFile[i].file),
          fileType: prevFile[i].fileType,
        };

        let url = "/requests/fileupload";
        const response = await api.post(url, processedData, {
          headers: {
            authorizationtoken: `${authToken}`,
          },
        });
        console.log("upload File response", response.data);

        if (response.data.status) {
        } else {
          setLoading(false);
          setAlertMsg(response.data.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("upload File error", error);
        setAlertMsg(error.response.data.message);
      }
    }
  };

  const calculateFeeAmount = (loanAmount) => {
    if (brokerdata?.hashchingUser === "yes") {
      return 0.04 * loanAmount;
    } else {
      return 0.05 * loanAmount;
    }
  };

  const postNewRequest = async () => {
    const processedData = {
      requestedBy: user.userId,
      emailId: user.emailId,
      name: values.fullName,
      loanAmount: values.loanAmount,
      settlementDate: values.settlementDate,
      requestDate: moment().format("YYYY-MM-DD"),
      dateTime: getCurrentTime(),
      repaymentDate: values.repaymentDate,
      loanApproved: values.loanApproved,
      fileurls: prevFile,
      aggregator: values.aggregator,
      commissionAdvance: parseInt(values.commissiomnAdvance),
      commissionRate: values.commissionRate,
      commissionDate: values.commissionDate,
      feeAmount: calculateFeeAmount(values.commissiomnAdvance),
      logDateTime: getCurrentTime(),
    };

    setLoading(true);

    try {
      let url = "/requests";
      const response = await api.post(url, processedData, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      console.log("PostNewRequest response", response.data);

      if (response.data.status) {
        uploadFile(response.data.requestId);
        setLoading(false);
        getRequestData();
        getBrokerdata();
        back();
      } else {
        setAlertMsg(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("PostNewRequest error", error);
      setAlertMsg(error.response.data.message);
    }
  };

  function formatFileSize(sizeInBytes) {
    const kiloByte = 1024;
    const megaByte = kiloByte * 1024;
    const gigaByte = megaByte * 1024;

    if (sizeInBytes >= gigaByte) {
      return (sizeInBytes / gigaByte).toFixed(2) + " GB";
    } else if (sizeInBytes >= megaByte) {
      return (sizeInBytes / megaByte).toFixed(2) + " MB";
    } else if (sizeInBytes >= kiloByte) {
      return (sizeInBytes / kiloByte).toFixed(2) + " KB";
    } else {
      return sizeInBytes + " bytes";
    }
  }

  const handleFormsubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    setErrors(Validation(values, brokerdata, prevFile));
    setTimeout(() => {
      if (
        Object.values(values).every((value) => value !== "") === true &&
        Object.values(errors).some((value) => value === "") === true
      ) {
        isValid && postNewRequest();
      }
    }, 300);
  };

  const handleRemoveTempFile = () => {
    setTempFile("");
    setUploadView(false);
  };

  const fileChange = (e) => {
    const file = e.target.files[0];

    const allowedExtensions = ["png", "jpg", "jpeg", "pdf"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (allowedExtensions.includes(fileExtension)) {
      const fileSizeInMB = file.size / (1024 * 1024);

      if (fileSizeInMB <= 2) {
        setTempFile(file);
        setUploadView(true);
        setErrors({
          ...errors,
          settleMentProof: "",
        });
      } else {
        setErrors({
          ...errors,
          settleMentProof:
            "Please upload a PDF, PNG, or JPG to support your application. Max file size permitted is 2MB.",
        });
      }
    } else {
      setErrors({
        ...errors,
        settleMentProof:
          "Sorry, that file type can’t be accepted as a proof of settlement. Please upload a PDF, PNG, or JPG file.",
      });
    }
  };

  const handleFileUpload = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const maxFileLimit = 10;
    if (prevFile.length >= maxFileLimit) {
      setErrors({
        ...errors,
        settleMentProof: `You can only upload up to ${maxFileLimit} files.`,
      });
      return;
    } else {
      setPrevFile((prevfiles) => [
        ...prevfiles,
        {
          url: URL.createObjectURL(file),
          name: file.name,
          type: fileExtension,
          fileType: file.type,
          size: formatFileSize(file.size),
          fileName: fileName,
          file: file,
        },
      ]);

      handleUpdate({
        settleMentProof: [...values.settleMentProof, file],
      });

      setErrors({
        ...errors,
        settleMentProof: "",
      });
    }
  };
  const tooltip1 =
    "The rate of commission your aggregator will pay you for this settlement";
  const tooltip2 =
    "How much of your expected commission would you like to receive in advance? This amount cannot be greater than the commission you`re expected to receive for this loan. currently Brokerpay allows advances of up to $12,000 - $15,000 per request";
  const tooltip3 =
    "Enter the date that your aggregator will pay out your commission for this client.";

  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  
  return (
    <UIView>
      <Container>
        <div className="tab-row">
          <div className="back-link">
            <KeyboardArrowLeftIcon
              onClick={() => navigate(-1)}
              className="primary pointer me-1"
            />
            New Request
          </div>
        </div>
        <Card style={{ width: "100%" }}>
          <Card.Body>
            <Form className="container" onSubmit={handleFormsubmit}>
              {alertMsg && (
                <Alert className="mb-2" severity="error">
                  {alertMsg}
                </Alert>
              )}

              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Full Name of Client:</Form.Label>
                    <input
                      placeholder="Name"
                      type="text"
                      name="fullName"
                      value={values.fullName}
                      maxLength={30}
                      onKeyDown={(e) => Onlytext(e)}
                      onChange={(event) => handleUpdateField(event, "fullName")}
                      className="name-input"
                    />
                    {errors.fullName && (
                      <p className="error">{errors.fullName}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Total Loan Amount:</Form.Label>
                    <CurrencyInput
                      placeholder="$"
                      name="loanAmount"
                      maxLength={9}
                      type="text"
                      value={values.loanAmount.toLocaleString()}
                      onChange={(e) => handleChangenumber(e, "loanAmount")}
                      className="name-input"
                      onKeyDown={(e) => {
                        exceptThisSymbols.includes(e.key) && e.preventDefault();
                        OnlyNumValue(e, 9);
                      }}
                    />
                    {errors.loanAmount && (
                      <p className="error">{errors.loanAmount}</p>
                    )}
                  </Form.Group>
                </Col>

                <Col md={6} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Commission Rate:
                      <LightTooltip title={tooltip1}>
                        <IconButton>
                          <img src={QuestionIcon} alt="icon" />
                        </IconButton>
                      </LightTooltip>
                    </Form.Label>
                    <input
                      placeholder="%"
                      type="number"
                      name="commissionRate"
                      maxLength={5}
                      value={values.commissionRate}
                      onKeyDown={(e) => {
                        percentage(e);
                      }}
                      onChange={(e) => handleRateChange(e, "commissionRate")}
                      className="name-input"
                    />
                    {errors.commissionRate && (
                      <p className="error">{errors.commissionRate}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Aggregator:</Form.Label>
                    <input
                      placeholder="Enter"
                      type="text"
                      name="aggregator"
                      maxLength={15}
                      onKeyDown={(e) => Onlytext(e)}
                      value={values.aggregator}
                      onChange={(event) =>
                        handleUpdateField(event, "aggregator")
                      }
                      className="name-input"
                    />
                    {errors.aggregator && (
                      <p className="error">{errors.aggregator}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>
                      Commission Advance Requested:
                      <LightTooltip title={tooltip2}>
                        <IconButton>
                          <img src={QuestionIcon} alt="QuestionIcon" />
                        </IconButton>
                      </LightTooltip>
                    </Form.Label>
                    <CurrencyInput
                      placeholder="$"
                      type="test"
                      name="commissiomnAdvance"
                      maxLength={9}
                      value={values.commissiomnAdvance}
                      onChange={(e) =>
                        handleChangenumber(e, "commissiomnAdvance")
                      }
                      className="name-input"
                    />
                    {errors.commissiomnAdvance && (
                      <p className="error">{errors.commissiomnAdvance}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6} sm={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Settlement Date:</Form.Label>
                    <DatePicker
                      placeholder={"---/---/---"}
                      className="input-style"
                      isClearable
                      format="YYYY-MM-DD"
                      // disabledDate={disabledDate}
                      onChange={(date) => {
                        handleUpdate({
                          settlementDate: moment(date).format("YYYY-MM-DD"),
                        });
                        if (date === null) {
                          errors.settlementDate =
                            "Please select the settlement date";
                        } else {
                          errors.settlementDate = "";
                        }
                      }}
                      suffixIcon={<img src={calendericon} alt="icon" />}
                    />
                    {errors.settlementDate && (
                      <p className="error">{errors.settlementDate}</p>
                    )}
                  </Form.Group>
                </Col>

                <Col md={6} sm={12}>
                  <Form.Group className="mb-3" controlId="repaymentDate">
                    <Form.Label>Repayment Date:</Form.Label>
                    <DatePicker
                      placeholder={"---/---/---"}
                      className="input-style"
                      isClearable
                      format="YYYY-MM-DD"
                      disabledDate={disabledDate}
                      onChange={(date) => {
                        handleUpdate({
                          repaymentDate: moment(date).format("YYYY-MM-DD"),
                        });
                        if (date === null) {
                          errors.repaymentDate =
                            "Please select a repayment date";
                        } else {
                          errors.repaymentDate = "";
                        }
                      }}
                      suffixIcon={<img src={calendericon} alt="icon" />}
                    />
                    {errors.repaymentDate && (
                      <p className="error">{errors.repaymentDate}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Col>
                <Form.Group className="mb-3" controlId="repaymentDate">
                  <Form.Label>
                    Date your commission will be paid:
                    <LightTooltip title={tooltip3}>
                      <IconButton>
                        <img src={QuestionIcon} alt="question" />
                      </IconButton>
                    </LightTooltip>
                  </Form.Label>
                  <DatePicker
                    placeholder={"---/---/---"}
                    className="input-style"
                    isClearable
                    format="YYYY-MM-DD"
                    disabledDate={disabledDate}
                    onChange={(date) => {
                      handleUpdate({
                        commissionDate: moment(date).format("YYYY-MM-DD"),
                      });
                      if (date === null) {
                        errors.commissionDate =
                          "Please select the date your comission will be paid";
                      } else {
                        errors.commissionDate = "";
                      }
                    }}
                    suffixIcon={<img src={calendericon} alt="icon" />}
                  />
                  {errors.commissionDate && (
                    <p className="error">{errors.commissionDate}</p>
                  )}
                </Form.Group>
              </Col>

              <Form.Label>Attachment</Form.Label>
              <input
                className="d-none"
                type="file"
                multiple
                ref={uploadRef}
                onChange={(e) => fileChange(e)}
              />
              <>
                <Row>
                  <Col md={6}>
                    <Table>
                      <thead>
                        <tr>
                          <th>File name</th>
                          <th>Type</th>
                          <th>Size</th>
                          <th></th>
                        </tr>
                      </thead>

                      {prevFile && prevFile.length ? (
                        <tbody>
                          {prevFile.map((data, index) => (
                            <tr>
                              <td>{data.fileName}</td>
                              <td>{data.type.toUpperCase()}</td>
                              <td>{data.size}</td>
                              <td
                                className="fw-bold pointer"
                                onClick={() => {
                                  setIndex(index);
                                  setShow(true);
                                }}
                              >
                                x
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : null}
                    </Table>
                    {(prevFile == "" || prevFile === []) && (
                      <div className="center no_douc"> No document added</div>
                    )}
                  </Col>
                  <Col md={6}>
                    <div className="proof-settlement p-2">
                      {uploadView ? (
                        <>
                          <div className="p-2">
                            <div>
                              <span className="fw-bold">Attached file:</span>{" "}
                              <span className="mx-1">{tempFile?.name}</span>
                              <span
                                onClick={() => handleRemoveTempFile()}
                                className="float-end fw-bold pointer"
                              >
                                x
                              </span>
                            </div>
                            <p className="primary fw-bold my-1">
                              Add a title for this document
                            </p>
                            <Row>
                              <Col
                                className="mb-1"
                                xs={12}
                                sm={9}
                                md={8}
                                lg={9}
                                xl={9}
                                xxl={10}
                              >
                                {" "}
                                <input
                                  className="filename_input p-1"
                                  placeholder="Enter file name"
                                  type="text"
                                  onChange={(e) => setFileName(e.target.value)}
                                />
                              </Col>
                              <Col xs={3} sm={3} md={4} lg={3} xl={3} xxl={2}>
                                <button
                                  onClick={() => {
                                    handleFileUpload(tempFile);
                                    handleRemoveTempFile();
                                  }}
                                  className="filename_btn px-4 py-1"
                                >
                                  Add
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        <div className="p=2">
                          <Row>
                            <Col
                              onClick={() => uploadRef.current.click()}
                              className="center pointer"
                            >
                              <span>
                                <img src={DcoumentIcon} alt="doc-icon" />
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="upload-doc">
                              <b>
                                Upload proof of Settlement. Accepted file types
                                include PDF, PNG, or JPG
                              </b>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </>
              {errors.settleMentProof && (
                <p className="error d-flex justify-content-center align-items-start gap-1">
                  {errors.settleMentProof}
                </p>
              )}
              <Row className="pb-3">
                <Col>
                  <Button type="submit" className={`theme-btn submit-btn px-4`}>
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <UIModal
        show={show}
        setShow={setShow}
        body={
          <div>
            <p className="center">Are you sure want to remove this file?</p>
            <div className="center">
              <button
                onClick={() => {
                  setPrevFile([
                    ...prevFile.slice(0, index),
                    ...prevFile.slice(index + 1, prevFile.length),
                  ]);

                  handleUpdate({
                    settleMentProof: [
                      ...values.settleMentProof.slice(0, index),
                      ...values.settleMentProof.slice(
                        index + 1,
                        values.settleMentProof.length
                      ),
                    ],
                  });
                  setShow(false);
                }}
                className="theme-btn px-4 me-2"
              >
                Yes
              </button>
              <button
                onClick={() => setShow(false)}
                className="theme-btn px-4 ms-2"
              >
                No
              </button>
            </div>
          </div>
        }
        close={true}
        size={"sm"}
      />
    </UIView>
  );
};

export default Newrequest;
