import sortIcon from '../../../assets/images/sort.svg';

const Sort = () => {
    return (
        <>
            <img className='pointer mx-1' src={sortIcon} />
        </>
    );
}

export default Sort;