import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Form from "react-bootstrap/Form";
import { Forms } from "..";
import { Validators } from "../../../../Utilities/validator";
import { api } from "../../../../Services/api";
import Styled from "styled-components";
import { getCurrentTime } from "../../CurrentDateTime";

const UserDetails = ({ setSubmittedForm, setPageView, email }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changeInForm, setChangeInForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [formArr, setFormArr] = useState([
    {
      label: "",
      name: "fisrtname",
      type: "text",
      placeholder: "First Name",
      maxLength: "15",
      validators: [
        {
          check: Validators.required,
          message: "This is a required field",
        },
        {
          check: Validators.name,
          message: "Your fist name should be between 2 and 15 characters.",
        },
      ],
      error: false,
      errormsg: "",
      value: "",
      show: true,
      col: 6,
    },
    {
      label: "",
      name: "lastname",
      type: "text",
      placeholder: "Last Name",
      maxLength: "15",
      validators: [
        {
          check: Validators.required,
          message: "This is a required field",
        },
        {
          check: Validators.name,
          message: "Your last name should be between 2 and 15 characters.",
        },
      ],
      error: false,
      errormsg: "",
      value: "",
      show: true,
      col: 6,
    },
    {
      label: "",
      name: "email",
      type: "email",
      placeholder: "Email address",
      validators: [
        {
          check: Validators.required,
          message: "This is a required field",
        },
        {
          check: Validators.email,
          message: "Please enter a valid email address.",
        },
      ],
      error: false,
      errormsg: "",
      value: email,
      show: true,
      col: 6,
    },
    {
      label: "",
      name: "phonenumber",
      type: "contactnumber",
      maxLength: 10,
      placeholder: "Phone number",
      validators: [
        {
          check: Validators.required,
          message: "This is a required field",
        },
        {
          check: Validators.phonenumber,
          message:
            "Please enter a valid Australian mobile number beginning with 04.",
        },
        {
          check: Validators.phonenumberLength,
          message: "Number must be 10 digits long",
        },
      ],
      error: false,
      errormsg: "",
      value: "",
      show: true,
      col: 6,
    },
    {
      label: "",
      name: "businessname",
      type: "busiessName",
      maxLength: "25",
      placeholder: "Business name",
      validators: [
        {
          check: Validators.required,
          message: "This is a required field",
        },
        {
          check: Validators.name,
          message: "Your business name should be between 2 and 25 characters.",
        },
      ],
      error: false,
      errormsg: "",
      value: "",
      show: true,
    },
    {
      label: "",
      name: "abn",
      type: "abn",
      maxLength: "11",
      placeholder: "ABN",
      validators: [
        {
          check: Validators.required,
          message: "This is a required field",
        },
        {
          check: Validators.abn,
          message: "Please enter a valid ABN",
        },
      ],
      error: false,
      errormsg: "",
      value: "",
      show: true,
      col: 6,
    },
    {
      label: "",
      name: "aggregator",
      type: "text",
      placeholder: "Aggregator",
      validators: [
        {
          check: Validators.required,
          message: "This is a required field",
        },
        {
          check: Validators.aggregatorLength,
          message: "Please enter a valid aggregator name",
        },
      ],
      error: false,
      maxLength: "15",
      errormsg: "",
      value: "",
      show: true,
      col: 6,
    },
    {
      label: "",
      name: "licence",
      type: "select",
      placeholder: "Select certification type",
      validators: [
        {
          check: Validators.required,
          message: "Please select your license type",
        },
      ],
      error: false,
      options: [
        { label: "CRN", id: "crn" },
        { label: "ACL", id: "acl" },
      ],
      errormsg: "",
      value: "",
      show: true,
      col: 6,
    },
    {
      label: "",
      name: "licencenumber",
      type: "licensenumber",
      placeholder: "CRN/ACL number",
      validators: [
        {
          check: Validators.required,
          message: "This is a required field",
        },
        {
          check: Validators.licensenumber,
          message: "Please enter a valid license number",
        },
      ],
      error: false,
      errormsg: "",
      value: "",
      show: true,
      col: 6,
    },
  ]);

  useEffect(() => {
    setFormArr([
      {
        label: "",
        name: "fisrtname",
        type: "text",
        placeholder: "First Name",
        maxLength: "15",
        validators: [
          {
            check: Validators.required,
            message: "This is a required field",
          },
          {
            check: Validators.name,
            message: "Your fist name should be between 2 and 15 characters.",
          },
        ],
        error: false,
        errormsg: "",
        value: "",
        show: true,
        col: 6,
      },
      {
        label: "",
        name: "lastname",
        type: "text",
        placeholder: "Last Name",
        maxLength: "15",
        validators: [
          {
            check: Validators.required,
            message: "This is a required field",
          },
          {
            check: Validators.name,
            message: "Your last name should be between 2 and 15 characters.",
          },
        ],
        error: false,
        errormsg: "",
        value: "",
        show: true,
        col: 6,
      },
      {
        label: "",
        name: "email",
        type: "email",
        placeholder: "Email address",
        validators: [
          {
            check: Validators.required,
            message: "This is a required field",
          },
          {
            check: Validators.email,
            message: "Please enter a valid email address.",
          },
        ],
        error: false,
        errormsg: "",
        value: email,
        show: true,
        col: 6,
      },
      {
        label: "",
        name: "phonenumber",
        type: "contactnumber",
        maxLength: 10,
        placeholder: "Phone number",
        validators: [
          {
            check: Validators.required,
            message: "This is a required field",
          },
          {
            check: Validators.phonenumber,
            message:
              "Please enter a valid Australian mobile number beginning with 04.",
          },
          {
            check: Validators.phonenumberLength,
            message: "Number must be 10 digits long",
          },
        ],
        error: false,
        errormsg: "",
        value: "",
        show: true,
        col: 6,
      },
      {
        label: "",
        name: "businessname",
        type: "busiessName",
        maxLength: "25",
        placeholder: "Business name",
        validators: [
          {
            check: Validators.required,
            message: "This is a required field",
          },
          {
            check: Validators.name,
            message:
              "Your business name should be between 2 and 25 characters.",
          },
        ],
        error: false,
        errormsg: "",
        value: "",
        show: true,
      },
      {
        label: "",
        name: "abn",
        type: "abn",
        maxLength: "11",
        placeholder: "ABN",
        validators: [
          {
            check: Validators.required,
            message: "This is a required field",
          },
          {
            check: Validators.abn,
            message: "Please enter a valid ABN",
          },
        ],
        error: false,
        errormsg: "",
        value: "",
        show: true,
        col: 6,
      },
      {
        label: "",
        name: "aggregator",
        type: "text",
        placeholder: "Aggregator",
        validators: [
          {
            check: Validators.required,
            message: "This is a required field",
          },
          {
            check: Validators.aggregatorLength,
            message: "Please enter a valid aggregator name",
          },
        ],
        error: false,
        maxLength: "15",
        errormsg: "",
        value: "",
        show: true,
        col: 6,
      },
      {
        label: "",
        name: "licence",
        type: "select",
        placeholder: "Select certification type",
        validators: [
          {
            check: Validators.required,
            message: "Please select your license type",
          },
        ],
        error: false,
        options: [
          { label: "CRN", id: "crn" },
          { label: "ACL", id: "acl" },
        ],
        errormsg: "",
        value: "",
        show: true,
        col: 6,
      },
      {
        label: "",
        name: "licencenumber",
        type: "licensenumber",
        placeholder: "CRN/ACL number",
        validators: [
          {
            check: Validators.required,
            message: "This is a required field",
          },
          {
            check: Validators.licensenumber,
            message: "Please enter a valid license number",
          },
        ],
        error: false,
        errormsg: "",
        value: "",
        show: true,
        col: 6,
      },
    ]);
  }, [email]);

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth();
  let dd = today.getDate();

  let formatDate = dd + "/" + mm + "/" + yyyy;

  const SubmitForm = (form, valid) => {
    if (valid) {
      setLoading(true);
      let licence = form.licence;
      const obj = {
        firstName: form.fisrtname,
        lastName: form.lastname,
        emailId: form.email,
        phoneNumber: form.phonenumber,
        businessName: form.businessname,
        abn: form.abn,
        aggregator: form.aggregator,
        licenceNumber:
          licence === "crn"
            ? {
                CRN: form.licencenumber,
                ACL: "",
              }
            : {
                CRN: "",
                ACL: form.licencenumber,
              },
        address: "",
        DOB: "",
        createddate: formatDate,
        hashchingUser: "no",
        createdDate : getCurrentTime(),
        profile: '',
        logDateTime: getCurrentTime(),
      };
      api
        .post("/register", obj)
        .then(function (response) {
          if (response.data.status === true) {
            setPageView("submissionScreen");
            setSubmitted(true);
            setLoading(false);
            setSubmittedForm(true);
            setError(false);
          }
        })
        .catch(function (error) {
          if (error.response.data.status === false) {
            setError(true);
            setErrorMessage(error.response.data.message);
            console.log(
              "loginResponse [error]-->",
              error.response.data.message
            );
          }
          setLoading(false);
        });
    }
  };

  return (
    <>
      <UserDetailsWrap>
        <div>
          <div className="loginFormScreens flex-fill bg-white">
            <Form.Group>
              <Form.Label
                style={{
                  fontSize: "32px",
                  color: " #0094FF",
                }}
                className="mx-2"
              >
                <b>User Details</b>
              </Form.Label>
            </Form.Group>
            {error ? (
              <Alert className="m-2 p-1 text-center" variant="danger">
                {errorMessage}
              </Alert>
            ) : (
              <></>
            )}
            <Forms
              title={""}
              formArr={formArr}
              submitBtn={"Submit"}
              onSubmit={SubmitForm}
              loading={loading}
              submitted={submitted}
              changeInForm={changeInForm}
              setChangeInForm={setChangeInForm}
              back={true}
              register={true}
              value={true}
              setPageView={setPageView}
              defaultValue={email}
            />
          </div>
        </div>
      </UserDetailsWrap>
    </>
  );
};

const UserDetailsWrap = Styled.div`

.back_btn{
  float: left !important;
}

.submit_btn{
  float: right !important;
}

.text-center{
  padding-left: 0 !important;
}

`;

export default UserDetails;
