import React, { useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Howitworks from "./HowItworks";
import FAQ from "./FAQ";
import Contact from "./Conatctus";
import TermsConditions from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import logo from "../../../assets/landingpage/img/brokerpay.png";
import brokerpay from "../../../assets/landingpage/img/Group_28.png";
import LandingPageWrap from "./style";
import SignupModal from "./SignupModal";

const LandingPage = () => {
  const [pageView, setPageView] = useState("home");
  const [show, setShow] = useState(false);

  $(window).on("scroll", function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 200) {
      $(".app-header").addClass("sticky-nav");
    } else {
      $(".app-header").removeClass("sticky-nav");
    }
  });

  function handleCallClick() {
    window.location.href = "tel:1800444744";
  }

  const handleModalOpen = () => {
    document.body.classList.add('signup_modal_open');
  };

  return (
    <>
      <LandingPageWrap>
        <>
          <div>
            {/* header start  */}
            <div className="app-header box-container-fluid">
              <div style={{ width: "100%" }}>
                <div className="logo mt-3">
                  <Link to="/">
                    {" "}
                    <img
                      className="logo-brokerpay"
                      style={{ cursor: "pointer" }}
                      src={logo}
                      srcSet={logo}
                      alt="Brokerpay"
                    />
                  </Link>
                </div>
                <div className="btn-group float-end">
                  <Link to="/login">
                    <button className="btn-login login-nav me-5">Log in</button>
                  </Link>
                  <button
                    onClick={() => {setShow(true); handleModalOpen()}}
                    className="btn-signin "
                  >
                    Sign up
                  </button>
                </div>
              </div>
            </div>
            {/* header end  */}
            {/* Menu start */}
          </div>
          <header className="app-header menu-bar" id="appHeader">
            <div className="heading">
              <div className="nav-bar">
                <nav id="navbar">
                  <ul className="heading-content">
                    <li className="btn-heading">
                      <b>
                        <a
                          onClick={() => {
                            setPageView(false);
                          }}
                          href="#howitworks"
                        >
                          How It Works
                        </a>
                      </b>
                    </li>
                    <li className="btn-heading1">
                      <b>
                        <a
                          onClick={() => {
                            setPageView(false);
                          }}
                          href="#faq"
                        >
                          FAQ
                        </a>
                      </b>
                    </li>
                    <li className="btn-heading2">
                      <b>
                        <a
                          onClick={() => {
                            setPageView(false);
                          }}
                          href="#Applynow"
                        >
                          Contact Us
                        </a>
                      </b>
                    </li>
                    {/* <li className="number"><b>1800 444 744</b></li> */}
                  </ul>
                </nav>
              </div>
            </div>
          </header>
          {/* Menu end */}

          {pageView === "TermsConditions" ? (
            <section id="TermsConditions">
              <TermsConditions />
            </section>
          ) : pageView === "PrivacyPolicy" ? (
            <section id="PrivacyPolicy">
              <PrivacyPolicy />
            </section>
          ) : (
            <>
              <section className="pt-5">
                <Howitworks setShow={setShow} />
              </section>
              <section className="work pt-5">
                <div>
                  <div className="container-fluid">
                    <div className="work-heading">
                      <p className="work-heading-content">
                        How Does Brokerpay Work?
                      </p>
                      <div className="work-content">
                        <div className="num">
                          <p className="num1">1</p>
                          <p className="num2">2</p>
                          <p className="num3">3</p>
                        </div>
                        <div className="num1-heading">
                          <p className="num1-content1">Create your account</p>
                          <p className="num1-content2 box-text">
                            Provide us with a few details about your business so
                            we can verify your eligibility to receive funding.
                          </p>
                          <p className="num2-content1">
                            Provide proof of settlement
                          </p>
                          <p className="num2-content2 box-text">
                            Provide settlement documents for the deal you want
                            funded.
                          </p>
                          <p className="num3-content1">
                            Funds approved in as little as 24 hours
                          </p>
                          <p className="num3-content2 box-text">
                            The Brokerpay team will review your application and
                            approve your funds same-day.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="faq" className="mb-5" />
              <section>
                <FAQ />
              </section>
              <section id="Applynow" className="mb-5" />
              <section className="mb-5">
                <Contact />
              </section>
            </>
          )}

          <footer className="footer">
            <div className="container">
              <div className="row foot-row">
                <div className="col-md-3 col-sm-6">
                  <Link to="/">
                    {" "}
                    <img
                      className="brokerpay pointer"
                      src={brokerpay}
                      srcSet={brokerpay}
                      alt="Brokerpay"
                    />
                  </Link>
                </div>
                <div className="col-md-3 col-sm-6">
                  <ul className="foot-col2">
                    <li>
                      <a href="#howitworks">How It Works</a>
                    </li>
                    <li>
                      <a href="#faq">FAQ</a>
                    </li>
                    <li>
                      <a href="#Contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-contact col-md-3 col-sm-6">
                  <ul className="foot-col2">
                    <li>
                      <Link to="/login">Sign In</Link>
                    </li>
                    <li>
                      <div className="pointer" onClick={() => setShow(true)}>Sign Up</div>
                    </li>
                  </ul>
                </div>
                <div className="footer-contact col-md-3 col-sm-3">
                  <ul className="foot-col2">
                    {/* <li>Contact</li>
                    <li>
                      <Link to="#" onClick={handleCallClick}>
                        1800-444-744
                      </Link>
                    </li> */}
                    <li>
                      <a href="mailto: customercare@brokerpay.com.au">
                        customercare@brokerpay.com.au
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="secondary-footer">
              <div className="container">
                <div className="row foot-col3">
                  <div className="col-md-3 col-sm-3 foot-col4">
                    <p
                      onClick={() => {
                        setPageView("PrivacyPolicy");
                      }}
                    >
                      <a href="#PrivacyPolicy">Privacy Policy</a>
                    </p>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <p
                      onClick={() => {
                        setPageView("TermsConditions");
                      }}
                    >
                      <a href="#TermsConditions">Terms & Conditions</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      </LandingPageWrap>

      <SignupModal setShow={setShow} show={show} />
    </>
  );
};
export default LandingPage;
