import Styled from "styled-components";


const TableWrap = Styled.div`


.scroll-container::-webkit-scrollbar {
    background: #E8E8E8;
    height: 8px;
  }
  .scroll-container::-webkit-scrollbar-thumb {
    background-color: #0094FF;
    border-radius: 6px;
    border: 3px solid  #0094FF;
  }
//   .table-div{
//     min-height: 60vh;
//     max-height: 80vh;
//   }

.table-striped>tbody>tr:nth-of-type(odd)>* {
    color:${({ theme }) => theme["table_data-text"]};
    --bs-table-accent-bg:  #F8F7F7;
    // text-align:center;
   
}
.table-bordered>:not(caption)>*>* {
    border: none;
    // padding: 10px;
}
        .table>:not(:first-child) {
            // width:600px;
            border-top: 1px solid ${({ theme }) => theme["border"]};
            vertical-align: middle;
            text-align:center;
        }
        .table
        {
            // border-color: ${({ theme }) => theme["border"]};
            border-color: transparent;
            // vertical-align: middle;
        }
        
        // .table-view-card, .card{
        //     min-height: calc(100vh - 190px);
        // }
        table {
            padding-left:0px;
            margin-left:0px;
            border-collapse: collapse;
            
            // border-right:hidden;
            thead {
                th {
                    /* padding: 20px; */
                    font-size:18px;
                    font-weight:600;
                    font: normal normal medium 18px/21px Roboto;
                    text-align:center;
                    vertical-align: middle;
                    // background-color: yellow;
                    
                }
            }
            tbody {
                tr {
                    td {
                        // border: 1px solid #ddd;
                        line-height: 1.42857143;
                        vertical-align: middle;
                        // width:200px;
                        color:#131313;
                        font: normal normal normal 18px/21px Roboto;
                        // font-size: 18px;
                        width:300px;
                        // background-color:yellow;
                    }
                    // th {
                    //     // border: 1px solid #ddd;
                    //     background-color:yellow;
                    //     line-height: 1.42857143;
                    //     vertical-align: middle;
                    //     padding: 10px !important;
                    //     color:#131313;
                    //     font-size: 14px;
                    //     white-space: nowrap;
                    //     text-align: center;
                    // }
                }
            }
            tr td {
                font-size: 15px ! important;
                padding-top: 10px !important;
                padding-bottom: 10px !important;
                white-space: nowrap;

             }
            tr th{
                white-space: nowrap; 
                font-size: 15px ! important;
            }
            .table>:not(:first-child) {
                border-top: 1px solid currentColor;
            }
            td:nth-child(1) {
                border-left:none;
              }
            th:nth-child(1) {
                border-left:none;
              }
            
             
            .imgfirstLetr {
                display: inline-block;
                background-color: #c3c3c3;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                color: #fff;
                box-shadow: inherit;
                font-size: 18px;
                
                padding: 6px 6px;
                margin: 0 auto;
                text-align: center;
                vertical-align: middle;
            }
            .imgFirstLetMain {
                span {
                    display: inline-block;
                    vertical-align: inherit;
                }
            }
            .btn-outline-light {
                color: #212529;
                border-color: #d3d3d3;
                background-color: yellow;
                // font-size:24px;
                &:hover {
                    color: #212529;
                    background-color: #f8f9fa;
                    border-color: #999;
                }
            }
            .indicator {
                // background: #5db239;
                background-color:yellow;
                border-radius: 50%;
                display: block;
                height: 8px;
                width: 8px;
                padding: 1px;
                position: absolute;
                bottom: 5px;
                top: inherit;
                right: -3px;
                text-align: center;
                transition: top 0.1s ease-out;
                font-size: 0.675rem;
                color: #ffffff;
            }
            .actionLink {
                svg {
                    height: 25px;
                    path {
                        fill: ${({ theme }) => theme["orange-color"]};
                    }
                }
                padding: 5px;
            }
        }
        table.border-0 {
            tbody {
                tr {
                    td {
                        border: inherit;
                        span {
                            font-size: 14px;
                        }
                    }
                    th {
                        border: inherit;
                    }
                }
            }
        }
        .table {
            
                &:not(:first-child) {
                    border-top: inherit;
                }
            
        }
        @media (max-width: 991px) {
            table {
                tbody {
                    tr {
                        td {
                            // white-space: nowrap;
                        }
                        th {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        @media (max-width: 780px) {
            table {
                thead {
                    tr {
                        th {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }

        /* @media (max-width: 1505px) {
            .table-bordered>:not(caption)>*>* {
                min-width:190px;
            
          }
        } */
        
`;

export default TableWrap;
