import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import RequestWrap from "./style";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserReport from "./UserReport";
import PaymentReport from "./PaymentReport";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { api } from "../../../../Services/api";
import { useSelector } from "react-redux";

const Reports = ({ userLoading }) => {

    const authToken = useSelector((state) => state.Auth.authToken);
    const permissions = useSelector((state) => state.Tracking.permissions.Administrative);

    const [key, setKey] = useState(permissions && permissions.viewUserReports ?'user' : 'Payment');
    const [paymentData, setpaymentData] = useState([]);

    useEffect(() => {
        getPaymentsData()
    }, [])

    const getPaymentsData = async () => {
        try {
            let url = `/requests/completed`
            const response = await api.get(url, {
                headers: {
                    'authorizationtoken': `${authToken}`,
                }
            })
            if (response.data.status === true) {
                setpaymentData(response.data.data)
            }
        } catch (error) {
            console.log('getPaymentsData [error]-->', error)
        }
    }

    return (
        <React.Fragment>
            <RequestWrap>
                <Container>
                    <div className="tab-row">
                        <div
                            className="back-link"
                        >
                            Reports
                        </div>

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="tab-group mb-3"
                        >
                            <Tab
                                disabled={permissions && permissions.viewUserReports ? false : true}
                                className={key === 'new' ? "active" : ''}
                                eventKey="user"
                                title="User reports"
                            >
                                <UserReport userLoading={userLoading} />
                            </Tab>
                            <Tab
                                disabled={permissions && permissions.viewpaymentsReports ? false : true}
                                className={key === 'all' ? "active" : ''}
                                eventKey="Payment"
                                title="Payment reports"
                            >
                                <PaymentReport paymentData={paymentData} />
                            </Tab>
                        </Tabs>
                    </div>
                </Container>
            </RequestWrap>
        </React.Fragment>
    );
};

export default Reports;
