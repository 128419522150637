import React from "react";
import UIGraphView from "./style";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const UIBarChart = ({ data, stroke, title }) => {
  return (
    <>
      <UIGraphView>
        <p className="graph-title">{title}</p>
        <div className="white-bg center">
          <ResponsiveContainer width="100%" height={250}>
            <BarChart
              data={data}
              barSize={30}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                axisLine={{ stroke: "#0094FF", strokeWidth: 2 }}
              />
              <YAxis tickFormatter={(tick) => `$${tick.toLocaleString()}`} />
              <Tooltip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div
                        style={{
                          backgroundColor: "#95d7f985",
                          padding: "10px",
                          color: "#0094FF",
                        }}
                      >
                        {`$${payload[0].value.toLocaleString()}`}
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Legend />
              <Bar dataKey="$" fill={stroke} label={null} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </UIGraphView>
    </>
  );
};
export default UIBarChart;
