import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import UsersWrap from "./style";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import UserTable from "./UserTable";
import NewUser from "./NewUser";
import DeletedUser from "./DeletedUser";
import { api } from "../../../../Services/api";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";
import { useMedia } from "react-use";

const Users = ({ getAllActivityLog, getAllUserData }) => {
  const authToken = useSelector((state) => state.Auth.authToken);
  const AllUsers = useSelector((state) => state.Tracking.allUsers);

  const [key, setKey] = useState("userlist");
  const [viewuser, setViewUser] = useState([]);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deletedUsers, setdeletedusers] = useState([]);
  const [invisible, setInvisible] = useState(false);

  const Newusers = AllUsers.filter((val) => {
    if (val.status === "Unverified") {
      return val;
    }
  });
  const responsiveMenu = useMedia("(max-width: 900px)");

  useEffect(() => {
    getDeletedUsers();
  }, []);

  useEffect(() => {
    setInvisible(Newusers == "" || Newusers == undefined ? true : false);
  }, [Newusers]);

  const getDeletedUsers = async () => {
    await api
      .get("/deleteuser", {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          setdeletedusers(response.data.data);
        }
      })
      .catch(function (error) {
        console.log("getDeletedUsers [error]-->", error.response.data);
      });
  };

  useEffect(() => {
    getNotes();
  }, [viewuser]);

  const getNotes = async () => {
    setLoading(true);
    await api
      .get("/adminnotes/" + viewuser.userId, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          setNotes(response.data.data);
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("getNotes [error]-->", error.response.data);
      });
  };

  return (
    <React.Fragment>
      <UsersWrap>
        <Container>
          <div className="tab-row">
            <div className="back-link">Users</div>

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="tab-group mb-3"
            >
              <Tab
                className={key === "userlist" ? "active" : ""}
                eventKey="userlist"
                title="User list"
              >
                <UserTable
                  title={"User List"}
                  setViewUser={setViewUser}
                  viewuser={viewuser}
                  getNotes={getNotes}
                  notes={notes}
                  setNotes={setNotes}
                  getAllUserData={getAllUserData}
                  getDeletedUsers={getDeletedUsers}
                  getAllActivityLog={getAllActivityLog}
                />
              </Tab>

              <Tab
                className={key === "newuser" ? "active" : ""}
                eventKey="newuser"
                // title="New users"
                title={
                  <Badge invisible={invisible} color="error" variant="dot">
                    New users
                  </Badge>
                }
              >
                <NewUser
                  title={"New users"}
                  getAllActivityLog={getAllActivityLog}
                  setInvisible={setInvisible}
                />
              </Tab>
              <Tab
                className={key === "deleteduser" ? "active" : ""}
                eventKey="deleteduser"
                title="Deleted users"
              >
                <DeletedUser
                  title={"Deleted users"}
                  setViewUser={setViewUser}
                  viewuser={viewuser}
                  getNotes={getNotes}
                  notes={notes}
                  setNotes={setNotes}
                  getAllUserData={getAllUserData}
                  getDeletedUsers={getDeletedUsers}
                  deletedUsers={deletedUsers}
                  getAllActivityLog={getAllActivityLog}
                />
              </Tab>
            </Tabs>
          </div>
        </Container>
      </UsersWrap>
    </React.Fragment>
  );
};

export default Users;
