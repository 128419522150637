import Styled from "styled-components";

const Cardwrap = Styled.div`

.circle-position {
  position: relative;
}

.userCircle {
  position: absolute;
  top: 15px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #1958a7;
}

.threeDot {
  right: 20px;
  top: 20px;
  position:absolute;
  cursor: pointer;
}

.info-required {
  color: #FF7F00;
  margin-bottom: 0;
}
.processing {
  color: #FFBA00;
  margin-bottom: 0;
}

.declined {
  color: #FF0000;
  margin-bottom: 0;
}
   

.card {
	border: 1 solid #dedede;
  width:100%;
	
	box-shadow: 0px 2px 6px #00000029;
  // height: 100vh;
  // width:100%;
}
.cardbody{
  padding: 1rem 1rem;
  
  font: normal normal medium 24px/35px Poppins;
letter-spacing: 0px;
color: #000000;
border-radius: 10px;
border: 1px solid #26C984;
}
.editpopup{
  
}
.user-FL {
 display-flex;
 vertical-align; center;
}
.user-img{
  width: 98px;
height: 98px;
}
.flagPosition {
  top: -2px;
}
.deletebtn{
  color: #FF0000;
  font-size: 20px;
}
.Results {
  font: normal normal normal 16px/21px Roboto;
  color: #303030;
  padding-left:20px;
}

.request-id {
    font: normal normal medium 18px/21px Roboto;
    color: #0094FF;
    padding-left:20px;
    font-weight: bold;
 }

 .request-date {
  padding-bottom: 20px;
 }

 .status {
  font: normal normal normal 14px/16px Roboto;
  font-weight: 500;
 }

.title{
  font-size: 20px;
  letter-spacing: 0px;
}
.popuptext{
  font-size: 20px;
}
.popup-content{
  width: 80px;
}
.dropdown-menu{
  width: 180px;
height: 141px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 6px #00000030;
}

@media (max-width: 732px) {
  .user-FL {
    top: 45px;
    left: 47px;
    font-weight: 450;
  }
  .threeDot {
    right: 20px;
    cursor: pointer;
    position: initial !important;
  }
  
}

@media (max-width: 768px) {
  .user-FL {
    top: 46px;
    left: 46px;
    font-weight: 450;
  }

  .threeDot {
    right: 20px;
    cursor: pointer;
    position: initial !important;
  }
}

@media (max-width: 1400px) {
  
  
  .Results {
    font: normal normal normal 14px/21px Roboto;
    padding-left:10px;
  }

  .request-id {
    padding-left: 10px;
  }
}


`;

export default Cardwrap;

