import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import UserPaymentTable from "./UserPaymentTable";
import UserNotes from "./UserNotes";
import UserProfile from "./UserProfile";
import Searchbox from "../../../../Reuseable/Searchbox";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Delete from "../../../../../assets/images/icons/delete.svg";
import Edit from "../../../../../assets/images/icons/edit.svg";
import UsersWrap from "../style";
import BarLoader from "react-spinners/ClipLoader";
import NewUsersWrap from "../NewUser/style";
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import { api } from "../../../../../Services/api";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import UIModal from "../../../../Reuseable/UIModal";
import Alerts from "@mui/material/Alert";
import { format } from "date-fns";
import { NoDataFound } from "../../../../Reuseable/NoDataFound";
import Styled from "styled-components";
import Iframe from "react-iframe";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import UserLinkedAccount from "./UserLinkedAccount";
import { Money } from "../../../../Reuseable/NumberFormatter";
import hashching from "../../../../../assets/images/hashching_logo.svg";
import OnboardingPanel from "./Onbording";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { getCurrentTime } from "../../../../Reuseable/CurrentDateTime";

const UsersActivityWrap = Styled.div`

th{
  background: #E6E6E6 0% 0% no-repeat padding-box;
}

td{
  background: #F5F5F5 0% 0% no-repeat padding-box;
}

.table>tbody>tr:hover>* { 
  --bs-table-accent-bg: #F5F5F5 !important;
  background: #F5F5F5  !important;
}

`;

const UserDataPreview = ({
  show,
  setShow,
  deleteView,
  viewuser,
  setViewUser,
  notes,
  getNotes,
  setNotes,
  reactivateUser,
  loading,
  getAllUserData,
  getDeletedUsers,
  getAllActivityLog,
}) => {
  const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");

  const allRequest = useSelector((state) => state.Tracking.allRequest);
  const allActivityLog = useSelector((state) => state.Tracking.allActivityLog);
  const authToken = useSelector((state) => state.Auth.authToken);
  const permissions = useSelector(
    (state) => state.Tracking.permissions.Account
  );

  const tableRef = useRef(null);

  const [searchterm, setSearchterm] = useState("");
  const [tabView, settabView] = useState("onboarding");
  const [edit, setEdit] = useState(false);
  const [editnode, setEditNode] = useState("");
  const [editValue, setEditValue] = useState("");
  const [showDelete, setshowDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [contract, setContract] = useState("");
  const [bankData, setBankData] = useState("");
  const [open, setOpen] = useState(false);

  const columns = [
    "Request date",
    "Request ID",
    "Client name",
    "Amount",
    "Request status",
    "Repayment status",
    "Repayment date",
  ];

  const userPayments = allRequest.filter((val) => {
    if (val.requestedBy == viewuser?.userId) {
      if (val.status !== "Declined") {
        return val;
      }
    }
  });
  const tbody = (
    <tbody className="modal-table">
      {userPayments &&
        userPayments
          .filter((val) => {
            if (searchterm === "") {
              return val;
            } else if (
              val.name.toLowerCase().includes(searchterm.toLowerCase()) ||
              val.requestId.toLowerCase().includes(searchterm.toLowerCase())
            ) {
              return val;
            }
          })
          .map((data) => {
            return (
              <tr>
                <td>{data.dateTime}</td>
                <td>{data.requestId}</td>
                <td>{data.name}</td>
                <td>{Money(data.commissionAdvance)}</td>
                <td
                  className={
                    data.status === "pending"
                      ? "pending"
                      : data.status === "Approved"
                      ? "approved"
                      : "declined"
                  }
                >
                  {data.status}
                </td>
                <td>{data.repaymentStatus}</td>
                <td>{formatDate(data.repaymentDate)}</td>
              </tr>
            );
          })}
    </tbody>
  );

  const AccountActivityData = allActivityLog.filter((val) => {
    if (val.userId == viewuser?.userId) {
      return val;
    }
  });

  const accountColumn = ["Date and Time", "Action", "Notes"];

  const Accounttbody = (
    <tbody className="modal-table">
      {AccountActivityData
      .filter((val) => {
        if (val.viewMode == "Admin") {
          return false;
        } else {
          return val;
        }
      })
      .map((row) => (
        <tr>
          <td>{row.datetime}</td>
          <td className="action-column">{row.action}</td>
          <td>{row.notes}</td>
        </tr>
      ))}
    </tbody>
  );

  const handleChange = (event) => {
    settabView(event.target.value);
  };

  const handleEditChange = (e) => {
    setEditValue(e.target.value);
  };

  const editUserData = async () => {
    setEditNode("");

    let obj =
      editnode === "DOB"
        ? {
            userId: viewuser.userId,
            emailId: viewuser.emailId,
            name: editnode,
            value: formatDate(editValue),
            logDateTime: getCurrentTime(),
          }
        : {
            userId: viewuser.userId,
            emailId: viewuser.emailId,
            name: editnode,
            value: editValue,
            logDateTime: getCurrentTime(),
          };
    if (editnode === "score") {
      viewuser.score = editValue;
    } else if (editnode === "brokerLimit") {
      viewuser.brokerLimit = editValue;
    } else if (editnode === "personname") {
      viewuser.personname = editValue;
    } else if (editnode === "phoneNumber") {
      viewuser.phoneNumber = editValue;
    } else if (editnode === "emailId") {
      viewuser.emailId = editValue;
    } else if (editnode === "address") {
      viewuser.address = editValue;
    } else if (editnode === "aggregator") {
      viewuser.aggregator = editValue;
    } else if (editnode === "accountDetails.Accountnumber") {
      viewuser.accountDetails.Accountnumber = editValue;
    } else if (editnode === "ABN") {
      viewuser.ABN = editValue;
    } else if (editnode === "creditScore") {
      viewuser.creditScore = editValue;
    } else if (editnode === "faEnabled") {
      viewuser.faEnabled = editValue;
    } else if (editnode === "DOB") {
      viewuser.DOB = formatDate(editValue);
    }
    await api
      .patch("/brokerpayuserauth/userlists", obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          getAllActivityLog();
          getAllUserData();
        }
      })
      .catch(function (error) {
        console.log("editUserData [error]-->", error.response);
      });
  };

  const numFormat = (e) => {
    const numberFormat = /^[0-9]+$/;
    var key = e.keyCode || e.charCode;
    if (numberFormat.test(e.key) || key == 8 || key == 46) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const Onlytext = (e) => {
    const re = /^[A-Za-z]+$/;
    if (e.key === " " || re.test(e.key)) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const getBankDetails = async () => {
    await api
      .get("/userbankdetails/" + viewuser.userId, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status) {
          let data = response.data.data;
          setBankData(data);
        }
      })
      .catch(function (error) {
        console.log("getBankDetails [error]-->", error.response.data);
      });
  };

  const ConfirmDeleteAccount = async (data) => {
    setDeleteLoading(true);
    const obj = {
      userId: data.userId,
      emailId: data.emailId,
      logDateTime: getCurrentTime(),
    };
    try {
      let url = "/deleteuser";
      const response = await api.post(url, obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status) {
        getAllUserData();
        setshowDelete(false);
        setDeleteLoading(false);
        setShow(false);
        getDeletedUsers();
        viewuser.status = "Deleted";
      } else {
        setAlertMsg(response.data.message);
        setshowDelete(false);
        setDeleteLoading(false);
      }
    } catch (error) {
      setDeleteLoading(false);
      console.log("ConfirmDeleteAccount [error]-->", error);
    }
  };

  useEffect(() => {
    if (show) getFile();
    getBankDetails();
  }, [show]);

  const getFile = async () => {
    try {
      let url = `/usercontract/${viewuser.userId}?emailId=${viewuser.emailId}`;
      const response = await api.get(url, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status) {
        setContract(response.data.URL);
      } else {
        setContract(false);
      }
    } catch (error) {
      console.log("Approverequest [error]-->", error);
    }
  };

  const deleteFile = async () => {
    try {
      let url = `/brokerpayuserauth/contract/${viewuser.userId}`;
      const response = await api.delete(url, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status) {
        setContract(false);
        getFile();
        setOpen(false);
        setShow(true);
      }
    } catch (error) {
      console.log("Approverequest [error]-->", error);
    }
  };

  const ConfirmDelete = () => {
    return (
      <UsersWrap>
        <div>
          <div className="center m-2">
            Are you sure you want to delete this user?
          </div>
          <div className="center m-2">
            <Button
              className="me-2 px-4 theme-btn"
              onClick={() => {
                ConfirmDeleteAccount(viewuser);
              }}
            >
              {deleteLoading ? (
                <BarLoader
                  as="span"
                  size={14}
                  animation="grow"
                  color="#00C6FF"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Yes"
              )}
            </Button>
            <Button
              className="ms-2 px-4 theme-btn"
              onClick={() => setshowDelete(false)}
            >
              No
            </Button>
          </div>
        </div>
      </UsersWrap>
    );
  };

  const convertToCSV = () => {
    if (!tableRef.current) return;

    const tableData = [];
    const headers = [];
    const rows = tableRef.current.querySelectorAll("tbody tr");

    tableRef.current.querySelectorAll("thead th").forEach((th) => {
      headers.push(th.innerText);
    });

    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.innerText);
      });
      tableData.push(rowData);
    });

    const csvData = [headers, ...tableData];
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    saveAs(blob, `${viewuser.userId} Payment-report.csv`);
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          settabView("onboarding");
          // setNotes('')
          setEdit(false);
          setEditNode("");
          setAlertMsg("");
          setContract("");
        }}
        className="mt-5 mb-5"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <UsersWrap>
            <Container className="body">
              {alertMsg && (
                <Alerts className="mb-2 m-2 center" severity="error">
                  {alertMsg}
                </Alerts>
              )}
              <div>
                <span>User details</span>
              </div>
              <div>
                <Card className="themegradient link-card user-card">
                  <Row>
                    <Col md={12} lg={5}>
                      <div>
                        <div className="user-icons">
                          {deleteView ? (
                            <>
                              {loading ? (
                                <BarLoader
                                  as="span"
                                  size={14}
                                  animation="grow"
                                  color="#00C6FF"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                <button
                                  className="reactive"
                                  onClick={() => reactivateUser(viewuser)}
                                >
                                  reactivate
                                </button>
                              )}
                            </>
                          ) : permissions && permissions.deleteAccounts ? (
                            <Button
                              className="delete-btn"
                              disabled={alertMsg && true}
                            >
                              <img
                                className="pointer"
                                alt={"delete"}
                                src={Delete}
                                disabled={alertMsg && true}
                                onClick={() => {
                                  setshowDelete(true);
                                }}
                              />
                            </Button>
                          ) : null}
                          {permissions &&
                          permissions.updateuserProfileinformation ? (
                            <>
                              {edit ? (
                                <EditOffIcon
                                  className="
                                   mt-2"
                                  onClick={() => {
                                    setEdit(false);
                                    setEditNode("");
                                  }}
                                />
                              ) : (
                                <img
                                  className="pointer"
                                  onClick={() => {
                                    setEdit(true);
                                  }}
                                  alt={"edit"}
                                  src={Edit}
                                />
                              )}
                            </>
                          ) : null}
                        </div>
                        <div>
                          <div className="name">
                            {viewuser?.hashchingUser === "yes" ? (
                              <img
                                width={25}
                                height={25}
                                src={hashching}
                                alt="hashching"
                              />
                            ) : null}{" "}
                            {edit && editnode === "personname" ? (
                              <div className="d-flex">
                                <Form.Control
                                  className="limit-name px-2"
                                  required
                                  maxLength={30}
                                  type="text"
                                  value={editValue}
                                  onChange={handleEditChange}
                                  onKeyDown={(e) => Onlytext(e)}
                                />
                                <Button
                                  className="limit-input"
                                  onClick={() => editUserData()}
                                >
                                  <DoneIcon fontSize="small" />
                                </Button>
                                <Button
                                  className="limit-input"
                                  onClick={() => {
                                    setEditNode("");
                                  }}
                                >
                                  <CloseIcon fontSize="small" />
                                </Button>
                              </div>
                            ) : (
                              <>
                                {viewuser?.personname}
                                {edit ? (
                                  <EditIcon
                                    onClick={() => {
                                      setEditNode("personname");
                                      setEditValue(viewuser.personname);
                                    }}
                                    className="edit"
                                  />
                                ) : null}
                              </>
                            )}
                          </div>
                          <div className="user-data">
                            User ID {viewuser?.userId}
                          </div>
                        </div>
                        <div className="user-data contact">
                          {edit && editnode === "phoneNumber" ? (
                            <div className="d-flex">
                              <Form.Control
                                className="limit-input limit-number px-2"
                                required
                                maxLength={10}
                                value={editValue}
                                onChange={handleEditChange}
                                onKeyDown={(e) => numFormat(e)}
                              />
                              <Button
                                className="limit-input"
                                onClick={() => editUserData()}
                              >
                                <DoneIcon fontSize="small" />
                              </Button>
                              <Button
                                className="limit-input"
                                onClick={() => {
                                  setEditNode("");
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </Button>
                            </div>
                          ) : (
                            <span className="modal-contact">
                              Ph {viewuser?.phoneNumber}
                              {edit ? (
                                <EditIcon
                                  onClick={() => {
                                    setEditNode("phoneNumber");
                                    setEditValue(viewuser.phoneNumber);
                                  }}
                                  className="edit"
                                />
                              ) : null}
                            </span>
                          )}
                          <span className="modal-divider">|</span>
                          {edit && editnode === "emailId" ? (
                            <div className="d-flex">
                              <Form.Control
                                className="limit-input limit-number px-2"
                                required
                                maxLength={50}
                                value={editValue}
                                onChange={handleEditChange}
                              />
                              <Button
                                className="limit-input"
                                onClick={() => editUserData()}
                              >
                                <DoneIcon fontSize="small" />
                              </Button>
                              <Button
                                className="limit-input"
                                onClick={() => {
                                  setEditNode("");
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </Button>
                            </div>
                          ) : (
                            <span className="modal-contact">
                              Email: {viewuser?.emailId}
                              {edit ? (
                                <EditIcon
                                  onClick={() => {
                                    setEditNode("emailId");
                                    setEditValue(viewuser.emailId);
                                  }}
                                  className="edit"
                                />
                              ) : null}
                            </span>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={7}>
                      <Row>
                        <Col className="card-row" md={4} lg={4}>
                          <Card className="userdetails-card center">
                            <div className="primary">Score</div>
                            <div className="num-data">
                              {edit && editnode === "score" ? (
                                <div className="d-flex mx-3">
                                  <Form.Control
                                    className="limit-input limit-form px-2"
                                    required
                                    maxLength={10}
                                    type="number"
                                    value={editValue}
                                    onChange={handleEditChange}
                                    onKeyDown={(e) => numFormat(e)}
                                  />
                                  <Button
                                    className="limit-input primary"
                                    onClick={() => editUserData()}
                                  >
                                    <DoneIcon fontSize="small" />
                                  </Button>
                                  <Button
                                    className="limit-input primary"
                                    onClick={() => {
                                      // setedit(false);
                                      setEditNode("");
                                    }}
                                  >
                                    <CloseIcon fontSize="small" />
                                  </Button>
                                </div>
                              ) : (
                                <>
                                  {viewuser?.score}

                                  {edit ? (
                                    <EditIcon
                                      onClick={() => {
                                        setEditNode("score");
                                        setEditValue(viewuser.score);
                                      }}
                                      className="edit primary"
                                    />
                                  ) : null}
                                </>
                              )}
                            </div>
                          </Card>
                        </Col>
                        <Col className="card-row" md={4} lg={4}>
                          <Card className="userdetails-card center">
                            <div className="primary">Owing</div>
                            <div className="num-data">
                              {Money(viewuser?.owing)}
                            </div>
                            <div className="due-data">
                              ${viewuser?.overDue} overdue
                            </div>
                          </Card>
                        </Col>
                        <Col className="card-row" md={4} lg={4}>
                          <Card className="userdetails-card center">
                            <div className="primary center">
                              Available funds
                            </div>

                            <div className="num-data">
                              {Money(viewuser?.availableFunds)}
                            </div>
                            <div className="due-data">
                              {edit && editnode === "brokerLimit" ? (
                                <div className="d-flex mx-3">
                                  <Form.Control
                                    className="limit-input limit-form px-2"
                                    required
                                    maxLength={10}
                                    type="number"
                                    value={editValue}
                                    onChange={handleEditChange}
                                    onKeyDown={(e) => numFormat(e)}
                                  />
                                  <Button
                                    className="limit-input primary"
                                    onClick={() => editUserData()}
                                  >
                                    <DoneIcon fontSize="small" />
                                  </Button>
                                  <Button
                                    className="limit-input primary"
                                    onClick={() => {
                                      setEditNode("");
                                    }}
                                  >
                                    <CloseIcon fontSize="small" />
                                  </Button>
                                </div>
                              ) : (
                                <>
                                  of {Money(viewuser?.brokerLimit)}
                                  {edit ? (
                                    <EditIcon
                                      onClick={() => {
                                        setEditNode("brokerLimit");
                                        setEditValue(viewuser.brokerLimit);
                                      }}
                                      className="edit primary"
                                    />
                                  ) : null}
                                </>
                              )}
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </div>
              <div className="content-section">
                <div className="large-view">
                  <Navbar>
                    <Container>
                      <Nav className="me-auto">
                        <Nav.Link
                          className=" modal-nav"
                          onClick={() => settabView("onboarding")}
                        >
                          Onboarding
                        </Nav.Link>
                        <Nav.Link
                          className="modal-nav"
                          onClick={() => settabView("payments")}
                        >
                          Payments
                        </Nav.Link>
                        <Nav.Link
                          className=" modal-nav"
                          onClick={() => settabView("account")}
                        >
                          Account Activity
                        </Nav.Link>
                        <Nav.Link
                          className=" modal-nav"
                          onClick={() => settabView("notes")}
                        >
                          Notes
                        </Nav.Link>
                        <Nav.Link
                          className=" modal-nav"
                          onClick={() => settabView("profile")}
                        >
                          Profile
                        </Nav.Link>
                        <Nav.Link
                          className=" modal-nav"
                          onClick={() => settabView("linked")}
                        >
                          Linked Account
                        </Nav.Link>
                      </Nav>
                      {tabView === "payments" && (
                        <div className="search-box search-container">
                          <Searchbox
                            placeholder="Enter name, Request ID"
                            handleSearch={setSearchterm}
                          />
                        </div>
                      )}
                    </Container>
                  </Navbar>
                </div>
                <div className="mobile-view">
                  <Navbar>
                    <Container>
                      <Nav className="me-auto">
                        <FormControl
                          variant="standard"
                          sx={{ m: 1, minWidth: 50 }}
                        >
                          <Select
                            value={tabView}
                            onChange={handleChange}
                            displayEmpty
                          >
                            <MenuItem value="onboarding">Onboarding</MenuItem>
                            <MenuItem value="payments">Payments</MenuItem>
                            <MenuItem value={"account"}>
                              Account Activity
                            </MenuItem>
                            <MenuItem value={"notes"}>Notes</MenuItem>
                            <MenuItem value={"profile"}>Profile</MenuItem>
                            <MenuItem value={"linked"}>Linked Account</MenuItem>
                          </Select>
                        </FormControl>
                      </Nav>
                      {tabView === "payments" && (
                        <div className="search-box search-container">
                          <Searchbox
                            placeholder="Enter name, Request ID"
                            handleSearch={setSearchterm}
                          />
                        </div>
                      )}
                    </Container>
                  </Navbar>
                </div>
                <div className="user-view-box p-2">
                  <NewUsersWrap>
                    {tabView === "payments" ? (
                      <>
                        {userPayments && userPayments.length ? (
                          <UserPaymentTable
                            columns={columns}
                            tbody={tbody}
                            tableRef={tableRef}
                            striped={true}
                          />
                        ) : (
                          <NoDataFound body="No payment data to display" />
                        )}
                      </>
                    ) : tabView === "account" ? (
                      <>
                        {AccountActivityData && AccountActivityData.length ? (
                          <UsersActivityWrap>
                            <UserPaymentTable
                              columns={accountColumn}
                              tbody={Accounttbody}
                              tableRef={tableRef}
                              striped={false}
                            />
                          </UsersActivityWrap>
                        ) : (
                          <NoDataFound body="This user don’t have any activity at the moment." />
                        )}
                      </>
                    ) : tabView === "notes" ? (
                      <UserNotes
                        notes={notes}
                        viewuser={viewuser}
                        getNotes={getNotes}
                      />
                    ) : tabView === "onboarding" ? (
                      <OnboardingPanel
                        viewuser={viewuser}
                        contract={contract}
                        setOpen={setOpen}
                        setShow={setShow}
                      />
                    ) : tabView === "linked" ? (
                      <UserLinkedAccount
                        viewuser={viewuser}
                        bankData={bankData}
                      />
                    ) : (
                      <UserProfile
                        viewuser={viewuser}
                        setContract={setContract}
                        contract={contract}
                        setEditNode={setEditNode}
                        setEditValue={setEditValue}
                        editUserData={editUserData}
                        handleEditChange={handleEditChange}
                        editValue={editValue}
                        numFormat={numFormat}
                        edit={edit}
                        editnode={editnode}
                        Onlytext={Onlytext}
                        getFile={getFile}
                        setOpen={setOpen}
                        setShow={setShow}
                        authToken={authToken}
                        getAllUserData={getAllUserData}
                        getAllActivityLog={getAllActivityLog}
                      />
                    )}
                  </NewUsersWrap>
                </div>
              </div>
              {userPayments && userPayments.length && tabView === "payments" ? (
                <div
                  onClick={convertToCSV}
                  className="float-end primary pointer mt-3"
                >
                  Download
                </div>
              ) : null}
            </Container>
          </UsersWrap>
        </Modal.Body>
      </Modal>

      <UIModal
        show={showDelete}
        setShow={setshowDelete}
        body={<ConfirmDelete />}
        size="md"
      />

      <Modal
        className="mt-4"
        centered
        size={"lg"}
        show={open}
        onHide={() => {
          setOpen(false);
          setShow(true);
        }}
      >
        <UsersWrap>
          <div>
            <div className="contract-header pt-2 px-2">
              <Row>
                <Col className="d-flex">
                  <div
                    onClick={() => {
                      setOpen(false);
                      setShow(true);
                    }}
                  >
                    <ExpandCircleDownIcon className="primary pointer backcircle-arrow" />
                  </div>
                  <p className="contract-name mx-1">
                    {viewuser.personname + " " + "contract.pdf"}
                  </p>
                </Col>
                {/* <Col>
                  <div
                    className="del-contract primary pointer"
                    onClick={() => deleteFile()}
                  >
                    Delete Contract
                  </div>
                </Col> */}
              </Row>
            </div>

            <Iframe
              width="100%"
              styles={{ height: "75vh" }}
              scrolling="no"
              frameBorder="0"
              src={contract}
              title="Documents"
            />
          </div>
        </UsersWrap>
      </Modal>
    </React.Fragment>
  );
};

export default UserDataPreview;
