import Styled from "styled-components";

const UserReportWrap = Styled.div`

th, td {
  text-align: left !important; 
  padding: 10px;
  padding-left: 15px !important;
}
`;

export default UserReportWrap;
