import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import OverviewWrap from "./style";
import UIGraph from "../../../Reuseable/UiGraph";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import UIBarChart from "../../../Reuseable/UIBarChart";
import { useSelector } from "react-redux";
import GridLoader from "react-spinners/GridLoader";
import { Money } from "../../../Reuseable/NumberFormatter";

const Overview = ({ overviewLoading }) => {
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);
  const allOverview = useSelector((state) => state.Tracking.allOverview);

  const dataA = allOverview.repayments;

  const data = allOverview.overdue;

  let newUserPer = "";
  let deletedUserPer = "";

  if (allOverview.lastMonthCreatedUsers == 0) {
    newUserPer =
      (allOverview.thisMonthCreatedUsers * 100) /
      (allOverview.lastMonthCreatedUsers + 1);
  } else if (
    allOverview.lastMonthCreatedUsers > allOverview.thisMonthCreatedUsers
  ) {
    newUserPer =
      ((allOverview.lastMonthCreatedUsers - allOverview.thisMonthCreatedUsers) /
        allOverview.lastMonthCreatedUsers) *
      100;
  } else {
    newUserPer =
      ((allOverview.thisMonthCreatedUsers - allOverview.lastMonthCreatedUsers) /
        allOverview.lastMonthCreatedUsers) *
      100;
  }

  if (allOverview.lastMonthDeletedUsers == 0) {
    deletedUserPer =
      (allOverview.thisMonthDeletedUsers * 100) /
      (allOverview.lastMonthDeletedUsers + 1);
  } else if (
    allOverview.lastMonthDeletedUsers > allOverview.thisMonthDeletedUsers
  ) {
    deletedUserPer =
      ((allOverview.lastMonthDeletedUsers - allOverview.thisMonthDeletedUsers) /
        allOverview.lastMonthCreatedUsers) *
      100;
  } else {
    deletedUserPer =
      ((allOverview.thisMonthDeletedUsers - allOverview.lastMonthDeletedUsers) /
        allOverview.lastMonthDeletedUsers) *
      100;
  }

  return (
    <React.Fragment>
      {overviewLoading ? (
        <div className="center overview_container">
          <GridLoader color="#36d7b7" />
        </div>
      ) : (
        <OverviewWrap>
          <div className="mb-4">
            <div>
              <h2 className="center primary">Hello {brokerdata.personname}!</h2>
              <div className="center">
                <div className="active-number center">
                  <div className="num">{allOverview.allActiveUser}</div>
                  <p className="active-text">Active Users</p>
                </div>
                {/* <span className="counting">counting...</span> */}
              </div>
            </div>
          </div>
          <Row>
            <Col
              xxl={4}
              xl={6}
              lg={12}
              md={12}
              sm={12}
              className="overview-card card-center"
            >
              <div className="d-block p-2 first-card">
                <span className="title-card">New users this month</span>
                <div className="white-bg cards center w-100">
                  <span className="card-num">
                    {" "}
                    {allOverview.thisMonthCreatedUsers}
                  </span>
                  {allOverview.thisMonthCreatedUsers >
                  allOverview.lastMonthCreatedUsers
                    ? "Increased"
                    : "Decreased"}{" "}
                  this month by
                  <span className="card-per">
                    {Math.round(newUserPer)}%
                    {allOverview.thisMonthCreatedUsers >
                    allOverview.lastMonthCreatedUsers ? (
                      <NorthIcon />
                    ) : (
                      <SouthIcon />
                    )}
                  </span>
                </div>
              </div>
            </Col>
            <Col
              xxl={4}
              xl={6}
              lg={12}
              md={12}
              sm={12}
              className="overview-card center"
            >
              <div className="d-block p-2 w-100">
                <span className="title-card">Cancelled users this month</span>
                <div className="white-bg cards center w-100">
                  <span className="card-num">
                    {" "}
                    {allOverview.thisMonthDeletedUsers}
                  </span>
                  {allOverview.thisMonthDeletedUsers >
                  allOverview.lastMonthDeletedUsers
                    ? "Increased"
                    : "Decreased"}{" "}
                  this month by
                  <span className="card-per">
                    {Math.round(deletedUserPer)}%
                    {allOverview.thisMonthDeletedUsers >
                    allOverview.lastMonthDeletedUsers ? (
                      <NorthIcon />
                    ) : (
                      <SouthIcon />
                    )}
                  </span>
                </div>
              </div>
            </Col>
            <Col
              xxl={4}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className="overview-card card-center"
            >
              <div className="d-block p-2 w-100 end-block">
                <span className="title-card">New requests this month</span>
                <div className="white-bg center cards w-100">
                  <div className="d-block">
                    <Row>
                      <span className="card-num">
                        {allOverview.thisMonthCreatedRequests?.requestCount}
                      </span>
                    </Row>
                    <Row>
                      <span className="request">Requests</span>
                    </Row>
                  </div>
                  <div>
                    <span className="card-value me-2 ms-4">
                      {Money(allOverview.thisMonthCreatedRequests?.totalAmount)}
                    </span>
                    Value
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="mx-2">
          <Row>
            <Col xxl={6} className="graph-row center">
              <UIGraph
                data={dataA}
                stroke="#0094FF"
                title=" Value of expected repayments"
              />
            </Col>
            <Col xxl={6} className="graph-row overdue-graph center">
              <UIBarChart
                data={data}
                stroke="#26C984"
                title="Overdue repayments"
              />
            </Col>
            </Row>
          </div>
        </OverviewWrap>
      )}
    </React.Fragment>
  );
};

export default Overview;
