import React, { useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import { Row, Col, Card, Button, InputGroup } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { validateInput } from "../../../Utilities/validator";
import { WysiwygEditor } from "../WysiwygEditor";
import "./style.css";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import BarLoader from "react-spinners/ClipLoader";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ReCAPTCHA from "react-google-recaptcha";
import InfoIcon from "@mui/icons-material/Info";

export const Forms = ({
  title,
  formArr,
  submitBtn,
  onSubmit,
  formlinks,
  loading,
  value,
  cancelBtn,
  onCancel,
  classes,
  submitted,
  changeInForm,
  setChangeInForm,
  forgotPassword,
  invalid,
  rechaptcha,
  setPageView,
  signup,
  Login,
  back,
  hashching,
  errorMessage,
  message,
  register,
  setShow,
  defaultValue,
  setErrorMessage,
}) => {
  const prepareForm = (formArr) => {
    return formArr.reduce((r, v) => ({ ...r, [v.name]: "" }), {});
  };

  /*************************** react state  intiaalize *******************/

  const captchaRef = useRef(null);
  const navigate = useNavigate();

  const [formAr, setFormAr] = useState(formArr);
  const [valid, setValid] = useState(false);
  const intialForm = prepareForm(formArr);
  const [form, setform] = useState(intialForm);
  const [isSubmit, setSubmit] = useState(false);
  const [isCancel, setCancel] = useState(false);
  const [isMoreThan, setIsMoreThan] = useState(false);
  const [showpassword, setShowPassword] = useState(false);
  const [showpasswordType, setShowPasswordtype] = useState("");
  const [editorChanged, setEditorChanged] = useState(false);
  const [recaptchaerror, setRecaptchaError] = useState("");
  const [submitError, setSubmitError] = useState("");
  /************** Functions ******************/
  const onChangeHandler = (e) => {
    setChangeInForm(true);
    setform((p) => ({ ...p, [e.target.name]: e.target.value }));
    formAr.forEach((item) => {
      let index = formAr.findIndex((item) => item.name == e.target.name);
      if (index != undefined) formAr[index].value = e.target.value;
    });

    form.email && form.email.length >= 2
      ? setIsMoreThan(true)
      : setIsMoreThan(false);

    if (
      e.target.name === "email" &&
      errorMessage &&
      errorMessage.length &&
      hashching
    ) {
      setErrorMessage("");
    }

    setCancel(false);
    setSubmit(false);
    setValid(false);
  };

  const onCheckboxHandler = (e) => {
    formAr.forEach((value) => {
      if (value.name === e.target.name) {
        if (value.value) {
          value.value = false;
          setform((p) => ({ ...p, [e.target.name]: false }));
        } else {
          value.value = true;
          setform((p) => ({ ...p, [e.target.name]: true }));
        }
      }
    });
  };

  const onSubmitHandle = () => {
    setSubmit(true);
    var keys = Object.values(form);
    keys.forEach((item, index) => {
      formAr.forEach((item1, index1) => {
        if (index === index1) {
          let result = validateInput(item1.validators, item);
          formAr[index1].error = result.error;
          formAr[index1].errormsg = result.message;
          setFormAr(formAr);
          if (item1.match) {
            if (form[item1.match] !== item) {
              formAr[index].error = true;
              formAr[index].errormsg = "Enter same password";
            }
          }
          if (formAr.find((item) => item.error === true)) setValid(false);
          else setValid(true);
        }
      });
    });

    if (rechaptcha) {
      if (!recaptchaerror) {
        setSubmitError("Please validate this feild");
      } else {
        setSubmitError(false);
      }
    }
  };

  const onCancelHandle = (e) => {
    setCancel(true);
    onCancel(changeInForm);
  };

  useEffect(() => {
    setFormAr(formArr);
    formArr.forEach((item) => {
      setform((p) => ({ ...p, [item.name]: item.value }));
    });
  }, [value]);

  useEffect(() => {
    if (formAr.find((item) => item.error == true)) setValid(false);
  }, [form]);

  useEffect(() => {
    if (valid) {
      onSubmit(form, valid, isMoreThan);
      setEditorChanged(true);
    }
  }, [valid]);

  useEffect(() => {
    formAr.forEach((item) => {
      item.value = "";
    });
    setFormAr(formAr);
    setform(formArr.reduce((r, v) => ({ ...r, [v.name]: "" }), {}));
    setEditorChanged(false);
    if (rechaptcha) {
      captchaRef.current.reset();
    }
  }, [submitted]);

  const contactLimit = (e) => {
    const data = e.target.value;
    var key = e.keyCode || e.charCode;
    if (data.length < 10 || key === 8 || key === 46) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const abn = (e) => {
    const data = e.target.value;
    var key = e.keyCode || e.charCode;
    if (data.length < 11 || key === 8 || key === 46) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const licensenumber = (e) => {
    const data = e.target.value;
    var key = e.keyCode || e.charCode;
    if (data.length < 8 || key === 8 || key === 46) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const bsbFormat = (e) => {
    const numberFormat = /^[0-9-]+$/;
    var key = e.keyCode || e.charCode;
    if (numberFormat.test(e.key) || key === 8 || key === 46) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/-/g, "");

    let formattedValue = "";
    for (let i = 0; i < sanitizedValue.length; i++) {
      formattedValue += sanitizedValue[i];
      if ((i + 1) % 3 === 0 && i !== sanitizedValue.length - 1) {
        formattedValue += "-";
      }
    }
    setform((p) => ({ ...p, bsb: formattedValue }));
    return formattedValue;
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const Onlytext = (e) => {
    const re = /^[A-Za-z]+$/;
    if (e.key === " " || e.key === "-" || re.test(e.key)) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const BuisssnessName = (e) => {
    const re = /^[A-Za-z]+$/;
    if (
      e.key === " " ||
      e.key === "-" ||
      e.key === "&" ||
      e.key === "/" ||
      e.key === "+" ||
      e.key === "_" ||
      e.key === "." ||
      re.test(e.key)
    ) {
      return true;
    } else {
      e.preventDefault();
    }
  };

  const handleSplChar = (e) => {
    if (e.key === "#") {
      e.preventDefault();
    }
    if (e.key === "$") {
      e.preventDefault();
    }
    if (e.key === "^") {
      e.preventDefault();
    }
    if (e.key === "&") {
      e.preventDefault();
    }
    if (e.key === "*") {
      e.preventDefault();
    }
    if (e.key === "(") {
      e.preventDefault();
    }
    if (e.key === "!") {
      e.preventDefault();
    }
    if (e.key === ")") {
      e.preventDefault();
    }
    if (e.key === "%") {
      e.preventDefault();
    }
    if (e.key === "=") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (defaultValue && defaultValue.length) {
      setform((p) => ({ ...p, email: defaultValue }));
    }
  }, [defaultValue]);

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  let passwordErrormsg =
    "Password must be at least 8 characters long, contain one or more uppercase characters, one or more lowercase characters, one or more numeric values, and one or more special characters.";

  return (
    <Card className="border-0 mb-2 px-2">
      <Card.Body className={"loginformbody formBody " + classes}>
        <Form
          autoComplete="off"
          onSubmit={(e) => {
            onSubmitHandle();
            e.preventDefault();
          }}
        >
          {title !== "" ? (
            <h5 className="formScreenUser mb-0 pb-3">{title}</h5>
          ) : (
            <></>
          )}
          <Row className="">
            {formAr.map(
              (
                {
                  label,
                  name,
                  type,
                  placeholder,
                  validators,
                  error,
                  errormsg,
                  options,
                  rows,
                  value,
                  maxLength,
                  match,
                  disable,
                  checkbox_label,
                  col,
                  id,
                  newPassword,
                },
                index
              ) => {
                const validateField = (e, match) => {
                  let result = validateInput(validators, e.target.value);
                  formAr[index].error = result.error;
                  formAr[index].errormsg = result.message;
                  setFormAr(formAr);
                  if (match) {
                    if (form[match] !== e.target.value) {
                      formAr[index].error = true;
                      formAr[index].errormsg = "Enter same password";
                    }
                  }
                };

                const onChangeEditorHandler = (value) => {
                  setform((p) => ({ ...p, ["description"]: value }));
                };

                return (
                  <Col
                    sm={col ? col : 12}
                    key={index}
                    className="mb-3 p-0 col-form"
                  >
                    <Form.Group controlId={name}>
                      {label !== "" ? (
                        <>
                          <Form.Label className="fw-bold text-dark">
                            {label}

                            {/* <span className="mandatorySign text-danger">*</span> */}
                          </Form.Label>
                          {newPassword ? (
                            <Tooltip title={passwordErrormsg}>
                              <span className="pointer mx-1">
                                <InfoIcon
                                  className="mb-1"
                                  color="primary"
                                  fontSize="small"
                                />
                              </span>
                            </Tooltip>
                          ) : null}
                        </>
                      ) : (
                        <></>
                      )}
                      {type === "text" ? (
                        <Form.Control
                          type={type}
                          placeholder={placeholder}
                          disabled={disable}
                          value={value}
                          onKeyDown={(e) => {
                            Onlytext(e);
                            handleSplChar(e);
                          }}
                          name={name}
                          maxLength={maxLength}
                          onChange={(e) => {
                            onChangeHandler(e);
                            validateField(e);
                          }}
                        />
                      ) : type === "alltext" ? (
                        <Form.Control
                          className={
                            errorMessage && hashching && "invalid_value"
                          }
                          type={type}
                          placeholder={placeholder}
                          disabled={disable}
                          value={value}
                          name={name}
                          maxLength={maxLength}
                          onChange={(e) => {
                            onChangeHandler(e);
                            validateField(e);
                          }}
                        />
                      ) : type === "busiessName" ? (
                        <Form.Control
                          type={type}
                          placeholder={placeholder}
                          disabled={disable}
                          value={value}
                          name={name}
                          onKeyDown={(e) => BuisssnessName(e)}
                          maxLength={maxLength}
                          onChange={(e) => {
                            onChangeHandler(e);
                            validateField(e);
                          }}
                        />
                      ) : type === "floatinglabel-text" ? (
                        <InputGroup>
                          <FloatingLabel label={label}>
                            <Form.Control
                              type={type}
                              placeholder={placeholder}
                              disabled={disable}
                              value={value}
                              onKeyDown={(e) => {
                                handleKeyDown(e);
                                handleSplChar(e);
                              }}
                              name={name}
                              maxLength={maxLength}
                              onChange={(e) => {
                                onChangeHandler(e);
                                validateField(e);
                              }}
                            />
                          </FloatingLabel>
                        </InputGroup>
                      ) : type === "userid" ? (
                        <InputGroup>
                          <Form.Control
                            className="mt-1"
                            type={type}
                            placeholder={placeholder}
                            disabled={disable}
                            value={value}
                            maxLength={maxLength}
                            onKeyDown={(e) => {
                              handleKeyDown(e);
                              handleSplChar(e);
                            }}
                            name={name}
                            onChange={(e) => {
                              onChangeHandler(e);
                              validateField(e);
                            }}
                          />
                        </InputGroup>
                      ) : type === "password" ? (
                        <>
                          <InputGroup>
                            {error && newPassword ? (
                              <Form.Control
                                className={
                                  error && newPassword && "new-password-error"
                                }
                                type={
                                  showpassword && showpasswordType === name
                                    ? "text"
                                    : "password"
                                }
                                placeholder={placeholder}
                                disabled={disable}
                                value={value}
                                name={name}
                                maxLength={maxLength}
                                onChange={(e) => {
                                  onChangeHandler(e);
                                  validateField(e, match);
                                }}
                              />
                            ) : (
                              <Form.Control
                                className={
                                  invalid === "1" && "new-password-error"
                                }
                                type={
                                  showpassword && showpasswordType === name
                                    ? "text"
                                    : "password"
                                }
                                placeholder={placeholder}
                                disabled={disable}
                                value={value}
                                name={name}
                                maxLength={maxLength}
                                onChange={(e) => {
                                  onChangeHandler(e);
                                  validateField(e, match);
                                }}
                              />
                            )}

                            <IconButton
                              className="passwordhow-icon"
                              size="sm"
                              onClick={() => {
                                setShowPasswordtype(name);
                              }}
                            >
                              {showpassword && showpasswordType === name ? (
                                <RemoveRedEyeIcon
                                  onClick={() => setShowPassword(false)}
                                />
                              ) : (
                                <VisibilityOffIcon
                                  onClick={() => setShowPassword(true)}
                                />
                              )}
                            </IconButton>
                          </InputGroup>
                        </>
                      ) : type === "email" || type === "number" ? (
                        <Form.Control
                          className={
                            (invalid === "2" && "new-password-error") ||
                            (errorMessage && hashching && "invalid_value")
                          }
                          type={type}
                          placeholder={placeholder}
                          disabled={
                            defaultValue && defaultValue.length ? true : disable
                          }
                          value={
                            defaultValue && defaultValue.length
                              ? defaultValue
                              : value
                          }
                          name={name}
                          maxLength={maxLength}
                          onChange={(e) => {
                            onChangeHandler(e);
                            validateField(e);
                          }}
                        />
                      ) : type === "bsb" ? (
                        <Form.Control
                          placeholder={placeholder}
                          maxLength={6}
                          disabled={disable}
                          value={value}
                          name={name}
                          onKeyDown={(e) => bsbFormat(e)}
                          onChange={(e) => {
                            onChangeHandler(e);
                            validateField(e);
                          }}
                        />
                      ) : type === "contactnumber" ? (
                        <>
                          <Form.Control
                            type="number"
                            placeholder={placeholder}
                            disabled={disable}
                            value={value}
                            name={name}
                            maxLength={maxLength}
                            onKeyDown={(e) => {
                              exceptThisSymbols.includes(e.key) &&
                                e.preventDefault();
                              contactLimit(e);
                            }}
                            onChange={(e) => {
                              onChangeHandler(e);
                              validateField(e);
                            }}
                          />
                        </>
                      ) : type === "abn" ? (
                        <>
                          <Form.Control
                            type="number"
                            placeholder={placeholder}
                            disabled={disable}
                            value={value}
                            name={name}
                            maxLength={maxLength}
                            onKeyDown={(e) => {
                              exceptThisSymbols.includes(e.key) &&
                                e.preventDefault();
                              abn(e);
                            }}
                            onChange={(e) => {
                              onChangeHandler(e);
                              validateField(e);
                            }}
                          />
                        </>
                      ) : type === "licensenumber" ? (
                        <>
                          <Form.Control
                            type="number"
                            placeholder={placeholder}
                            disabled={disable}
                            value={value}
                            name={name}
                            maxLength={maxLength}
                            onKeyDown={(e) => {
                              exceptThisSymbols.includes(e.key) &&
                                e.preventDefault();
                              licensenumber(e);
                            }}
                            onChange={(e) => {
                              onChangeHandler(e);
                              validateField(e);
                            }}
                          />
                        </>
                      ) : type === "select" ? (
                        <>
                          <Form.Select
                            className={
                              value === ""
                                ? "select_placeholder reusable_select"
                                : "reusable_select"
                            }
                            name={name}
                            value={value}
                            disabled={disable}
                            onChange={(e) => {
                              onChangeHandler(e);
                              validateField(e);
                            }}
                            aria-label="Default select example"
                          >
                            <option value="" disabled selected>
                              {placeholder}
                            </option>
                            {options.map((item, index) => (
                              <option value={item.id} key={index}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Select>
                        </>
                      ) : type === "textarea" ? (
                        <>
                          <WysiwygEditor
                            value={value}
                            onChange={onChangeEditorHandler}
                            changed={editorChanged}
                          />
                          <Form.Control
                            style={{ display: "none" }}
                            disabled
                            as="textarea"
                            name={name}
                            value={value}
                          />
                        </>
                      ) : type === "textareaonly" ? (
                        <>
                          <Form.Control
                            as="textarea"
                            name={name}
                            value={value}
                            onChange={(e) => {
                              onChangeHandler(e);
                              validateField(e);
                            }}
                          />
                        </>
                      ) : type === "checkbox" ? (
                        <Form.Check
                          type="checkbox"
                          name={name}
                          checked={value}
                          value={value}
                          label={checkbox_label}
                          onChange={(e) => onCheckboxHandler(e)}
                        />
                      ) : (
                        <></>
                      )}
                    </Form.Group>
                    {error ? (
                      <small className="text-danger d-block">{errormsg}</small>
                    ) : (
                      <>
                        {errorMessage && hashching && (
                          <small className="text-danger d-block">
                            {errorMessage}
                          </small>
                        )}
                      </>
                    )}
                  </Col>
                );
              }
            )}
          </Row>
          {formlinks}

          {rechaptcha && (
            <>
              <div className="center">
                <ReCAPTCHA
                  sitekey="6LfOx_MmAAAAADrjn22k_cDxQ-Xtn-Puc9U1mwqt"
                  ref={captchaRef}
                  onExpired={() => {
                    setRecaptchaError(false);
                    setSubmitError(true);
                  }}
                  onChange={() => {
                    setRecaptchaError(true);
                    setSubmitError(false);
                  }}
                />
              </div>
              <div className="center mb-2">
                {submitError && (
                  <small className="text-danger d-block">{submitError}</small>
                )}
              </div>
            </>
          )}

          {forgotPassword && (
            <Row className="mb-2">
              <div
                className="d-flex justify-content-end forgot-password pointer"
                onClick={() => setPageView("forgot-password")}
              >
                Forgot Password?
              </div>
            </Row>
          )}

          <Row className="mb-2">
            <Col className="text-center" sm={12}>
              {cancelBtn ? (
                <Button
                  type="button"
                  onClick={(e) => onCancelHandle(e)}
                  className="fs-5 btn"
                  variant="secondary"
                >
                  Cancel
                </Button>
              ) : (
                <></>
              )}
              {Login && (
                <Button
                  className="loginSubmitBtn back_btn btn me-3"
                  onClick={() => setPageView("login")}
                  variant=""
                >
                  Back
                </Button>
              )}
              {register && (
                <Button
                  className="loginSubmitBtn back_btn btn me-3"
                  onClick={() => setPageView("hashching")}
                  variant=""
                >
                  Back
                </Button>
              )}
              <Button
                type="submit"
                className="loginSubmitBtn submit_btn btn"
                variant=""
              >
                {loading ? (
                  <BarLoader
                    as="span"
                    size={14}
                    animation="grow"
                    color="#ffffff"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <>{submitBtn}</>
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
      {signup && (
        <div className="mb-4">
          Don’t have an account?{" "}
          <span onClick={() => setShow(true)} className="signup_link pointer">
            Sign up here
          </span>
        </div>
      )}
    </Card>
  );
};
