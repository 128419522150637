export const getCurrentTime = () => {
  const formatTime = (time) => {
    return time.toString().padStart(2, "0");
  };

  const dateObj = new Date();
  const day = formatTime(dateObj.getDate());
  const month = formatTime(dateObj.getMonth() + 1); 
  const year = dateObj.getFullYear();
  let hours = dateObj.getHours();
  const minutes = formatTime(dateObj.getMinutes());
  const amOrPm = hours >= 12 ? "PM" : "AM";

  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  const formattedTime = `${day}/${month}/${year} ${formatTime(
    hours
  )}:${minutes} ${amOrPm}`;
  return formattedTime;
};
