import React, { useState } from "react";
import ConfirmSignUp from "../../SignUp/ConfirmSignup";
import HashchingAccountSignup from "../../SignUp/HashchingSignup";
import { Button, Col, Modal, Row } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import VerifyAccount from "../../SignUp/VerifyAccount";
import UserDetails from "../../../Reuseable/Forms/UserDetails";
import LoginWrap from "../../SignUp/style";
import HashchingAccount from "../../SignUp/HaschingAccount";
import InvalidAccount from "../../SignUp/InvalidAccount";
import OTPConfirmation from "../../SignUp/OTPConfirmation";
import SubmittedScreen from "../../SignUp/SubmittedScreen";
import HashchingPassword from "../../SignUp/SetPassword";

const SignupModal = ({ show, setShow }) => {
  const [pageView, setPageView] = useState("hashching");
  const [hashchingData, setHashchingData] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleModalClose = () => {
    document.body.classList.remove("signup_modal_open");
  };

  return (
    <>
      <Modal
        size={
          pageView === "regsiter" ||
          pageView === "confirm_account" ||
          pageView === "submissionScreen"
            ? "lg"
            : "md"
        }
        centered
        show={show}
        onHide={() => {
          setShow(false);
          setPageView("hashching");
          handleModalClose();
        }}
      >
        <Modal.Body>
          {/* <div
              className="close-btn primary"
              onClick={() => setShow(false)}
            >
              <CloseIcon />
            </div> */}
          <LoginWrap>
            {pageView === "user_type" ? (
              <ConfirmSignUp setPageView={setPageView} />
            ) : pageView === "hashching" ? (
              <HashchingAccountSignup
                setHashchingData={setHashchingData}
                setPageView={setPageView}
                setEmail={setEmail}
              />
            ) : pageView === "regsiter" ? (
              <UserDetails email={email} setPageView={setPageView} />
            ) : pageView === "verify_account" ? (
              <VerifyAccount
                setPageView={setPageView}
                hashchingData={hashchingData}
                email={email}
              />
            ) : pageView === "confirm_account" ? (
              <HashchingAccount
                setPageView={setPageView}
                hashchingData={hashchingData}
                email={email}
                password={password}
              />
            ) : pageView === "invalid_account" ? (
              <InvalidAccount setPageView={setPageView} email={email} />
            ) : pageView === "otp_page" ? (
              <OTPConfirmation
                email={email}
                hashchingData={hashchingData}
                setPageView={setPageView}
              />
            ) : pageView === "submissionScreen" ? (
              <SubmittedScreen />
            ) : pageView === "set_password" ? (
              <HashchingPassword
                hashchingData={hashchingData}
                email={email}
                setPageView={setPageView}
                setPassword={setPassword}
              />
            ) : null}
          </LoginWrap>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SignupModal;
