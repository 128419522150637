import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import UIModalView from '../UIModal/style';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import BarLoader from 'react-spinners/ClipLoader';

const Notes = ({ open, setOpen, setNotes, notes, Submit, loading }) => {

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (notes.length) {
            Submit()
        }
    };

    return (
        <>
            <Modal
                show={open}
                onHide={() => {
                    setOpen(false);
                    setValidated(false);
                    setNotes('');
                }}
                centered
            >
                <UIModalView>
                    <Modal.Body>
                        <div className='p-2'>
                            <Form
                                noValidate
                                validated={validated}
                            >
                                <Form.Group
                                    md="3"
                                    controlId="validationCustom05"
                                >
                                    <Form.Label className='notes-title'>Add Note</Form.Label>
                                    <Form.Control
                                        className='notes-textfeild'
                                        as="textarea"
                                        style={{ height: '100px' }}
                                        required
                                        onChange={e => setNotes(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please fill this field.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <div className='float-end'>
                                    <Button
                                        onClick={() => setOpen(false)}
                                        className='cancel-btn px-4 m-3'
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleSubmit}
                                        className='theme-btn px-4 my-3'
                                    >
                                        {
                                            loading ?

                                                <BarLoader
                                                    as="span"
                                                    size={14}
                                                    animation="grow"
                                                    color='#00C6FF'
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                :
                                                'Proceed'
                                        }
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal.Body>
                </UIModalView>
            </Modal>
        </>
    );
};

export default Notes;