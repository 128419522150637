import {Money} from "../NumberFormatter";

const Validation = (values, brokerdata, prevFile) => {
  let errors = {};

  let availableFunds = Money(brokerdata.availableFunds).props.children;

  if (!values.fullName) {
    errors.fullName = "This is a required field";
  } else {
    if (values.fullName.length < 3) {
      errors.fullName = "Please enter a name between 3 and 30 characters";
    } else {
      errors.fullName = "";
    }
  }
  if (!values.loanAmount) {
    errors.loanAmount = "This is a required field";
  } else {
    if (values.loanAmount < 9999) {
      errors.loanAmount = "Minimum 10,000";
    } else {
      errors.loanAmount = "";
    }
  }
  if (!values.settlementDate) {
    errors.settlementDate = "Please select the settlement date";
  } else {
    errors.settlementDate = "";
  }
  if (!values.repaymentDate) {
    errors.repaymentDate = "Please select a repayment date";
  } else {
    errors.repaymentDate = "";
  }
  if (prevFile == "" || prevFile == []) {
    errors.settleMentProof = "This is a required field";
  }
  if (!values.aggregator) {
    errors.aggregator = "This is a required field";
  } else {
    if (values.aggregator.length < 3) {
      errors.aggregator = "Please enter a valid aggregator name";
    } else {
      errors.aggregator = "";
    }
  }
  if (!values.commissiomnAdvance) {
    errors.commissiomnAdvance = "This is a required field";
  } else {
    if (
      values.commissiomnAdvance > parseInt(brokerdata.availableFunds) ||
      values.commissiomnAdvance < 1000
    ) {
      errors.commissiomnAdvance = `Please enter an amount between $1,000 and ${availableFunds}.`;
    } else {
      errors.commissiomnAdvance = "";
    }
  }
  if (!values.commissionRate) {
    errors.commissionRate = "This is a required field";
  }
  if (!values.commissionDate) {
    errors.commissionDate =
      "Please select the date your comission will be paid";
  } else {
    errors.commissionDate = "";
  }

  return errors;
};
export default Validation;
