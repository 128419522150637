import Styled from 'styled-components';

const FirstLoginView = Styled.div`
    table th, table td {
      border: 1px solid #0094FF;
      text-align: center;
      background-color: #DBEFFF;
    }
   td{
      width: 50%;
   }
   .fee-structure{
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.1px;
      text-align: justify;
   }
   .fee-list{
      margin: 20px;
      list-style: disc !important;
   }

`;

export default FirstLoginView;
