import React from "react";
import UIModalView from "./style";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";

const UIModal = ({
  show,
  title,
  setShow,
  body,
  size,
  close,
  backdrop,
  padding,
}) => {
  return (
    <>
      <Modal
        className="mt-1"
        centered
        size={size ? size : "lg"}
        show={show}
        onHide={() => setShow(false)}
        backdrop={backdrop ? "static" : true}
      >
        <UIModalView>
          <div className={padding === false ? "" : "body"}>
            <div className="header">
              {title ? <h6 className="title fw-bold">{title}</h6> : <></>}
              {close ? null : (
                <div className="close-btn" onClick={() => setShow(false)}>
                  <CloseIcon />
                </div>
              )}
            </div>
            <div>{body}</div>
          </div>
        </UIModalView>
      </Modal>
    </>
  );
};
export default UIModal;
