import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DashboardImg from '../../../../../assets/images/newlogin-img.png';
import DashboardView from '../style';
import FeeStructure from './FeeStructure';

const FirstLogin = () => {

    const [show, setShow] = useState(false);

    return (
        <DashboardView>
            <div className="first-login" >
                <p className="primary fw-bold" >
                    Why wait weeks for your commission?
                </p>
                <p>
                    <b>
                        Brokerpay offers advanced settlement funding to Australian mortgage brokers, giving you the freedom to access your commission when you need it.
                    </b>
                    &nbsp;
                    Access your commission as soon as 48 hours after settlement, with low fees and no credit check required.
                </p>
                <Row>
                    <Col md={9}>
                        <p className="primary fw-bold">
                            How does it work?
                        </p>
                        <p>
                            <p>
                                <ul className='list'>
                                    <li>
                                        Hit the New Request button and fill out the application form, including your proof of settlement (settlement advice)
                                    </li>
                                    <li>
                                        The Brokerpay team will review your application, including the documents you have provided. You will be required to pay the $10 application fee to proceed with your enquiry
                                    </li>
                                    <li>
                                        Once your request has been approved your funds will be processed in as little as 48 hours.
                                    </li>
                                    <li>
                                        You’ll be required to add Brokerpay as a referrer in your aggregator platform, so that your aggregator will pay us directly.
                                    </li>
                                    <li>
                                        The Brokerpay team will reach out to you 1 week before your repayment date, and again on date repayment is expected.
                                    </li>
                                </ul>
                            </p>
                        </p>
                    </Col>
                    <Col className='center' md={3}>
                        <img className='dashboard-img' src={DashboardImg} />
                    </Col>
                </Row>
                <p>
                    View our
                     <b
                     onClick={()=> setShow(true)}
                      className="primary pointer" 
                      >
                        &nbsp;
                        fee structure here
                        </b>
                        </p>
            </div>
            <FeeStructure
                show={show}
                setShow={setShow}
            />
        </DashboardView>
    );
}

export default FirstLogin;