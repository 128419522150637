import React, { useState } from "react";
import { Button, Form, Col, Container, Row, Card } from "react-bootstrap";
import { api } from "../../../../../../Services/api";
import { useSelector } from "react-redux";
import BarLoader from "react-spinners/ClipLoader";
import moment from "moment";
import { NoDataFound } from "../../../../../Reuseable/NoDataFound";

const UserLinkedAccount = ({ viewuser, bankData }) => {
  const maskingFunction = (data) => {
    let subNum = data.toString().substring(0, 3);
    let endNum = data.toString().substring(3, 0);
    subNum = subNum + "*****" + endNum;
    return subNum;
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={6}>
          <Container className="Linked-account">
            <b className="mb-3">Linked Account</b>
            <Card className="themegradient Linked-card">
              <Card.Body>
                <Col>
                  <Col>
                    <b>Bank Name : </b>
                    {bankData.accountDetails?.BankName}{" "}
                  </Col>
                  <Col>
                    <b>BSB :</b> {bankData.accountDetails?.Bsb}{" "}
                  </Col>
                  <Col>
                    <b>Account Name : </b>
                    {bankData.accountDetails?.AccountName}
                  </Col>
                  <Col>
                    <b>Account Number : </b>**********
                    {bankData.accountDetails?.Accountnumber}{" "}
                  </Col>
                </Col>
              </Card.Body>
            </Card>
          </Container>
        </Col>
        <Col lg={6}>
          <Container className="Linked-account">
            <b className="mb-3">Card details</b>
            <Card className="themegradient Linked-card">
              <Card.Body>
              <div className="center">Card number</div>
                    <div className="center card_number">
                      XXXX XXXX XXXX {bankData?.cardDetails?.cardNumber}
                    </div>
                    <div className="card_footer">
                      <span>
                        Expiry date:{" "}
                        {bankData?.cardDetails?.expMonth +
                          "/" +
                          bankData?.cardDetails?.expYear}
                      </span>
                      <span className="float-end">CVC: ***</span>
                    </div>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserLinkedAccount;
