import Styled from "styled-components";
import img1 from "../../../assets/landingpage/img/Ellipse.png";
import img2 from "../../../assets/landingpage/img/Mask_Group.png";
import img3 from "../../../assets/landingpage/img/Group_33.png";

const LandingPageWrap = Styled.div`
overflow-x: hidden;

html {
    scroll-behavior: smooth;
}
.{
    overflow-x: hidden;
}
body {
   overflow-x: hidden;
   /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    font-family: 'Roboto';
    font-weight: 500; */
    // display: flex ;
}

p {
    font-weight: 500;
}

a{
    color: black;
} 
a: hover{
    color: white;
} 
h5{
    font-weight: 600;
}
.url{
    color: #0094FF !important;
}
.url:hover{
    color: #0094FF !important;
}
.box-container-fluid {
    width: 100%;
    margin-bottom: 20px;
}
.list{
    list-style-type: square;
    line-height: 40px;
}
.list{
    list-style-type: square;
    line-height: 40px;
}
.numbered-list {
    list-style-type: decimal; 
    line-height: 40px;
}
.nav {
    display: flex;
    justify-content: center;
}
.logo{
    position: relative;
}
.btn-group{
   top: -45px;
   padding-right: 10%;
}
.logo-brokerpay {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 174px;
    height: 55px;
}
.loginformbody {
    .btn {
    float: right ! important;
}
}
.card-box6{
    padding: 30px;
}
.nav ul li {
    list-style: none;
    display: inline-block;
    margin: 0 10px;
    margin-top: 40px;
}

button:focus-visible,
button:focus {
    outline: none;
}
.menu-bar{
    margin-top: 90px;
}

.btn-login,
.btn-signin,
.content1,
.content3,
.work-heading-content {
    font-weight: 600;
}

.btn-login {
    margin-left: 330px;
    width: 100px;
    height: 40px;
    background: #0094FF;
    border: none;
    border-radius: 20px;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 5px 20px 5px 20px;
}

.btn-submit {
    float: right;
    width: 160px;
    height: 40px;
    background: #0094FF;
    font-weight: 500;
    border: none;
    border-radius: 20px;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 5px 10px 5px 10px;
}

.btn-signin {
    background: none;
    border: none;
    border-radius: 20px;
    opacity: 1;
    font-size: 18px;
    color: #0094FF;
    margin-bottom: 5px;
}

.heading {
    background: #F1F9FF;
    /* margin-top:10px; */
    opacity: 1;
    height: 50px;
    width: 100%;
    padding: 12px;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
}

nav.sticky {
    position: fixed;
}

.app-header {
    position: absolute;
    z-index: 10;
    top: auto;

    width: 100%;

    -webkit-transition: all .1s ease;
    transition: all .1s ease;

    background: #fff;
}

.app-header.sticky-nav {
    position: fixed;
    z-index: 1030;
    top: 0;
    bottom: auto;

    padding: 0;

    -webkit-animation: smoothScroll .3s forwards;
    animation: smoothScroll .3s forwards;

    background: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, .15);
}

@-webkit-keyframes smoothScroll {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes smoothScroll {
    0% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

.nav-bar ul li {
    list-style: none;
    display: inline-block;
}

a {
    -webkit-transition: all .3s;
    transition: all .3s;
    text-decoration: none;

    color: #FFFFFF;
}

a:hover {
    text-decoration: none;
    color: #000000;
}

.heading-content a {
    color: #000000;
    font-weight: 600 !important;
    font-size: 24px;
    padding-left: 32px;
}

.btn-heading {
    margin-left: 70px;
}

.btn-headings {
    margin-left: 60px;
}

.btn-heading1,
.btn-heading2 {
    margin-left: 20px;
}

.number {
    display: flex !important;
    float: right;
    margin-right: 10%;
    font-size: 24px;
}

.body-content {
    margin-top: 50px;
}

.contents {
    float: left;
    width: 50%;
    height: 640px;
}

.contents .btn-login {
    margin-left: 120px;
    width: 150px;
}

.contents1 {
    line-height: 25px;
}

.content1,
.content2,
.content3,
.content4 {
    margin-left: 120px;
}

.work-heading {
    margin-left: 110px;
}

.content1 {
    margin-top: 80px;
    width: 600px;
    height: 124px;
    font-size: 50px;
    line-height: 60px;
}

.content2 {
    width: 400px;
    height: 80px;
    font-size: 21px;
    font-weight: 600;
}

.con1 {
    color: #0094FF;
    line-height: 35px ! important;
}

.con2 {
    margin-bottom: 50px;
    padding-right: 80px;
}

.link {
    color: #0094FF;
    font-size: 18px;
    // margin-left: 50px;
    width: 200px;
    background-color: transparent;
    border: none;
}

.shape {
    margin-top: 100px;
}

.content-image {
    width: 50%;
    float: right;
    height: 680px;
    background: transparent url(${img1}) 0% 0% no-repeat padding-box;
    background-position: bottom right;
}

.image {
    margin-left: 40px;
    margin-top: 20px;
}

.image1 {
    float: right;
    margin-left: 80px;
    margin-top: -40px;
}

.content3 {
    font-size: 34px;
    line-height: 35px;
}

.content4 {
    font-size: 20px;
}

.box-text {
    line-height: 30px !important;
    font-size: 18px !important;
}

.card {
    // width: 100%;
    border: none;
}

.card1 {
    width: 100%;
    border: none;
}

.card-content {
    width: 100%;
    border: none;
    margin-top: 30px;
    background: transparent url(${img2}) 0% 0% no-repeat padding-box;
    background-position: top 100px left;
}

.card-box {
    margin-left: 0px;
    margin-right: 0px;
}

.card-box1,
.card-box2 {
    height: 200px;
}

/* .card-box2,
.card-box4{
    margin-left: 270px;
} */
.card-box3,
.card-box4 {
    height: 200px;
    margin-top: 40px;
}

.card-box1,
.card-box3 {
    margin-left: 105px;
}
.card-box2,
.card-box4 {
    margin-right: 105px; 
}

.card-box1,
.card-box2,
.card-box3,
.card-box4 {
    // width: 640px;
    background: #F1F9FF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    padding: 20px 20px;
}

.card-heading1,
.card-heading2,
.card-heading3,
.card-heading4 {
    font-size: 24px;
    font-weight: 600;
    color: #0094FF;
    opacity: 1;
}

.card-content1,
.card-content2,
.card-content3,
.card-content4 {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    opacity: 1;
    line-height: 35px;
}

.card-contact {
    width: 900px;
    font-size: 24px;
    font-weight: 600;
    color: #0094FF;
    opacity: 1;
    padding-left: 110px;
}

.card-contact1 {
    width: 550px;
    margin-top: 20px;
}

.card-contact1  a{
    color: #0094FF !important;
}

#Applynow {
    padding-top: 70px;
}

.work-heading-content {
    font-size: 34px;
}

.work-content,
.card-box7-heading-content {
    display: flex;
}

.num {
    // padding-left: 40px;
}

.num1,
.num2,
.num3 {
    width: 24px;
    height: 24px;
    text-align: center;
    background: #0094FF 0% 0% no-repeat padding-box;
    border-radius: 14px;
    opacity: 1;
    font-size: 16px;
    color: #FFFFFF;
    padding: 0px 6px;
}

.num1-heading {
    margin-left: 20px;
    line-height: 6px;
}

.num1-content1 {
    font: 600 22px 'Roboto';
}

.num2-content1,
.num3-content1 {
    font: 600 22px 'Roboto';
    margin-top: 25px;
}

.num1-content2,
.num2-content2,
.num3-content2 {
    font: 500 20px 'Roboto';
}

.num2,
.num3 {
    margin-top: 80px;
}

.footer {
    margin-top: 80px;
}

.footer {
    width: 100%;
    background: transparent linear-gradient(196deg, #00B8FF 0%, #0087FF 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.footer2 {
    margin-top: 30px;
}

ul li {
    list-style: none;
}

.brokerpay {
    margin-top: 30px;
    width: 174px;
    height: 55px;
    opacity: 1;
}

.foot-col2 {
    font: 18px 'Roboto';
    font-weight: 500;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 50px;
    line-height: 35px;
}

.foot-col3 {
    font: 18px 'Roboto';
    color: #FFFFFF;
    font-weight: 500;
    opacity: 1;
}

hr {
    background: #FFFFFF;
}

.card-box5 {

    background: #F1F9FF;
    box-shadow: 0px 3px 6px #00000015;
    opacity: 1;
}

.card-content5 {
    margin-top: 50px;
    margin-left: 108px;
    font: 24px 'Roboto';
    font-weight: 600;
    float: left;
    line-height: 35px;
}

.content6-logo {
    width: 422px;
    height: 243px;
    float: right;
}

.accordion-heading {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    font: 34px 'Roboto';
    font-weight: 500;
}

.accordion-container {
    width: 100%;
    /* height: 280px; */
    padding-left:  6%;
    padding-right: 6%;
}

.css-ahj2mt-MuiTypography-root {
    font-weight: 600 !important;
}

.expandicon {
    color: #0094FF;
}

.accordian {
    margin-top: 20px;
}

.accordian .card .card-header h3 {
    padding: 15px;
    font: 500 16px 'Roboto';
    box-shadow: 0px 1px 0px #00000015;
    cursor: pointer;
    position: relative;
    margin: 0;

}

.card-header {
    padding: 0px 0px;
    background-color: #FFFFFF;
}

.accordian .card .card-header {
    position: relative;
}

.accordian .card .card-header span {
    position: absolute;
    right: 20px;
    top: 12px;
    height: 25px;
    width: 25px;
    color: #0094FF;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    font-size: 13px;
    cursor: pointer;
}

.accordian .card .card-body {
    padding: 10px;
    font: 18px 'Roboto';
    margin-left: 5px;
}

.accordian .card .card-body {
    display: none;
}

.accordian .card .card-body p {
    font-size: 15px;
    line-height: 24px;
    color: #444444;
    font-weight: 400;
    margin: 0px;
}

.card-box6 {
    padding-bottom: 25px;
    background: #F1F9FF;
    background-size: cover;
    opacity: 1;
}

.card-box6-heading {
    font: 40px 'Roboto';
    font-weight: 500;
    color: #0094FF;
}

.card-box6-heading-content {
    padding-top: 20px;
}

.card-box6-content {
    font: 20px 'Roboto';
    font-weight: 400;
}

.form-control{
    border: 1px solid #C2C2C2 !important;
    border-radius: 5px !important;
}
.form-box {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    display: block;
    margin-bottom: 10px;
}

.form-box-content {
    padding: 10px 36px;
     display: block;
}
.btn-submit{
  
}
.title {
    font: 20px 'Roboto';
    font-weight: 500;
    margin-top: 10px;
}

.input-box {
    height: 40px;
}

.input-box1 {
    height: 84px;
}

.input-box,
.input-box1 {
    width:100%;
    background: #FFFFFF;
    border: 1px solid #C2C2C2;
    border-radius: 5px;
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 5px;
}

.btn-submit {
    margin-top: 15px;
    margin-left: 160px;
}

.btn-submit .btn-login {
    width: 180px;
    height: 40px;
    background: #0094FF;
    border-radius: 20px;
}

.card-box7 {
    margin: 60px ;
    background: #0094FF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
}

.card-box7-information {
    padding: 40px 60px;
}

.card-box7-heading {
    font: 24px'Roboto';
    font-weight: 500;
    color: #FFFFFF;
}

.card-box7-heading-content {
    margin-top: 20px;
    font: 16px 'Roboto';
    font-weight: 400;
    line-height: 18px;
    color: #FFFFFF;
}

.card-box7-content {
    margin-top: 3px;
    margin-left: 10px;
}

.privacy-body {
    padding-top: 90px;
    padding-bottom: 50px;
    padding-right: 10%;
    padding-left:10%;

}


.btn-signin{
    font-size: 24px;
}
.privacy-body p {
    font-size: 20px;
    font-weight: 500;
    text-align: justify;

      ul{
        list-style-position: outside;
        padding-left: 25px;
      }
      li{
       
      }
}

.privacy-body li {
    padding-left: 20px;
}

.privacy-heading {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #0094FF;
    text-transform: capitalize;
    opacity: 1;
}


@media (min-width:1240px) {
.card-contact{
    position: relative;
    bottom: 100px;
    display: flex;
}

}
@media (max-width:1440px) {
    .logo-brokerpay {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 174px;
        height: 55px;
    }

    .logo,
    .btn-nav {
        padding-left: 0px;
    }

    .btn-login {
        margin-left: 280px;
    }

    .heading {
        background: #F1F9FF;
        opacity: 1;
        height: 50px;
        width: 100%;
        padding: 12px;
        font-size: 18px;
        font-weight: 500;
        color: #000000;
    }

    // .card-box1,
    // .card-box2,
    // .card-box3,
    // .card-box4 {
    //     width: 580px;
    // }


    .content6-logo {
        float: unset;
    }

    /* .card-box2, 
    .card-box4 {
        margin-left: 250px;
    } */

    .btn-submit {
        margin-left: 150px;
    }
}

@media(max-width: 1300px) {
    .logo-brokerpay {
        display: flex;
        justify-content: center;
        margin: auto;
    }

    .btn-login {
        margin-left: 180px;
    }

    .heading {
        background: #F1F9FF;
        opacity: 1;
        height: 50px;
        width: 100%;
        padding: 12px;
        font-size: 18px;
        font-weight: 500;
        color: #000000;
    }

    .content6-logo {
        float: right;
    }

    .card .card-header {
        position: relative;
        width: 1050px;
    }


    .btn-submit {
        margin-left: 320px;
    }

    body {
        width: 1295px;
    }

}

@media(min-width: 900px) {
.privacy-heading{
    margin-top: 55px;
}
.card-box6{
    padding-left: 10%;
}
}
@media(max-width: 340px) {
    .card-contact1 a {
        font-size: 15px !important;
    }
}
@media(max-width: 1220px) and (min-width: 768px){

.row2-cardbox{
    margin-top: 30px !important;
}
.card-contact{
    position: relative;
    top: -50px;
}

}

@media(max-width: 1019px) and (min-width: 768px){
    .card-content-box{
   
    margin: auto;
}} 

@media (min-width: 800px) {
.form-box{
    width: 762px;
}
}
@media (max-width: 768px) {
    .content6-logo {
        float: unset !important;
        margin: auto;
        justify-content: center;
        display: flex;
    }
    .card-content{
        padding: 3%;
    }
}
@media (max-width: 1024px) {
    .logo-brokerpay {
        display: flex;
        justify-content: center;
        margin: auto;
    }

    .btn-login {
        margin-left: 200px;
    }

    /* .app-header
    {
        position: absolute;
        z-index: 10;
        top: auto;
        width: 100%;
        padding: .3em 0; 

        background: #fff;
    } */

    // .card-box1,
    // .card-box2,
    // .card-box3,
    // .card-box4 {
    //     width: 500px;
    // }

    /* .card-box2, 
    .card-box4 {
        margin-left: 220px;
    } */
    .content6-logo {
        float: right;
    }


    .btn-submit {
        margin-left: 300px;
    }
}
@media (max-width: 1120px){
.image-HIW{
   z-index: -1;
   position: relative;
}
.image1-HIW{
    z-index: -1;
   position: relative;
}
}
@media (max-width: 1000px){
    .link{
        margin-left: 100px;
    }
    .con2{
        padding-right: 0;
    }
}
@media (max-width: 900px){
    .btn-group{
        position: relative;
        width: 100%;
        top: 0;
        padding-right: 0;
        margin: auto;
        justify-content: center;
        margin-top: 10px;
    }
    .login-nav{
         margin-left: 0 !important;
    }
    .box-container-fluid {
        margin-bottom: 80px;
    }
    .number{
        display: none !important;
    }
    .menu-bar{
        margin-top: 130px;
    }
    .privacy-body{
        padding-top: 200px;
    }
}
@media (max-width: 800px){
    .image-HIW{
        display: block;
    margin-left: -120px;
    }
}
@media (max-width: 1000px){
    .content1{
        margin-left: 60px;
        font-size: 40px;
    }
    .content2, .content3, .content4{
        margin-left: 60px;
    }
    .btn-heading{
        margin-left: 0;
    }
    .work-heading, .card-content5 {
        margin-left: 20px;
    }
    .num{
        // padding-left: 20px;
    }
    .card-contact{
        padding-left: 40px;
    }
    .card-contact1{
        font-size: 20px;
        display: flex;
    }
    // .accordion-container {
    //     padding-left: 0px;
    // }
}
@media (max-width: 920px){
    .num2, .num3{
        position: relative;
        top: 30px;
    }
}
@media (max-width: 768px){
.footer-contact{
    display: none;
}
}
@media (max-width: 614px){
table{
    border-collapse: separate;
}

}
@media (max-width: 609px){
    .num3{
        top: 60px;
    }
}
@media (max-width: 500px){
    .brokerpay,  .foot-col3 p {
        margin: auto;
        justify-content: center;
        display: flex;
    }
    .foot-col2 li{
        margin: auto;
        justify-content: center;
        display: flex;
    }
    .foot-col3 p {
        margin-bottom: 20px;
    }
}
@media (max-width: 500px){
    #faq{
        position: relative;
    }
    .card-box7 {
        margin: 20px !important;
    }
.heading-content a{
    font-size: 20px;
}
.image-HIW{
    width: 250px;
    height: 250px;
    top: 320px;
}
.link{
    margin-left: 10px;
}
.link1{
    margin-left: 30px !important;
}
.shape{
    margin-top: 180px;
}
}
@media (max-width: 540px){  
    .content1,  .content3{
        margin-left: 30px; 
        font-size: 30px;
    }
    .content2,.content4{
        margin-left: 30px;
        font-size: 15px;
    }
    .box-text{
        font-size: 15px !important;
        display: flex;
    }
    .work-heading{
        margin-left: 20px; 
    }
    .num{
        // padding-left:20px;
    }
    .card-box7-information {
        padding: 20px !important;
    }
}
@media (max-width: 416px){
    .num3 {
         top: 100px;
    }
    .num2{
        top: 60px;
    }
}
    @media (max-width: 355px){
        .num3 {
            top: 130px;
       }
    }
@media (max-width: 431px){
    .heading-content a{
        padding-left: 10px;
        font-size: 16px;
    }
    .work-heading, .card-content5 {
        margin-left: 10px;
    }
    .card-contact {
        padding-left: 20px;
    }
    .content6-logo {
        margin-left: -90px;
    }
     .accordion-heading{
        margin-left: 10px;
     }
   .card-box7-content{
    font-size: 12px;
    word-break: break-all;
   }
   .num{
    padding-left: 0;
   }
}
@media (max-width: 431px){
    .content1{
        font-size: 28px;
    }
    .content2{
        font-size: 15px;
    }
}
@media (max-width: 370px){
    .content1{
        font-size: 24px;
    }
    .content2{
        font-size: 13px;
    }
    .image-HIW{
        width: 150px;
        height: 150px;
        top: 420px;
    }
}
@media (min-width: 768px){
.col-md-3 {
    width: 23% !important;
}}
@media(max-width: 992px) {
    .container {
        max-width: 1000px;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 1000px;
    }

     .card .card-header {
        position: relative;
        width: 800px;
    }

    .card-box1,
    .card-box2,
    .card-box3,
    .card-box4 {
        margin: auto;
        margin-top: 40px;
    }
}
@media(max-width: 767px){
    .card-contact1{
        font-size: 18px ! important;
    }
}
/* @media(max-width: 767px){
     body{
        width: 100%;
    }
    .box-container-fluid{
        width: 1050px;
    }
    .logo-brokerpay{
        margin-left: 370px;
    }
    
    .btn-login{
        margin-left: 50px;
    }
    .btn-heading{
        margin-left: 70px;
    }
    .btn-headings{
        margin-left: 0px;
    }

    .content1, 
    .content2, 
    .content3, 
    .content4, 
    .contents .btn-login {
        margin-left: 120px;
    }
    .work-heading{
        margin-left: 110px;
    }
    // .accordion-container{
    //     padding-left: 0px;
    // }
    .card-box{
        flex-wrap: wrap;
    }
    
    .contents,
    .card-content,
    .accordion,
    .card-box5{
        width: 1050px;
    }
    .card-box5{
        height: 260px;
    }
    .content3{
        width: 100%;
    }
    .content4{
        width: 550px;
    }
    .contents{
        float: none;
        height: 500px;
    }
    .content-image{
        float: none;
        width: 1050px;
    }
    .card-content5 {
        margin-top: 90px;
        width: 430px;
        margin-left: 65px;
        padding: 0px 40px;
    }
    .accordian{
        margin-top: 40px;
        margin-left: 125px;
    }
    .accordion-heading {
        width: 890px;
    }
  
    .work-content{
        margin-top: 30px;
    }
    .num2,
    .num3{
        margin-top: 84px;
    }
    .foot-col3,
    .card-box5{
        flex-wrap: nowrap;
    }
    .card-box1,
    .card-box2,
    .card-box3,
    .card-box4{
        margin-left: 105px;
    }
    .card-box2, 
    .card-box4 {
        margin-top: 50px;
    }

/* .card-box6 {
        width: 1050px;
        padding-left: 0px;
    }
    .card-box6-heading,
    .form-box{
        width: 600px;
    }
    // .input-box, 
    // .input-box1{
    //     width: 520px;
    // } 
    .btn-submit{
        margin-left: 290px;
    }

    .card-box7-information{
        padding-top: 50px;
    } 
    .footer{
        text-align: center;
        width: 1050px;
        height: 640px;
    }
    .foot-col2{
        margin-right: 50px;
    }
} */

/* @media (min-width: 1025px)
{
    .app-header
    {
        position: absolute;
        z-index: 10;
        top: auto;

        width: 100%;

        -webkit-transition: all .1s ease;
                transition: all .1s ease; 

        background: #fff;
    }
    .app-header.sticky-nav
    {
        position: fixed;
        z-index: 1030;
        top: 0;
        bottom: auto;

        padding: 0;

        -webkit-animation: smoothScroll .3s forwards;
                animation: smoothScroll .3s forwards; 

        background: #fff;
        box-shadow: 0 1px 10px rgba(151, 164, 175, .15);
    }
    @-webkit-keyframes smoothScroll
    {
        0%
        {
            -webkit-transform: translateY(-100px);
                    transform: translateY(-100px);
        }
        100%
        {
            -webkit-transform: translateY(0px);
                    transform: translateY(0px);
        }
    }
    @keyframes smoothScroll
    {
        0%
        {
            -webkit-transform: translateY(-100px);
                    transform: translateY(-100px);
        }
        100%
        {
            -webkit-transform: translateY(0px);
                    transform: translateY(0px);
        }
    }
} */
/* @media (max-width: 500px){
    body{
        width: 100%;
    }
    .box-container-fluid{
        width: 800px;
    }
    .nav{
        justify-content: unset;
    }.logo-brokerpay{
        margin-left: 250px;
    }
    .btn-login{
        margin-left: 100px;
    }
    .btn-heading{
        margin-left: 70px;
    }
    .number{
        margin-left: 250px;
    }
    .numbers{
        margin-left: 320px;
    }
    .content1, 
    .content2, 
    .content3, 
    .content4,
    .contents .btn-login {
        margin-left: 120px;
    }
    .work-heading{
        margin-left: 110px;
    }
    // .accordion-container{
    //     padding-left: 0px;
    // }
    .card-box{
        flex-wrap: wrap;
    }
    .contents,
    .card-content,
    .accordion,
    .card-box5{
        width: 800px;
    }
    .card-box5{
        height: 260px;
    }
    .content4{
        width: 550px;
    }
    .contents{
        float: none;
        height: 500px;
    }
    .content-image{
        float: none;
        width: 800px;
    }
    .accordian{
        margin-top: 40px;
        margin-left: 40px;
    }
    .accordion-heading {
        width: 800px;
    }
    .work{
        width: 700px;
    }
    .work-content{
        margin-top: 30px;
    }
    .foot-col3,
    .card-box5{
        flex-wrap: nowrap;
    }
    .card-box1,
    .card-box2,
    .card-box3,
    .card-box4{
        width: 73%;
        margin-left: 105px;
    }
    .card-box2, 
    .card-box4 {
        margin-top: 50px;
    }
    .card-content5 {
        margin-top: 70px;
        padding: 0px 40px;
    }
    .card-box6 {
        width: 800px;
        padding-left: 0px;
    }
    .card-box6-heading,
    .form-box{
        width: 600px;
    }
    .input-box, 
    .input-box1{
        width: 520px;
    } 
    .btn-submit{
        margin-left: 240px;
    }

    .card-box7-information{
        padding-top: 50px;
    } 
    .num2,
    .num3{
        margin-top: 84px;
    }
    .footer,
    .footer2{
        text-align: center;
        width: 800px;
        height: 580px;
    }
    .foot-col2{
        margin-right: 50px;
        margin-top: 30px;
    }
    .footer2{
        margin-top: 20px;
    }
    
} */
        
`;

export default LandingPageWrap;
