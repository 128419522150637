import { Row, Col, Card, Modal, Container, Button } from "react-bootstrap";
import React from "react";
import UICardView from "../style";
import { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "../../../../assets/images/logo/avatar.png";
import BankaccountDetails from "./BankAccountDetails";
import { api } from "../../../../Services/api";
import DeleteAccountOTPConfirmation from "./DeleteAccountOTPConfirmation";
import BarLoader from "react-spinners/ClipLoader";
import UIModal from "../../../Reuseable/UIModal";
import Contact from "../../LandingPage/Conatctus";
import LandingPageWrap from "../../LandingPage/style";
import { getCurrentTime } from "../../../Reuseable/CurrentDateTime";
import PaymentForm from "../../../Reuseable/CardPaymentForm/PaymentForm";
import AccountPaymentMethod from "../../../Reuseable/AccountPaymentLink";

const AccountCard = ({ getBrokerdata, getBankDetails }) => {
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);
  const bankAccount = useSelector((state) => state.Tracking.bankAccount);
  const authToken = useSelector((state) => state.Auth.authToken);

  const [loading, setLoading] = useState(false);
  const [deleteError, setdeleteError] = useState(false);

  const ConfirmDeleteAccount = async () => {
    setLoading(true);
    const obj = {
      userId: brokerdata.userId,
      emailId: brokerdata.emailId,
      logDateTime: getCurrentTime(),
    };
    try {
      let url = "/deleteuser";
      const response = await api.post(url, obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status) {
        setLoading(false);
        setDeleteShow(true);
        setModalShow(false);
      } else {
        setLoading(false);
        setModalShow(false);
        setdeleteError(true);
      }
    } catch (error) {
      setLoading(false);
      setModalShow(false);
      console.log("ConfirmDeleteAccount [error]-->", error);
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const [showcontact, setShowContact] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);

  const [clientSecret, setClientSecret] = useState("");
  const [clientSecretAccount, setClientSecretAccount] = useState("");
  const [page, setPage] = useState("page_1");
  const [openUpdate, setOpenUpdate] = useState(false);

  useEffect(() => {
    loadClientSecret();
    getBankDetails();
  }, []);

  const loadClientSecret = async () => {
    try {
      let url = "/userbankdetails/link";
      const response = await api.get(url);
      if (response.data.status === true) {
        setClientSecret(response.data.client_secretForCard);
        setClientSecretAccount(response.data.client_secretForAccount);
      }
    } catch (error) {
      console.log("loadClientSecret [error]-->", error);
    }
  };

  return (
    <UICardView>
      <Container>
        <div className="back-link">My Account</div>
        <Card className="settingscard">
          <Card.Body>
            <Row>
              <Col className="avatar " sm={12} md={1} lg={1} xl={1}>
                <div className="avatar_container">
                  <img
                    className="user-img"
                    alt="User"
                    src={brokerdata.profile ? brokerdata.profile : Avatar}
                  />
                </div>
              </Col>
              <Col sm={8} md={4} lg={3} xl={3}>
                <p
                  className="profile-name"
                  style={{
                    float: "left",
                    paddingTop: "20px",
                  }}
                >
                  {brokerdata.personname}
                </p>
              </Col>

              <Col>
                <Button
                  className={
                    deleteError
                      ? "disable-color delete bg-transparent border-0"
                      : "delete bg-transparent border-0"
                  }
                  onClick={() => setModalShow(true)}
                  disabled={deleteError}
                >
                  Delete My Account
                </Button>
              </Col>
            </Row>

            <div className="userid-line mt-1">
              <div>
                <p> {brokerdata.emailId}</p>
              </div>
              <div className="display px-4">
                <p> | </p>
              </div>
              <div>
                <p>Ph: {brokerdata.phoneNumber}</p>
              </div>
              <div>
                <Button
                  className={
                    deleteError
                      ? "disable-color delete1 bg-transparent border-0"
                      : "delete1 bg-transparent border-0"
                  }
                  onClick={() => setModalShow(true)}
                  disabled={deleteError}
                >
                  Delete My Account
                </Button>
              </div>
            </div>

            <Row>
              <Col>
                <Col className="themegradient mb-2">
                  <Row>
                    <Col
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{
                        color: "#FFFFFF",
                        paddingTop: "5px",
                      }}
                    >
                      <p>{brokerdata.businessName}</p>
                    </Col>
                  </Row>
                  <div className="userid-line">
                    <div>
                      <p> Buisness ABN: {brokerdata.ABN}</p>
                    </div>
                    <div className="display px-4">
                      <p> | </p>
                    </div>
                    {brokerdata.licenceNumber ? (
                      <div>
                        {brokerdata.licenceNumber.ACL == "" ? (
                          <p>CRN:{brokerdata.licenceNumber.CRN}</p>
                        ) : (
                          <p>ACL: {brokerdata.licenceNumber.ACL}</p>
                        )}
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Col>
            </Row>
            {deleteError ? (
              <div className="d-inline fw-bold p-2">
                We are unable to close your account while you have open
                requests. If you have any questions get in touch with Brokerpay
                support on
                <span className="primary">
                  <a
                    className="m-1"
                    href="mailto: customercare@brokerpay.com.au"
                  >
                    customercare@brokerpay.com.au.
                  </a>
                </span>
              </div>
            ) : null}
          </Card.Body>
        </Card>
        <Row>
          <Col md={6}>
            <Card className="my-3 p-3">
              <p className="p-pading">Linked bank account</p>
              <div className="bank-details">
                {bankAccount?.accountDetails ? (
                  <Card className="themegradient">
                    <Card.Body>
                      <Col>
                        <Col>
                          <b>Bank Name : </b>
                          {bankAccount?.accountDetails?.BankName}{" "}
                        </Col>
                        <Col>
                          <b>BSB :</b> {bankAccount?.accountDetails?.Bsb}{" "}
                        </Col>
                        <Col>
                          <b>Account Name : </b>
                          {bankAccount?.accountDetails?.AccountName}
                        </Col>
                        <Col>
                          <b>Account Number : </b> ***********
                          {bankAccount?.accountDetails?.Accountnumber}
                        </Col>
                      </Col>
                    </Card.Body>
                  </Card>
                ) : null}
                <div
                  onClick={() => {
                    setOpenUpdate(true);
                    setPage("page_1");
                  }}
                  className="primary pointer fw-bold float-end mt-2 "
                >
                  Update
                </div>
              </div>
            </Card>
          </Col>
          <Col md={6}>
            <Card className=" my-3 p-3">
              <p className="p-pading">Card details</p>
              <div className="bank-details">
                {/* {bankAccount.cardDetails && bankAccount.cardDetails.length ? ( */}
                <Card className="themegradient">
                  <Card.Body>
                    <div className="center">Card number</div>
                    <div className="center card_number">
                      XXXX XXXX XXXX {bankAccount?.cardDetails?.cardNumber}
                    </div>
                    <div className="card_footer">
                      <span>
                        Expiry date:{" "}
                        {bankAccount?.cardDetails?.expMonth +
                          "/" +
                          bankAccount?.cardDetails?.expYear}
                      </span>
                      <span className="float-end">CVC: ***</span>
                    </div>
                  </Card.Body>
                </Card>
                {/* ) : null} */}
                <div
                  onClick={() => {
                    setOpenUpdate(true);
                    setPage("page_2");
                  }}
                  className="primary pointer fw-bold float-end mt-2 "
                >
                  Update
                </div>
              </div>
            </Card>
          </Col>
        </Row>

        <Modal
          size="lg"
          show={modalShow}
          onHide={() => setModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="p-3">
            <UICardView>
              <h4 className="center text-center">
                Are you sure you want to delete your account?
              </h4>
              <p className="settings-modal-content">
                Once your account has been deleted you will no longer be able to
                access your account history and past payment records. You can
                download a copy of your account history
                <Link to="/home/accounthistory" className="primary px-1">
                  here
                </Link>
              </p>
              <Row>
                {loading ? (
                  <div className="center">
                    <BarLoader
                      as="span"
                      size={14}
                      animation="grow"
                      color="#0094FF"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <>
                    <Col>
                      <Button
                        className="theme-btn float-end px-4"
                        onClick={() => setModalShow(false)}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="theme-btn px-4"
                        onClick={() => {
                          ConfirmDeleteAccount();
                        }}
                      >
                        Proceed
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </UICardView>
          </Modal.Body>
        </Modal>
      </Container>

      <UIModal
        show={openUpdate}
        setShow={setOpenUpdate}
        padding={true}
        close={true}
        size={"md"}
        body={
          page === "page_1" ? (
            <AccountPaymentMethod
              clientSecretAccount={clientSecretAccount}
              setPage={setPage}
              onBoarding={false}
              setOpenUpdate={setOpenUpdate}
              getBankDetails={getBankDetails}
            />
          ) : (
            <PaymentForm
              clientSecret={clientSecret}
              setShow={setOpenUpdate}
              getBankDetails={getBankDetails}
              onBoarding={false}
            />
          )
        }
      />

      <DeleteAccountOTPConfirmation
        deleteShow={deleteShow}
        setDeleteShow={setDeleteShow}
        ConfirmDeleteAccount={ConfirmDeleteAccount}
        setShowContact={setShowContact}
      />

      <UIModal
        show={showcontact}
        setShow={setShowContact}
        padding={false}
        close={true}
        body={
          <LandingPageWrap>
            <Contact />
          </LandingPageWrap>
        }
      />
    </UICardView>
  );
};
export default AccountCard;
