import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import ResetForm from "./ResetForm";
import ResetOtpVerificationForm from "./ResetOtpVerificationForm";

const ResetPasswordForm = ({ close, isLoggedin, fisrtLogin }) => {
  const [otpConfirmation, setOtpConfirmation] = useState(false);
  const [formValues, setFormValues] = useState({});

  return (
    <>
      <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Label className="center reset-password primary">
          <b>Reset Password</b>
        </Form.Label>
      </Form.Group>
      <Form.Group>
        {!otpConfirmation ? (
          <ResetForm
            fisrtLogin={fisrtLogin}
            setOtpConfirmation={setOtpConfirmation}
            setFormValues={setFormValues}
            close={close}
          />
        ) : (
          <ResetOtpVerificationForm
            close={close}
            formValues={formValues}
            isLoggedin={isLoggedin}
          />
        )}
      </Form.Group>
    </>
  );
};

export default ResetPasswordForm;
