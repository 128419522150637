import Styled from "styled-components";
import style from "styled-components";



const SidebarWrap = Styled.div`
            /*   ------------------------ */
            input.form-control::placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
            input.form-control::-webkit-input-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
    
            input.form-control::-moz-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
    
            input.form-control:-ms-input-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
    
            input.form-control:-moz-placeholder {
                color: ${({ theme }) => theme["placeholder"]};
            }
                .sidebar 
                {
                    position: fixed;
                    top: 0px;
                    left: 0;
                    bottom: 0;
                    width: 160px;
                    z-index: 11;
                    padding: 70px 0 0;
                    background: ${({ theme }) => theme["background-color"]};
                    min-height: calc(100vh - 70px);
                    .nav {
                        &:not(.sub-menu) {
                            padding-bottom: 60px;
                            padding-top: 0px;
                        }
                        overflow: hidden;
                        flex-wrap: nowrap;
                        flex-direction: column;
                        .nav-link {
                            -webkit-font-smoothing: antialiased;
                            font-size: 14px;
                            letter-spacing: 0.2px;
                            color: ${({ theme }) => theme["panel-heading"]};
                            text-shadow: none;
                            text-align: left;
                            padding: 6px 10px 6px 28px;
                            // border-radius: 0 15px 15px 0;
                            font-weight: 500;
                            svg {
                                margin-right: 15px;
                                width: 17px;
                                height: 17px;
                                vertical-align: middle;
                                path 
                                {
                                    fill:${({ theme }) => theme["icon-color"]};
                                }
                            }
                            span {
                                vertical-align: middle;
                            }
                            &:focus,&:hover, &:active,&.active {
                                // color : ${({ theme }) => theme["hover-icon-color"]};
                                // background-color: #e8eaed;
                                background-color: ${({ theme }) => theme["panel-color"]};
                                svg {
                                    path {
                                        fill: ${({ theme }) =>
                                          theme["hover-icon-color"]};
                                    }
                                }
                            }
                
                        }
                        .dropmenuitems {
                            .nav-link {
                                padding: 6px 10px 6px 60px;
                                font-size: 13px;
                                color: ${({ theme }) => theme["panel-heading"]};
                            }
                            .nav-link.active {
                                font-weight: 500;
                                background-color: transparent;
                                color: ${({ theme }) => theme["orange-color"]};
                            }
                        }
                        .nav-link.active {
                            font-weight: 600;
                        }
                    }
                    li.nav-item {
                        list-style: none;
                        margin: 3px 0;
                        .dropmenuitems {
                            ul {
                                padding-left: 0px;
                                .nav-link {
                                    &:focus {
                                        background-color: transparent;
                                        border-radius: 0;
                                    }
                                    &:active {
                                        background-color: transparent;
                                        border-radius: 0;
                                    }
                                    &:hover {
                                        background-color: transparent;
                                        border-radius: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .serchFieldMain {
                    svg {
                        path {
                            fill: ${({ theme }) => theme["icon-color"]};
                        }
                    }
                }
                .sidebarMainLinks {
                    position: absolute;
                    padding: 0px 0px 0;
                    top: 135px;
                    overflow: hidden;
                    overflow-y: auto;
                    min-height: calc(100vh - 70px);
                }
                
                .sidebarMain {
                    .serchFieldMain {
                        padding-top: 10px;
                        margin: 0 27px;
                        svg {
                        position: absolute;
                        top: 26px;
                        left: 0px;
                        width: 14px;
                        height: 14px;
                    }
                    input.form-control {
                        width: 100%;
                        border-radius: 0;
                        border: none;
                        border-bottom: 1px solid #e1e5eb;
                        background-color: transparent;
                        height: 42px;
                        padding-bottom: 0;
                        padding-left: 25px;
                        color: ${({ theme }) => theme["themeColor"]};
                    }
                }
            
                
                .sideBar {
                    .siteLogo {
                        min-height: 90px;
                    }
                    .nav-menu {
                        a {
                            svg {
                                vertical-align: middle;
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }
                .sidebar.backtoMainSideBar {
                    ul.nav {
                        padding-top: 10px;
                    }
                }
                .mobSideBar {
                    .backLinkMain {
                        .backlink {
                            .backTxt {
                                display: none;
                            }
                            padding: 10px 10px 10px 14px;
                        }
                    }
                    .sidebarMain {
                        .serchFieldMain {
                        margin: 0 17px;
                        input.form-control {
                            display: none;
                        }
                    }
                }
                .sidebar {
                    .nav {
                        .dropmenuitems {
                            .nav-link {
                                display: none;
                            }
                        }
                        .nav-link {
                            padding: 6px 10px 6px 17px;
                        }
                    }
                }
                
                .sidebarMainLinks {
                    width: 60px;
                }
                .sidebar.sidebarInerLinks {
                    width: 60px;
                }
                .sidebar.sidebarMain {
                    width: 60px;
                }
                .listArowIcon {
                    display: none;
                }
                .navItemArrow {
                    display: none;
                }
                .nav-link {
                    span.dsktopView {
                    display: none;
                    }
                }
                .main-panel {
                    margin-left: 60px;
                }
                
            }
            .backLinkMain {
                padding: 0;
                text-align: left;
                border-bottom: 1px solid ${({ theme }) => theme["border-color"]};
                .backlink {
                    text-decoration: none;
                    line-height: normal;
                    display: block;
                    padding: 15px 10px 15px 25px;
                    font-size: 14px;
                    letter-spacing: 0.2px;
                    text-shadow: none;
                    text-align: left;
                    font-weight: 500;
                    color: ${({ theme }) => theme["panel-heading"]};
                    background-color: ${({ theme }) => theme["background-color"]};
                   
                    .backTxt {
                        padding-left: 6px;
                        padding-top: 0px;
                        display: inline-block;
                    }
                }
            }
            .backtoMainSideBar {
                position: fixed;
                top: 0px;
                left: 0;
                bottom: 0;
                width: 160px;
                z-index: 11;
                padding: 70px 0 0;
                background: #fff;
                min-height: calc(100vh - 70px);
                left: -260px;
                visibility: hidden;
                transition: all ease-in-out 0.5s;
            }
            .sidebar.sidebarInerLinks {
                position: absolute;
                padding: 0;
                top: 119px;
                overflow: hidden;
                overflow-y: auto;
                min-height: calc(100vh - 70px);
            }
            .backtoMainSideBar.backtoMainSideBarActive {
                left: 0;
                visibility: visible;
                transition: all ease-in-out 0.5s;
            }
            .navItemArrow {
                float: right;
                width: 27px;
                height: 27px;
                margin: 0 auto;
            }
            .mobSideBar {
                .main-panel {
                    margin-left: 60px;
                }
            }
            .main-panel {
                margin-left: 160px;
                display: flex;
                flex-direction: column;
                min-width: 0;
                overflow: hidden;
                min-height: calc(100vh - 70px);
                z-index: 11;
                background: rgba(242, 245, 245, 0.8);
                padding: 15px;
                width: 100%;
                flex-grow: 1;
            }
          
            @media (max-width: 575px) {
                .main-panel {
                    margin-left: 0;
                }
                .mobSideBar .main-panel {
                    margin-left: 0;
                }
                .sidebar {
                    z-index: 111;
                    width:0
                }
                .mobSideBar .sidebar.sidebarMain {
                    width: 0;
                }
                .mobSideBar .sidebarMainLinks {
                    width: 0;
                }
                .mobSideBar .sidebarMain .serchFieldMain {
                    display: none;
                }
                .mobSideBar .sidebar.sidebarMain {
                    box-shadow: 0 0 10px #00000020;
                    -webkit-transition: all 0.5s ease-out;
                    width: 160px;
                    z-index: 111;
                }
                
                
            }    
`;

const Filterbox = style.div`
            @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500&display=swap');
    
            *{
                font-family: Poppins,sans-serif !important;
            }
            .nav-link{
                display: flex;
                color: #303030;
                font-size: 15px;
                font-weight: 600;
               
            }
            .head-icon {
                color:#303030 ;
                &:active,
                &:hover,
                &:focus {
                  color: #0094FF;
                }
              }
              @media (max-width: 830px) {
                .filter-box{
                    margin-top: 70px !important;
                }
            }
              .active{
                display: flex;
                margin-right: 5px;
              }
              .head-icon1{
                display:none !important;
            }
            .line{
                margin: 0;
                border-bottom: 2px solid #0094FF;
            }
            .head-icon {
                fill: black;
                margin-right: 5px;
            }
            .logo-div{
                width: 30px;
            }
            .filter-box{
                margin-top: 30px;
            }
            .nav-link:active, .nav-link:focus, .nav-link:focus-within{
                color: #0094FF;
                .head-icon{
                    display:none;
                }
                .head-icon1{
                    display:inline;
                }
            }
            .sidebar-active{
                margin-top: 10px;
                display: inline-flex;
                // justify-content: center;
                 width: 200px;
                left: 0px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                opacity: 1;
                overflow-y: auto;
                overflow-x: hidden;
                // height: calc(100vh - 70px);
            }
    
            .container{
                margin-top: 10px;
                background-color: #ffffff;
                display: inline-flex;
                justify-content: center;
                position: absolute;
                left: -800px;
                pointer-events: none;
                overflow-y: hidden;
                overflow-x: hidden;
            }
    
            .menu-icon{
                margin-top: 35px;
                margin-right: 20px;
                margin-left: 10px;
                font-weight: bold;
                font-size: 25px;
                cursor: pointer;
            }
            .menu{
                color: #1958A7;
            }
            .filter-box
            {
              //  width: 300px;
                // height: 1165px;
                margin-left: 15px;
                box-shadow: 0px 0px 0px 0px #bcbcbc;
            }   
            .sc-gsDKAQ cNQRpu {
                display: none;
            }
            .filter-box-header {
                margin-top: 30px;
                width: 215px;
                height: 60px;
                display: flex;
                justify-content: space-between;
            }
            h5{
                margin-left: 10px;
                margin-top: 11px;
                font-weight: bold;
            }
            .num{
                margin-right: 10px;
            }
            .closemenu,.menu{
                font-size: 30px;
                font-weight: bold;
            }
            .accordion-button:not(.collapsed) {
                color: #000000;
                background-color: #ffffff;
                border-width: 2px;
            }
            .accordion-button:focus{
                box-shadow: 0 0 0 0 #ffffff;
            }
            .accordion-button {
                height: 10px;
                width: 230px;
                font-weight: bold;
                color: black;
                background-color: #ffffff;
                box-shadow: 0 0 0 0 #ffffff;
            }
            .accordion-collapse{
                width: 230px;
            }
            .accordion-body{
                width: 230px;
            }
            .categories,.tags,.ratings,.duration{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: small;
                font-weight: 500;
            }
            .accordion-body1 .accordion-body-content{
                padding: 20px;
                height: 140px;
                overflow-y: scroll;
            }
            .accordion-body1 .accordion-body-content::-webkit-scrollbar-track
            {
                -webkit-box-shadow: inset 0 0 6px #0000004d;
                box-shadow: 0px;
                background-color: #ffffff;
            }
            .accordion-body1 .accordion-body-content::-webkit-scrollbar
            {
                width: 6px;
                background-color: #F5F5F5;
            }
            .accordion-body1 .accordion-body-content::-webkit-scrollbar-thumb
            {
                background-color: #878383;
            }
            .accordion-button:not(.collapsed)::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                transform: rotate(-180deg);
            }
            .checkbox-color .form-check-input:checked {
                background-color: #1958a7;
                border-color: #1958a7;
            }
            .checkbox-color .form-check-input:focus{
                border-color: #0c0c0c40;
            }
            .rating-content .form-check-input:checked[type=radio]:focus{
                border-color: #0c0c0c40;
            } 
`
;

export {SidebarWrap,Filterbox}; 