import { ActionTypes } from "./actions";
import jwt_decode from 'jwt-decode'

const checkLogin =  localStorage.getItem('isLoggedIn');
const authToken = localStorage.getItem('token');
let user = '';
const menuList = JSON.parse(localStorage.getItem('menuItem'))

if(authToken)
    user = jwt_decode(authToken);

  
const intialState = { 
    isLoggedIn : checkLogin,
    authToken : authToken,
    user: user,
    menuList: menuList
};

export const AuthReducer = (state = intialState, { type,payload }) => {
    switch (type) {
        case ActionTypes.LOGIN:
            return {
                ...state,
                isLoggedIn : payload.isLoggedIn,
                authToken : payload.authToken,
                user: payload.user,
                menuList: payload.menuList
            };
        case ActionTypes.LOGOUT:
            return {
                ...state,
                isLoggedIn : payload.isLoggedIn,
                authToken : payload.authToken,
                user: payload.user,
                menuList: payload.menuList
            };
        default : 
            return state;     
    }
}