import React, { useState } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { Money } from "../../NumberFormatter";
import Cardwrap from "./style";

const UIcardDisplay = ({ Role, formatDate, setRequestShow, setPopupData }) => {
  const processing = <p className="processing">Awaiting Approval</p>;

  return (
    <>
      <Cardwrap>
        <Card
          className="pointer"
          onClick={() => {
            setRequestShow(true);
            setPopupData(Role);
          }}
        >
          <Card.Body className="cardbody">
            <Row>
              <Row>
                <Col
                  className="text-nowrap request-id pointer"
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  ID-{Role.requestId}
                </Col>
              </Row>
              <Row>
                <Col
                  className="Results text-nowrap request-date"
                  sm={4}
                  md={5}
                  lg={7}
                  xl={8}
                >
                  Submitted on: {Role.dateTime}{" "}
                </Col>
              </Row>
              <Row>
                <Col
                  className="Results text-nowrap fw-bold"
                  sm={4}
                  md={5}
                  lg={7}
                  xl={8}
                >
                  Request Value: {Money(Role.commissionAdvance)}{" "}
                </Col>
              </Row>
              <Row>
                <Col
                  className="Results text-nowrap"
                  sm={4}
                  md={5}
                  lg={7}
                  xl={8}
                >
                  Total Fees: {Money(Role.feeAmount)}{" "}
                </Col>
              </Row>
              <Row>
                <Col
                  className="Results text-nowrap pb-3"
                  sm={4}
                  md={5}
                  lg={7}
                  xl={8}
                >
                  Client: {Role.name}{" "}
                </Col>
              </Row>
              <Row>
                <Col
                  className="Results text-nowrap status"
                  sm={4}
                  md={5}
                  lg={7}
                  xl={8}
                >
                  Status:{" "}
                </Col>
              </Row>
              <Row>
                <Col
                  className="Results text-nowrap"
                  sm={4}
                  md={5}
                  lg={7}
                  xl={8}
                >
                  {processing}{" "}
                </Col>
              </Row>
            </Row>
          </Card.Body>
        </Card>
      </Cardwrap>
    </>
  );
};

export default UIcardDisplay;
