import React, { useState } from "react";
import { Forms } from "../../../Reuseable/Forms";
import LoginWrap from "../style";
import hashching from "../../../../../src/assets/images/hashchinglogo.svg";
import { Validators } from "../../../../Utilities/validator";
import Styled from "styled-components";
import { api } from "../../../../Services/api";

const HashchingAccountSignup = ({
  setPageView,
  setHashchingData,
  setEmail,
}) => {
  const [submitted, setSubmitted] = useState("");
  const [changeInForm, setChangeInForm] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const getHashchingBroker = async (form, valid) => {
    if (valid) {
      setLoading(true);
      setEmail(form.email);
      setErrorMessage(false);
      try {
        let url = "/brokerpayuserauth/hashchinguser/" + form.email;
        const response = await api.get(url);
        if (response.data.status === true) {
          let data = response.data.data;
          setHashchingData(data[0]);

          setLoading(false);
          setSubmitted(true);
          setErrorMessage(false);
          setPageView("verify_account");
        } else {
          setLoading(false);
          setPageView("regsiter");
          setSubmitted(false);
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(error.response.data.message);
        setSubmitted(true);
        console.log("getHashchingBroker [error]-->", error.response.data.message);
      }
    }
  };

  return (
    <React.Fragment>
      <LoginWrap>
        <HashchingWrap>
          <div>
            <b className="signup_text center mb-2">Sign Up</b>
            <div>
              <Forms
                title={""}
                formArr={[
                  {
                    label: "Get started by entering your email address",
                    name: "email",
                    type: "email",
                    placeholder: "example@domain.com.au",
                    validators: [
                      {
                        check: Validators.required,
                        message: "This is a required field",
                      },
                      {
                        check: Validators.email,
                        message: "Please enter a valid Email ID.",
                      },
                    ],
                    error: false,
                    maxLength: 50,
                    errormsg: "",
                    value: "",
                    show: true,
                  },
                ]}
                submitBtn={"Continue"}
                onSubmit={getHashchingBroker}
                loading={loading}
                submitted={submitted}
                changeInForm={changeInForm}
                setChangeInForm={setChangeInForm}
                setPageView={setPageView}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                hashching={true}
                message={
                  "Please enter a valid broker ID or continue as other broker."
                }
              />
            </div>
          </div>
        </HashchingWrap>
      </LoginWrap>
    </React.Fragment>
  );
};

const HashchingWrap = Styled.div`

.form-label {
    font-size: 14px !important
}

.loginSubmitBtn{
  margin-bottom: 0px !important;
}

.col-form {
padding: 0 !important
}

.text-center {
    display: flex !important;
    justify-content: end !important
}

.form-control{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0094FF;
    border-radius: 5px;
}

`;

export default HashchingAccountSignup;
