import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-body">
        <h2 className="privacy-heading">PRIVACY NOTICE</h2>
        <p>Last updated January 24, 2023</p>
        <hr />
        <p>
          This privacy notice for Brokerpay Pty Ltd (doing business as
          Brokerpay) (“Brokerpay”, “we”, “us”, or “our”), describes how and why
          we might collect, store, use, and/or share (“process”) your
          information when you use our services (“Services”), such as when you:
        </p>
        <p>
          <ul>
            <li className="list">
              Visit our website at{" "}
              <a className="url" href="https://www.brokerpay.co">
                https://www.brokerpay.co
              </a>{" "}
              or any website of ours that links to this privacy notice
            </li>
            <li className="list">
              Download and use our mobile application (Brokerpay), or any other
              application of ours that links to this privacy notice
            </li>
            <li className="list">
              Engage with us in other related ways, including any sales,
              marketing, or event
            </li>
          </ul>
        </p>
        <p>
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at
          customercare@brokerpay.com.au
        </p>
        <h5>SUMMARY OF KEY POINTS</h5>
        <p>
          <i>
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics using our table
            of contents below to find the section you are looking for.
          </i>
        </p>
        <p>
          <b>What personal information do we process? </b>When you visit, use,
          or navigate our Services, we may process personal information
          depending on how you interact with Brokerpay and the Services, the
          choices you make, and the products and features you use.
        </p>
        <p>
          Do we process any sensitive personal information? We do not process
          sensitive personal information.
        </p>
        <p>
          Do we receive any information from third parties? We do not receive
          any information from third parties.
        </p>
        <p>
          How do we process your information? We process your information to
          provide, improve, and administer our Services, communicate with you,
          for security and fraud prevention, and to comply with law. We may also
          process your information for other purposes with your consent. We
          process your information only when we have a valid legal reason to do
          so.
        </p>
        <p>
          In what situations and with which parties do we share personal
          information? We may share information in specific situations and with
          specific third parties.
        </p>
        <p>
          How do we keep your information safe? We have organisational and
          technical processes and procedures in place to protect your personal
          information. However, no electronic transmission over the internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorised third parties will not be able to defeat our security and
          improperly collect, access, steal, or modify your information.
        </p>
        <p>
          What are your rights? Depending on where you are located
          geographically, the applicable privacy law may mean you have certain
          rights regarding your personal information. Click here to learn more.
        </p>
        <p>
          How do you exercise your rights? The easiest way to exercise your
          rights is by contacting us. We will consider and act upon any request
          in accordance with applicable data protection laws.
        </p>
        <h5>TABLE OF CONTENTS</h5>
        <p>
          <ul>
            <li>1. WHAT INFORMATION DO WE COLLECT?</li>
            <li>2. HOW DO WE PROCESS YOUR INFORMATION?</li>
            <li>
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
              INFORMATION?
            </li>
            <li>
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </li>
            <li>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
            <li>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
            <li>7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
            <li>8. HOW LONG DO WE KEEP YOUR INFORMATION??</li>
            <li>9. HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
            <li>10. DO WE COLLECT INFORMATION FROM MINORS?</li>
            <li>11. WHAT ARE YOUR PRIVACY RIGHTS?</li>
            <li>12. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
            <li>13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
            <li>14. DO WE MAKE UPDATES TO THIS NOTICE?</li>
            <li>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
            <li>
              16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </li>
          </ul>
        </p>
        <h5>1. WHAT INFORMATION DO WE COLLECT?</h5>
        <p>Personal information you disclose to us</p>
        <p>
          <i>
            In Short: We collect personal information that you provide to us.
          </i>
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>Personal Information Provided by You</p>
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make, and
          the products and features you use. The personal information we collect
          may include the following:
        </p>
        <p>
          <li className="list">debit/credit card numbers</li>
          <li className="list">billing addresses</li>
          <li className="list">contact or authentication data</li>
          <li className="list">passwords</li>
          <li className="list">usernames</li>
          <li className="list">job titles</li>
          <li className="list">email addresses</li>
          <li className="list">phone numbers</li>
          <li className="list">names</li>
          <li className="list">company name</li>
          <li className="list">number of employees</li>
          <li className="list">project names</li>
        </p>
        <p>Sensitive Information.</p>
        <p>We do not process sensitive information.</p>
        <p>Payment Data.</p>
        <p>
          We may collect data necessary to process your payment if you make
          purchases, such as your payment instrument number, and the security
          code associated with your payment instrument. All payment data is
          stored by Stripe. You may find their privacy notice link(s) here:
          https://stripe.com/en-au/privacy.
        </p>
        <p>Social Media Login Data.</p>
        <p>
          We may provide you with the option to register with us using your
          existing social media account details, like your Facebook, Twitter, or
          other social media account. If you choose to register in this way, we
          will collect the information described in the section called 'HOW DO
          WE HANDLE YOUR SOCIAL LOGINS?' below.
        </p>
        <p>Application Data.</p>
        <p>
          If you use our application(s), we also may collect the following
          information if you choose to provide us with access or permission:
        </p>
        <p>
          <ul>
            <li className="list">
              <i>Mobile Device Data.</i> We automatically collect device
              information (such as your mobile device ID, model, and
              manufacturer), operating system, version information and system
              configuration information, device and application identification
              numbers, browser type and version, hardware model Internet service
              provider and/or mobile carrier, and Internet Protocol (IP) address
              (or proxy server). If you are using our application(s), we may
              also collect information about the phone network associated with
              your mobile device, your mobile device’s operating system or
              platform, the type of mobile device you use, your mobile device’s
              unique device ID, and information about the features of our
              application(s) you accessed.
            </li>
            <li className="list">
              <i>Push Notifications.</i> We may request to send you push
              notifications regarding your account or certain features of the
              application(s). If you wish to opt out from receiving these types
              of communications, you may turn them off in your device's
              settings.
            </li>
          </ul>
        </p>
        <p>
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
        </p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <h5>2. HOW DO WE PROCESS YOUR INFORMATION?</h5>
        <p>
          <i>
            In Short: We process your information to provide, improve, and
            administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
          </i>
        </p>
        <p>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>
        <p>
          <ul>
            <li className="list">
              To facilitate account creation and authentication and otherwise
              manage user accounts. We may process your information so you can
              create and log in to your account, as well as keep your account in
              working order.
            </li>
            <li className="list">
              To deliver and facilitate delivery of services to the user. We may
              process your information to provide you with the requested
              service.
            </li>
            <li className="list">
              To respond to user inquiries/offer support to users. We may
              process your information to respond to your inquiries and solve
              any potential issues you might have with the requested service.
            </li>
            <li className="list">
              To send administrative information to you. We may process your
              information to send you details about our products and services,
              changes to our terms and policies, and other similar information.
            </li>
            <li className="list">
              To fulfil and manage your orders. We may process your information
              to fulfil and manage your orders, payments, returns, and exchanges
              made through the Services.
            </li>
            <li className="list">
              To enable user-to-user communications. We may process your
              information if you choose to use any of our offerings that allow
              for communication with another user.
            </li>
            <li className="list">
              To request feedback. We may process your information when
              necessary to request feedback and to contact you about your use of
              our Services.
            </li>
            <li className="list">
              To send you marketing and promotional communications. We may
              process the personal information you send to us for our marketing
              purposes, if this is in accordance with your marketing
              preferences. You can opt out of our marketing emails at any time.
              For more information, see 'WHAT ARE YOUR PRIVACY RIGHTS?' below).
            </li>
            <li className="list">
              To protect our Services. We may process your information as part
              of our efforts to keep our Services safe and secure, including
              fraud monitoring and prevention.
            </li>
            <li className="list">
              To identify usage trends. We may process information about how you
              use our Services to better understand how they are being used so
              we can improve them.
            </li>
            <li className="list">
              Services to better understand how they are being used so we can
              improve them. To save or protect an individual's vital interest.
              We may process your information when necessary to save or protect
              an individual’s vital interest, such as to prevent harm.
            </li>
          </ul>
        </p>
        <h5>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h5>
        <p>
          <i>
            In Short: We only process your personal information when we believe
            it is necessary and we have a valid legal reason (i.e. legal basis)
            to do so under applicable law, like with your consent, to comply
            with laws, to provide you with services to enter into or fulfil our
            contractual obligations, to protect your rights, or to fulfil our
            legitimate business interests.
          </i>
        </p>
        <p>
          <i>
            If you are located in the EU or UK, this section applies to you.
          </i>
        </p>
        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>
        <p>
          <ul>
            <li className="list">
              Consent. We may process your information if you have given us
              permission (i.e. consent) to use your personal information for a
              specific purpose. You can withdraw your consent at any time.
            </li>
            <li className="list">
              Performance of a Contract. We may process your personal
              information when we believe it is necessary to fulfil our
              contractual obligations to you, including providing our Services
              or at your request prior to entering into a contract with you.
            </li>
            <li className="list">
              Legitimate Interests. We may process your information when we
              believe it is reasonably necessary to achieve our legitimate
              business interests and those interests do not outweigh your
              interests and fundamental rights and freedoms. For example, we may
              process your personal information for some of the purposes
              described in order to:
              <p>
                <li className="list">
                  Send users information about special offers and discounts on
                  our products and services
                </li>
                <li className="list">
                  Analyse how our Services are used so we can improve them to
                  engage and retain users
                </li>
                <li className="list">
                  Diagnose problems and/or prevent fraudulent activities
                </li>
                <li className="list">
                  Understand how our users use our products and services so we
                  can improve user experience
                </li>
              </p>
            </li>
            <li className="list">
              Legal Obligations. We may process your information where we
              believe it is necessary for compliance with our legal obligations,
              such as to cooperate with a law enforcement body or regulatory
              agency, exercise or defend our legal rights, or disclose your
              information as evidence in litigation in which we are involved.
            </li>
            <li className="list">
              Vital Interests. We may process your information where we believe
              it is necessary to protect your vital interests or the vital
              interests of a third party, such as situations involving potential
              threats to the safety of any person.
            </li>
          </ul>
        </p>
        <p>
          <i>If you are located in Canada, this section applies to you.</i>
        </p>
        <p>
          We may process your information if you have given us specific
          permission (i.e. express consent) to use your personal information for
          a specific purpose, or in situations where your permission can be
          inferred (i.e. implied consent). You can withdraw your consent at any
          time.
        </p>
        <p>
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </p>
        <p>
          <ul>
            <li className="list">
              If collection is clearly in the interests of an individual and
              consent cannot be obtained in a timely way
            </li>
            <li className="list">
              For investigations and fraud detection and prevention
            </li>
            <li className="list">
              For business transactions provided certain conditions are met
            </li>
            <li className="list">
              If it is contained in a witness statement and the collection is
              necessary to assess, process, or settle an insurance claim
            </li>
            <li className="list">
              For identifying injured, ill, or deceased persons and
              communicating with next of kin
            </li>
            <li className="list">
              If we have reasonable grounds to believe an individual has been,
              is, or may be victim of financial abuse
            </li>
            <li className="list">
              If it is reasonable to expect collection and use with consent
              would compromise the availability or the accuracy of the
              information and the collection is reasonable for purposes related
              to investigating a breach of an agreement or a contravention of
              the laws of Canada or a province
            </li>
            <li className="list">
              If disclosure is required to comply with a subpoena, warrant,
              court order, or rules of the court relating to the production of
              records
            </li>
            <li className="list">
              If it was produced by an individual in the course of their
              employment, business, or profession and the collection is
              consistent with the purposes for which the information was
              produced
            </li>
            <li className="list">
              If the collection is solely for journalistic, artistic, or
              literary purposes
            </li>
            <li className="list">
              If the information is publicly available and is specified by the
              regulations
            </li>
          </ul>
        </p>
        <h5>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h5>
        <p>
          <i>
            In Short: We may share information in specific situations described
            in this section and/or with the following third parties.
          </i>
        </p>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <p>
          <ul>
            <li className="list">
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </li>
            <li className="list">
              Affiliates. We may share your information with our affiliates, in
              which case we will require those affiliates to honour this privacy
              notice. Affiliates include our parent company and any
              subsidiaries, joint venture partners, or other companies that we
              control or that are under common control with us.
            </li>
            <li className="list">
              Other Users. When you share personal information (for example, by
              posting comments, contributions, or other content to the Services)
              or otherwise interact with public areas of the Services, such
              personal information may be viewed by all users and may be
              publicly made available outside the Services in perpetuity. If you
              interact with other users of our Services and register for our
              Services through a social network (such as Facebook), your
              contacts on the social network will see your name, profile photo,
              and descriptions of your activity. Similarly, other users will be
              able to view descriptions of your activity, communicate with you
              within our Services, and view your profile.
            </li>
          </ul>
        </p>
        <h5>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h5>
        <p>
          <i>
            In Short: We may use cookies and other tracking technologies to
            collect and store your information.
          </i>
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice
        </p>
        <h5>6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h5>
        <p>
          <i>
            In Short: If you choose to register or log in to our Services using
            a social media account, we may have access to certain information
            about you.
          </i>
        </p>
        <p>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or Google
          logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile
          information we receive may vary depending on the social media provider
          concerned, but will often include your name, email address, friends
          list, and profile picture, as well as other information you choose to
          make public on such a social media platform.
        </p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>
        <h5>7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h5>
        <p>
          <i>
            In Short: We may transfer, store, and process your information in
            countries other than your own.
          </i>
        </p>
        <p>
          Our servers are located in Australia. If you are accessing our
          Services from outside Australia, please be aware that your information
          may be transferred to, stored, and processed by us in our facilities
          and by those third parties with whom we may share your personal
          information (see 'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
          INFORMATION?' above), in the United States, and other countries.
        </p>
        <p>
          If you are a resident in the European Economic Area (EEA) or United
          Kingdom (UK), then these countries may not necessarily have data
          protection laws or other similar laws as comprehensive as those in
          your country. However, we will take all necessary measures to protect
          your personal information in accordance with this privacy notice and
          applicable law.
        </p>
        <h5>8. HOW LONG DO WE KEEP YOUR INFORMATION?</h5>
        <p>
          <i>
            In Short: We keep your information for as long as necessary to
            fulfil the purposes outlined in this privacy notice unless otherwise
            required by law.
          </i>
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us to keep your personal information for longer than one
          (1) months past the termination of the user's account
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymise such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <h5>9. HOW DO WE KEEP YOUR INFORMATION SAFE?</h5>
        <p>
          <i>
            In Short: We aim to protect your personal information through a
            system of organisational and technical security measures.
          </i>
        </p>
        <p>
          We have implemented appropriate and reasonable technical and
          organisational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorised third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <h5>10. DO WE COLLECT INFORMATION FROM MINORS?</h5>
        <p>
          <i>
            In Short: We do not knowingly collect data from or market to
            children under 18 years of age.
          </i>
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we may have collected from children under age 18,
          please contact us at customercare@brokerpay.com.au
        </p>
        <h5>11. WHAT ARE YOUR PRIVACY RIGHTS?</h5>
        <p>
          <i>
            In Short: In some regions, such as the European Economic Area (EEA),
            United Kingdom (UK), and Canada, you have rights that allow you
            greater access to and control over your personal information. You
            may review, change, or terminate your account at any time.
          </i>
        </p>
        <p>
          In some regions (like the EEA, UK, and Canada), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. You can make such a request by contacting us by using the
          contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT
          THIS NOTICE?' below.
        </p>
        <p>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your local data protection supervisory authority. You can
          find their contact details here:
          https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
        </p>
        <p>
          Withdrawing your consent: If we are relying on your consent to process
          your personal information, which may be express and/or implied consent
          depending on the applicable law, you have the right to withdraw your
          consent at any time. You can withdraw your consent at any time by
          contacting us by using the contact details provided in the section
          'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent. Opting out
          of marketing and promotional communications: You can unsubscribe from
          our marketing and promotional communications at any time by clicking
          on the unsubscribe link in the emails that we send, replying 'STOP' or
          'UNSUBSCRIBE' to the SMS messages that we send, or by contacting us
          using the details provided in the section 'HOW CAN YOU CONTACT US
          ABOUT THIS NOTICE?' below. You will then be removed from the marketing
          lists. However, we may still communicate with you — for example, to
          send you service-related messages that are necessary for the
          administration and use of your account, to respond to service
          requests, or for other non-marketing purposes. account, to respond to
          service requests, or for other non-marketing purposes.
        </p>
        <h5>Account Information</h5>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
          <p>
            <ul>
              <li className="list">
                Log in to your account settings and update your user account.
              </li>
              <li className="list">
                Contact us using the contact information provided.
              </li>
            </ul>
          </p>
        </p>
        <p>
          Contact us using the contact information provided. Upon your request
          to terminate your account, we will deactivate or delete your account
          and information from our active databases. However, we may retain some
          information in our files to prevent fraud, troubleshoot problems,
          assist with any investigations, enforce our legal terms and/or comply
          with applicable legal requirements.
        </p>
        <p>
          Cookies and similar technologies: Most Web browsers are set to accept
          cookies by default. If you prefer, you can usually choose to set your
          browser to remove cookies and to reject cookies. If you choose to
          remove cookies or reject cookies, this could affect certain features
          or services of our Services. To opt out of interest-based advertising
          by advertisers on our Services visit
          http://www.aboutads.info/choices/.
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at customercare@brokerpay.com.au
        </p>
        <h5>12. CONTROLS FOR DO-NOT-TRACK FEATURES</h5>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ('DNT') feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognising and implementing DNT
          signals has been finalised. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <h5>13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h5>
        <p>
          <i>
            In Short: Yes, if you are a resident of California, you are granted
            specific rights regarding access to your personal information.
          </i>
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the 'Shine The
          Light' law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </p>
        <h5>14. DO WE MAKE UPDATES TO THIS NOTICE?</h5>
        <p>
          <i>
            In Short: Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
          </i>
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated 'Revised' date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <h5>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h5>
        <p>
          If you have questions or comments about this notice, you may email us
          at customercare@brokerpay.com.au or by post to:
        </p>
        <p>Brokerpay Pty Ltd</p>
        <p>2 23 Foster St</p>
        <p>Surry Hills, New South Wales 2010</p>
        <p>Australia</p>
        <h5>
          16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h5>
        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please contact
          customercare@brokerpay.com.au
        </p>
      </div>
    </>
  );
};
export default PrivacyPolicy;
