import Styled from "styled-components";

const ActivityWrap = Styled.div`

 padding: 10px;
 font-size: 18px;
 font-weight: 600;

 .back-link{
   color: black;
 }
.action{
  width: 40%;
}
th, td {
  text-align: left !important; 
  padding: 10px;
  padding-left: 15px !important;
}
 .tab-row{
   display: block;
   width: 100%;

   .nav-link{
      font-weight: 600;
      color: black !important;
      background: white;
      border: none;
      width: 204px;
   }
   .nav{
   .active{
    
      background: #E2F2FF 0% 0% no-repeat padding-box;
      border-radius: 5px 0px 0px 5px;
   }
  }
  }

 .tab-group{
   float: right;
 }
tr{
 td{
  font-size: 17px  !important;
   padding-top: 15px !important;
   padding-bottom: 15px !important;
 }
 th{
  font-weight: 700 !important;
 }
}
.table-btn{
   width: 100px;
   height: 30px;
}

@media (max-width:450px){
  font-size: 15px;
}
@media  (max-width:600px){
  tr{
    td{
  font-size: 13px !important;
  }
  th{
    font-size: 14px !important;
  }
}
}
`;

export default ActivityWrap;
