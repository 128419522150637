import Styled from "styled-components";
import slide1 from "../../../assets/images/slide1.svg";
import slide2 from "../../../assets/images/slide2.svg";

const LoginWrap = Styled.div`

        
        *,*::before,*::after {
            box-sizing: border-box;
        }

        .loginPageContent {
            overflow: auto;
            // display: flex;
            justify-content: center;
            align-items: center;
        }
        .img-con{
            //    z-index:-1
                height:100vh;
                // width:100%;
                position:absolute;
                right:0px;
                z-index:-1;
            }
        .img-con3{
            display:none;
            height:100vh;
            // width:100%;
            position:absolute;
            right:0px;
            z-index:-1;
        }
        .img-con1{
            //    z-index:-1
                height:225px;
                width:164px;
                position:absolute;
                right:380px;
                z-index:-1;
            }
        .img-con2{
            //    z-index:-1
                height:356px;
                width:299px;
                height:335px;
                position:absolute;
                right:130px;
                bottom:80px;
                z-index:-1;
            }
            .position{
                position:absolute;
                z-index:100;
            }


        .container-width {
            margin-top:130px;
            float: left;           
            width: 440px;
            background:tranparent;
            // box-shadow: 0px 0px 8px #0000004F;
            border-radius: 10px;
            opacity: 1;
            // padding-left:0px;
        }
        .loginPage-Content {
            display: flex;
            // justify-content: center;
            align-items: center;
        }
        .containerwidth {          
            width: 845px;
            background:tranparent;
            border-radius: 10px;
            opacity: 1;
        }
        .logo{
            width:130px;
        }
        .loginFormScreens {
            margin: 25px;
            border-radius: 10px;
        }
        .logoimg {
            max-height: 50px;
            padding-top: 0px;
        }
        .formScreenUser {
            color: #111247;
            font-weight: 600;
        }
        .formScreenTitle {
            color: #555;
            font-weight: 600;
        }
        .forgotLink {
            color:  ${({ theme }) => theme["orange-color"]};
            font-size: 14px;
            font-weight: 500;
            &:hover {
                color: ${({ theme }) => theme["orange-hover"]};
            }
            &:active {
                color: ${({ theme }) => theme["orange-hover"]};
            }
            &:focus {
                color: ${({ theme }) => theme["orange-hover"]};
            }
        }
        .loginFormScreens {
            .form-control {
                padding: 0px;
                font-size: 15px;
                border:none;
                border-bottom:1px solid #0094FF;
                border-radius:0px;
            }
        }
        form {
            label {
                color: ${({ theme }) => theme["gray-color"]};
                font-size:22px;
            }
        }
        .singleSlide {
            margin: 0;
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
            position: relative;
        }
        .slide1 {
            background-image: url(${slide1});
            height: 100vh;
        }
        .slide2 {
            background-image: url(${slide2});
            height: 100vh;
        }
        .slideOverlay {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: #4b58614d;
        }
        .loginScreenSlides {
            .slick-dots {
                position: absolute;
                bottom: 56px;
                display: block;
                padding: 0;
                margin: 0 auto;
                list-style: none;
                text-align: center;
                // right: -40%;
                bottom: 16px;
                li {
                    button {
                        
                        font-size: 0;
                        line-height: 0;
                        display: block;
                        width: 11px;
                        height: 11px;
                        padding: 5px;
                        cursor: pointer;
                        color: transparent;
                        border: 0;
                        outline: none;
                        // background: #a5abb1;
                        background: #fff;
                        border-radius: 50%;
                        &:before {
                            color: transparent !important;
                        }
                    }
                }
                li.slick-active {
                    button {
                        background: ${({ theme }) => theme["orange-color"]};
                    }
                }
            }
        }
        .loginScreenSlides.slick-dots {
            li.slick-active {
                button {
                    &:before {
                        color: transparent !important;
                    }
                }
            }
        }   

        @media only screen and (max-width:1508px){
           
                .img-con1{
                        height:185px;
                        width:134px;
                        position:absolute;
                        right:320px;
                    }
                .img-con2{
                        width:299px;
                        height:335px;
                        position:absolute;
                        right:160px;
                        bottom:115px;
                    }
                    .container-width {
                        margin-top:140px;
                    }
            
        }
        @media only screen and (max-width:1208px){
           
            .img-con1{
                    height:185px;
                    width:134px;
                    position:absolute;
                    right:320px;
                }
            .img-con2{
                    width:249px;
                    height:285px;
                    position:absolute;
                    right:125px;
                    bottom:80px;
                }
                .container-width {
                    margin-top:50px;
                }
        
    }
    @media only screen and (max-width:991px){
        .img-con1{
            height:125px;
            width:134px;
        }
}
    
     
        @media only screen and (max-width:420px){
                .container-width {
                    margin-top:110px;
                }
        
    }
        @media only screen and (max-width:350px){
            .container-width {
               width: 300px !important;
            }
            .loginPageContent {
               min-width: 320px !important;               
            }
            .card{
                padding: 0px !important;
            }
            .loginFormScreens {
                margin: 10px !important;
            }
            
        }

        @media only screen and (max-width:800px) {
            .container-width {
               margin-top:50px;
            }
            .containerwidth {
                width:100%;
             }
             .img-con{
                display:none;
            }
            .img-con3{
                display:inline;
            }
            .img-con1{
                height:125px;
                width:134px;
                position:absolute;
                right:120px;
            }
            .loginPageContent {
                min-width: 350px;
                max-height: 100vh;
                display: flex;
                align-items: center;
            }
            .loginFormScreens {
                margin: 20px;
            }
                
         }
         @media only screen and (max-width:430px) {
       
            .img-con1{
                height:105px;
                width:104px;
                position:absolute;
                right:20px;
            }
            .img-con2{
                width:289px;
                height:315px;
                position:absolute;
                right:0px;
                bottom:108px;
            }
            
           
         }
        //  @media only screen and (min-width:1024px) {
        //     .container-width {
        //         // position: relative;
        //         left: 20%;
        //         padding: 0;

        //     }
        //  }
        
        
`;

export default LoginWrap;
