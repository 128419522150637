import Styled from 'styled-components';

const UICardView = Styled.div`

.New-request{
    font-size:18px;
    font-weight:600;
    padding-bottom:0px;
  }
.body-container{
    // margin-left:100px;
    // margin-right:100px;
}
.p-pading{
    font-size:16px;
    font-weight:600;
 }
 .avatar_container{
    border: 1px solid #0094FF;     
}
 .deleteacc-title{
    text-align: center;
}
.TermsText{
    display: none !important;
}
.card_number{
    font-size: 20px;
}
.card_footer{
    margin-top: 12px;
}
a:hover{
    color: black;
}
.settings-modal-content{
    text-align: center;
}
.disable-color{
    color: #6F7070 !important;
}
 .profile-name{
    font-size:18px;
    font-weight:600;
    padding-left: 0;
 }
.plus{
    font-size: 30px;
    font-weight: 500;
}
.loginformbody {
    .form-control {
        padding: 0px;
        font-size: 15px;
        border:none;
        border-bottom:1px solid #0094FF;
        border-radius:0px;
    }
}
.body{
    padding: 20px;
    card{
        box-shadow: none !important;
        border: none !important;
    }
}
.body-title{
    font-weight: 700;
    padding-left: 10px;
    padding-top: 10px;

}
.loginSubmitBtn{
    float: right !important;
}
.linkaccount{
    font-size: 18px !important;
}
 .delete{
    font-size:18px;
    font-weight:600;
    margin-left:10px;
    color:#0094FF;
    float:right;
 }

 .delete1{
    font-size:18px;
    font-weight:600;
    color:#0094FF;
    display: none;
 }
 }

 .avatar{
    display: inline;
    justify-content: center;
        align-items: center;
}
 .user-img{
    height: 100%;
    object-fit: cover;

}
.avatar_container{
    height: 64px;
    width: 64px;
    border-radius:50%;
    overflow: hidden;
}
.bank-card{
    width:40%;
}
.bank-details{ 
    border-radius: 5px;
}
.search-bar{
    width: 609px;
    height:50px;
    box-shadow: 1px 3px 6px #0000004A;
    opacity: 1;
    margin-bottom:25px;
    margin-top:25px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
.MuiTextField-root{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004A;
    opacity: 1;
}
.search-font{
    text-align: left;
    font: normal normal medium 15px/35px Poppins;
    letter-spacing: 0px;
    color: #9B9B9B;
    opacity: 1;
}
.checked{
    color:#ffd600;
}
.star-str{
    color: #e4e4e4;
}
.tut-fa{
    margin:0px 20px;
    font-size:20px;
    cursor: pointer;
    font-size:29px;   
}
.t-name{
    font-weight: 600;
    font-size:25px;
}
#f-icons{
    
    display: flex;
    justify-content: flex-end;
}
.dotbtn{
    width:50px;
}
.tutor-ul{
    line-height:2;
}
.deletebtn{
    color: #FF0000;
}
// .user-img{
//     height: 64px;
//     width: 64px;
//     object-fit: cover;
// }
// .usr-avatar{
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }
.flag{
    display: inline-middle;
    height: 64px;
    width: 64px;  
}
.phone{
    transform: rotate(90deg);
}
.popup-div{
    position: relatives;
    width:100px;
}
.popup-div .btn1{
    color: red;
}
.btn1 , .btn2{
    margin: 0 !important;
}
.settingscard {
	border: 1 solid #dedede;
	border-radius: 8px;
	box-shadow: 0px 2px 6px #00000029;
}
.dropdown-menu{
    width: 188px;
height: 121px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 6px #00000030;
}
@media (min-width: 770px) {
.userid-line{
    display: flex;
}
}
@media (max-width: 770px) {
    .t-name{
        font-size:20px;
    }
    .tutor-ul{
        margin-top: 15px;
    }
    .checked{
        margin-top: 20px;
    }
    
}
@media (max-width: 498px) {
    .t-name{
    font-size:17px;
    }
    .tut-fa{
        margin:0px 10px;
        font-size:18px;
        
    }
    .flag{
        height: 20px;
        width: 30px; 
    }
    .tutor-ul{
        font-size:13px;
    }
}
@media (max-width: 1200px) {
    .bank-details{
        font-size:13px;
    }
    .bank-card{
        width:40%;
    }
  }
@media (max-width: 950px) {
    .bank-details{
        font-size:13px;
    }
    .bank-card{
        width:50%;
    }
  }
  @media (max-width: 992px) {
    .profile-name{
        margin-left:20px;
        
     }


@media (max-width: 768px) {
    .bank-details{
        font-size:13px;
    }
    .bank-card{
        width:60%;
    }
    .display{
        display:none;
    }
    .delete{
        display:none;
     }
     .delete1{
        display:inline;
        font-size:17px;
        padding:0px;
        
     }
     .profile-name{
        margin-left:10px;
    }
    .account{
        margin-top:20px;
    }
  }
  @media (max-width: 767px) {
   
    .profile-name{
        margin-left:0px;
    }
}
  @media (max-width: 500px) {
  
    .bank-card{
        width:100%;
    }
    .New-request{
        padding-bottom:0px;
          }
  }
//   @media (max-width: 576px) {
//     .delete2{
//         display:inline;
//     }
//     .delete3{
//         display:none;
//     }
//   }



  
`;

export default UICardView;