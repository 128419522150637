import Styled from 'styled-components';

const UIView = Styled.div`
.New-request{
    font-size:18px;
    font-weight:600;
    padding-bottom:10px;
  }
  .form-label{
    font-size: 17px;
    font-weight: 600;
  }
  .p-pading{
    font-size:18px;
    font-weight:600;
    color: #303030;
  }
 .delete2{
    display:none;
 }
 .ant-picker-clear {
  right: 30px !important;
 }
 .table > thead {
  background: #0094ff21 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
 }
 .table > :not(:first-child) {
   border-top: none !important;
}
.table > :not(caption) > * > * {
   padding: 0.5rem 1rem !important;
}
.no_douc{
  opacity: 0.5;
  font-size: 13px;
}
.file_view_icon{
  opacity: 0.5;
  font-size: 15px;
}
 .filename_input{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #0094FF;
  border-radius: 5px;
  width: 100%;
 }
 .filename_btn{
  background: #0094FF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: white;
  border: none;
 }
 .removebtn{
  position: absolute;
  margin-bottom: 120px;
  margin-left: 130px;
  color: red;
 }
.name-input{
  border: 1px solid #0094FF;
  width: 100%;
  border-Radius: 5px;
  height: 35px;
  padding-Left: 10px;
}
 p {
  margin-bottom: 0;
 }
 
 .filter{
  position:relative;
  outline:none;
  border:1px solid lightblue;
 }

 .form-label {
    color: #303030 !important;
 }

 .lable-style{
     color: #303030;
     font-size: 15px;
     font-weight: 600;
  }

 .upload-doc {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .4;
    padding-bottom: 5px;
    .b {
      font-size: 18px;
      opacity: .4;
    }
 }

 .pblm-icon {
  width: 12px;
  margin-top: -3px;
 }
 
 .error {
  font-size: .8rem;
  color: red;
 }

 .input-style {
  background: transparent;
  border: 1px solid #0094FF;
  width: 100%;
  border-radius: 5px;
  height: 35px;
  padding-left: 10px;
  color: black;
 }


 .settlement-date {
  font-size: 15px;
  font-weight: 600;
 }

 .proof-settlement {
    border: 1px solid #0094FF;
    margin: 10px;
    border-radius: 5px;
 }
 
 .upload-placeholder {
  padding-top: 10px;
  color: #0094FF;
  height: 50px;
  width: 100%;
  opacity: .5;
 }
 
 .submit-btn {
  float: right;
  margin-top: 10px;
  border-radius: 20px;
 }

 @media (max-width: 768px) {
  .p-pading{
    font-size:16px;
    
 }
 .New-request{
  font-size:16px;
}
}

@media (max-width: 767px) {
    
    .add-btn{
      padding-top:10px;
      padding-bottom:5px;
    }
  }
  @media (max-width: 575px) {
    .delete2{
        display:inline;
    }
    .delete3{
        display:none;
    }
  }



  
`;

export default UIView;