import Styled from "styled-components";

const RequestWrap = Styled.div`

 padding: 10px;
 font-size: 18px;
 font-weight: 600;

 .back-link{
   color: black;
 }
 .image-view{
  background: white;
  width: 70%;
  margin-top: 50px;
   height: auto;
  box-shadow: 0px 3px 6px #00000015;
  border-radius: 5px;
  overflow: hidden;
 }
 .disable-btn{
  opacity: 0.5 !important;
 }
 .image-view img {
  max-width: 100%;
  height: auto;
}

 .file-name{
  font-size: 13px;
  padding: 10px;
 }

 .tab-row{
   display: block;
   width: 100%;

   .nav-link{
      font-weight: 600;
      color: black !important;
      background: white;
      border: none;
      width: 204px;
   }
   .nav{
   .active{
    
      background: #E2F2FF 0% 0% no-repeat padding-box;
      border-radius: 5px 0px 0px 5px;
   }
  }
  }

 .tab-group{
   float: right;
 }
tr{
 td{
  font-size: 16px  !important;
   padding-top: 15px !important;
   padding-bottom: 15px !important;
 }
 th{
  font-weight: 700 !important;
  font-size: 16px  !important;
 }
}
.table-btn{
   width: 100px;
   height: 30px;
}
.pointer{
  cursor: pointer;
}
@media (max-width:450px){
  font-size: 15px;
}
@media  (max-width:600px){
  tr{
    td{
  font-size: 13px !important;
  }
  th{
    font-size: 14px !important;
  }
}
}
`;

export default RequestWrap;
