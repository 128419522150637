import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RepaymentsWrap from "./style";
import Tables from "../../../Reuseable/Tables";
import Searchbox from "../../../Reuseable/Searchbox";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import { format } from 'date-fns';
import { useSelector } from "react-redux";
import Sort from "../../../Reuseable/SortIcon"
import {Money} from "../../../Reuseable/NumberFormatter";
import { NoDataFound } from "../../../Reuseable/NoDataFound";
import Calender from '../../../../assets/images/Calender.svg'
import UserDataPreview from "../Users/UserDataPreview";
import { api } from "../../../../Services/api";

const formatDate = (date) => format(new Date(date), 'dd/MM/yyyy');

const Repayments = ({ getAllUserData, getAllActivityLog }) => {

    const allRepayments = useSelector((state) => state.Tracking.allRepayments);
    const allUsers = useSelector((state) => state.Tracking.allUsers);
    const authToken = useSelector((state) => state.Auth.authToken);

    const [searchterm, setSearchterm] = useState("");
    const [date, setDate] = useState("");
    const [sortcolumn, setSortcolumn] = useState("");
    const [amountSort, setAmountSort] = useState(false);
    const [requestdateSort, setRequestdateSort] = useState(false);
    const [duedateSort, setDuedateSort] = useState(false);

    const [show, setShow] = useState(false);
    const [viewuser, setViewUser] = useState([]);
    const [notes, setNotes] = useState('')

    useEffect(() => {
        getNotes()
    }, [viewuser])

    const getNotes = async () => {
        await api.get('/adminnotes/' + viewuser.userId, {
            headers: {
                'authorizationtoken': `${authToken}`,
            }
        }).then(function (response) {
            if (response.data.status === true) {
                setNotes(response.data.data)
            }
        }).catch(function (error) {
            console.log('getBrokerdata [error]-->', error.response.data);
        });
    }

    const navigate = useNavigate();

    const head = <thead>
        <tr>
            <th className="pointer" onClick={() => { setRequestdateSort(!requestdateSort); setSortcolumn('requestdate') }} >
                Request Date
                <Sort />
            </th>
            <th>
                Request ID
            </th>
            <th>
                User
            </th>
            <th className="pointer" onClick={() => { setAmountSort(!amountSort); setSortcolumn('amount') }}>
                Amount
                <Sort />
            </th>
            <th className="pointer" onClick={() => { setDuedateSort(!duedateSort); setSortcolumn('duedate') }}>
                Due Date
                <Sort />
            </th>

        </tr>
    </thead>

    const isinThePast = (date) => {
        const today = new Date();

        today.setHours(0, 0, 0, 0)
        return date < today;
    }

    const RepaymentData = allRepayments.map((data) => {

        let userData = allUsers.find(
            (e) => e.userId == data.requestedBy
        );
        if (userData) data.user = userData.personname;
        else data.user = "-";

        return data;
    }).sort((a, b) => {
        if (parseInt((a.requestId).slice(2)) > parseInt((b.requestId).slice(2)))
            return -1;
        else return 1;
    })
        .sort((item1, item2) => {
            if (sortcolumn === 'amount') {
                if (amountSort) {
                    if (parseInt(item1.commissionAdvance) < parseInt(item2.commissionAdvance))
                        return -1;
                    else return 1;
                } else {
                    if (parseInt(item2.commissionAdvance) > parseInt(item1.commissionAdvance))
                        return 1;
                    else return -1;
                }
            }
            else if (sortcolumn === 'requestdate') {
                if (requestdateSort) {
                    if (new moment(item1.requestDate).format("YYYYMMDD") < new moment(item2.requestDate).format("YYYYMMDD"))
                        return -1;
                    else return 1;
                } else {
                    if (new moment(item2.requestDate).format("YYYYMMDD") > new moment(item1.requestDate).format("YYYYMMDD"))
                        return 1;
                    else return -1;
                }
            }
            else if (sortcolumn === 'duedate') {
                if (duedateSort) {
                    if (new moment(item1.repaymentDate).format("YYYYMMDD") < new moment(item2.repaymentDate).format("YYYYMMDD"))
                        return -1;
                    else return 1;
                } else {
                    if (new moment(item2.repaymentDate).format("YYYYMMDD") > new moment(item1.repaymentDate).format("YYYYMMDD"))
                        return 1;
                    else return -1;
                }
            }
        }).filter(val => {
            if (searchterm === "") {
                return val;
            }
            else if (
                val.name.toLowerCase().includes(searchterm.toLowerCase()) ||
                val.requestId.toLowerCase().includes(searchterm.toLowerCase())
            ) {
                return val;
            }
        }).filter(val => {
            if (date) {
                if (moment(val.requestDate).isBetween(date[0], date[1], 'day', '[]')) {
                    return val;
                }
            }
            else {
                return val;
            }
        })

    const tbody = <tbody>

        {RepaymentData
            .map((row) => {
                let userData = allUsers.find(
                    (e) => e.userId == row.requestedBy
                );
                return (
                    <tr>
                        <td>{row.dateTime}</td>
                        <td>{row.requestId}</td>
                        {row.user === "-" ?
                            <td>{row.user}</td>
                            : <td
                                className="primary pointer"
                                onClick={() => {
                                    setViewUser(userData);
                                    setShow(true);
                                }}
                            >
                                {row.user}
                            </td>
                        }
                        <td>{Money(row.commissionAdvance)}</td>
                        <td className={isinThePast(new Date(row.repaymentDate)) ? 'declined' : ''}>{formatDate(row.repaymentDate)}</td>
                    </tr>
                )
            }
            )}
    </tbody>

    const data2 = { head, tbody };
    const { RangePicker } = DatePicker;

    return (
        <React.Fragment>
            <RepaymentsWrap>
                <Container>
                    <div className="tab-row">
                        <div
                            className="back-link"
                        >
                            Repayments
                        </div>
                    </div>
                    <Container>
                        <div>
                            <Row>
                                <Col sm={4} md={3} lg={3} xl={3}>
                                    <Searchbox
                                        placeholder="Enter name, request ID"
                                        handleSearch={setSearchterm}
                                    />
                                </Col>
                                <Col sm={4} md={5} lg={4} xl={3} >
                                    <RangePicker
                                        className="date-picker"
                                        locale={date}
                                        format={"YYYY-MM-DD"}
                                        value={date}
                                        onChange={(date) => setDate(date)}
                                        suffixIcon={
                                            <img
                                                src={Calender}
                                            />
                                        }
                                    />
                                </Col>
                            </Row>
                            <div className="my-3">
                                {RepaymentData && RepaymentData.length ?
                                    <Tables
                                        props={data2}
                                        striped={true}
                                    />
                                    :
                                    <NoDataFound
                                        body='You don’t have any reyayments at the moment.'
                                    />
                                }
                            </div>
                        </div>
                    </Container>
                </Container>

                <UserDataPreview
                    deleteView={viewuser.status === 'Deleted' ? true : false}
                    show={show}
                    setShow={setShow}
                    viewuser={viewuser}
                    setViewUser={setViewUser}
                    notes={notes}
                    getNotes={getNotes}
                    setNotes={setNotes}
                    getAllUserData={getAllUserData}
                    getAllActivityLog={getAllActivityLog}
                />
            </RepaymentsWrap>
        </React.Fragment>
    );
};

export default Repayments;
