import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { api } from "../../../../../Services/api";
import Tables from "../../../../Reuseable/Tables";
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SettingsWrap from "../style";
import BarLoader from 'react-spinners/ClipLoader';
import Alert from '@mui/material/Alert';

const ManageTeam = ({ administratorData, getAdministrator }) => {

    const authToken = useSelector((state) => state.Auth.authToken);

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState('');
    const [message, setMessage] = useState('');
    const [modalView, setModalView] = useState('reset');


    useEffect(() => {
        setTimeout(() => {
            setMessage(false);
        }, 5000);

    }, [message]);

    const resetpassword = async (data) => {
        setLoading(true)
        setMessage(false)
        let obj = {
            emailId: data.emailId,
            userId: data.userId,
            administrator: true
        }
        await api.post('/brokerpayuserauth/forgotpassword', obj, {
            headers: {
                'authorizationtoken': `${authToken}`,
            }
        }).then(function (response) {
            if (response.data.status === true) {
                setLoading(false)
                setMessage('Reset passowrd mail has sent to respective administrator')
                setShow(false)
            }
        }).catch(function (error) {
            setLoading(false)
            console.log('resetpassword [error]-->', error.response);
        });
    }

    const deleteAdministrator = async (data) => {
        setLoading(true)
        setMessage(false)
        await api.delete('/deleteuser/' + data.userId, {
            headers: {
                'authorizationtoken': `${authToken}`,
            }
        }).then(function (response) {
            if (response.data.status === true) {
                setLoading(false)
                setMessage(`${data.emailId} has been deleted `)
                setShow(false)
                getAdministrator()
            }
        }).catch(function (error) {
            setLoading(false)
            console.log('resetpassword [error]-->', error.response);
        });
    }

    const columns = ['User', 'Profile', 'Email', 'Action']

    const tbody = <tbody>

        {administratorData.map((row) => (
            <tr>
                <td>{row.personname}</td>
                <td>{row.userRole}</td>
                <td>{row.emailId}</td>
                <td className="btn-group">
                    <Button
                        onClick={() => {
                            setShow(true);
                            setData(row);
                            setModalView('reset')
                        }}
                        className="text-btn"
                    >
                        Reset password
                    </Button>
                    <Button
                        className="text-btn"
                        onClick={() => {
                            setShow(true);
                            setData(row);
                            setModalView('delete')
                        }}
                    >
                        Delete
                    </Button>
                </td>
            </tr>
        ))}
    </tbody>

    const data2 = { columns, tbody };

    return (
        <React.Fragment>
            {message &&
                <Alert className="approved m-3" severity="success">
                    {message}
                </Alert>
            }
            <Container>
                <div on >
                    <Tables
                        props={data2}
                        striped={true}
                    />
                </div>
            </Container>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Body className="p-2 py-4">
                    <SettingsWrap>
                        <div className="center">
                            {modalView === 'delete' ?
                                'Are you sure want to delete this Administrator?'
                                :
                                'Are you sure want to reset password?'
                            }
                        </div>
                        <div className="center my-3">
                            <Button
                                className="theme-btn2 me-2 resetpassword-btn"
                                onClick={() =>
                                    setShow(false)
                                }
                            >
                                Close
                            </Button>
                            <Button
                                className="theme-btn ms-2 resetpassword-btn"
                                onClick={() =>
                                (modalView === 'delete' ?
                                    deleteAdministrator(data)
                                    :
                                    resetpassword(data)
                                )
                                }
                            >
                                {
                                    loading ?
                                        <BarLoader
                                            as="span"
                                            size={14}
                                            animation="grow"
                                            color='#00C6FF'
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        :
                                        (modalView === 'delete' ? 'Delete' : 'Reset')
                                }
                            </Button>
                        </div>
                    </SettingsWrap>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default ManageTeam;
