import React from "react";

export function Money(value) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const Decimalformatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  function isDecimalNumber(number) {
    return number !== Math.floor(number);
  }

  return <span>{isDecimalNumber(value) ? Decimalformatter.format(value) : formatter.format(value)}</span>;}


export const formatDigitNumber = (number) => {
  if (number >= 1000) {
    const units = ["", "k", "M", "B", "T"];
    const unitIndex = Math.floor(Math.log10(number) / 3);
    const convertedNumber = (number / Math.pow(1000, unitIndex)).toFixed(1);
    return `${convertedNumber}${units[unitIndex]}`;
  }
  return number.toString();
};
