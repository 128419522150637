import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import content6logo from "../../../../assets/landingpage/img/Group 36.png";

const FAQ = () => {
  return (
    <>
      <div className="card sec2">
        <div className="container-fluid">
          <div className="row card-box5">
            <div className="col-md-6">
              <p className="card-content5">
                Want to know more about how it all works? See our FAQ below or
                get in touch with us.
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="content6-logo"
                src={content6logo}
                srcSet={content6logo}
                alt="Logo"
              />
            </div>
            <div className="card-contact">
              <div className="card-contact1">
                <a href="mailto: customercare@brokerpay.com.au">
                  Email: customercare@brokerpay.com.au
                </a>
              </div>
              {/* <div className="card-contact1">Ph: 1800 444 744</div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="accordion-heading">Questions? Look Here.</div>

      <div className="accordion-container">
        <div className="accordian">
          <div className="Typography">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expandicon" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Does Brokerpay charge fees or interest?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Brokerpay doesn’t charge interest on your commission advances.
                  Brokerpay takes a small fee for each advance. The fee amount
                  will vary depending on the size of the advance you request.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expandicon" />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>
                  How quickly can I receive my commission after settlement?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Once you've submitted your proof of settlement, we will
                  process your application within 24 hours. First time
                  applications and public holidays may result in longer
                  processing times.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expandicon" />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>When do I need to repay the funds?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  You can choose a repayment date of 2, 4, 6 or 8 weeks after
                  you've received your settlement advance when submitting your
                  application.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};
export default FAQ;
