import React, { useState } from "react";
import {
  useStripe,
  useElements,
  AuBankAccountElement,
} from "@stripe/react-stripe-js";
import BecsForm from "../../BecsForm";
import { useSelector } from "react-redux";
import { api } from "../../../../../Services/api";

export default function PaymentSetupForm({
  clientSecretAccount,
  setPage,
  onBoarding,
  setOpenUpdate,
  getBankDetails,
}) {
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    accountholderName: "",
    bankName: "",
    bsb: "",
  });

  const stripe = useStripe();
  const elements = useElements();

  const hamldeAddBankaccount = (data, name, bank) => {
    let obj = { BankDetails: data, AccountName: name, BankName: bank };
    api
      .post("/userbankdetails/" + brokerdata.userId, obj)
      .then(function (response) {
        if (response.data.status === true) {
          setLoading(false);
          if (onBoarding) {
            setPage("page_2");
          } else {
            getBankDetails();
            setOpenUpdate(false);
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const accountholderName = event.target["accountholderName"];
    const bankName = event.target["bankName"];
    if (accountholderName.value == "" || accountholderName.value == null) {
      errors.accountholderName = "This is a required field";
    }
    if (bankName.value == "" || bankName.value == null) {
      errors.bankName = "This is a required field";
    }

    if (
      !stripe ||
      !elements ||
      accountholderName.value == "" ||
      bankName.value == ""
    ) {
      return;
    }
    setLoading(true);
    const auBankAccount = elements.getElement(AuBankAccountElement);

    const result = await stripe.confirmAuBecsDebitSetup(clientSecretAccount, {
      payment_method: {
        au_becs_debit: auBankAccount,
        billing_details: {
          name: accountholderName.value,
          email: brokerdata.emailId,
        },
      },
    });
    if (result.setupIntent) {
      hamldeAddBankaccount(
        result.setupIntent,
        accountholderName.value,
        bankName.value
      );
    }
    if (result.error) {
      setLoading(false);
      setErrors({
        ...errors,
        bsb: result.error.message,
      });
    } else {
      setErrors({
        ...errors,
        bsb: "",
      });
    }
  };
  return (
    <BecsForm
      onBoarding={onBoarding}
      onSubmit={handleSubmit}
      disabled={!stripe}
      setErrors={setErrors}
      errors={errors}
      loading={loading}
    />
  );
}
