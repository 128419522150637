import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMedia } from "react-use";
import SideMenuBar from "../sidebar";
import Header from "../header";
import LayoutWrap from "./style";
import $ from "jquery";
import { api } from "../../../../Services/api";
import {
  setBrokerData,
  setAllRequest,
  setAllUsers,
  setActivityLog,
  setRepayments,
  setOverview,
  setAllAdministrators,
  setPermissions,
  setBankDetails,
} from "../../../../redux/Tracking/actionCreator";
import { useLocation } from "react-router-dom";
import Authroute from "../../../../routes";
import Adminroute from "../../../../routes/Adminroute";
import UIModal from "../../../Reuseable/UIModal";
import ResetPasswordForm from "../../../Reuseable/Forms/ResetPasswordForm";
import { GridLoader, SyncLoader } from "react-spinners";

const Main = () => {
  const dispatch = useDispatch();
  const panelview = useSelector((state) => state.Layout.panelview);
  const authToken = useSelector((state) => state.Auth.authToken);
  const menuList = useSelector((state) => state.Auth.menuList);
  const user = useSelector((state) => state.Auth.user);
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);

  let path = useLocation();

  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [userLoading, setuserLoading] = useState(false);
  const [overviewLoading, setOverviewLoading] = useState(false);
  const [activeNav, setActiveNav] = useState(path.pathname.slice(6));
  const [show, setShow] = useState(false);

  useEffect(() => {
    getBrokerdata();
    getRequestData();
    getRepayments();
    if (user && user.userRole === "Broker") {
      getBankDetails();
    }
  }, []);

  useEffect(() => {
    if (
      user &&
      (user.userRole === "Admin" || (user && user.userRole === "Administrator"))
    ) {
      getAllUserData();
      getAllActivityLog();
      getOverviewData();
      getAdministrator();
    }
  }, []);

  useEffect(() => {
    if (brokerdata.loginStatus) {
      setShow(true);
    }
  }, [brokerdata]);

  const showResposiveSideBar = useMedia("(max-width: 829px)");

  const [active, setActive] = useState(false);
  const [settingsmenu, setSettingsmenu] = useState("role");

  function togleMenu1() {
    $(".page-body-wrapper").removeClass("mobSideBar");
  }

  const handleToggle = () => {
    setActive((prev) => !prev);
  };

  const getBrokerdata = async () => {
    setLoading(true);

    await api
      .get("/brokerpayuserauth", {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          let data = response.data.data;
          dispatch(setBrokerData(data));
          if (user.userRole === "Admin" || user.userRole === "Administrator") {
            dispatch(setPermissions(data.permissions));
          }
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("getBrokerdata [error]-->", error.response.data);
      });
  };

  const getBankDetails = async () => {
    await api
      .get("/userbankdetails/" + user.userId, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {

        if (response.data.status) {
          let data = response.data.data;
          dispatch(setBankDetails(data));
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log("getBankDetails [error]-->", error.response.data);
      });
  };

  const getOverviewData = async () => {
    setLoading(true);
    setOverviewLoading(true);
    await api
      .get("/overview", {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          dispatch(setOverview(response.data.data));
          setTimeout(() => {
            setLoading(false);
            setOverviewLoading(false);
          }, 1000);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setOverviewLoading(false);
        console.log("getOverviewData [error]-->", error.response.data);
      });
  };

  const getRequestData = async () => {
    setRequestLoading(true);
    try {
      let url = "/requests";
      const response = await api.get(url, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status) {
        let data = response.data.data;
        dispatch(setAllRequest(data));
        setRequestLoading(false);
      }
    } catch (error) {
      setRequestLoading(false);
      console.log("getRequestData [error]-->", error);
    }
  };

  const getRepayments = async () => {
    try {
      let url = "/requests/approval";
      const response = await api.get(url, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status) {
        let data = response.data.data;
        dispatch(setRepayments(data));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log("getRepayments [error]-->", error);
    }
  };

  const getAllUserData = async () => {
    setuserLoading(true);
    try {
      let url = "/brokerpayuserauth/userlists";
      const response = await api.get(url, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        let data = response.data.data;
        dispatch(setAllUsers(data));
        setuserLoading(false);
      }
    } catch (error) {
      setuserLoading(false);
      console.log("getAllUserData [error]-->", error);
    }
  };

  const getAllActivityLog = async () => {
    try {
      let url = "/activitylog";
      const response = await api.get(url, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status) {
        let data = response.data.data;
        dispatch(setActivityLog(data));
      }
    } catch (error) {
      console.log("getAllActivityLog [error]-->", error);
    }
  };

  const getAdministrator = async () => {
    await api
      .get("/brokerpayuserauth/administrator", {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          dispatch(setAllAdministrators(response.data.data));
        }
      })
      .catch(function (error) {
        console.log("getAdministrator [error]-->", error.response.data);
      });
  };

  return (
    <LayoutWrap className="adminDshbordBody">
      <div className="maincontainer">
        <Header
          panelview={panelview}
          handleToggle={handleToggle}
          showResposiveSideBar={showResposiveSideBar}
          setActiveNav={setActiveNav}
          activeNav={activeNav}
          setShow={setShow}
        />
        <div className="container-fluid page-body-wrapper" onClick={togleMenu1}>
          {user &&
          (user.userRole === "Admin" ||
            (user && user.userRole === "Administrator")) ? (
            <SideMenuBar
              settingsmenu={settingsmenu}
              setSettingsmenu={setSettingsmenu}
              active={active}
              handleToggle={handleToggle}
              path={path.pathname}
              showResposiveSideBar={showResposiveSideBar}
              menuList={menuList}
              setActiveNav={setActiveNav}
              activeNav={activeNav}
            />
          ) : null}
          <div
            className={
              "main-panel " + (panelview === "topmenu" ? "topmenu" : "")
            }
          >
            <div className="content-wrapper">
              {user &&
              (user.userRole === "Admin" ||
                (user && user.userRole === "Administrator")) ? (
                <>
                  {loading ? (
                    <div className="center overview_container">
                      <GridLoader color="#36d7b7" />
                    </div>
                  ) : (
                    <Adminroute
                      settingsmenu={settingsmenu}
                      getRequestData={getRequestData}
                      getAllActivityLog={getAllActivityLog}
                      getAllUserData={getAllUserData}
                      getAdministrator={getAdministrator}
                      overviewLoading={overviewLoading}
                    />
                  )}
                </>
              ) : (
                <>
                  {loading ? (
                    <div className="center overview_container">
                      <SyncLoader color="#0094FF" />
                    </div>
                  ) : (
                    <>
                      <UIModal
                        show={show}
                        setShow={setShow}
                        body={
                          <ResetPasswordForm
                            close={() => setShow(false)}
                            isLoggedin={true}
                            fisrtLogin={true}
                          />
                        }
                        close={brokerdata.loginStatus && true}
                        backdrop={brokerdata.loginStatus && true}
                        size={"md"}
                      />
                      <Authroute
                        loading={loading}
                        getBrokerdata={getBrokerdata}
                        getRequestData={getRequestData}
                        requestLoading={requestLoading}
                        setShow={setShow}
                        getBankDetails={getBankDetails}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </LayoutWrap>
  );
};

export default Main;
