import React, { useState } from "react";
import { Button, Form, Col, Container, Row } from "react-bootstrap";
import { api } from "../../../../../../Services/api";
import { useSelector } from "react-redux";
import BarLoader from 'react-spinners/ClipLoader';
import moment from 'moment';
import { NoDataFound } from "../../../../../Reuseable/NoDataFound";

const UserNotes = ({ notes, viewuser, getNotes }) => {

  const authToken = useSelector((state) => state.Auth.authToken);
  const user = useSelector((state) => state.Auth.user);

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  let Note = notes?.notes;

  const handleChange = e => {
    setValue(e.target.value);
  }
  let formateddate = moment().format('hh:mm A') + ' ' + '|' + ' ' + moment().format('DD-MM-YYYY');

  const postNotes = async () => {
    const obj = {
      userId: viewuser.userId,
      notes:
      {
        note: value,
        datetime: formateddate,
        name: user.personname
      }
    }
    setLoading(true);
    await api.post('/adminnotes', obj, {
      headers: {
        'authorizationtoken': `${authToken}`,
      }
    }).then(function (response) {
      console.log('postNotes  [response]-->', response)
      if (response.data.status === true) {
        setLoading(false);
        setValue('')
        getNotes()
      }
    }).catch(function (error) {
      setLoading(false);
      getNotes()
      console.log('postNotes [error]-->', error.response.data);
    });
  }

  return (
    <React.Fragment>
          <Container className="userdata-container">
            {Note && Note?.map((data) => (
              <div className="message-box">
                <Row>
                  <Col lg={9} md={12} sm={12}>
                    <div className=" dataset">
                      <div>
                        <b>
                          {data.name}:
                        </b>
                      </div>
                      <div>
                        {data.note}
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={12} sm={12}>
                    <div className="dataset time d-inline-flex">
                      <div>
                        {data.datetime}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </Container>
      <Form>
        <Row>
          <Col  lg={10}>
            <Form.Group  controlId="formBasicEmail">
              <Form.Control
                className="notes-input"
                type="text"
                value={value}
                placeholder="Type here..."
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Button
              className="submit-btn"
              onClick={() => postNotes()}
            >
              {
                loading ?
                  <BarLoader
                    as="span"
                    size={14}
                    animation="grow"
                    color='#00C6FF'
                    role="status"
                    aria-hidden="true"
                  />
                  : 'Post'
              }
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default UserNotes;
