import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentSetupForm from "./AccountPayment/AccountPaymentCard";

const stripePromise = loadStripe(
  "pk_test_51NXGHoEVvolENlZuo0YXeMygzXMYN8HBfid11spV05NysP9d5cVzNcfbIo7ZM3UTCkExvSU5CpGUmiCnujISKAPa00KIYFeFcG"
);

const AccountPaymentMethod = ({
  clientSecretAccount,
  setPage,
  onBoarding,
  setOpenUpdate,
  getBankDetails,
}) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentSetupForm
        clientSecretAccount={clientSecretAccount}
        setPage={setPage}
        onBoarding={onBoarding}
        setOpenUpdate={setOpenUpdate}
        getBankDetails={getBankDetails}
      />
    </Elements>
  );
};
export default AccountPaymentMethod;
