import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Tables from "../../../../Reuseable/Tables";
import Searchbox from "../../../../Reuseable/Searchbox";
import UserDataPreview from "../UserDataPreview";
import { useSelector } from "react-redux";
import { NoDataFound } from "../../../../Reuseable/NoDataFound";
import { InputGroup } from "react-bootstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CancelIcon from "@mui/icons-material/Cancel";
import hashching from "../../../../../assets/images/hashching_logo.svg";

const UserTable = ({
  title,
  setViewUser,
  viewuser,
  getNotes,
  notes,
  setNotes,
  getAllUserData,
  getAllActivityLog,
  getDeletedUsers,
}) => {
  const AllUsers = useSelector((state) => state.Tracking.allUsers);
  const permissions = useSelector(
    (state) => state.Tracking.permissions.Account
  );

  const [searchterm, setSearchterm] = useState("");
  const [filter, setFilter] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [show, setShow] = useState(false);

  const columns = ["", "Name", "User ID", "Status", "Email", "Phone No"];

  let ActiveUsers = AllUsers.filter((val) => {
    if (val.status === "verified") {
      return val;
    }
  });

  const tbody = (
    <tbody>
      {ActiveUsers.filter((val) => {
        if (searchterm === "") {
          return val;
        } else if (
          val.personname.toLowerCase().includes(searchterm.toLowerCase()) ||
          val.userId.toLowerCase().includes(searchterm.toLowerCase())
        ) {
          return val;
        }
      })
        .filter((val) => {
          if (filter === "") {
            return val;
          } else if (
            val.activationStatus.toLowerCase().includes(filter.toLowerCase())
          ) {
            return val;
          }
        })
        .map((row) => {
          return (
            <tr
              className={
                permissions && permissions.viewUsercontactDetails
                  ? "user-data pointer"
                  : "user-data"
              }
              onClick={() =>
                permissions && permissions.viewUsercontactDetails
                  ? (setShow(true), setViewUser(row))
                  : null
              }
            >
              <td className="icon_column">
                {row.hashchingUser === "yes" ? (
                  <img src={hashching} alt="hashching" />
                ) : null}
              </td>
              <td>{row.personname}</td>
              <td>{row.userId}</td>
              <td>{row.activationStatus}</td>
              <td>{row.emailId}</td>
              <td>{row.phoneNumber}</td>
            </tr>
          );
        })}
    </tbody>
  );

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const data2 = { columns, tbody };
  return (
    <React.Fragment>
      <Container className="user-container">
        {ActiveUsers == "" || ActiveUsers == undefined ? (
          <NoDataFound body="You don’t have any user at the moment." />
        ) : (
          <>
            <Row>
              <div className="search-title">
                <Row>
                  <Col md={5}>{title}</Col>
                  <Col md={7}>
                    <div className="d-inline-flex float-end">
                      <div className="search-deropdown me-2">
                        <InputGroup
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <KeyboardArrowDownIcon className="down-arrow" />
                          <select
                            className="search-dropdown"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            placeholder="Search Status"
                          >
                            <option disabled value={""}>
                              Select Status
                            </option>
                            <option value="Onboarding">Onboarding</option>
                            <option value="Active">Active</option>
                          </select>
                          {isHovering && filter !== "" && (
                            <div
                              className="reset-dropdown me-3"
                              onClick={() => setFilter("")}
                            >
                              <CancelIcon
                                fontSize="small"
                                className="primary"
                              />
                            </div>
                          )}
                        </InputGroup>
                      </div>
                      <div className="search-box ">
                        <Searchbox
                          placeholder="Enter name, User ID"
                          handleSearch={setSearchterm}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
            <div>
              <Tables props={data2} striped={false} />
            </div>
          </>
        )}
      </Container>

      <UserDataPreview
        show={show}
        setShow={setShow}
        viewuser={viewuser}
        setViewUser={setViewUser}
        notes={notes}
        getNotes={getNotes}
        setNotes={setNotes}
        getAllUserData={getAllUserData}
        getDeletedUsers={getDeletedUsers}
        getAllActivityLog={getAllActivityLog}
      />
    </React.Fragment>
  );
};

export default UserTable;
