import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import { Alert } from "react-bootstrap";
import "slick-carousel/slick/slick-theme.css";
import Form from 'react-bootstrap/Form';
import { Row, Col, Card, Modal, Container, Button } from "react-bootstrap";
import Contact from "../../../Pages/LandingPage/Conatctus";
import LandingPageWrap from "../../../Pages/LandingPage/style";
import UIModal from "../../UIModal";
import Validation from "../../../Reuseable/Validation";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import OTPInput, { ResendOTP } from "otp-input-react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../../Services/api";
import { userLogin } from "../../../../redux/Auth/actionCreator";
import jwt_decode from 'jwt-decode'
import BarLoader from 'react-spinners/ClipLoader';

const LoginotpForm = ({ isotpform }) => {

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const errorValidation = (values) => {
        let errors = {};
        if (!values.otp) {
            errors.otp = "This is a required field"
        } else if (values.otp.length < 6) {
            errors.otp = "This is a required field "
        }
        return errors;
    }

    const [dataIsCorrect, setDataiscorrect] = useState('');
    const [resendotpmessage, setresendotpmessage] = useState('');
    const [values, setValues] = useState('');
    const [errors, setErrors] = useState({});
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (otp) => {
        setValues({ otp });
    }

    const handleFormsubmit = (event) => {
        event.preventDefault();
        setErrors(errorValidation(values));
        setDataiscorrect('');
        setLoading(true);
        const obj = {
            emailId: isotpform.emailId,
            otp: values.otp,
        };
        api
            .post("/register/confirmotp", obj)
            .then(function (response) {
                let user = jwt_decode(response.data.authorizationToken);
                console.log("loginResponse", response);
                if (response.data.status === true) {
                    let result = {
                        isLoggedIn: true,
                        authToken: response.data.authorizationToken,
                        user: user,
                        menuList: response.data.menuList,
                    };
                    dispatch(userLogin(result));
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log("loginResponse", error.response);
                if (error.response.data.status === false) {
                    setDataiscorrect(error.response.data.message);
                    console.log("Error", error.response.data.message);
                    setLoading(false);
                }
            });
    };

    const ResendOTP = (event) => {
        event.preventDefault();
        setDataiscorrect('');
        const obj = {
            emailId: isotpform.emailId,
        };
        api
            .post("/register/sendotp", obj)
            .then(function (response) {
                if (response.data.status === true) {
                    setresendotpmessage(response.data.message);
                }
            })
            .catch(function (error) {
                if (error.response.data.status === false) {
                    setDataiscorrect(error.response.data.message);
                    console.log("Error", error.response.data.message);
                }
            });
    };


    return (
        <>
            <div>
                <div className="loginFormScreens flex-fill bg-white" >
                    <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label
                            style={{
                                paddingTop: "20px",
                                fontSize: "32px",
                                color: " #0094FF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                            }}>
                            <b>Log In</b>
                        </Form.Label>
                        {
                            dataIsCorrect ?
                                <Alert
                                    className="m-2 mb-2 p-1 text-center login-otp-alert"
                                    variant="danger">
                                    {dataIsCorrect}
                                </Alert>
                                :
                                <></>
                        }
                         {
                            resendotpmessage ?
                                <Alert
                                    className="m-2 mb-2 p-1 text-center login-otp-alert"
                                    variant="success">
                                    {resendotpmessage}
                                </Alert>
                                :
                                <></>
                        }
                        <Form.Label className="text-center login-content login-otp-alert" >
                            Enter the six-digit code sent to your Email ID
                        </Form.Label>

                    </Form.Group>
                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                    >
                        <Form.Label
                            style={{
                                fontSize: "22px",
                                fontWeight: "600",
                                marginBottom: "0px",
                                marginTop: "20px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center"
                            }}>
                            Enter Verification Code
                        </Form.Label>

                        <OTPInput
                            value={values.otp}
                            style={{
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            className="otp-input bg-white mx-2 text-lg focus:outline-none focus:shadow-outline  border-gray-300 rounded-lg  block w-full appearance-none leading-normal"
                            name="otp"
                            onChange={handleChange}
                            otpType="number"
                            OTPLength={6}
                            separator={
                                <span></span>
                            }
                        />
                        {
                            errors.otp &&
                            <p
                                style={{
                                    color: "red",
                                    fontSize: "10px",
                                    marginBottom: '-5px',
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <ReportProblemIcon
                                    style={{
                                        width: "12px",
                                        marginTop: "-3px"
                                    }}
                                />
                                {errors.otp}
                            </p>
                        }
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label
                                    className="pointer p-text"
                                    onClick={ResendOTP}
                                >
                                    Resend Code
                                </Form.Label>

                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label
                                    className="p-text pointer"
                                    onClick={() => setShow(true)}
                                    style={{
                                        float: "right"
                                    }}
                                >Contact Support
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Col
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                        <Button
                            style={{
                                background: '#0094FF',
                                border: 'none',
                                width: "110px",
                                height: '35px',
                                borderRadius: "20px",
                                marginTop: "5px",
                                marginBottom: "30px"
                            }}
                            onClick={handleFormsubmit}
                        >
                            {
                                loading ?
                                    (
                                        <BarLoader
                                            as="span"
                                            size={14}
                                            animation="grow"
                                            color='#ffffff'
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )
                                    :
                                    'Proceed'
                            }
                        </Button>
                    </Col>
                </div>
            </div>

            <UIModal
                show={show}
                setShow={setShow}
                close={true}
                padding={false}
                // title='Contact Support'
                body={<LandingPageWrap>
                    <Contact />
                </LandingPageWrap>}
            />

        </>
    )
}


export default LoginotpForm;