import React, { useState, useEffect } from "react";
import { Container, Form, Row, Col, Card, Button } from "react-bootstrap";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { api } from "../../../../../../Services/api";
import Switch from '@mui/material/Switch';
import { useSelector } from "react-redux";
import BarLoader from 'react-spinners/ClipLoader';
import { Alert } from "@mui/material";
import { getCurrentTime } from "../../../../../Reuseable/CurrentDateTime";

const regex = {
    email: new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    ),
};

const CreateAdministrator = ({ AdministrativeArr, Accountarr, Payments, setView, getAdministrator, Onlytext }) => {

    const authToken = useSelector((state) => state.Auth.authToken);

    const [loading, setLoading] = useState(false)
    const [adminPermissions, setAdminpermissions] = useState({
        addnewAdministrator: false,
        changeAdministratorRoles: false,
        viewAdministratorRoles: false,
        edituserrfoles: false,
        viewActivityLog: false,
        viewUserReports: false,
        viewpaymentsReports: false,
        ViewSettings: false,
        viewDashboard: false
    })
    const [accountPermission, setAccountPermission] = useState({
        viewuserAccount: false,
        viewUsercontactDetails: false,
        resetUserPassowrd: false,
        approveAccounts: false,
        deleteAccounts: false,
        updateuserProfileinformation: false,
    })
    const [paymentsPermissions, setPaymentsPermissions] = useState({
        viewRequests: false,
        viewRepayemnts: false,
        setMaxlimit: false,
        approveDeclineRequests: false,
        manageuuserPayemntsInformation: false,
    })
    const [selectallAdmin, setSelectallAdmin] = useState(false)
    const [selectallAccount, setSelectallAccount] = useState(false)
    const [selectallPayments, setSelectallPayments] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState('');
    const [values, setValues] = useState({
        name: '',
        email: ''
    });
    const [isValid, setIsValid] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)

    const Validation = (values) => {
        let errors = {};

        if (!values.name) {
            errors.name = "This is a required field"
        }
        if (!values.email) {
            errors.email = "This is a required field"
        }

        return errors;
    }

    const CheckEmail = e => {
        const result = regex.email.test(e.target.value);
        if (!result) {
            setInvalidEmail(true)
        } else {
            setInvalidEmail(false)
        }

    }

    useEffect(() => {

        setIsValid(JSON.stringify(errors) === '{}')

        return () => setIsValid(false)

    }, [errors])

    useEffect(() => {
        setErrors(Validation(values))

        return () => setErrors({})

    }, [values])

    const handlechange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    }

    const onSubmitForm = (e) => {
        e.preventDefault();
        setIsSubmitted(true)
        setErrors(Validation(values));

        isValid && postAdministrator()
    }

    const postAdministrator = async () => {

        let obj = {
            emailId: values.email,
            userRole: "Administrator",
            personname: values.name,
            permissions: {
                Administrative: adminPermissions,
                Account: accountPermission,
                Payments: paymentsPermissions
            },
            logDateTime: getCurrentTime(),
        }
        setLoading(true);
        setErrorMsg('')
        await api.post('/brokerpayuserauth/administrator', obj, {
            headers: {
                'authorizationtoken': `${authToken}`,
            }
        }).then(function (response) {
            if (response.data.status === true) {
                editMenuList(response.data.userId)
                setLoading(false);
                setIsValid(false)
                setIsSubmitted(false)
                setValues({
                    name: '',
                    email: ''
                })
                setView('dataView')
                getAdministrator()
            }
        }).catch(function (error) {
            setLoading(false);
            setIsValid(false)
            setIsSubmitted(false)
            // setValues({
            //     name: '',
            //     email: ''
            // })
            setErrorMsg(error.response.data.message)
            console.log('getBrokerdata [error]-->', error.response.data);
        });
    }

    const editMenuList = async (userId) => {
        let obj = {
            emailId: values.email,
            userId: userId,
            name: 'menuList',
            value: [
                {
                    "icon": "/favicons/overview.svg",
                    "name": "Overview",
                    "path": "overview",
                    "activeicon": "/favicons/overview-blue.svg",
                    "id": 1,
                    access: true
                },
                {
                    "icon": "/favicons/users.svg",
                    "name": "Users",
                    "path": "users",
                    "activeicon": "/favicons/users-blue.svg",
                    "id": 2,
                    access: accountPermission.viewuserAccount
                },
                {
                    "icon": "/favicons/request.svg",
                    "name": "Requests",
                    "path": "requests",
                    "activeicon": "/favicons/request-blue.svg",
                    "id": 3,
                    access: paymentsPermissions.viewRequests
                },
                {
                    "icon": "/favicons/repayments.svg",
                    "name": "Repayments",
                    "path": "repayments",
                    "activeicon": "/favicons/repayments-blue.svg",
                    "id": 4,
                    access: paymentsPermissions.viewRepayemnts
                },
                {
                    "icon": "/favicons/report.svg",
                    "name": "Reports",
                    "path": "reports",
                    "activeicon": "/favicons/report-blue.svg",
                    "id": 5,
                    access: adminPermissions.viewUserReports || adminPermissions.viewpaymentsReports
                },
                {
                    "icon": "/favicons/settings.svg",
                    "name": "Settings",
                    "path": "setting",
                    "activeicon": "/favicons/settings-blue.svg",
                    "id": 6,
                    access: adminPermissions.ViewSettings
                },
                {
                    "icon": "/favicons/activitylog.svg",
                    "name": "Activity Log",
                    "path": "activitylog",
                    "activeicon": "/favicons/activitylog-blue.svg",
                    "id": 7,
                    access: adminPermissions.viewActivityLog
                }
            ]
        }
        await api.patch('/brokerpayuserauth/administrator', obj, {
            headers: {
                'authorizationtoken': `${authToken}`,
            }
        }).then(function (response) {
            if (response.data.status === true) {
            }
        }).catch(function (error) {
            console.log('editMenuList [error]-->', error.response);
        });
    }

    const onChangeAdmin = (id) => {
        setAdminpermissions((prevState) => {

            let temp = {
                ...prevState, ...prevState.adminPermissions
            }

            if (id === 'addnewAdministrator') temp.addnewAdministrator = !temp.addnewAdministrator
            if (id === 'changeAdministratorRoles') temp.changeAdministratorRoles = !temp.changeAdministratorRoles
            if (id === 'viewAdministratorRoles') temp.viewAdministratorRoles = !temp.viewAdministratorRoles
            if (id === 'edituserrfoles') temp.edituserrfoles = !temp.edituserrfoles
            if (id === 'viewActivityLog') temp.viewActivityLog = !temp.viewActivityLog
            if (id === 'viewUserReports') temp.viewUserReports = !temp.viewUserReports
            if (id === 'ViewSettings') temp.ViewSettings = !temp.ViewSettings
            if (id === 'viewDashboard') temp.viewDashboard = !temp.viewDashboard
            if (id === 'viewpaymentsReports') temp.viewpaymentsReports = !temp.viewpaymentsReports

            return temp;
        }
        )
    }

    const AdminSelectall = (data) => {

        setAdminpermissions(() => {


            let temp = adminPermissions;
            temp.addnewAdministrator = data
            temp.changeAdministratorRoles = data
            temp.viewAdministratorRoles = data
            temp.edituserrfoles = data
            temp.viewActivityLog = data
            temp.viewUserReports = data
            temp.ViewSettings = data
            temp.viewDashboard = data
            temp.viewpaymentsReports = data

            return temp;
        }
        )
    }

    const onChangeAccount = (id) => {

        setAccountPermission((prevState) => {

            let temp = {
                ...prevState, ...prevState.accountPermission
            }

            if (id === 'viewuserAccount') temp.viewuserAccount = !temp.viewuserAccount
            if (id === 'viewUsercontactDetails') temp.viewUsercontactDetails = !temp.viewUsercontactDetails
            if (id === 'resetUserPassowrd') temp.resetUserPassowrd = !temp.resetUserPassowrd
            if (id === 'approveAccounts') temp.approveAccounts = !temp.approveAccounts
            if (id === 'deleteAccounts') temp.deleteAccounts = !temp.deleteAccounts
            if (id === 'updateuserProfileinformation') temp.updateuserProfileinformation = !temp.updateuserProfileinformation

            return temp;
        }
        )
    }

    const AccountSelectall = (data) => {

        setAccountPermission(() => {

            let temp = accountPermission;
            temp.viewuserAccount = data
            temp.viewUsercontactDetails = data
            temp.resetUserPassowrd = data
            temp.approveAccounts = data
            temp.deleteAccounts = data
            temp.updateuserProfileinformation = data

            return temp;
        }
        )
    }

    const onChangePayment = (id) => {

        setPaymentsPermissions((prevState) => {

            let temp = {
                ...prevState, ...prevState.paymentsPermissions
            }

            if (id === 'viewRequests') temp.viewRequests = !temp.viewRequests
            if (id === 'viewRepayemnts') temp.viewRepayemnts = !temp.viewRepayemnts
            if (id === 'setMaxlimit') temp.setMaxlimit = !temp.setMaxlimit
            if (id === 'approveDeclineRequests') temp.approveDeclineRequests = !temp.approveDeclineRequests
            if (id === 'manageuuserPayemntsInformation') temp.manageuuserPayemntsInformation = !temp.manageuuserPayemntsInformation

            return temp;
        }
        )
    }

    const Selectallpayments = (data) => {

        setPaymentsPermissions(() => {

            let temp = paymentsPermissions;
            temp.viewRequests = data
            temp.viewRepayemnts = data
            temp.setMaxlimit = data
            temp.approveDeclineRequests = data
            temp.manageuuserPayemntsInformation = data

            return temp;
        }
        )
    }


    return (
        <React.Fragment>
            <Container className="bg-white p-4">
                {errorMsg &&
                    <Alert
                        className="mb-4 "
                        severity="error"
                    >
                        {errorMsg}
                    </Alert>
                }
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3 d-flex"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="mt-1">Name</Form.Label>
                                <Form.Control
                                    onKeyDown={e => Onlytext(e)}
                                    onChange={e => {
                                        handlechange(e)
                                    }}
                                    type="text"
                                    placeholder="Enter"
                                    name='name'
                                />
                            </Form.Group>
                            {errors.name && isSubmitted &&
                                <p className="declined error">
                                    {errors.name}
                                </p>
                            }
                        </Col>
                        <Col md={6}>
                            <Form.Group
                                className="mb-3 d-flex"
                                controlId="formBasicEmail"
                            >
                                <Form.Label className="mt-1">Email</Form.Label>
                                <Form.Control
                                    onChange={e => { CheckEmail(e); handlechange(e) }}
                                    type="email"
                                    placeholder="Enter"
                                    name='email'
                                />
                            </Form.Group>
                            {errors.email && isSubmitted &&
                                <p className="declined error">
                                    {errors.email}
                                </p>
                            }
                            {invalidEmail && values.email.length ?
                                <p className="declined error">
                                    Please enter valid Email-ID
                                </p>
                                : null
                            }

                        </Col>
                    </Row>
                    <div className="primary mt-3">Permissions</div>
                    <Row>
                        <Col md={12} lg={12} xl={4}>
                            <div className="my-3">Administrative</div>
                            <Card className='Add-card'>
                                {AdministrativeArr.map((label) => {

                                    let value = ''

                                    if (label.id === 'addnewAdministrator') value = adminPermissions.addnewAdministrator
                                    if (label.id === 'changeAdministratorRoles') value = adminPermissions.changeAdministratorRoles
                                    if (label.id === 'viewAdministratorRoles') value = adminPermissions.viewAdministratorRoles
                                    if (label.id === 'edituserrfoles') value = adminPermissions.edituserrfoles
                                    if (label.id === 'viewActivityLog') value = adminPermissions.viewActivityLog
                                    if (label.id === 'viewUserReports') value = adminPermissions.viewUserReports
                                    if (label.id === 'ViewSettings') value = adminPermissions.ViewSettings
                                    if (label.id === 'viewDashboard') value = adminPermissions.viewDashboard
                                    if (label.id === 'viewpaymentsReports') value = adminPermissions.viewpaymentsReports

                                    return (
                                        <div>
                                            <div className="d-inline-block label">{label.name}</div>
                                            <div className="d-inline-block float-end">
                                                <FormGroup >
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                checked={value}
                                                                onChange={() => onChangeAdmin(label.id)}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="selectall ">
                                    <div className="d-inline-flex ">
                                        <span className="mx-2 mt-2"> Select all </span>
                                        <FormGroup >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        onChange={() => {
                                                            setSelectallAdmin(!selectallAdmin);
                                                            AdminSelectall(!selectallAdmin)
                                                        }}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col md={12} lg={12} xl={4}>
                            <div className="my-3">Account</div>
                            <Card className='Add-card'>
                                {Accountarr.map((label) => {

                                    let value = ''

                                    if (label.id === 'viewuserAccount') value = accountPermission.viewuserAccount
                                    if (label.id === 'viewUsercontactDetails') value = accountPermission.viewUsercontactDetails
                                    if (label.id === 'resetUserPassowrd') value = accountPermission.resetUserPassowrd
                                    if (label.id === 'approveAccounts') value = accountPermission.approveAccounts
                                    if (label.id === 'deleteAccounts') value = accountPermission.deleteAccounts
                                    if (label.id === 'updateuserProfileinformation') value = accountPermission.updateuserProfileinformation

                                    return (
                                        <div>
                                            <div className="d-inline-block label">{label.name}</div>
                                            <div className="d-inline-block float-end">
                                                <FormGroup >
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                checked={value}
                                                                onChange={() => onChangeAccount(label.id)}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="selectall ">
                                    <div className="d-inline-flex ">
                                        <span className="mx-2 mt-2"> Select all </span>
                                        <FormGroup >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        onChange={() => {
                                                            setSelectallAccount(!selectallAccount);
                                                            AccountSelectall(!selectallAccount)
                                                        }}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <Col md={12} lg={12} xl={4}>
                            <div className="my-3">Payments</div>
                            <Card className='Add-card'>
                                {Payments.map((label) => {
                                    let value = ''

                                    if (label.id === 'viewRequests') value = paymentsPermissions.viewRequests
                                    if (label.id === 'viewRepayemnts') value = paymentsPermissions.viewRepayemnts
                                    if (label.id === 'setMaxlimit') value = paymentsPermissions.setMaxlimit
                                    if (label.id === 'approveDeclineRequests') value = paymentsPermissions.approveDeclineRequests
                                    if (label.id === 'manageuuserPayemntsInformation') value = paymentsPermissions.manageuuserPayemntsInformation

                                    return (
                                        <div>
                                            <div className="d-inline-block label">{label.name}</div>
                                            <div className="d-inline-block float-end">
                                                <FormGroup >
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={value}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                onChange={() => onChangePayment(label.id)}
                                                            />
                                                        }
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className="selectall ">
                                    <div className="d-inline-flex ">
                                        <span className="mx-2 mt-2"> Select all </span>
                                        <FormGroup >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        onChange={() => {
                                                            setSelectallPayments(!selectallPayments);
                                                            Selectallpayments(!selectallPayments)
                                                        }}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                        <div>
                            <Button
                                onClick={e => onSubmitForm(e)}
                                className="theme-btn float-end px-5 mt-4"
                            >

                                {
                                    loading ?
                                        (
                                            <BarLoader
                                                as="span"
                                                size={14}
                                                animation="grow"
                                                color='#ffffff'
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )
                                        :

                                        'Add'
                                }
                            </Button>
                        </div>
                    </Row>

                </Form>
            </Container>
        </React.Fragment>
    );
};

export default CreateAdministrator;
