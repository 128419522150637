import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { api } from "../../../../Services/api";
import CheckoutForm from "..";

const stripePromise = loadStripe(
  "pk_test_51NXGHoEVvolENlZuo0YXeMygzXMYN8HBfid11spV05NysP9d5cVzNcfbIo7ZM3UTCkExvSU5CpGUmiCnujISKAPa00KIYFeFcG"
);

export default function PaymentForm({
  clientSecret,
  setShow,
  postAccountData,
  getBankDetails,
  onBoarding,
}) {
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            clientSecret={clientSecret}
            setShow={setShow}
            postAccountData={postAccountData}
            onBoarding={onBoarding}
            getBankDetails={getBankDetails}
          />
        </Elements>
      )}
    </div>
  );
}
