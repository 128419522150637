import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { format } from "date-fns";
import { Money } from "../../../../Reuseable/NumberFormatter";
import DashboardView from "../style";

const RequestView = ({ show, setShow, popupData, title }) => {
  const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");

  return (
    <>
      <Modal centered show={show} onHide={() => setShow(false)}>
        <Modal.Header style={{ background: "#E2F2FF" }}>
          <div className="primary fw-bold">{title}</div>
        </Modal.Header>
        <DashboardView>
          {popupData && (
            <Modal.Body className="request_popup_body">
              <Row>
                <Col xs={6} md={8}>
                  <span className="fw-bold">Full Name of Client:</span>
                </Col>
                <Col xs={6} md={4}>
                  <span>{popupData?.name}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={8}>
                  <span className="fw-bold">Total Loan Amount:</span>
                </Col>
                <Col xs={6} md={4}>
                  <span>{Money(popupData?.loanAmount)}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={8}>
                  <span className="fw-bold">Commission Rate:</span>
                </Col>
                <Col xs={6} md={4}>
                  <span>{popupData?.commissionRate}%</span>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={8}>
                  <span className="fw-bold">Aggregator:</span>
                </Col>
                <Col xs={6} md={4}>
                  <span>{popupData?.aggregator}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={8}>
                  <span className="fw-bold">Commission Advance Requested:</span>
                </Col>
                <Col xs={6} md={4}>
                  <span>{Money(popupData?.commissionAdvance)}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={8}>
                  <span className="fw-bold">Settlement Date:</span>
                </Col>
                <Col xs={6} md={4}>
                  <span>{formatDate(popupData?.settlementDate)}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={8}>
                  <span className="fw-bold">Repayment Date:</span>
                </Col>
                <Col xs={6} md={4}>
                  <span>{formatDate(popupData?.repaymentDate)}</span>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={8}>
                  <span className="fw-bold">
                    Date your commission will be paid:
                  </span>
                </Col>
                <Col xs={6} md={4}>
                  <span>{formatDate(popupData?.commissionDate)}</span>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <span className="fw-bold">Attachment:</span>
                </Col>
                <Col md={12}>
                  <div className="file_list_card p-2">
                    {popupData?.fileurls.map((data) => (
                      <Row>
                        <Col xs={6} md={6}>
                          <span>{data.fileName}</span>
                        </Col>
                        <Col xs={3} md={3}>
                          {data.type.toUpperCase()}
                        </Col>
                        <Col xs={3} md={3}>
                          {data.size}
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={8}>
                  <span className="fw-bold">Request Status:</span>
                </Col>
                <Col xs={6} md={4}>
                 {popupData.status === 'Pending' ? <span className="popup_processing">Awaiting Approval</span>: <span className="">{popupData.status}</span>}
                </Col>
              </Row>
            </Modal.Body>
          )}
        </DashboardView>
      </Modal>
    </>
  );
};

export default RequestView;
