import React from "react";
import { Button } from "react-bootstrap";

const ConfirmSignUp = ({ setPageView }) => {
  return (
    <React.Fragment>
      <div className="loginFormScreens flex-fill bg-white">
        <b className="signup_text center mb-2">Sign Up</b>
        <br />
        <div className="text-center login-content page_text_1">
          Are you a Hashching Broker?
        </div>
        <div className="center sign-btn-group my-4">
          <Button
            onClick={() => setPageView("hashching")}
            className="yes-btn theme-btn me-2 px-5"
          >
            Yes
          </Button>
          <Button
            onClick={() => setPageView("regsiter")}
            className="yes-btn theme-btn ms-2 px-5"
          >
            No
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConfirmSignUp;
