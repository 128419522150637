import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { color } from '@mui/system'
import LoaderWrap from './style'

const Loading = () => {
    return (
        <LoaderWrap>
             <div className='loader-div'>
                <span class="loader"></span>
            </div>
        </LoaderWrap>
       
    )
}

export default Loading