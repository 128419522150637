import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import Alert from "@mui/material/Alert";
import { useSelector, useDispatch } from "react-redux";
import DashboardView from "./style";
import UIcardDisplay from "../../../Reuseable/UIFormBuilder/UICardDisplay";
import { Row, Col, Card } from "react-bootstrap";
import Tables from "../../../Reuseable/Tables";
import { useNavigate, useParams } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { NoDataFound } from "../../../Reuseable/NoDataFound";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";
import { Money, formatDigitNumber } from "../../../Reuseable/NumberFormatter";
import FirstLogin from "./FirstLogin";
import BankaccountDetails from "../../Settings/AccountCard/BankAccountDetails";
import { api } from "../../../../Services/api";
import OnBoarding from "../OnBoarding";
import jwt_decode from "jwt-decode";
import Backdrop from "@mui/material/Backdrop";
import { ScaleLoader } from "react-spinners";
import { setBrokerData } from "../../../../redux/Tracking/actionCreator";
import { getCurrentTime } from "../../../Reuseable/CurrentDateTime";
import RequestView from "./RequestView";

const Dashboard = ({ requestLoading, getBrokerdata, redirect }) => {
  const user = useSelector((state) => state.Auth.user);
  const authToken = useSelector((state) => state.Auth.authToken);
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);
  const allRequest = useSelector((state) => state.Tracking.allRequest);
  const allRepayments = useSelector((state) => state.Tracking.allRepayments);

  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [contractLoading, setContractLoading] = useState(false);
  const [requestShow, setRequestShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState("");
  const [changeInForm, setChangeInForm] = useState("");
  const [popupData, setPopupData] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    if (redirect && user && id.length) {
      const getIdTokenFromURL = () => {
        if (id) {
          const decodedToken = jwt_decode(id);
          if (decodedToken.emailId === user.emailId) {
            setTimeout(() => {
              postAccountData("contractSigned");
            }, 300);
          }
        }
      };
      getIdTokenFromURL();
    }
  }, [user]);

  const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");

  let data = allRequest.filter((val) => {
    if (val.status === "Pending") {
      return val;
    }
  });

  const dataForDisplay = expanded ? data : data.slice(0, 8);
  const availablePercentage = (brokerdata.owing / brokerdata.brokerLimit) * 100;

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const User = () => {
    navigate("/home/newrequest");
  };
  const columns = [
    "Payment Date",
    "Request Id",
    "Amount",
    "Fees",
    "Client",
    "Due Date",
  ];

  const tbody = (
    <tbody>
      {allRepayments.map((row) => (
        <tr>
          <td>{formatDate(row.commissionDate)}</td>
          <td>{row.requestId}</td>
          <td>{Money(row.commissionAdvance)}</td>
          <td>{Money(row.feeAmount)}</td>
          <td>{row.name}</td>
          <td>{formatDate(row.repaymentDate)}</td>
        </tr>
      ))}
    </tbody>
  );

  const postAccountDetails = async (form, valid) => {
    setLoading(true);
    if (valid) {
      const obj = {
        userId: user.userId,
        emailId: user.emailId,
        personname: brokerdata.personname,
        accountDetails: {
          BankName: form.bankname,
          Bsb: form.bsb,
          Accountnumber: form.accountNumber,
          AccountName: form.accountName,
        },
      };

      try {
        let url = "/userbankdetails";
        const response = await api.post(url, obj, {
          headers: {
            authorizationtoken: `${authToken}`,
          },
        });
        if (response.data.status) {
          setSubmitted(true);
          setLoading(false);
          brokerdata.accountDetails = {
            BankName: form.bankname,
            Bsb: form.bsb,
            Accountnumber: form.accountNumber,
            AccountNameL: form.accountName,
          };
          setShow(false);
          setOpen(false);
          postAccountData("AccountLinked");
        } else {
          setErrorMessage(true);
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(true);
        console.log("postAccountDetails [error]-->", error);
      }
    }
  };

  const setContractFile = async () => {
    let obj = {
      emailId: user.emailId,
      userId: parseInt(user.userId),
    };

    try {
      let url = `/usercontract/${user.userId}?emailId=${encodeURIComponent(
        user.emailId
      )}`;
      const response = await api.post(url, obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status) {
        console.log("Contract uploaded successfully");
      }
    } catch (error) {
      console.log("setContractFile [error]-->", error);
    }
  };

  const postAccountData = async (name) => {
    let obj = {
      emailId: user.emailId,
      userId: user.userId,
      name: name,
      value: true,
      dateTime: getCurrentTime(),
      logDateTime: getCurrentTime(),
    };

    try {
      let url = "/brokerpayuserauth/activation";
      const response = await api.post(url, obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status) {
        if (name === "contractSigned") {
          navigate("/home/dashboard");
          setContractFile();
          dispatch(setBrokerData(response.data.data));
        } else {
          dispatch(setBrokerData(response.data.data));
        }
      }
    } catch (error) {
      console.log("postAgreementData [error]-->", error);
    }
  };

  const postAgreementData = async () => {
    let obj = {
      emailId: brokerdata.emailId,
      userId: parseInt(brokerdata.userId),
      personName: brokerdata.personname,
      businessName: brokerdata.businessName,
    };

    setContractLoading(true);

    try {
      let url = "/usercontract";
      const response = await api.post(url, obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status) {
        window.location.href = response.data.URL;
      }
    } catch (error) {
      console.log("postAgreementData [error]-->", error);
    }
  };

  function sumPendingAmounts(allRequest) {
    let totalPendingAmount = 0;
    for (let i = 0; i < allRequest.length; i++) {
      if (allRequest[i].status === "Pending") {
        totalPendingAmount += parseInt(allRequest[i].commissionAdvance);
      }
    }
    return totalPendingAmount;
  }

  const pendingAmountSum =
    sumPendingAmounts(allRequest) + parseInt(brokerdata.owing);
  const pendingCommission = (pendingAmountSum / brokerdata.brokerLimit) * 100;

  const data2 = { columns, tbody };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={contractLoading}
      >
        <ScaleLoader color="#0094FF" />
      </Backdrop>

      {brokerdata.activationStatus === "Active" &&
      brokerdata.contractSigned === true &&
      brokerdata.AccountLinked === true ? (
        <DashboardView>
          <Container>
            {open ? (
              <Alert className="my-2" severity="error">
                Please link bank account before creating new request
                <b
                  className="primary pointer mx-1"
                  onClick={() => setShow(true)}
                >
                  Link Account
                </b>
              </Alert>
            ) : null}
            <p className="p-pading">Current Available Funds</p>
            <Row>
              <Col sm={12} md={12} lg={12} xl={12} className="newrequest1">
                <Card>
                  <div className="box">
                    <Row>
                      <Col>
                        <div className="progress-item">
                          <ProgressBar>
                            <ProgressBar
                              variant="success"
                              now={availablePercentage}
                              key={1}
                            />
                            <ProgressBar
                              className="pending_progress"
                              now={pendingCommission - availablePercentage}
                              key={2}
                              label={
                                "$" +
                                formatDigitNumber(sumPendingAmounts(allRequest))
                              }
                            />
                          </ProgressBar>
                          <Row sm={12}>
                            <Col>
                              <div
                                style={{
                                  width: `${availablePercentage}%`,
                                }}
                                className="d-inline-flex"
                              >
                                {availablePercentage > 10 ? " $0" : null}
                              </div>

                              {brokerdata.owing !== 0 ? (
                                <div className="progress1 d-inline-flex">
                                  {brokerdata && brokerdata.owing
                                    ? "$" + formatDigitNumber(brokerdata.owing)
                                    : ""}
                                </div>
                              ) : null}

                              {availablePercentage <= 70 ? (
                                <div className="d-inline-flex float-end ">
                                  {brokerdata && brokerdata.brokerLimit
                                    ? "$" +
                                      formatDigitNumber(brokerdata.brokerLimit)
                                    : ""}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                          <div className="avail-limit">
                            Available limit :{" "}
                            <span className="limit">
                              {Money(brokerdata.availableFunds)}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
              <Col sm={12} md={12} lg={12} xl={12} className="newrequest">
                <Card>
                  <button className="box1" onClick={User}>
                    New Request
                  </button>
                </Card>
              </Col>
            </Row>
            {brokerdata.loginStatus || allRequest === [] || allRequest == "" ? (
              <div>
                <FirstLogin />
              </div>
            ) : (
              <div>
                <p className="p-pading pending-request mb-2">
                  Pending Requests
                </p>
                {requestLoading ? (
                  <div className="center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <Row>
                      {data && data.length ? (
                        <>
                          {dataForDisplay.map((Role, index) => {
                            if (brokerdata) Role.user = brokerdata.personname;
                            else Role.user = Role.name;

                            return (
                              <Col sm={6} md={6} lg={4} xl={3} className="mb-2">
                                <UIcardDisplay
                                  formatDate={formatDate}
                                  key={index}
                                  Role={Role}
                                  setPopupData={setPopupData}
                                  setRequestShow={setRequestShow}
                                />
                              </Col>
                            );
                          })}
                        </>
                      ) : (
                        <div className="mx-1 me-3">
                          <NoDataFound body="You don’t have any pending requests at the moment." />
                        </div>
                      )}
                    </Row>
                  </>
                )}
                <Row>
                  <Col>
                    <p className="p-pading ">Upcoming payments</p>
                  </Col>
                  {data.length > 6 ? (
                    <Col>
                      <p
                        onClick={() => setExpanded(!expanded)}
                        className="p-pading view-all"
                      >
                        {expanded ? "View less" : "View All"}
                      </p>
                    </Col>
                  ) : null}
                </Row>
                {allRepayments == [] || allRepayments == "" ? (
                  <NoDataFound body="You don’t have any repayments at the moment." />
                ) : (
                  <Tables props={data2} />
                )}
              </div>
            )}
          </Container>

          <BankaccountDetails
            show={show}
            setShow={setShow}
            loading={loading}
            submitted={submitted}
            changeInForm={changeInForm}
            setChangeInForm={setChangeInForm}
            postAccountDetails={postAccountDetails}
            errorMessage={errorMessage}
            postAccountData={postAccountData}
          />

          <RequestView
            show={requestShow}
            setShow={setRequestShow}
            popupData={popupData}
            title={"New request details"}
          />
        </DashboardView>
      ) : (
        <>
          <OnBoarding
            brokerdata={brokerdata}
            getBrokerdata={getBrokerdata}
            SetLinkAccount={setShow}
            postAgreementData={postAgreementData}
            postAccountData={postAccountData}
          />

          <BankaccountDetails
            show={show}
            setShow={setShow}
            postAccountDetails={postAccountDetails}
            postAccountData={postAccountData}
            onBoarding={true}
          />
        </>
      )}
    </>
  );
};

export default Dashboard;
