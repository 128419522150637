import Styled from "styled-components";

const SettingsWrap = Styled.div`

 padding: 10px;
 font-size: 18px;
 font-weight: 600;

 .back-link{
   color: black;
 }
.selectall{
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 15px;
  margin-bottom: 10px;
}
.resetpassword-btn{
  width: 150px;
}
 .reset-btn{
    border: none;
    background: transparent;
    font-weight: 500;
 }
.drop-menu{
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  display: inline;
}
.dropdown-toggle::after {
  display: none !important;
}
.dropdown-menu.show {
  box-shadow: 0px 3px 6px #00000015;
  border-radius: 5px;
  background: #E8F5FF;
  border: none;
}
.dropdown-item:active {
  background: transparent;
}
.btn-group{
  gap: 30px;
}
.add-card{
  background: #0094FF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000015;
  border: 1px solid #0094FF;
  border-radius: 5px;
  opacity: 0.57;
  height: 115px;
  color: white;
  font-size: 15px;
  margin-bottom: 15px;
}
.data-card{
  min-height: 115px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000015;
  border: 1px solid #0094FF;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  font-size: 13px;
  margin-bottom:  15px;
}
.team-data{
  gap: 20px;
}
.username{
  font-size: 15px;
}
.team-email{
  margin-top: 20px;
}
.plus{
  font-size: 40px;
}
 .tab-row{
   display: block;
   width: 100%;

   .nav-link{
      font-weight: 600;
      color: black !important;
      background: white;
      border: none;
      width: 204px;
   }
   .nav{
   .active{
    
      background: #E2F2FF 0% 0% no-repeat padding-box;
      border-radius: 5px 0px 0px 5px;
   }
  }
  }

 .tab-group{
   float: right;
 }
tr{
 td{
  font-size: 17px  !important;
   padding-top: 15px !important;
   padding-bottom: 15px !important;
 }
 th{
  font-weight: 700 !important;
 }
}
.table-btn{
   width: 100px;
   height: 30px;
}

.form-control, .form-select{
  border: 2px solid #B8DEFC;
  border-radius: 5px
  margin-right: 10px;
  margin-left: 10px;
  font-size: 15px ;
}
.error{
  font-size: 12px;
}
}
.form-label{
 width: 75px;
}
.form-control:placeholder, .form-select:placeholder{
opacity: 0.5px;
}
.form-group{
  font-size: 15px;
}
.Add-card{
  border: 2px solid #B8DEFC;
  border-radius: 5px;
  padding: 20px;
  min-height: 430px;
}
.label{
  font-size: 15px;
}
.MuiSwitch-thumb{
  color: white;
  width: 12px !important;
  height: 12px !important;
  margin-top: 4px;
  margin-right: -20px;
}
.Mui-checked+ .MuiSwitch-track{
  background-color:#03C83E !important;
}
.view-data-page{
  gap: 20px;
  font-size: 14px;
}
.view-select{
  height: 30px;
  font-size: 13px !important;
  font-weight: 600;
}
h3{
  font-weight: 600;
}
@media (max-width:480px){
.view-data-page{
  display: block;
}
.divider{
  display: none;
}
}
@media (min-width:480px){
  .view-data-page{
    display: flex;
  }
 
}
@media (max-width:450px){
  font-size: 15px !important;
  .selectall{
    position: relative !important;
  }
}
@media  (max-width:600px){
  tr{
    td{
  font-size: 13px !important;
  }
  th{
    font-size: 14px !important;
  }
}
}
`;

export default SettingsWrap;
