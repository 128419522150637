import React, { useState } from "react";
import { Row, Col, FormControl, InputGroup } from "react-bootstrap";
import './style.css';
import SearchIcon from '../../../assets/images/search-icon.svg';


const Searchbox = ({ handleSearch, placeholder }) => {

  const [searchFocused, setSearchFocused] = useState(false);

  const SearchFilter = (e) => {
    handleSearch(e.target.value.trim());
  }

  return (
    <>
      <InputGroup className={`searchbar ${searchFocused ? 'search-focus' : ''}`} style={{ marginTop: "5px", }} >

        <FormControl
          className="search bg-transparent "
          style={{ background: "#F6FBFF 0% 0% no-repeat padding-box", font: "normal normal normal 14px/21px Roboto", paddingLeft: "5px", }}
          placeholder={placeholder}
          aria-label="Search"
          aria-describedby="basic-addon1"
          onChange={SearchFilter}
          onFocus={()=>setSearchFocused(true)}
          onBlur={()=>setSearchFocused(false)}
        />
        <img src={SearchIcon} className="fabutton mt-1 pointer" />
      </InputGroup>






    </>
  );
}

export default Searchbox;
