import React, { useRef, useEffect } from "react";
import TableWrap from './style';
import { Card, Table, Row, Col, Alert, Spinner } from "react-bootstrap";
import ScrollContainer from 'react-indiana-drag-scroll'

const Tables = ({ props, striped, tableRef }) => {
  const onWheel = e => {
    e.preventDefault();
    const container = scrollRef.current;
    const containerScrollPosition = scrollRef.current.scrollLeft;

    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
    });
  };

  const scrollRef = useRef(null);
  return (
    <TableWrap >
      <Card className="border-0 mb-3 table-view-card" id="my-table">
        <Row >
          <div style={props.style1} >
            {/* <Col sm={12} className="mb-2"> <h3 style={props.style2}>{props.header}</h3> </Col> */}
            {props.extra ? <Col sm={12} className="mb-2"> {props.extra} </Col> : <></>}
            <Col md={12}>
              <TableWrap >
                <ScrollContainer
                  hideScrollbars={false}
                  onWheel={onWheel}
                  ref={scrollRef}
                  className="scroll-container"
                >
                  <div className="table-div mb-5 mt-2">
                    <Table
                      ref={tableRef}
                      className="mb-0"
                      striped={striped}
                      // responsive
                      bordered
                      // hover
                      size="sm md lg xl xxl"
                      id='#my-table'
                    >
                      {props.columns ?
                        <thead>
                          <tr>{props.columns
                            .map((item, i) => {
                              return <th
                                className="p-2"
                                key={i}
                              >
                                {item}
                              </th>
                            })
                          }</tr>
                        </thead>
                        : <> {props.head}</>
                      }
                      {props.tbody}
                    </Table >
                  </div>
                </ScrollContainer>
                {/* {props.pagination} */}
              </TableWrap>
            </Col>
          </div>
        </Row>

      </Card>
    </TableWrap >
  );


}

export default Tables;
