import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Modal, Table } from "react-bootstrap";
import Tables from "../../../../Reuseable/Tables";
import { api } from "../../../../../Services/api";
import { useSelector } from "react-redux";
import { Money } from "../../../../Reuseable/NumberFormatter";
import Notes from "../../../../Reuseable/Notes";
import PulseLoader from "react-spinners/PulseLoader";
import { NoDataFound } from "../../../../Reuseable/NoDataFound";
import { format } from "date-fns";
import Backdrop from "@mui/material/Backdrop";
import { getCurrentTime } from "../../../../Reuseable/CurrentDateTime";
import Iframe from "react-iframe";
import UserDataPreview from "../../Users/UserDataPreview";
import ConfirmSubmission from "../../../../Reuseable/Notes/ConfirmSubmission";
import UIView from "../../../Newrequest/style";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import UIModal from "../../../../Reuseable/UIModal";

const NewRequest = ({ getRequestData, getAllActivityLog, getAllUserData }) => {
  const allRequest = useSelector((state) => state.Tracking.allRequest);
  const allUsers = useSelector((state) => state.Tracking.allUsers);
  const authToken = useSelector((state) => state.Auth.authToken);
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);
  const permissions = useSelector(
    (state) => state.Tracking.permissions.Payments
  );

  const [open, setOpen] = useState(false);
  const [fileView, setFileView] = useState(false);
  const [fileList, setFileList] = useState(false);
  const [openView, setOpeView] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [requestID, setRequestID] = useState("");
  const [show, setShow] = useState(false);
  const [viewuser, setViewUser] = useState([]);
  const [ActivityNotes, setActivityNotes] = useState([]);
  const [deleteLoading, setDeleteloading] = useState(false);
  const [viewData, setViewData] = useState([]);

  const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");

  const NewRequestData = allRequest.filter((val) => {
    if (val.status.toLowerCase() === "pending") {
      return val;
    }
  });

  const [data, setData] = useState(NewRequestData);
  const [fileindex, setFileIndex] = useState("");
  const [loading, setloading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [content, setcontent] = useState("");
  const [alert, setAlert] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [item, setItem] = useState([]);
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    setData(NewRequestData);
  }, [allRequest]);

  const Approverequest = async () => {
    const data = allUsers.filter((val) => {
      if (val.userId === item.userId) {
        return val;
      }
    });
    setloading(true);
    try {
      let obj = {
        emailId: data[0].emailId,
        requestId: item.requestId,
        userId: item.userId,
        status: status,
        approvedBy: brokerdata.userId,
        approvedname: brokerdata.personname,
        loanAmount: item.loanAmount,
        commissionAdvance: item.commissionAdvance,
        notes: ActivityNotes,
        logDateTime: getCurrentTime(),
      };
      let url = "/requests/approval";
      const response = await api.patch(url, obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status === true) {
        getRequestData();
        getAllActivityLog();
        getAllUserData();
        setloading(false);
        setIsModalOpen(false);
        if (status === "Declined") {
          setAlert(true);
          let msg = `Request ID ${item.requestId} for ${data[0].personname} has been declined!`;
          setcontent(msg);
        } else {
          setAlert(false);
          let msg = `Request ID ${item.requestId} for ${data[0].personname} has been approved!`;
          setcontent(msg);
        }
        setConfirmOpen(true);
      }
    } catch (error) {
      setloading(false);
      console.log("Approverequest [error]-->", error);
    }
  };

  const getFile = async (id, file) => {
    setloading(true);
    try {
      let url = `/requests/fileupload/${id}/${file}`;
      const response = await api.get(url, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      });
      if (response.data.status === true) {
        setFileUrl(response.data.URL);
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.log("Approverequest [error]-->", error);
    }
  };

  useEffect(() => {
    getNotes();
  }, [viewuser]);

  const getNotes = async () => {
    await api
      .get("/adminnotes/" + viewuser.userId, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          setNotes(response.data.data);
        }
      })
      .catch(function (error) {
        console.log("getBrokerdata [error]-->", error.response.data);
      });
  };

  const reactivateUser = async (data) => {
    setDeleteloading(true);
    let obj = {
      userId: data.userId,
      emailId: data.emailId,
      logDateTime: getCurrentTime(),
    };
    await api
      .post("/deleteuser/reactivate", obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          setDeleteloading(false);
          getAllUserData();
          getAllActivityLog();
          viewuser.status = "verified";
        }
      })
      .catch(function (error) {
        setDeleteloading(false);
        console.log("getDeletedUsers [error]-->", error.response);
      });
  };

  const columns = [
    "Request Date",
    "Request ID",
    "User",
    "Amount",
    "User Limit",
    "User Score",
    "Outstanding Payments",
    "Repayment Date",
    "Documents",
    "Action",
  ];

  const tbody = (
    <tbody>
      {data
        .sort((a, b) => {
          if (parseInt(a.requestId.slice(2)) > parseInt(b.requestId.slice(2)))
            return -1;
          else return 1;
        })
        .map((item) => {
          let userData = allUsers.find((e) => e.userId == item.requestedBy);
          if (userData) item.user = userData.personname;
          else item.user = item.name;

          if (userData) item.userLimit = userData.brokerLimit;
          else item.userLimit = "-";

          if (userData) item.userScore = userData.score;
          else item.userScore = "-";

          if (userData) item.outstandingpayments = userData.owing;
          else item.outstandingpayments = "-";

          return (
            <tr className="pointer">
              <td
                onClick={() => {
                  setViewData(item);
                  setOpeView(true);
                }}
              >
                {item.dateTime}
              </td>
              <td
                onClick={() => {
                  setViewData(item);
                  setOpeView(true);
                }}
              >
                {item.requestId}
              </td>
              <td
                className="primary fw-bold pointer"
                onClick={() => {
                  setViewUser(userData);
                  setShow(true);
                }}
              >
                {item.user}
              </td>
              <td
                onClick={() => {
                  setViewData(item);
                  setOpeView(true);
                }}
              >
                {Money(item.commissionAdvance)}
              </td>
              <td
                onClick={() => {
                  setViewData(item);
                  setOpeView(true);
                }}
              >
                {Money(item.userLimit)}
              </td>
              <td
                onClick={() => {
                  setViewData(item);
                  setOpeView(true);
                }}
              >
                {item.userScore}
              </td>
              <td
                onClick={() => {
                  setViewData(item);
                  setOpeView(true);
                }}
              >
                {Money(item.outstandingpayments)}
              </td>
              <td
                onClick={() => {
                  setViewData(item);
                  setOpeView(true);
                }}
              >
                {formatDate(item.repaymentDate)}
              </td>
              <td>
                <p
                  className="theme-color2 fw-bold pointer pt-2"
                  onClick={() => {
                    setFileView(true);
                    setFileList(item.fileurls);
                    setRequestID(item.requestId);
                  }}
                >
                  View
                </p>
              </td>
              <td>
                <button
                  disabled={
                    permissions && permissions.approveDeclineRequests
                      ? false
                      : true
                  }
                  className={
                    permissions && permissions.approveDeclineRequests
                      ? "theme-btn mx-3 table-btn"
                      : "disable-btn theme-btn mx-3 table-btn"
                  }
                  onClick={() => {
                    setStatus("Approved");
                    setItem(item);
                    setIsModalOpen(true);
                  }}
                >
                  Approve
                </button>
                <button
                  disabled={
                    permissions && permissions.approveDeclineRequests
                      ? false
                      : true
                  }
                  className={
                    permissions && permissions.approveDeclineRequests
                      ? "cancel-btn table-btn"
                      : "disable-btn cancel-btn table-btn"
                  }
                  onClick={() => {
                    setStatus("Declined");
                    setItem(item);
                    setIsModalOpen(true);
                  }}
                >
                  Decline
                </button>
              </td>
            </tr>
          );
        })}
    </tbody>
  );

  let Body = (
    <>
      {openView && (
        <div className="mt-3 p-1">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className=" mb-3">
              <h5 className="test-wrap primary">ID-{viewData?.requestId}</h5>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
              <b>Full Name of Client:</b>
            </Col>
            <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
              <span className="test-wrap">{viewData?.name}</span>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
              <b>Total Loan Amount:</b>
            </Col>
            <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
              <span className="test-wrap">{Money(viewData?.loanAmount)}</span>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
              <b>Commission Rate:</b>
            </Col>
            <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
              <span className="test-wrap">{viewData?.commissionRate}%</span>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
              <b>Aggregator:</b>
            </Col>
            <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
              <span className="test-wrap">{viewData?.aggregator}</span>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
              <b>Commission Advance Requested:</b>
            </Col>
            <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
              <span className="test-wrap">
                {Money(viewData?.commissionAdvance)}
              </span>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
              <b>Settlement Date:</b>
            </Col>
            <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
              <span className="test-wrap">
                {formatDate(viewData?.settlementDate)}
              </span>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
              <b>Repayment Date:</b>
            </Col>
            <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
              <span className="test-wrap">
                {formatDate(viewData?.repaymentDate)}
              </span>
            </Col>
            <Col xs={6} sm={6} md={6} lg={4} className="mb-3">
              <b>Date your commission will be paid:</b>
            </Col>
            <Col xs={6} sm={6} md={6} lg={2} className="mb-3">
              <span className="test-wrap">
                {formatDate(viewData?.commissionDate)}
              </span>
            </Col>
          </Row>
          <Row>
            <div className="center">
              <button
                disabled={
                  permissions && permissions.approveDeclineRequests
                    ? false
                    : true
                }
                className={
                  permissions && permissions.approveDeclineRequests
                    ? "theme-btn mx-3 px-4 table-btn"
                    : "disable-btn theme-btn mx-3 px-4 table-btn"
                }
                onClick={() => {
                  setStatus("Approved");
                  setItem(item);
                  setIsModalOpen(true);
                  setOpeView(false);
                }}
              >
                Approve
              </button>
              <button
                disabled={
                  permissions && permissions.approveDeclineRequests
                    ? false
                    : true
                }
                className={
                  permissions && permissions.approveDeclineRequests
                    ? "cancel-btn  px-4  table-btn"
                    : "disable-btn  px-4  cancel-btn table-btn"
                }
                onClick={() => {
                  setStatus("Declined");
                  setItem(item);
                  setIsModalOpen(true);
                  setOpeView(false);
                }}
              >
                Decline
              </button>
            </div>
          </Row>
        </div>
      )}
    </>
  );

  const data2 = { columns, tbody };
  return (
    <React.Fragment>
      <Container>
        <div>
          <div className="my-3">New Requests:</div>
          {NewRequestData && NewRequestData.length ? (
            <div>
              <Tables props={data2} striped={true} />
            </div>
          ) : (
            <NoDataFound body="You don’t have any new request at the moment." />
          )}
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={() => {
            setOpen(false);
            setFileView(true);
          }}
        >
          {loading ? (
            <div className="center">
              <PulseLoader size={10} color="#36d7b7" />
            </div>
          ) : (
            <>
              {fileName.type === "png" || fileName.type === "jpg" ? (
                <div className="image-view">
                  <div className="primary file-name">{fileName.fileName}</div>
                  <div className="center p-5">
                    <img
                      className="d-inline-flex"
                      alt="Request File"
                      src={fileUrl}
                    />
                  </div>
                </div>
              ) : fileName.type === "pdf" || fileName.type === "PDF" ? (
                <Iframe
                  width="70%"
                  styles={{ height: "75vh" }}
                  scrolling="no"
                  frameBorder="0"
                  src={fileUrl}
                  title="Documents"
                />
              ) : null}
            </>
          )}
        </Backdrop>

        <Modal
          className="mt-5"
          size="lg"
          centered
          show={fileView}
          onHide={() => {
            setFileView(false);
            setFileList("");
            setFileName("");
            setFileIndex("");
          }}
        >
          <UIView>
            <Table className="p-2">
              <thead>
                <tr>
                  <th className="ms-1">File name</th>
                  <th>Type</th>
                  <th>Size</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="p-2">
                {fileList &&
                  fileList.map((data, index) => (
                    <>
                      <tr className="pointer">
                        <td
                          onClick={() => {
                            setOpen(true);
                            setFileView(false);
                            setFileName(data);
                            getFile(requestID, data.name);
                          }}
                        >
                          {data.fileName}
                        </td>
                        <td
                          onClick={() => {
                            setOpen(true);
                            setFileView(false);
                            setFileName(data);
                            getFile(requestID, data.name);
                          }}
                        >
                          {data.type.toUpperCase()}
                        </td>
                        <td
                          onClick={() => {
                            setOpen(true);
                            setFileView(false);
                            setFileName(data);
                            getFile(requestID, data.name);
                          }}
                        >
                          {data.size}
                        </td>
                        <td
                          className="fw-bold pointer float-end"
                          onClick={() => {
                            setFileName(data);
                            getFile(requestID, data.name);
                            setFileIndex(index);
                          }}
                        >
                          <RemoveRedEyeIcon className="file_view_icon" />
                        </td>
                      </tr>
                      {fileindex === index && (
                        <tr>
                          <td colSpan={4}>
                            <div>
                              {loading ? (
                                <div className="center">
                                  <PulseLoader size={10} color="#36d7b7" />
                                </div>
                              ) : (
                                <>
                                  {fileName.type === "png" ||
                                  fileName.type === "jpg" ? (
                                    <div className="image-view">
                                      <div className="center">
                                        <img
                                          className="d-inline-flex w-100"
                                          src={fileUrl}
                                          alt="Request File"
                                        />
                                      </div>
                                    </div>
                                  ) : fileName.type === "pdf" ||
                                    fileName.type === "PDF" ? (
                                    <Iframe
                                      width="100%"
                                      styles={{ height: "75vh" }}
                                      scrolling="no"
                                      frameBorder="0"
                                      src={fileUrl}
                                      title="Documents"
                                    />
                                  ) : null}
                                </>
                              )}
                            </div>
                          </td>{" "}
                        </tr>
                      )}
                    </>
                  ))}
              </tbody>
            </Table>
          </UIView>
        </Modal>

        <UserDataPreview
          deleteView={viewuser.status === "Deleted" ? true : false}
          show={show}
          setShow={setShow}
          viewuser={viewuser}
          setViewUser={setViewUser}
          notes={notes}
          getNotes={getNotes}
          setNotes={setNotes}
          getAllUserData={getAllUserData}
          reactivateUser={reactivateUser}
          loading={deleteLoading}
          getAllActivityLog={getAllActivityLog}
        />
      </Container>
      <Notes
        open={isModalOpen}
        setOpen={setIsModalOpen}
        setNotes={setActivityNotes}
        notes={ActivityNotes}
        Submit={Approverequest}
        loading={loading}
      />
      <ConfirmSubmission
        open={confirmOpen}
        setOpen={setConfirmOpen}
        content={content}
        alert={alert}
      />
      <UIModal
        show={openView}
        setShow={setOpeView}
        body={Body}
        size={"lg"}
        close={true}
      />
    </React.Fragment>
  );
};

export default NewRequest;
