import React from "react";
import { Route, Routes, useNavigate, Router } from "react-router-dom";
import Overview from "../components/Pages/Admin/Overview";
import Requests from "../components/Pages/Admin/Requests";
import Repayments from "../components/Pages/Admin/Repayments";
import Users from "../components/Pages/Admin/Users";
import Setting from "../components/Pages/Admin/Setting";
import Reports from "../components/Pages/Admin/Reports";
import ActivityLog from "../components/Pages/Admin/ActivityLog";
import { useSelector } from "react-redux";

const Adminroute = ({
  settingsmenu,
  getRequestData,
  getAllActivityLog,
  getAllUserData,
  userLoading,
  getAdministrator,
  overviewLoading,
}) => {
  const menuList = useSelector((state) => state.Auth.menuList);

  return (
    <Routes>
      {menuList && menuList[0].id === 1 && menuList[0].access && (
        <Route
          exact
          path="/"
          element={<Overview overviewLoading={overviewLoading} />}
        />
      )}
      {menuList && menuList[0].id === 1 && menuList[0].access && (
        <Route
          path="overview"
          element={<Overview overviewLoading={overviewLoading} />}
        />
      )}
      {menuList && menuList[2].id === 3 && menuList[2].access && (
        <Route
          path="requests"
          element={
            <Requests
              getRequestData={getRequestData}
              getAllActivityLog={getAllActivityLog}
              getAllUserData={getAllUserData}
            />
          }
        />
      )}
      {menuList && menuList[3].id === 4 && menuList[3].access && (
        <Route
          path="repayments"
          element={
            <Repayments
              getAllUserData={getAllUserData}
              getAllActivityLog={getAllActivityLog}
            />
          }
        />
      )}
      {menuList && menuList[1].id === 2 && menuList[1].access && (
        <Route
          path="users"
          element={
            <Users
              getAllActivityLog={getAllActivityLog}
              getAllUserData={getAllUserData}
            />
          }
        />
      )}
      {menuList && menuList[4].id === 5 && menuList[4].access && (
        <Route path="reports" element={<Reports userLoading={userLoading} />} />
      )}
      {menuList && menuList[6].id === 7 && menuList[6].access && (
        <Route
          path="activitylog"
          element={<ActivityLog getAllActivityLog={getAllActivityLog} />}
        />
      )}
      {menuList && menuList[5].id === 6 && menuList[5].access && (
        <Route
          path="setting"
          element={
            <Setting
              settingsmenu={settingsmenu}
              getAdministrator={getAdministrator}
            />
          }
        />
      )}
    </Routes>
  );
};

export default Adminroute;
