import React, { useState } from "react";
import email from "../../../../assets/landingpage/img/email.png";
import { api } from "../../../../Services/api";
import { Forms } from "../../../Reuseable/Forms";
import { Validators } from "../../../../Utilities/validator";
import { Alert } from "react-bootstrap";

const Contact = () => {
  const [submitted, setSubmitted] = useState("");
  const [changeInForm, setChangeInForm] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const contactUs = (form, valid) => {
    if (valid) {
      const obj = {
        name: form.name,
        emailId: form.email,
        message: form.message,
        phoneNumber: form.phoneNumber
      };
      setLoading(true);
      api
        .post("/contactus", obj)
        .then(function (response) {
          console.log("contactUs  [response]-->", response);
          if (response.data.status === true) {
            setSubmitted(true);
            setLoading(false);
            setErrorMessage('Message sent. The Brokerpay team will be in touch as soon as possible.');
          }
        })
        .catch((error) => {
          console.log("contactUs [error]-->", error.response);
          if (error.response.data.status === false) {
            setErrorMessage(error.response.data.message);
          }
          setLoading(false);
        });
    }
  };

  // function handleCallClick() {
  //   window.location.href = "tel:1800444744";
  // }

  return (
    <>
      <div className="card-box6">
        <div className="card-box6-heading">
          <p className="card-box6-heading-content">Get In Touch</p>
        </div>
        <div className="card-box6-content">
          <p>
            Have questions, or want to find out more about Brokerpay? Leave us a
            message below.
          </p>
        </div>
        <div className="form-box  mb-3">
          <div>
            {errorMessage ? (
              <Alert className="m-2 p-1 text-center" variant="success">
                {errorMessage}
              </Alert>
            ) : (
              <></>
            )}
          </div>
          <div>
            <Forms
              title={""}
              formArr={[
                {
                  label: "Name",
                  name: "name",
                  type: "text",
                  placeholder: "",
                  validators: [
                    {
                      check: Validators.required,
                      message: "This is a required field",
                    },
                    {
                      check: Validators.name,
                      message: "Name must be more than 3 characters",
                    },
                  ],
                  error: false,
                  maxLength: 30,
                  errormsg: "",
                  value: "",
                  show: true,
                },
                {
                  label: "Email",
                  name: "email",
                  type: "email",
                  placeholder: "",
                  maxLength: 30,
                  validators: [
                    {
                      check: Validators.required,
                      message: "This is a required field",
                    },
                    { check: Validators.email, message: "Email is not Valid" },
                  ],
                  error: false,
                  errormsg: "",
                  value: "",
                  show: true,
                },
                {
                  label: "Phone Number",
                  name: "phoneNumber",
                  type: "contactnumber",
                  placeholder: "",
                  maxLength: 10,
                  validators: [
                    {
                      check: Validators.phonenumber,
                      message:
                        "Please enter a valid Australian mobile number beginning with 04.",
                    },
                    {
                      check: Validators.phonenumberLength,
                      message: "Number must be 10 digits long",
                    },
                  ],
                  error: false,
                  errormsg: "",
                  value: "",
                  show: true,
                },
                {
                  label: "Message",
                  name: "message",
                  type: "textareaonly",
                  placeholder: "",
                  validators: [
                    {
                      check: Validators.required,
                      message: "This is a required field",
                    },
                  ],
                  error: false,
                  errormsg: "",
                  value: "",
                  show: true,
                },
              ]}
              submitBtn={"Submit"}
              onSubmit={contactUs}
              rechaptcha={true}
              loading={loading}
              submitted={submitted}
              changeInForm={changeInForm}
              setChangeInForm={setChangeInForm}
            />
          </div>
        </div>
      </div>
      <section id="Contact">
        <div className="card-box7">
          <div className="card-box7-information">
            <div className="card-box7-heading">Contact Information</div>
            <div className="card-box7-heading-content">
              <div className="card-box7-icon">
                {/* <p>
                  <img src={location} srcSet={location} alt="location" />
                </p> */}
                <p>
                  <img src={email} srcSet={email} alt="email" />
                </p>
                {/* <p>
                  <img src={phone} srcSet={phone} alt="phone" />
                </p> */}
              </div>
              <div className="card-box7-content">
                {/* <p className="card-box7-content1">
                  L21, Tower A, The Zenith, 821 Pacific Hwy, Chatswood NSW 2067,
                  Australia
                </p> */}
                <p className="card-box7-content2">
                  <a href="mailto: customercare@brokerpay.com.au">
                    customercare@brokerpay.com.au
                  </a>
                </p>
                {/* <p className="card-box7-content3">
                  <Link onClick={handleCallClick} to="#">
                    1800-444-744
                  </Link>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Contact;
