import React from "react";
import line from "../../../../assets/landingpage/img/Group_38.png";
import image from "../../../../assets/landingpage/img/img.png";
import image1 from "../../../../assets/landingpage/img/Ellipse.png";
import { Button, Card, Col, Row } from "react-bootstrap";

const Howitworks = ({ setShow }) => {
  return (
    <>
      <div className="body-content">
        <div className="contents">
          <p className="content1">
            Access your commission <br /> in hours, not weeks.
          </p>
          <p className="content2 test-wrap">
            Brokerpay provides advance settlement <br /> funding for Australian
            mortgage brokers.
          </p>
          <p>
            <button className="btn-login link1">
              <div onClick={() => setShow(true)}>Apply Now</div>
            </button>
            <Button type="text" href="#howitworks" className="link">
              <b>How it works &#62;</b>
            </Button>
          </p>
          <div className="shape">
            <img className="line" src={line} srcSet={line} alt="line" />
          </div>
        </div>
        <div className="content-image">
          <img
            className="image image-HIW "
            src={image}
            srcSet={image}
            alt="person"
          />
          <img
            className="image1 image1-HIW"
            src={image}
            srcSet={image1}
            alt="background"
          />
        </div>
      </div>

      <div className="contents11">
        <p className="content3 con1">Brokerpay is industry specific</p>
        <p className="content4 con2">
          Meaning we understand the way mortgage brokers work, and the way you
          get paid. Delayed settlements can mean an irregular cash flow for your
          business - unlock your commission the same day settlement is confirmed
          with <b>Brokerpay</b>.
        </p>
      </div>

      <div className="contents11">
        <p className="content3">Why Use Brokerpay?</p>
      </div>

      <div className="card-content px-5">
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Card className="card-box1 card-content-box">
              <p className="card-heading1">Interest-free</p>
              <p className="card-content1 box-text">
                Brokerpay doesn’t charge interest on your commission advances.
                Brokerpay takes a small percentage of each requested advance as
                a fee.
              </p>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Card className="card-box2 card-content-box">
              <p className="card-heading2">Hassle-free application process</p>
              <p className="card-content2 box-text">
                All you need to provide is the proof of settlement.
              </p>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Card className="card-box3 row2-cardbox card-content-box">
              <p className="card-heading3">Quick funding</p>
              <p className="card-content3 box-text">
                Get access to your commission within as little as 24 hours after
                settlement.
              </p>
            </Card>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Card
              id="howitworks"
              className="card-box4 row2-cardbox card-content-box"
            >
              <p className="card-heading4">Easy account set up</p>
              <p className="card-content4 box-text">
                Set up your account within minutes and get paid the next day.
              </p>
            </Card>
          </Col>
        </Row>
      </div>
      {/* <SELECTION_ALL /> */}
    </>
  );
};
export default Howitworks;
