import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { AdminSidebar } from "../../../../data/menu";
import { Filterbox } from "./style";
import CloseIcon from '@mui/icons-material/Close';
import LogoutButton from "../../../Reuseable/UIButton/LogoutButton";

const togleMenu2 = () => {
  $(".backtoMainSideBar").toggleClass("backtoMainSideBarActive");
}

const togleMenu3 = () => {
  $(".backtoMainSideBar").removeClass("backtoMainSideBarActive");
}

const CreateItem = (props) => {

  let itemJsx;

  if (props.item.type == null) {
    itemJsx = (
      <NavLink className={"nav-link" + (props.darkmode === true ? " darkmode " : "")} to={props.item.path}>
        {/* <FontAwesomeIcon icon={props.item.leftIcon} /> */}
        <img className="me-2" src={props.item.leftIcon} />
        <span className="dsktopView">{props.item.name}</span>
      </NavLink>
    );
  }
  else if (props.item.type === "collapse") {
    itemJsx = (
      <>
        <a className={"nav-link expandNav" + (props.darkmode === true ? " darkmode " : "")}
          data-bs-toggle="collapse" href={"#" + props.item.name} aria-expanded="false" >
          <img className="me-2" width="20" height="20" src={props.item.leftIcon} alt="left-icons" />
          <span className="dsktopView">{props.item.name}</span>
          {/* <span className="navItemArrow"><FontAwesomeIcon icon={props.item.rightIcon} /></span> */}
        </a>
        <div className="collapse dropmenuitems" id={props.item.name}>
          <ul>{props.item.child.map((item, i) => {
            return (
              <li className="nav-item" key={i}>
                {item.type === null ?
                  <NavLink className={"nav-link linkNavItem" + (props.darkmode === true ? " darkmode " : "")} to={item.path}>
                    <span>{item.name}</span>
                  </NavLink>
                  :
                  <>
                    <a className={"nav-link expandNav" + (props.darkmode === true ? " darkmode " : "")}
                      data-bs-toggle="collapse" href={"#" + item.name} aria-expanded="false" >
                      <span className="dsktopView">{item.name}</span>
                      {/* <span className="navItemArrow"><FontAwesomeIcon icon={props.item.rightIcon} /></span> */}
                    </a>
                    <div className="collapse dropmenuitems" id={item.name}>
                      <ul>{item.child.map((item, i) => {
                        return (
                          <li className="nav-item" key={i}>
                            <NavLink className={"nav-link linkNavItem" + (props.darkmode === true ? " darkmode " : "")} to={item.path}>
                              <span>{item.name}</span>
                            </NavLink>
                          </li>
                        );
                      })
                      }
                      </ul>
                    </div>
                  </>
                }
              </li>
            );
          })}
          </ul>
        </div>
      </>
    );
  }
  else {
    itemJsx = (
      <>
        <a className={"nav-link" + (props.darkmode === true ? " darkmode " : "")} href="#" onClick={togleMenu2} >
          <img className="me-2" width="20" height="20" src={props.item.leftIcon} alt="left-icons" />
          <span className="dsktopView">{props.item.name}</span>
          <span className="listArowIcon">
            {/* <FontAwesomeIcon icon={props.item.rightIcon} style={{ float: "right", marginTop: "2px", marginRight: "10px" }} /> */}
          </span>
        </a>
        <div className={"backtoMainSideBar" + (props.darkmode === true ? " darkmode " : "")}>
          <div className="backLinkMain">
            <a className={"backlink" + (props.darkmode === true ? " darkmode " : "")} href="#" onClick={togleMenu3}>
              {/* <FontAwesomeIcon icon={faArrowCircleLeft} /> */}
              <span className="backTxt">Back</span>
            </a>
          </div>
          <div className={"sidebar sidebarInerLinks" + (props.darkmode === true ? " darkmode " : "")}>
            <div className="d-flex flex-column">
              <div className="nav-menu">
                <ul className="nav flex-column">
                  {props.item.child.map((item, i) => {
                    return <CreateItem item={item} key={i} darkmode={props.darkmode} />
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return <li className="nav-item">{itemJsx}</li>;
}

const SideMenu = () => {
  const darkmode = useSelector((state) => state.Layout.darkmode);
  const user = useSelector((state) => state.Auth.user);
  return (
    <>
    </>
  );
}

const SideMenuBar = ({
  active,
  handleToggle,
  settingsmenu,
  setSettingsmenu,
  showResposiveSideBar,
  menuList,
  setActiveNav,
  activeNav
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const darkmode = useSelector((state) => state.Layout.darkmode);

  useEffect(() => {
    setFilteredData(<SideMenu sideBar={AdminSidebar} darkmode={darkmode} />);
  }, []);

  const searchfilter = (query, sideBar, dataArray = []) => {
    sideBar.forEach((item) => {
      if (item.name.toLowerCase().includes(query.toLowerCase())) {
        dataArray.push(item);
      } else if (item.child) {
        searchfilter(query, item.child, dataArray);
      }
    });
    setFilteredData(<SideMenu sideBar={dataArray} darkmode={darkmode} />);
  };


  return (
    <>
      {
        showResposiveSideBar ?
          <Offcanvas
            show={active}
            onHide={handleToggle}
            style={{
              width: "200px"
            }}>

            <Offcanvas.Body className="p-3">
              <div style={{ width: "95%", margin: "auto" }}>
                <Filterbox>
                  <div className="filter-box">
                    {menuList ?
                      <>
                        {menuList?.map((item) => {
                          return (
                            <ul className="navbar-nav">
                              <li className="nav-item mx-2" onClick={() => setActiveNav(item.path)}>
                                <NavLink
                                  to={item.path}
                                  className={activeNav === item.path ? "primary nav-link" : 'nav-link'}
                                >
                                  <div className="logo-div">
                                    <img
                                      className={activeNav === item.path ? "head-icon1" : 'active'}
                                      alt={item.name}
                                      src={item.icon}
                                    />
                                    <img
                                      className={activeNav === item.path ? "active" : 'head-icon1'}
                                      alt={item.name}
                                      src={item.activeicon}
                                    />
                                  </div>
                                  {item.name}
                                </NavLink>
                                {
                                  item.path === 'setting' && activeNav === 'settings' ?
                                    <div className="d-block">
                                      {/* <div
                                        onClick={() => setSettingsmenu('team')}
                                        className={
                                          settingsmenu === 'team' ?
                                            "primary nav-link ms-4 pointer line"
                                            :
                                            'nav-link ms-4 pointer'
                                        }
                                      >
                                        Manage team
                                      </div> */}
                                      <div
                                        onClick={() => setSettingsmenu('role')}
                                        className={
                                          settingsmenu === 'role' ?
                                            "primary nav-link ms-4 pointer line"
                                            :
                                            'nav-link ms-4 pointer'
                                        }
                                      >
                                        Manage roles and permissions
                                      </div>
                                    </div>
                                    :
                                    null
                                }
                              </li>
                            </ul>
                          )
                        })}
                      </>
                      : null}
                    <div
                      className="mx-1"
                    >
                      <LogoutButton />
                    </div>
                  </div>
                </Filterbox>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          :

          <Filterbox>
            <div className='sidebar-active position-relative'>
              <div>
                <div className="filter-box">
                  {menuList ?
                    <>
                      {menuList?.map((item, i) => {
                        return (
                          <>
                            {item.access &&
                              <ul className="navbar-nav">
                                <li className="nav-item mx-2" onClick={() => setActiveNav(item.path)}>
                                  <NavLink
                                    className={activeNav === item.path ? "primary nav-link" : 'nav-link'}
                                    to={item.path}
                                  >
                                    <div className="logo-div">
                                      <img
                                        className={activeNav === item.path ? "head-icon1" : 'active'}
                                        alt={item.name}
                                        src={item.icon}
                                      />
                                      <img
                                        className={activeNav === item.path ? "active" : 'head-icon1'}
                                        alt={item.name}
                                        src={item.activeicon}
                                      />
                                    </div>
                                    {item.name}
                                  </NavLink>
                                  {
                                    item.path === 'setting' && activeNav === 'setting' ?
                                      <div className="d-block">
                                        {/* <NavLink
                                      onClick={() => setSettingsmenu('team')}
                                      to={item.path}
                                      className={
                                        settingsmenu === 'team' ?
                                          "primary nav-link ms-4 pointer line"
                                          :
                                          'nav-link ms-4 pointer'
                                      }
                                    >
                                      Manage team
                                    </NavLink> */}
                                        <NavLink
                                          to={item.path}
                                          onClick={() => setSettingsmenu('role')}
                                          className={
                                            settingsmenu === 'role' ?
                                              "primary nav-link ms-4 pointer line"
                                              :
                                              'nav-link ms-4 pointer'
                                          }
                                        >
                                          Manage roles and permissions
                                        </NavLink>
                                      </div>
                                      :
                                      null
                                  }
                                </li>
                              </ul>
                            }
                          </>
                        )
                      })}
                    </>
                    : null}
                </div>
              </div>
            </div>
          </Filterbox>
      }

    </>
  );
};

export default SideMenuBar;