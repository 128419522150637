import Styled from 'styled-components';

const UIModalView = Styled.div`

.title {
    color:#0094FF !important;
}
.notes-title{
    font-weight: 900;
    font-size: 16px;
}

.notes-textfeild{
    border: 1px solid #DDDDDD !important;
    border-radius: 5px !important;
}
.card-box6{
    padding: 30px !important;
}
.form-control.is-invalid, .was-validated .form-control:invalid {
   background-image: none !important; 
}
.was-validated .form-control:valid, .form-control.is-valid {
    background-image: none !important; 
}
.close-btn{
    color: #00C6FF !important;
}
.header{
    display: block;
    width: 100%;
}
.close-btn{
    float: right;
    margin-bottom: 10px;
    cursor: pointer;
}
.body{
    padding: 15px;
}
.card-box6-heading,
.card-box6-content,
.form-box  {
    margin-left: 0 ! important;
}
.card-box7 {
    margin: 0px !important;
}
.form-box {
    width: auto !important;
}

`;

export default UIModalView;