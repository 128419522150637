import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import logoutB from "./logoutB.svg";
import 'bootstrap/dist/css/bootstrap.css';
import './style.css'
import { userLogout } from "../../../../redux/Auth/actionCreator";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetTracking } from "../../../../redux/Tracking/actionCreator";



const LogoutButton = () => {
    const [show, setShow] = useState(false);

    const dispatch = useDispatch();


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let navigate = useNavigate();
    const logoutUser = () => {
        let result = {
            isLoggedIn: false,
            authToken: "",
            user: ""
        }
        dispatch(userLogout(result));
        dispatch(resetTracking());
        navigate("/login");
    };

    return (
        <>
            <Button
                style={{
                    color: '#FF1717',
                    fontWeight: '600',
                    padding:'0'
                }}
                variant=''
                onClick={handleShow}
            >
                <img
                    src={logoutB}
                    height={17}
                    width={27}
                    alt="Logout"
                    className='me-2'
                >
                </img>
                Logout
            </Button>

            <Modal
                className='mt-5'
                show={show}
                onHide={handleClose}
                size="sm"
            >
                <Modal.Header
                    closeButton
                    className="border-0 px-4"
                >
                    <Modal.Title
                        className="ms-auto"
                    >
                        Logout User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className="m-auto"
                >Are you sure want to Logout?
                </Modal.Body>
                <Modal.Footer className="border-0 m-auto">
                    <Button
                        className="mx-3 theme-btn2"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <Button
                        className="mx-3 theme-btn"
                        onClick={logoutUser}
                    >
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default LogoutButton; 