import Styled from "styled-components";

const RequestWrap = Styled.div`

 padding: 10px;
 font-size: 18px;
 font-weight: 600;

 .back-link{
   color: black;
 }

 .tab-row{
   display: block;
   width: 100%;

   .nav-link{
      font-weight: 600;
      color: black !important;
      background: white;
      border: none;
      width: 204px;
   }
   .nav{
   .active{ 
      background: #E2F2FF 0% 0% no-repeat padding-box;
      border-radius: 5px 0px 0px 5px;
   }
  }
  }

 .tab-group{
   float: right;
 }
tr{
 td{
  font-size: 15px  !important;
   padding-top: 15px !important;
   padding-bottom: 15px !important;
   white-space: nowrap; 
 }
 th{
  font-weight: 700 !important;
  font-size: 16px  !important;
  white-space: nowrap; 
  padding: 20px;
 }
}
.table-btn{
   width: 100px;
   height: 30px;
}
.sorticon{
  font-size: 17px;
}
.download-btn{
  float: right;
  margin-top: 5px;
}
@media (max-width:450px){
  font-size: 15px;
}
@media  (max-width:600px){
  tr{
    td{
  font-size: 13px !important;
  }
  th{
    font-size: 14px !important;
  }
}
}
`;

export default RequestWrap;
