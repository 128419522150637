import React, { useState, useRef } from "react";
import { Button, Form, Col, Container, Row, Modal } from "react-bootstrap";
import { api } from "../../../../../../Services/api";
import Snackbar from "@mui/material/Snackbar";
import Alerts from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { getCurrentTime } from "../../../../../Reuseable/CurrentDateTime";

const UserProfile = ({
  viewuser,
  setContractName,
  contract,
  setEditValue,
  setEditNode,
  editUserData,
  handleEditChange,
  editValue,
  numFormat,
  edit,
  editnode,
  Onlytext,
  getFile,
  setOpen,
  setShow,
  getAllUserData,
  authToken,
  getAllActivityLog,
}) => {
  const [errorOpen, setErrorOpen] = useState(false);

  const uploadRef = useRef();

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const editUserAccountData = async (node) => {
    setEditNode("");

    let obj = {
      userId: viewuser.userId,
      emailId: viewuser.emailId,
      name: "licenceNumber",
      logDateTime: getCurrentTime(),
      value:
        node === "CRN"
          ? {
              CRN: editValue,
              ACL: "",
            }
          : {
              CRN: "",
              ACL: editValue,
            },
    };
    await api
      .patch("/brokerpayuserauth/userlists", obj, {
        headers: {
          authorizationtoken: `${authToken}`,
        },
      })
      .then(function (response) {
        if (response.data.status === true) {
          viewuser.licenceNumber =
            node === "CRN"
              ? {
                  'CRN': editValue,
                  'ACL': "",
                }
              : {
                  'CRN': "",
                  'ACL': editValue,
                };
          getAllActivityLog();
          getAllUserData();
        }
      })
      .catch(function (error) {
        console.log("editUserData [error]-->", error.response);
      });
  };

  let vertical = "bottom";
  let horizontal = "center";

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => setErrorOpen(false)}
      >
        <Alerts
          onClose={() => setErrorOpen(false)}
          className="center"
          severity="error"
          sx={{ width: "100%" }}
        >
          Please upload only PDF files!
        </Alerts>
      </Snackbar>
      <Container className="message-box">
        <div>
          <Row>
            <Col lg={5} md={12}>
              <b>Business Name: </b>
              <div className="d-inline-flex">
                {edit && editnode === "businessName" ? (
                  <div className="d-flex mx-3">
                    <Form.Control
                      className="limit-input limit-form1 px-2"
                      required
                      maxLength={30}
                      value={editValue}
                      onChange={handleEditChange}
                    />
                    <Button
                      className="limit-input primary"
                      onClick={() => editUserData()}
                    >
                      <DoneIcon fontSize="small" />
                    </Button>
                    <Button
                      className="limit-input primary"
                      onClick={() => {
                        setEditNode("");
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Button>
                  </div>
                ) : (
                  <div className="">
                    {viewuser.businessName}
                    {edit ? (
                      <EditIcon
                        onClick={() => {
                          setEditNode("businessName");
                          setEditValue(viewuser.businessName);
                        }}
                        className="edit primary"
                      />
                    ) : null}
                  </div>
                )}
              </div>
            </Col>
            <Col lg={7} md={12}>
              <b> Linked Account: </b>
              {viewuser.accountDetails?.Accountnumber}
            </Col>
            <Col lg={5} md={12}>
              <b> CRN/ACL: </b>
              {viewuser.licenceNumber.ACL.length ? (
                <>
                  <div className="d-inline-flex">
                    {edit && editnode === "licenceNumber" ? (
                      <div className="d-flex mx-3">
                        <Form.Control
                          className="limit-input limit-form1 px-2"
                          required
                          maxLength={20}
                          value={editValue}
                          onChange={handleEditChange}
                          onKeyDown={(e) => numFormat(e)}
                        />
                        <Button
                          className="limit-input primary"
                          onClick={() => editUserAccountData("ACL")}
                        >
                          <DoneIcon fontSize="small" />
                        </Button>
                        <Button
                          className="limit-input primary"
                          onClick={() => {
                            setEditNode("");
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </Button>
                      </div>
                    ) : (
                      <>
                        {viewuser.licenceNumber.ACL}
                        {edit ? (
                          <EditIcon
                            onClick={() => {
                              setEditNode("licenceNumber");
                              setEditValue(viewuser.licenceNumber.ACL);
                            }}
                            className="edit primary"
                          />
                        ) : null}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="d-inline-flex">
                    {edit && editnode === "licenceNumber" ? (
                      <div className="d-flex mx-3">
                        <Form.Control
                          className="limit-input limit-form1 px-2"
                          required
                          maxLength={20}
                          value={editValue}
                          onChange={handleEditChange}
                          onKeyDown={(e) => numFormat(e)}
                        />
                        <Button
                          className="limit-input primary"
                          onClick={() => editUserAccountData("CRN")}
                        >
                          <DoneIcon fontSize="small" />
                        </Button>
                        <Button
                          className="limit-input primary"
                          onClick={() => {
                            setEditNode("");
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </Button>
                      </div>
                    ) : (
                      <>
                        {viewuser.licenceNumber.CRN}
                        {edit ? (
                          <EditIcon
                            onClick={() => {
                              setEditNode("licenceNumber");
                              setEditValue(viewuser.licenceNumber.CRN);
                            }}
                            className="edit primary"
                          />
                        ) : null}
                      </>
                    )}
                  </div>
                </>
              )}
            </Col>
            <Col lg={7} md={12}>
              <b>2FA Enable: </b>
              <div className="d-inline-flex">
                {edit && editnode === "faEnabled" ? (
                  <div className="d-flex mx-3">
                    <select
                      className="limit-input limit-form1 p-2 mb-2"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                    >
                      <option value={"Yes"}>Yes</option>
                      <option value={"No"}>No</option>
                    </select>
                    <Button
                      className="limit-input primary"
                      onClick={() => editUserData()}
                    >
                      <DoneIcon fontSize="small" />
                    </Button>
                    <Button
                      className="limit-input primary"
                      onClick={() => {
                        setEditNode("");
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Button>
                  </div>
                ) : (
                  <>
                    {viewuser?.faEnabled}
                    {edit ? (
                      <EditIcon
                        onClick={() => {
                          setEditNode("faEnabled");
                          setEditValue(viewuser.faEnabled);
                        }}
                        className="edit primary"
                      />
                    ) : null}
                  </>
                )}
              </div>
            </Col>
            <Col lg={5} md={12}>
              <b> ABN: </b>
              <div className="d-inline-flex">
                {edit && editnode === "ABN" ? (
                  <div className="d-flex mx-3">
                    <Form.Control
                      className="limit-input limit-form1 px-2"
                      required
                      maxLength={20}
                      value={editValue}
                      onChange={handleEditChange}
                      onKeyDown={(e) => numFormat(e)}
                    />
                    <Button
                      className="limit-input primary"
                      onClick={() => editUserData()}
                    >
                      <DoneIcon fontSize="small" />
                    </Button>
                    <Button
                      className="limit-input primary"
                      onClick={() => {
                        setEditNode("");
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Button>
                  </div>
                ) : (
                  <>
                    {viewuser.ABN}
                    {edit ? (
                      <EditIcon
                        onClick={() => {
                          setEditNode("ABN");
                          setEditValue(viewuser.ABN);
                        }}
                        className="edit primary"
                      />
                    ) : null}
                  </>
                )}
              </div>
            </Col>
            {/* <Col lg={7} md={12}>
              <b> Credit Score: </b>
              <div className="d-inline-flex">
                {edit && editnode === "creditScore" ? (
                  <div className="d-flex mx-3">
                    <Form.Control
                      className="limit-input limit-form1 px-2"
                      required
                      maxLength={20}
                      value={editValue}
                      onChange={handleEditChange}
                      onKeyDown={(e) => numFormat(e)}
                    />
                    <Button
                      className="limit-input primary"
                      onClick={() => editUserData()}
                    >
                      <DoneIcon fontSize="small" />
                    </Button>
                    <Button
                      className="limit-input primary"
                      onClick={() => {
                        setEditNode("");
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Button>
                  </div>
                ) : (
                  <>
                    {viewuser.creditScore}
                    {edit ? (
                      <EditIcon
                        onClick={() => {
                          setEditNode("creditScore");
                          setEditValue(viewuser.creditScore);
                        }}
                        className="edit primary"
                      />
                    ) : null}
                  </>
                )}
              </div>
            </Col> */}
            <Col lg={5} md={12}>
              <b> DOB: </b>
              <div className="d-inline-flex">
                {edit && editnode === "DOB" ? (
                  <div className="d-flex mx-3">
                    <input
                      type="date"
                      className="limit-input limit-form1 px-2"
                      value={editValue}
                      onChange={handleEditChange}
                    />
                    <Button
                      className="limit-input primary"
                      onClick={() => editUserData()}
                    >
                      <DoneIcon fontSize="small" />
                    </Button>
                    <Button
                      className="limit-input primary"
                      onClick={() => {
                        setEditNode("");
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Button>
                  </div>
                ) : (
                  <>
                    {viewuser.DOB === "00/00/0000" || viewuser.DOB == ""
                      ? "-"
                      : viewuser.DOB}
                    {edit ? (
                      <EditIcon
                        onClick={() => {
                          setEditNode("DOB");
                          setEditValue(viewuser.DOB);
                        }}
                        className="edit primary"
                      />
                    ) : null}
                  </>
                )}
              </div>
            </Col>
            <Col lg={7} md={12}>
              <b> Aggregator: </b>
              <div className="d-inline-flex">
                {edit && editnode === "aggregator" ? (
                  <div className="d-flex mx-3">
                    <Form.Control
                      className="limit-input limit-form1 px-2"
                      required
                      maxLength={100}
                      value={editValue}
                      onChange={handleEditChange}
                    />
                    <Button
                      className="limit-input primary"
                      onClick={() => editUserData()}
                    >
                      <DoneIcon fontSize="small" />
                    </Button>
                    <Button
                      className="limit-input primary"
                      onClick={() => {
                        setEditNode("");
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Button>
                  </div>
                ) : (
                  <>
                    {viewuser.aggregator}
                    {edit ? (
                      <EditIcon
                        onClick={() => {
                          setEditNode("aggregator");
                          setEditValue(viewuser.aggregator);
                        }}
                        className="edit primary"
                      />
                    ) : null}
                  </>
                )}
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <div className="d-inline-flex">
              {" "}
              <b className="me-1"> Address:</b>
              {edit && editnode === "address" ? (
                <div className="d-flex mx-3">
                  <Form.Control
                    className="limit-input limit-form1 px-2"
                    required
                    maxLength={100}
                    value={editValue}
                    onChange={handleEditChange}
                  />
                  <Button
                    className="limit-input primary"
                    onClick={() => editUserData()}
                  >
                    <DoneIcon fontSize="small" />
                  </Button>
                  <Button
                    className="limit-input primary"
                    onClick={() => {
                      setEditNode("");
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </Button>
                </div>
              ) : (
                <>
                  {viewuser.address}

                  {edit ? (
                    <EditIcon
                      onClick={() => {
                        setEditNode("address");
                        setEditValue(viewuser.address);
                      }}
                      className="edit primary"
                    />
                  ) : null}
                </>
              )}
            </div>
          </Row>
          <br />
          <Row>
            <div className="d-inline-flex">
              {viewuser.contractSigned ? <b> Contract:</b> : null}
              {
                viewuser.contractSigned ? (
                  <div
                    onClick={() => {
                      setOpen(true);
                      setShow(false);
                    }}
                    className="text-btn ms-2"
                  >
                    {viewuser.personname + " " + "contract.pdf"}
                  </div>
                ) : (
                  <></>
                )
                //   <div
                //   className="text-btn ms-2"
                //   onClick={() => uploadRef.current.click()}
                //  >
                //    Upload Contract
                //   <input
                //    className="d-none"
                //     type="file"
                //     ref={uploadRef}
                //    onChange={(e) => {
                //     uploadFile();
                //   }}
                //   />
                //  </div>
              }
            </div>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default UserProfile;
