import { ActionTypes } from "./actions";

const intialState = {
  selectedId: '',
  showAlert: false,
  alertMessage: '',
  brokerdata: [],
  allRequest: [],
  allAdministrators: [],
  allActivityLog: [],
  allRepayments: [],
  allOverview: [],
  bankAccount: [],
  permissions: {
    Administrative: [],
    Account: [],
    Payments: [],
  },
  allUsers: [],
};

export const TrackingReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.RESET_TRACKING:
      return {
        brokerdata: [],
        allRequest: [],
        allActivityLog: [],
        allRepayments: [],
        allOverview: [],
        bankAccount: [],
        permissions: {}
      };
    case ActionTypes.SET_SELECTED_ID:
      return {
        ...state,
        selectedId: payload
      };
    case ActionTypes.SET_ALL_REQUEST:
      return {
        ...state,
        allRequest: payload
      };
      case ActionTypes.SET_BANK_DETAILS:
        return {
          ...state,
          bankAccount: payload
        };
  
    case ActionTypes.SET_SHOW_ALERT:
      return {
        ...state,
        showAlert: payload
      };
    case ActionTypes.SET_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: payload
      };
    case ActionTypes.SET_BROKER_DATA:
      return {
        ...state,
        brokerdata: payload
      };
    case ActionTypes.SET_All_USER_LIST:
      return {
        ...state,
        allUsers: payload
      };
    case ActionTypes.SET_Activity_LOG_LIST:
      return {
        ...state,
        allActivityLog: payload
      };
    case ActionTypes.SET_REPAYMENTS:
      return {
        ...state,
        allRepayments: payload
      };
    case ActionTypes.SET_OVERVIEW:
      return {
        ...state,
        allOverview: payload
      };
    case ActionTypes.SET_ALL_ADMINISTRATORS:
      return {
        ...state,
        allAdministrators: payload
      };
    case ActionTypes.SET_PERMISSIONS:
      return {
        ...state,
        permissions: payload
      };
    default:
      return state;
  }
};
