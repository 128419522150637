import React, { useState, useRef } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Tables from "../../../../Reuseable/Tables";
import Searchbox from "../../../../Reuseable/Searchbox";
import moment from "moment";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import Sort from "../../../../Reuseable/SortIcon";
import "antd/dist/antd.css";
import { Money } from "../../../../Reuseable/NumberFormatter";
import { useSelector } from "react-redux";
import { NoDataFound } from "../../../../Reuseable/NoDataFound";
import { saveAs } from "file-saver";
import Papa from "papaparse";

const PaymentReport = ({ paymentData }) => {
  const allUsers = useSelector((state) => state.Tracking.allUsers);

  const tableRef = useRef(null);

  const [searchterm, setSearchterm] = useState("");
  const [sortcolumn, setSortcolumn] = useState("");
  const [paymentDate, setRaymentDate] = useState(false);
  const [requestDate, setRequestDate] = useState(false);
  const [UserDate, setUserDate] = useState(false);

  const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");

  let Data = paymentData
    .map((data) => {
      let userData = allUsers.find((e) => e.userId == data.requestedBy);
      if (userData) data.user = userData.personname;
      else data.user = "-";

      data.paymentStatus = "Upcoming";

      return data;
    })
    .sort((item1, item2) => {
      if (sortcolumn === "username") {
        if (UserDate) {
          if (item1.user < item2.user) return -1;
          else return 1;
        } else {
          if (item1.user < item2.user) return 1;
          else return -1;
        }
      } else if (sortcolumn === "requestdate") {
        if (requestDate) {
          if (
            new moment(item1.requestDate).format("YYYYMMDD") <
            new moment(item2.requestDate).format("YYYYMMDD")
          )
            return -1;
          else return 1;
        } else {
          if (
            new moment(item2.requestDate).format("YYYYMMDD") >
            new moment(item1.requestDate).format("YYYYMMDD")
          )
            return 1;
          else return -1;
        }
      } else if (sortcolumn === "paymentdate") {
        if (paymentDate) {
          if (
            new moment(item1.paymentDate).format("YYYYMMDD") <
            new moment(item2.paymentDate).format("YYYYMMDD")
          )
            return -1;
          else return 1;
        } else {
          if (
            new moment(item2.paymentDate).format("YYYYMMDD") >
            new moment(item1.paymentDate).format("YYYYMMDD")
          )
            return 1;
          else return -1;
        }
      }
    })
    .filter((val) => {
      if (searchterm === "") {
        return val;
      } else if (
        val.user.toLowerCase().includes(searchterm.toLowerCase()) ||
        val.requestId.toLowerCase().includes(searchterm.toLowerCase())
      ) {
        return val;
      }
    });

  const head = (
    <thead>
      <tr>
        <th
          className="pointer"
          onClick={() => {
            setUserDate(!UserDate);
            setSortcolumn("username");
          }}
        >
          User Name
          <Sort />
        </th>
        <th>user ID</th>
        <th>Request ID</th>
        <th>Amount</th>
        <th
          className="pointer"
          onClick={() => {
            setRequestDate(!requestDate);
            setSortcolumn("requestdate");
          }}
        >
          Request Date
          <Sort />
        </th>
        <th className="pointer">Request Status</th>
        <th
          className="pointer"
          onClick={() => {
            setRaymentDate(!paymentDate);
            setSortcolumn("paymentdate");
          }}
        >
          Payment Date
          <Sort />
        </th>
        <th className="pointer">Payment Status</th>
      </tr>
    </thead>
  );

  const tbody = (
    <tbody>
      {Data.map((row) => (
        <tr>
          <td>{row.user}</td>
          <td>{row.userId}</td>
          <td>{row.requestId}</td>
          <td>{Money(row.commissionAdvance)}</td>
          <td>{formatDate(row.requestDate)}</td>
          <td
            className={
              row.repaymentStatus === "Declined"
                ? "declined"
                : row.repaymentStatus === "Approved"
                ? "approved"
                : "pending"
            }
          >
            {row.repaymentStatus}
          </td>
          <td>{formatDate(row.paymentDate)}</td>
          <td>{row.paymentStatus}</td>
        </tr>
      ))}
    </tbody>
  );

  const convertToCSV = () => {
    if (!tableRef.current) return;

    const tableData = [];
    const headers = [];
    const rows = tableRef.current.querySelectorAll("tbody tr");

    tableRef.current.querySelectorAll("thead th").forEach((th) => {
      headers.push(th.innerText);
    });

    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.innerText);
      });
      tableData.push(rowData);
    });

    const csvData = [headers, ...tableData];
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    saveAs(blob, `Payment-report.csv`);
  };

  const data2 = { head, tbody };
  return (
    <React.Fragment>
      <Container>
        <div>
          <div className="my-4">Payment reports</div>
          <Row>
            <Col sm={6} md={6} lg={4} xl={4}>
              <Searchbox
                placeholder="Enter name, request ID"
                handleSearch={setSearchterm}
              />
            </Col>
            {/* <Col sm={4} md={3} lg={3} xl={2} >
              <DatePicker
                className="date-picker"
                placeholder="Date range"
                locale={date}
                onChange={(date) => setDate(moment(date).format("YYYY-MM-DD"))}
                suffixIcon={
                  <img
                    src={Calender}
                  />
                }
              />
            </Col> */}
            {paymentData && paymentData.length  ?
              <Col sm={12} md={6} lg={9} xl={8}>
                <Button
                  className="theme-btn download-btn"
                  onClick={convertToCSV}
                >
                  Download
                </Button>
              </Col>
            : null}
          </Row>
          <div className="my-3">
            {paymentData && paymentData.length ? (
              <Tables tableRef={tableRef} props={data2} striped={true} />
            ) : (
              <NoDataFound
                key="info"
                variant="info"
                body="You don’t have any payment reports at the moment."
              />
            )}
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default PaymentReport;
