import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import UICardView from "../../style";
import PaymentForm from "../../../../Reuseable/CardPaymentForm/PaymentForm";
import AccountPaymentMethod from "../../../../Reuseable/AccountPaymentLink";
import { useSelector } from "react-redux";
import { api } from "../../../../../Services/api";

const BankaccountDetails = ({
  show,
  setShow,
  postAccountData,
  onBoarding,
  getBankDetails
}) => {
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);

  const [clientSecret, setClientSecret] = useState("");
  const [clientSecretAccount, setClientSecretAccount] = useState("");
  const [page, setPage] = useState("page_1");

  useEffect(() => {
    loadClientSecret();
  }, []);

  const loadClientSecret = async () => {
    try {
      let url = "/userbankdetails/link";
      const response = await api.get(url);
      if (response.data.status === true) {
        setClientSecret(response.data.client_secretForCard);
        setClientSecretAccount(response.data.client_secretForAccount);
      }
    } catch (error) {
      console.log("loadClientSecret [error]-->", error);
    }
  };

  return (
    <>
      <Modal size="md" show={show} centered onHide={() => setShow(false)}>
        <UICardView>
          <div className="body">
            {page === "page_1" ? (
              <AccountPaymentMethod
                clientSecretAccount={clientSecretAccount}
                setPage={setPage}
                onBoarding={true}
              />
            ) : (
              <PaymentForm
                clientSecret={clientSecret}
                setShow={setShow}
                postAccountData={postAccountData}
                getBankDetails={getBankDetails}
                onBoarding={onBoarding}
              />
            )}
          </div>
        </UICardView>
      </Modal>
    </>
  );
};
export default BankaccountDetails;
