import { ActionTypes } from "./actions";
import { useSelector } from "react-redux";
import { api } from "../../Services/api";
import { valueToPercent } from "@mui/base";

const setSelectedID = (response) => {
  return {
    type: ActionTypes.SET_SELECTED_ID,
    payload: response
  };
}

const setShowAlert = (response) => {
  return {
    type: ActionTypes.SET_SHOW_ALERT,
    payload: response
  };
}

const setAlertMessage = (response) => {
  return {
    type: ActionTypes.SET_ALERT_MESSAGE,
    payload: response
  };
}

const setBrokerData = (response) => {
  return {
    type: ActionTypes.SET_BROKER_DATA,
    payload: response
  };
}

const setAllRequest = (response) => {
  return {
    type: ActionTypes.SET_ALL_REQUEST,
    payload: response
  };
}

const setAllUsers = (response) => {
  return {
    type: ActionTypes.SET_All_USER_LIST,
    payload: response
  };
}

const setActivityLog = (response) => {
  return {
    type: ActionTypes.SET_Activity_LOG_LIST,
    payload: response
  };
}

const setRepayments = (response) => {
  return {
    type: ActionTypes.SET_REPAYMENTS,
    payload: response
  };
}

const setOverview = (response) => {
  return {
    type: ActionTypes.SET_OVERVIEW,
    payload: response
  };
}


const setAllAdministrators = (response) => {
  return {
    type: ActionTypes.SET_ALL_ADMINISTRATORS,
    payload: response
  };
}

const setBankDetails = (response) => {
  return {
    type: ActionTypes.SET_BANK_DETAILS,
    payload: response
  };
}

const setPermissions = (response) => {
  return {
    type: ActionTypes.SET_PERMISSIONS,
    payload: response
  };
}

const resetTracking = () => {
  return {
    type: ActionTypes.RESET_TRACKING,
    payload: ""
  }
}

export {
  setSelectedID,
  setShowAlert,
  setAlertMessage,
  setBrokerData,
  setAllRequest,
  setAllUsers,
  setActivityLog,
  setRepayments,
  setOverview,
  setAllAdministrators,
  setPermissions,
  resetTracking,
  setBankDetails
};
