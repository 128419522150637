import Styled from 'styled-components';

const DashboardView = Styled.div`
   .member-list{
      overflow: hidden;
      height: 400px;
      overflow-x: hidden;
   }
   .project-list {
      overflow: hidden;
      height: 400px;
      overflow-x: hidden;
   }
   .first-login{
      font-size: 17px;
      text-align: justify;
      font-weight: 500;
   }

   .pending_progress{
      background-color: #6ede61 !important;
   }

   .modal-content {
      top: 50px !important;
   }
   .list {
      counter-reset: my-counter;
      list-style-type: none;
      padding-left: 0; 
    }

    .request_popup_body{
      display: grid;
      gap: 10px;
    }
    
    .list li {
      position: relative; 
      padding-left: 25px; 
      margin-bottom: 10px; 
    }
    
    .list li::before {
      counter-increment: my-counter;
      content: counter(my-counter) ". ";
      position: absolute; 
      left: 0; 
      top: 0; 
      text-align: right; 
      width: 20px; 
    }
    .list ul{
      padding: 10px;
    }

   .table-title{
      font-size: 16px;
   }
   .list-block {
      list-style-type: none; 
      padding-left: 40px; 
    }
    
    .list-block li {
      position: relative; 
      margin-bottom: 10px; 
    }
    
    .list-block li::before {
      content: ""; 
      position: absolute; 
      left: 0px; 
      top: 8px; 
      width: 5px; 
      height: 5px;
      border-radius: 50%;
      background-color: black;
      margin-left: -20px; 
    }
   .p-pading{
      // padding:10px;
      font-size:18px;
      font-weight:600;
      // margin-left:10px;
   }
   .Nopending{
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000015;
      border: 1px solid #26C984;
      border-radius: 5px;
   }
   .avail-limit {
      font-size:18px;
      font-weight:600;
   }
   .progress-item{
      padding: 20px 20px 5px 20px;
   }
   .limit{
      font-size:32px;
      font-weight:600;
      // text-align:left;
      color: #0094FF;
      // padding-left:0px;
      /* padding-top:20px; */
   }
   
   .cards{
      display: grid;
      margin: 0px;
      grid-template-columns: repeat(4, 1fr);
      /* grid-auto-flow: column; */
      gap: 20px;
      margin: 20px 0px;
   }

   .progress1{
      margin-left: -15px;
   }

   .card-view{
      padding:0px;
      width:100%;
      display:flex;
      justify-content:center;
      // align-items:center;
   }
   .newrequest{
      box-shadow: 0px 3px 6px #00000015;
      width: 30%;
      border-radius: 5px;
      height: 137px;
      align-item:left;
      box-shadow:none;
   }
   .newrequest1{
      box-shadow: 0px 3px 6px #00000015;
      width: 70%;
      border-radius: 5px;
      height: 137px;
      align-item:left;
      box-shadow:none;
      // font-size:30px;
      // color:#FFFFFF
   }
   .box{
      width:100%;
      background:white;
      box-shadow: 0px 3px 6px #00000015;
      height:120px;
      border-radius: 5px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      opacity: 1;
   }

   .text-align-right {
      text-align: right;
   }
   
   .box1{
      width:100%;
      background: transparent linear-gradient(131deg, #0094FF 0%, #26C984 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000015;
      border-radius: 5px;
      opacity: 1;
      height:120px;
      display:flex;
      justify-content: center;
      align-items:center;
      font-size: 1.5rem;
      color: #FFFFFF;
      border: none;
   }
   
   .pending-request {
      padding-top: 30px;
      margin-bottom: -10px;
   }
   
   .firstLetr{
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #fff;
        box-shadow: inset 0px 3px 6px #ffffff30, 0px 3px 6px #00000029;
        font-size: 12px;
        padding: 4px 6px;
        text-align: center;
   }  
   .project-color-block{
       height : 80px;
       width : 80px;
       border-radius: 20%;
       margin:auto;
       color:white;
       font-size:22px;
   }

   .view-all {
      float: right; 
      cursor: pointer;
      color: #0094FF;
   }

@media (max-width: 1050px) {

   .cards {
      grid-template-columns: repeat(2, 1fr);
   }

   .p-pading{
      margin-left:0px;
      
   }  

   .dashboard-img{
      width: 250px;
      height: 250px;
   }

 }


@media (max-width: 780px) {

   .cards {
      grid-template-columns: repeat(1, 1fr);
   }
   
   .newrequest{
      width:100%;
   }
   .newrequest1{
      width:100%;
      margin-bottom:20px;
   }
   .li-view{
      display:inline;
   }
 }

@media (max-width: 580px) {

   .cards {
      grid-template-columns: repeat(2, 1fr);
   }
   
   .p-pading{
      
      font-size:17px;
      margin-left:0px;
      
   }
   .l-pading{
      font-size:15px;
      width:50%
   }

   .limit{
      font-size:22px;
   }
   
  
 }

 @media (max-width: 380px) {
   .cards {
      grid-template-columns: repeat(1, 1fr);
   }
 }
`;

export default DashboardView;
