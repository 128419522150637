export const Onlytext = (e) => {
  const re = /^[A-Za-z]+$/;
  if (e.key === " " || e.key === "-" || re.test(e.key)) {
    return true;
  } else {
    e.preventDefault();
  }
};

export const percentage = (e) => {
  const data = e.target.value;
  const key = e.keyCode || e.charCode;
  const decimalKey = 46;

  if (
    data.length < 5 ||
    key === 8 ||
    key === decimalKey 
  ) {
    return true;
  } else {
    e.preventDefault();
  }
};

export const numValue = (e, length) => {
  const data = e.target.value;
  var key = e.keyCode || e.charCode;
  if (data.length < length || key === 8 || key === 46 || key === ".") {
    return true;
  } else {
    e.preventDefault();
  }
};

export const OnlyNumValue = (e, length) => {
  const data = e.target.value;
  var key = e.keyCode || e.charCode;
  if (data.length < length || key === 8 || key === 46) {
    return true;
  } else {
    e.preventDefault();
  }
};
