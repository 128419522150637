import Styled from 'styled-components';

const UIGraphView = Styled.div`
width: 100%;
.white-bg{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000015;
    border-radius: 5px;
    padding: 20px 0px 20px 0px;
}

.recharts-legend-item{
 display: none !important;
}

.yAxis{
    .recharts-cartesian-axis-line{
        display: none;
    }
}

.xAxis{
    .recharts-cartesian-axis-line{
        color: #0094FF;
    }
}
.recharts-cartesian-axis-tick-line{
    display: none;
}
.recharts-cartesian-axis-ticks{
    margin-right: 10px;
}
.recharts-yAxis{
    margin-right: -10px;
}
.graph-title{
    font-weight: 600;
    margin-top: 5px;
}
  
`;

export default UIGraphView;