import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationWrap from './style';

export const Paginate = ({ listPerPage, totalList, active, selectPage, setCurrentpage, currentPage }) => {
  const pageNumbers = [];
  // const handleSelectPage = (number) => {
  //   setCurrentpage(number);
  // }
  const handleSelectPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentpage(value);
  };
  for (let i = 1; i <= Math.ceil(totalList / listPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <PaginationWrap>
      {/* {totalList > listPerPage ? */}
        <nav className='mt-3 mb-2'>
          {/* <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handleSelectPage(currentPage - 1)}
            />
            {pageNumbers.map(number => (
              <Pagination.Item
                className={number === currentPage ? 'primary' : 'disable'}
                key={number}
                active={number === active}
                onClick={e => handleSelectPage(number)}
              >
                {number}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={currentPage === pageNumbers[pageNumbers.length - 1]}
              onClick={() => handleSelectPage(currentPage + 1)}
            />
          </Pagination> */}
          <Pagination count={pageNumbers.length} page={currentPage} onChange={handleSelectPage} />
        </nav>
        {/* : <></>} */}
    </PaginationWrap>
  );
};
