import React from "react";
import { Route, Routes, useNavigate, Router } from "react-router-dom";
import Dashboard from "../components/Pages/Portal/Dashboard";
import Settings from "../components/Pages/Settings";
import AccountHistory from "../components/Pages/Accounthistory";
import Newrequest from "../components/Pages/Newrequest";
import { useSelector, useDispatch } from "react-redux";
import Resource from "../components/Pages/Resource";

const Authroute = ({
  loading,
  getBrokerdata,
  getRequestData,
  requestLoading,
  getBankDetails,
}) => {
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);

  return (
    <Routes>
      {brokerdata.activationStatus === "Onboarding" ||
      brokerdata.AccountLinked === false ||
      brokerdata.contractSigned === false ? (
        <>
          <Route
            path="dashboard/:id"
            element={
              <Dashboard
                redirect={true}
                getBrokerdata={getBrokerdata}
                requestLoading={requestLoading}
              />
            }
          />
          <Route
            path="home/dashboard"
            element={
              <Dashboard
                getBrokerdata={getBrokerdata}
                requestLoading={requestLoading}
              />
            }
          />
          <Route
            path="dashboard"
            element={
              <Dashboard
                getBrokerdata={getBrokerdata}
                requestLoading={requestLoading}
              />
            }
          />
        </>
      ) : (
        <>
          <Route
            exact
            path="/"
            element={
              <Dashboard
                getBrokerdata={getBrokerdata}
                requestLoading={requestLoading}
              />
            }
          />
          <Route
            path="home/dashboard"
            element={
              <Dashboard
                getBrokerdata={getBrokerdata}
                requestLoading={requestLoading}
              />
            }
          />
          <Route
            path="dashboard"
            element={
              <Dashboard
                getBrokerdata={getBrokerdata}
                requestLoading={requestLoading}
              />
            }
          />
          <Route
            path="dashboard/:id"
            element={
              <Dashboard
                redirect={true}
                getBrokerdata={getBrokerdata}
                requestLoading={requestLoading}
              />
            }
          />
          <Route
            path="home/dashboard/:id"
            element={
              <Dashboard
                redirect={true}
                getBrokerdata={getBrokerdata}
                requestLoading={requestLoading}
              />
            }
          />
          <Route
            path="settings"
            element={
              <Settings
                getBrokerdata={getBrokerdata}
                loading={loading}
                getBankDetails={getBankDetails}
              />
            }
          />
          <Route path="accounthistory" element={<AccountHistory />} />
          <Route
            path="/newrequest"
            element={
              <Newrequest
                getRequestData={getRequestData}
                getBrokerdata={getBrokerdata}
              />
            }
          />
          <Route path="resource" element={<Resource />} />
        </>
      )}
    </Routes>
  );
};

export default Authroute;
