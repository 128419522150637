import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Tables from "../../../../Reuseable/Tables";
import Searchbox from "../../../../Reuseable/Searchbox";
import moment from "moment";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "antd/dist/antd.css";
import { Paginate } from "../../../../Reuseable/Paginate";
import { useSelector } from "react-redux";
import { NoDataFound } from "../../../../Reuseable/NoDataFound";
import Loading from "../../../../Reuseable/Loading/Loading";
import { Money } from "../../../../Reuseable/NumberFormatter";
import Sort from "../../../../Reuseable/SortIcon";
import UserReportWrap from "./style";
import { saveAs } from "file-saver";
import Papa from "papaparse";

const UserReport = ({ userLoading }) => {
  const AllUsers = useSelector((state) => state.Tracking.allUsers);
  const allRepayments = useSelector((state) => state.Tracking.allRepayments);

  const repayments = allRepayments.reduce(
    (catsSoFar, { repaymentDate, userId }) => {
      if (!catsSoFar[userId]) catsSoFar[userId] = [];
      catsSoFar[userId].push(repaymentDate);
      return catsSoFar;
    },
    {}
  );

  const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");

  const [searchterm, setSearchterm] = useState("");
  const [sortcolumn, setSortcolumn] = useState("");
  const [paymentDate, setPaymentDate] = useState(false);
  const [accountDate, setAccountDate] = useState(false);
  const [UserDate, setUserDate] = useState(false);
  const [data1, setData2] = useState(AllUsers);

  useEffect(() => {
    setData2(AllUsers);
  }, [AllUsers]);

  const ActiveUser = data1
    .filter((val) => {
      if (val.status === "verified") {
        return val;
      }
    })
    .map((data) => {
      let repaymentDate = repayments[data.userId];

      const today = new Date();

      if (repaymentDate) {
        data.NextpaymentDate = repaymentDate.reduce((a, b) =>
          a - today < b - today ? a : b
        );
        data.paymentDate = data.NextpaymentDate;
        if (
          new moment(data.NextpaymentDate).format("YYYYMMDD") <
          new moment(today).format("YYYYMMDD")
        ) {
          data.NextpaymentDate = "-";
          data.paymentDate = "01-01-1111";
        }
      } else {
        data.NextpaymentDate = "-";
        data.paymentDate = "01-01-1111";
      }

      if (data.NextpaymentDate !== "-") {
        data.NextpaymentDate = formatDate(data.NextpaymentDate);
      }

      return data;
    })
    .sort((item1, item2) => {
      if (sortcolumn === "username") {
        if (UserDate) {
          if (item1.personname.toLowerCase() < item2.personname.toLowerCase())
            return -1;
          else return 1;
        } else {
          if (item1.personname.toLowerCase() < item2.personname.toLowerCase())
            return 1;
          else return -1;
        }
      } else if (sortcolumn === "accountdate") {
        if (accountDate) {
          if (
            new moment(item1.createdDate).format("YYYYMMDD") <
            new moment(item2.createdDate).format("YYYYMMDD")
          )
            return -1;
          else return 1;
        } else {
          if (
            new moment(item2.createdDate).format("YYYYMMDD") >
            new moment(item1.createdDate).format("YYYYMMDD")
          )
            return 1;
          else return -1;
        }
      } else if (sortcolumn === "paymentDate") {
        if (paymentDate) {
          if (
            new moment(item1.paymentDate).format("YYYYMMDD") <
            new moment(item2.paymentDate).format("YYYYMMDD")
          )
            return -1;
          else return 1;
        } else {
          if (
            new moment(item2.paymentDate).format("YYYYMMDD") >
            new moment(item1.paymentDate).format("YYYYMMDD")
          )
            return 1;
          else return -1;
        }
      }
    })
    .filter((val) => {
      if (searchterm === "") {
        return val;
      } else if (
        val.personname.toLowerCase().includes(searchterm.toLowerCase()) ||
        val.userId.toLowerCase().includes(searchterm.toLowerCase())
      ) {
        return val;
      }
    });

  const tableRef = useRef(null);

  const [currentPage, setCurrentpage] = useState(1);
  let todosPerPage = 10;
  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const data = ActiveUser.slice(indexOfFirstTodo, indexOfLastTodo);

  const head = (
    <thead>
      <tr>
        <th
          className="pointer"
          onClick={() => {
            setAccountDate(!accountDate);
            setSortcolumn("accountdate");
          }}
        >
          Account Creation Date
          <Sort />
        </th>
        <th>User ID</th>
        <th
          className="pointer"
          onClick={() => {
            setUserDate(!UserDate);
            setSortcolumn("username");
          }}
        >
          User Name
          <Sort />
        </th>
        <th>Phone Number</th>
        <th>Email</th>
        <th>Business Name</th>
        <th>CRN/ACL</th>
        <th>Aggregator</th>
        <th>Address</th>
        <th>DOB</th>
        <th>2FA Enabled </th>
        <th>Score</th>
        <th>User Limit</th>
        <th>Amount Owing</th>
        <th
          className="pointer"
          onClick={() => {
            setPaymentDate(!paymentDate);
            setSortcolumn("paymentDate");
          }}
        >
          Next Payment Date
          <Sort />
        </th>
        <th>Overdue</th>
        <th>Overdue Amount</th>
      </tr>
    </thead>
  );

  const tbody = (
    <tbody>
      {data.map((data) => {
        if (data.overDue > 0) {
          data.due = "Yes";
        } else {
          data.due = "No";
        }

        return (
          <tr>
            <td>{data.createdDate}</td>
            <td>{data.userId}</td>
            <td>{data.personname}</td>
            <td>{data.phoneNumber}</td>
            <td>{data.emailId}</td>
            <td>{data.businessName}</td>
            <td>
              {data.licenceNumber?.ACL.length
                ? data.licenceNumber.ACL
                : data.licenceNumber.CRN}
            </td>
            <td>{data.aggregator}</td>
            <td>{data.address}</td>
            <td>{data.DOB === "00/00/0000" || data.DOB ==  ""  ? "-" : data.DOB}</td>
            <td>{data.faEnabled}</td>
            <td>{data.score}</td>
            <td>{Money(data.brokerLimit)}</td>
            <td>{Money(data.owing)}</td>
            <td>{data.NextpaymentDate}</td>
            <td>{data.due}</td>
            <td>{Money(data.overDue)}</td>
          </tr>
        );
      })}
    </tbody>
  );

  const convertToCSV = () => {
    if (!tableRef.current) return;

    const tableData = [];
    const headers = [];
    const rows = tableRef.current.querySelectorAll("tbody tr");

    tableRef.current.querySelectorAll("thead th").forEach((th) => {
      headers.push(th.innerText);
    });

    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.innerText);
      });
      tableData.push(rowData);
    });

    const csvData = [headers, ...tableData];
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    saveAs(blob, `User-report.csv`);
  };

  const data2 = { head, tbody };
  return (
    <React.Fragment>
      <UserReportWrap>
        <Container>
          <div>
            <div className="my-4">User reports</div>
            <Row>
              <Col sm={8} md={6} lg={3} xl={4}>
                <Searchbox
                  placeholder="Enter name, User ID"
                  handleSearch={setSearchterm}
                />
              </Col>
              <Col sm={12} md={6} lg={9} xl={8}>
                <div className="float-end">
                  <div className=" d-inline-flex">
                    <Paginate
                      listPerPage={10}
                      totalList={ActiveUser.length}
                      setCurrentpage={setCurrentpage}
                      currentPage={currentPage}
                    />
                  </div>
                  <div className=" d-inline-flex">
                    <Button className="theme-btn" onClick={convertToCSV}>
                      Download
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            {userLoading ? (
              <Loading />
            ) : (
              <>
                {data && data.length ? (
                  <div className="my-3">
                    <Tables tableRef={tableRef} props={data2} striped={true} />
                  </div>
                ) : (
                  <NoDataFound body="You don’t have any user at the moment." />
                )}
              </>
            )}
          </div>
        </Container>
      </UserReportWrap>
    </React.Fragment>
  );
};

export default UserReport;
