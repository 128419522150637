import React from "react";
import { format } from "date-fns";

const OnboardingPanel = ({ viewuser, setOpen, setShow }) => {
  const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");

  const convertDateFormat = (dateString) => {
    const [day, month, year] = dateString.split("-");

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  return (
    <>
      <div className="onboarding_block p-2">
        <div className="mb-1">
          <b>Account created: </b> {viewuser.createdDate}
        </div>
        <div className="mb-1">
          <b> Account approved: </b>{" "}
          {viewuser.approvedDate}
        </div>
        <div className="mb-1">
          <b>Contract signed: </b>{" "}
          {viewuser.contractSigned ? (<>
            <span className="approved">Completed on {viewuser?.contractSignedTime}</span>
            <button
              onClick={() => {
                setOpen(true);
                setShow(false);
              }}
              className="theme-btn px-3 mx-2"
            >
              View Contract
            </button>
            </>
          ) : (
            <span className="pending">Pending</span>
          )}
        </div>
        <div className="mb-1">
          <b> Bank account linked:</b>{" "}
          {viewuser.AccountLinked ? (
            <span className="approved">Completed on {viewuser?.AccountLinkedTime}</span>
          ) : (
            <span className="pending">Pending</span>
          )}
        </div>
      </div>
    </>
  );
};

export default OnboardingPanel;
