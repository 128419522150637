import Styled from 'styled-components';

const PaginationWrap = Styled.div`


  
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected, .css-1xr9krm.Mui-selected {
    color: #00C6FF;
    font-weight: 600;
    background: transparent !important;
    border: none !important;
}
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
    color: #C3C3C3 ;
}
`;

export default PaginationWrap;