import React from "react";
import LoginWrap from "../style";
import Brokerpay from "../../../../../src/assets/images/logo/brokerpay.svg";

const SubmittedScreen = ({}) => {
  return (
    <React.Fragment>
      <LoginWrap>
        <div className="center">
          <img className="logo" alt="User" src={Brokerpay} />
        </div>

        <div className="thanks-text">
          <span
            className="center primary fw-bold"
            style={{
              fontSize: "20px",
            }}
          >
            Thank you for signing up with Brokerpay.
          </span>
          <br />
          <span
            className="center justify"
            style={{
              fontSize: "18px",
            }}
          >
            Keep an eye on your email! We’ll verify the information you’ve
            provided and send you an email with the next steps soon.
          </span>
        </div>
      </LoginWrap>
    </React.Fragment>
  );
};

export default SubmittedScreen;
