import Alert from 'react-bootstrap/Alert';

export const NoDataFound = ({ body }) => {

    return (
        <Alert className="nodata-alert center fw-bold">
            {body}
        </Alert>
    )

}