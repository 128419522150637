export const ActionTypes = 
{
    RESET_TRACKING : "RESET_TRACKING",
    SET_SELECTED_ID : "SET_SELECTED_ID",
    SET_SHOW_ALERT : "SET_SHOW_ALERT",
    SET_ALERT_MESSAGE : "SET_ALERT_MESSAGE",
    SET_BROKER_DATA : "SET_BROKER_DATA",
    SET_ALL_REQUEST: "SET_ALL_REQUEST",
    SET_ALL_ADMINISTRATORS : "SET_ALL_ADMINISTRATORS",
    SET_All_USER_LIST : "SET_All_USER_LIST",
    SET_Activity_LOG_LIST : "SET_Activity_LOG_LIST",
    SET_REPAYMENTS : "SET_REPAYMENTS",
    SET_OVERVIEW : "SET_OVERVIEW",
    SET_PERMISSIONS : "SET_PERMISSIONS",
    SET_BANK_DETAILS: "SET_BANK_DETAILS"
}