import React, { useState, useEffect, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import Tables from "../../Reuseable/Tables";
import Searchbox from "../../Reuseable/Searchbox";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import Alert from "react-bootstrap/Alert";
import { InputGroup } from "react-bootstrap";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import { AccountHistoryWrap } from "./style";
import { format } from "date-fns";
import CancelIcon from "@mui/icons-material/Cancel";
import { Money } from "../../Reuseable/NumberFormatter";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Calender from "../../../assets/images/Calender.svg";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import RequestView from "../Portal/Dashboard/RequestView";

const { RangePicker } = DatePicker;

const AccountHistory = () => {
  const allRequest = useSelector((state) => state.Tracking.allRequest);

  const tableRef = useRef(null);

  let [accountData, setData] = useState(allRequest);
  const [searchterm, setSearchterm] = useState("");
  const [searchterm1, setSearchterm1] = useState("default");
  const [searchterm2, setSearchterm2] = useState("default");
  const [isHovering, setIsHovering] = useState(false);
  const [show, setShow] = useState(false);
  const [popupData, setPopupData] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    setData(allRequest);
  }, [allRequest]);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const columns = [
    "Request Date",
    "Request ID",
    "Client Name",
    "Amount",
    "Fees",
    "Request Status",
    "Repayment Status",
    "Repayment Date",
  ];

  const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");

  let accountviewData = accountData
    .filter((val) => {
      if (date) {
        if (moment(val.requestDate).isBetween(date[0], date[1], "day", "[]")) {
          return val;
        }
      } else {
        return val;
      }
    })
    .filter((val) => {
      if (searchterm === "") {
        return val;
      } else if (
        val.requestId.toLowerCase().includes(searchterm.toLowerCase()) ||
        val.name.toLowerCase().includes(searchterm.toLowerCase())
      ) {
        return val;
      }
    })
    .filter((val) => {
      if (searchterm1 === "default") {
        return val;
      } else if (
        val.repaymentStatus.toLowerCase() === searchterm1.toLowerCase()
      ) {
        return val;
      }
    })
    .filter((val) => {
      if (searchterm2 === "default") {
        return val;
      } else if (val.status.toLowerCase().includes(searchterm2.toLowerCase())) {
        return val;
      }
    });

  const reset = () => {
    setSearchterm1("default");
    setSearchterm2("default");
    setSearchterm("");
  };

  const convertToCSV = () => {
    if (!tableRef.current) return;

    const tableData = [];
    const headers = [];
    const rows = tableRef.current.querySelectorAll("tbody tr");

    tableRef.current.querySelectorAll("thead th").forEach((th) => {
      headers.push(th.innerText);
    });

    rows.forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell) => {
        rowData.push(cell.innerText);
      });
      tableData.push(rowData);
    });

    const csvData = [headers, ...tableData];
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    saveAs(blob, `Account_History.csv`);
  };

  const tbody = (
    <tbody>
      {accountviewData.map((row) => {
        return (
          <tr
          className="pointer"
            onClick={() => {
              setPopupData(row);
              setShow(true);
            }}
          >
            <td>{formatDate(row.requestDate)}</td>
            <td>{row.requestId}</td>
            <td>{row.name}</td>
            <td>{Money(row.commissionAdvance)}</td>
            <td>{Money(row.feeAmount)}</td>
            <td
              className={
                row.status === "Pending"
                  ? "pending"
                  : row.status === "Approved"
                  ? "approved"
                  : "declined"
              }
            >
              {row.status}
            </td>
            <td>
              {row.repaymentStatus === "Overdue" || null || undefined ? (
                <>
                  {" "}
                  {row.repaymentStatus} <span className="not-equal">!</span>{" "}
                </>
              ) : (
                row.repaymentStatus
              )}
            </td>
            <td>{formatDate(row.repaymentDate)}</td>
          </tr>
        );
      })}
    </tbody>
  );

  const data2 = { columns, tbody };

  return (
    <AccountHistoryWrap>
      <Container>
        <div className="back-link">Account history</div>
        {accountData && accountData.length ? (
          <Row>
            <Col xs={6} sm={6} md={6} lg={3} xl={2}>
              <Searchbox
                placeholder="Enter name, request ID"
                handleSearch={setSearchterm}
              />
            </Col>
            <Col xs={6} sm={6} md={6} lg={3} xl={3}>
              <RangePicker
                className="date-picker"
                placeholder={["Start Date", "End Date"]}
                locale={date}
                format={"YYYY-MM-DD"}
                value={date}
                onChange={(date) => setDate(date)}
                separator={
                  <CompareArrowsIcon className="primary  calender-arrow" />
                }
                suffixIcon={<img src={Calender} alt="Calender" />}
              />
            </Col>
            <Col className="account_dropdown" xs={6} sm={6} md={6} lg={3} xl={2}>
              <InputGroup
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <KeyboardArrowDownIcon className="arrow-down" />
                <select
                  className="select-style"
                  value={searchterm1}
                  onChange={(e) => setSearchterm1(e.target.value)}
                >
                  <option value={"default"} disabled>
                    Repayment Status
                  </option>
                  <option>Upcoming</option>
                  <option>Due</option>
                  <option>Overdue</option>
                  <option>Complete</option>
                </select>
                {isHovering && searchterm1 !== "default" && (
                  <div
                    className="reset-dropdown me-3"
                    onClick={() => setSearchterm1("default")}
                  >
                    <CancelIcon fontSize="small" className="primary" />
                  </div>
                )}
              </InputGroup>
            </Col>
            <Col xs={6} sm={6} md={6} lg={3} xl={2}>
              <InputGroup
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <KeyboardArrowDownIcon className="arrow-down" />
                <select
                  className="select-style"
                  value={searchterm2}
                  onChange={(e) => setSearchterm2(e.target.value)}
                >
                  <option value={"default"} disabled>
                    Request Status
                  </option>
                  <option>pending</option>
                  <option>Approved</option>
                  
                  <option>Declined</option>
                </select>
                {isHovering && searchterm2 !== "default" && (
                  <div
                    className="reset-dropdown me-3"
                    onClick={() => setSearchterm2("default")}
                  >
                    <CancelIcon fontSize="small" className="primary" />
                  </div>
                )}
              </InputGroup>
            </Col>

            <Col sm={12} md={12} lg={12} xl={3}>
              <button
                className="theme-btn download py-1 px-4"
                onClick={convertToCSV}
              >
                Download
              </button>
            </Col>
          </Row>
        ) : null}
        <Col className="mt-2">
          {accountviewData && accountviewData.length ? (
            <Tables tableRef={tableRef} id="content" props={data2} />
          ) : (
            <Alert
              className="NoData center py-4 my-2"
              key={"info"}
              variant={"info"}
            >
              You’ve not made any repayments yet.
            </Alert>
          )}
        </Col>
      </Container>
      <RequestView title={'Request details'} show={show} setShow={setShow} popupData={popupData} />
    </AccountHistoryWrap>
  );
};

export default AccountHistory;
