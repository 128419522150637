import Styled from 'styled-components';

const LoginWrap = Styled.div`
  code {
    font-family: ${({ theme }) => theme["font-stack"]};
}
h1 {
    font-family: ${({ theme }) => theme["font-stack"]};
}
h2 {
    font-family: ${({ theme }) => theme["font-stack"]};
}
h3 {
    font-family: ${({ theme }) => theme["font-stack"]};
}
h4 {
    font-family: ${({ theme }) => theme["font-stack"]};
}
h5 {
    font-family: ${({ theme }) => theme["font-stack"]};
}
h6 {
    font-family: ${({ theme }) => theme["font-stack"]};
}
a:active,
a:hover,
a:focus {
// color: ${({ theme }) => theme["orange-color"]};
}
.text-orange
{
    color:${({ theme }) => theme["orange-color"]};
}
.text-orange:hover
{
    color: ${({ theme }) => theme["orange-hover"]};
}
.btn-close {
    &:focus {
        box-shadow: inherit;
    }
}
.btn 
{
    &.btn-orange {
        color: #fff;
        background-color: ${({ theme }) => theme["orange-color"]};
        border-color: ${({ theme }) => theme["orange-color"]};
        margin: 0 auto;
        font-size: 16px;
        &:hover {
            color: #fff;
            background-color: ${({ theme }) => theme["orange-hover"]};
        border-color:${({ theme }) => theme["orange-hover"]};
        }
    }
}
.main-panel{
    margin-top: 10px;
    background:#f0f7fd;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 60px);
}
.adminDshbordBody{
   
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
.btn-grey {
    color:#fff;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    margin: 0 auto;
    font-size: 16px;
    &:hover {
        color: #212529;
    }
}
.form-check-input {
    &:checked {
        background-color: ${({ theme }) => theme["orange-color"]};
        border-color: ${({ theme }) => theme["orange-color"]};
    }
    &:focus {
        border-color: ${({ theme }) => theme["orange-color"]};
        outline: 0;
        box-shadow: inherit;
    }
    &:checked[type=radio] {
        &:focus {
            border-color: ${({ theme }) => theme["orange-color"]};
        }
    }
    cursor: pointer;
    border: 2px solid #0c0c0c40;
}
.form-check-input[type=checkbox] {
    border-radius: 2px;
}
.content-wrapper{
}
.card {
    border: 0 solid #dedede;
    border-radius: 8px;
    // box-shadow: 0px 2px 6px #00000029;
}
.main-panel.topmenu {
    margin-left: 0px;
}
.main-panel {
    display: flex;
    flex-direction: column;
    min-width: 0;
    z-index: 11;
    padding-top: 15px;
    padding-left:35px;
    padding-right:35px;
    width: 100%;
    flex-grow: 1;
  }
  .page-body-wrapper {
            
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden !important;
    overflow-y: hidden !important;
    
    }
    .maincontainer{
        overflow: hidden;
    }
    .toggle {
        width: 50px;
        padding-top: 50px;
        padding-left: 12px;
    }
    .menu{
        // display:none;
        position: absolute ;
        z-index: 9;
        color: #1958A7;
    }
    .touchArea{
        margin-right:1.25em;
    }
    .mobSideBar 
        {
            .main-panel {
                margin-left: 60px;
            }  

            .mobSideBar .sidebar.sidebarMain {
                width: 60px;
            }   

            .sidebarMain {
                .serchFieldMain {
                  margin: 0 17px;
                  input.form-control {
                   // display: none;
                  }
                }
              }
              .sidebar {
                .nav {
                  .dropmenuitems {
                    .nav-link {
                      display: none;
                    }
                  }
                  .nav-link {
                    padding: 6px 10px 6px 17px;
                  }
                }
              }

            .sidebarMainLinks {
                width: 60px;
              }
              .sidebar.sidebarInerLinks {
                width: 60px;
              }
              .sidebar.sidebarMain {
                width: 60px;
              }
              .listArowIcon {
                display: none;
              }
              .navItemArrow {
                display: none;
              }
              .nav-link {
                span.dsktopView {
                  display: none;
                }
              }
        }    
        
        *,*::before,*::after {
            box-sizing: border-box;
        }
        .loginPageContent {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .container-width {
            float: right;           
            width: 540px;
            box-shadow: 0px 0px 8px #0000004F;
            border-radius: 10px;
            opacity: 1;
        }
        .loginFormScreens {
            margin: 25px;
            border-radius: 10px;
        }
        .logoimg {
            max-height: 50px;
            padding-top: 0px;
        }
        .formScreenUser {
            color: #111247;
            font-weight: 600;
        }
        .formScreenTitle {
            color: #555;
            font-weight: 600;
        }
        .forgotLink {
            color:  ${({ theme }) => theme["orange-color"]};
            font-size: 14px;
            font-weight: 500;
            &:hover {
                color: ${({ theme }) => theme["orange-hover"]};
            }
            &:active {
                color: ${({ theme }) => theme["orange-hover"]};
            }
            &:focus {
                color: ${({ theme }) => theme["orange-hover"]};
            }
        }
        .loginFormScreens {
            // .form-control {
            //     padding: 10px 20px;
            //     font-size: 15px;
            // .toggle {
            //     padding-top: 20px;
            //     padding-left: 5px;
            // }
            .mobSideBar .sidebar.sidebarMain {
                width: 0;
            }
        }
        form {
            label {
                color: ${({ theme }) => theme["gray-color"]};
            }
        }
        @media only screen and (max-width:350px){
            .container-width {
               width: 300px !important;
            }
            .loginPageContent {
               min-width: 320px !important;               
            }
            // .card{
            //     padding: 0px !important;
            // }
            // .loginFormScreens {
            //     margin: 10px !important;
            // .mobSideBar .sidebar.sidebarMain {
            //     // box-shadow: 0 0 10px #00000020;
            //     // -webkit-transition: all 0.5s ease-out;
            //     width: 160px;
            //     z-index: 111;
            // }
            .mobSideBar .nav-link span.dsktopView {
                display: inline-block;
            }
            
        }
        @media only screen and (max-width:800px) {
            .container-width {
                float: none;
                display: flex;
                justify-content: center !important;
                width: 320px;

            }

            .loginPageContent {
                min-width: 350px;
                max-height: 100vh;
                display: flex;
                align-items: center;
            }
            .loginFormScreens {
                margin: 20px;
            }
                
         }
         @media only screen and (min-width:1024px) {
            .container-width {
                position: relative;
                left: 20%;
                padding: 0;

            }
            
         }

  @media (max-width: 1240px) {
    .main-panel{
        padding-left:15px;
        padding-right:15px;
    }
  }
  @media (max-width: 767px) {
    .menu{
        // display:block;
    }
 
`;

export default LoginWrap;