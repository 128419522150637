import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component, path }) => {

  let authorizedPath = true;
 
  const user = useSelector((state) => state.Auth.user);
  const isLoggedIn = useSelector((state) => state.Auth.isLoggedIn);
  
  // return isLoggedIn  ? <Route path={path} element={<component />} /> : <Navigate to="home" />
 return isLoggedIn ? <Outlet /> : <Navigate to="/login" />
};

export default ProtectedRoute;
