import React, { useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import Styled from "styled-components";
import onBoardImg from "../../../../assets/images/onboadring.png";
import tickMark from "../../../../assets/images/check-mark.png";

const OnBoarding = ({ brokerdata,  SetLinkAccount, postAgreementData }) => {

  return (
    <OnBoardingView>
      <Container>
        <Card className="on_boarding_card p-4">
          <h4 className="primary my-4">Thanks for joining Brokerpay!</h4>
          <p className="">
            You’re one step closer to unlocking your commission sooner.
            <span className="fw-bolder ms-1">What’s next?</span>
          </p>
          <p>
            Before you can make a commission advance request you will need to
            sign the Brokerpay agreement and link your preferred bank account.
          </p>
          <p>
            Got questions? Head over to the{" "}
            <span className="fw-bold">resources tab</span>, or contact our team
            at
            <span className="primary fw-bold">
              {" "}
              customercare@brokerpay.com.au
            </span>
          </p>

          <div className="d-block">
            <button
              onClick={() => !brokerdata.contractSigned &&  postAgreementData()}
              className="theme-btn d-block onboard_btn py-2"
            >
              {brokerdata.contractSigned && (
                <img className="tick_image" src={tickMark} alt="tick" />
              )}
              Sign my Brokerpay agreement
            </button>
            <button
              onClick={() => !brokerdata.AccountLinked && SetLinkAccount(true)}
              className="theme-btn d-block onboard_btn py-2"
            >
              {brokerdata.AccountLinked && (
                <img className="tick_image" src={tickMark} alt="tick" />
              )}
              Link my bank account
            </button>
          </div>

          <div className="image_block">
            <img className="onboarding_image" src={onBoardImg} alt="logo" />
          </div>
        </Card>
      </Container>

      {/* <Modal centered show={show} onHide={() => setShow(false)}>
        <div className="p-3">
          <h5 className="primary">Sign my Brokerpay agreement</h5>
          <div>
            <p>
              <span className="fw-bold">Name:</span> {brokerdata.personname}
            </p>
            <button
              onClick={() => {
                setShow(false);
                postAgreementData("contractSigned");
              }}
              className="theme-btn py-1 px-3 float-end"
            >
              Agree & Sign
            </button>
          </div>
        </div>
      </Modal> */}
    </OnBoardingView>
  );
};

export default OnBoarding;

const OnBoardingView = Styled.div`

p{
    font-size: 16px;
    font-weight: medium;
}
.sigPad {
    width: 100%;
    height: 100%;
  }

.on_boarding_card{
//  height: 100vh;
}

.image_block{
    width: 100%;
    float: right;
    display: flex;
    justify-content: end;
}

.onboarding_image{
    width: 250px;
    height: 15%;
}

.onboard_btn{
    width: 300px;
    margin: 20px 0;
}

.tick_image{
    position: absolute;
    width: 45px;
    left: 40px;
    margin-top: -14px;
}
}
@media (min-width: 1100px) {
    .image_block{
        padding-right: 6%;
    }

}
@media (min-width: 1200px) {
    .onboarding_image{
       width: 350px !important;
    }
  }

@media (max-width: 430px) {
    .onboard_btn{
        width: auto;
         padding: 0 14px;
    }

    .onboarding_image{
        width: 220px;
        height: 10%;
    }
}

`;
