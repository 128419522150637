import React, { useState } from "react";
import hashching from "../../../../../src/assets/images/hashchinglogo.svg";
import { Spinner } from "react-bootstrap";
import { api } from "../../../../Services/api";
import HelpIcon from "@mui/icons-material/Help";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const VerifyAccount = ({ hashchingData, setPageView, email }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState("");

  const MaskedPhoneNumber = (phoneNumber) => {
    const maskedNumber = phoneNumber.replace(/.(?=.{4})/g, "*");

    return maskedNumber;
  };

  const SubmitForm = () => {
    setLoading(true);
    const obj = {
      emailId: email,
      name: hashchingData.first_name + " " + hashchingData.last_name,
      action: "SendOtp",
    };

    api
      .post("/brokerpayuserauth/hashchinguser/" + email, obj)
      .then(function (response) {
        if (response.data.status === true) {
          setSubmitted(true);
          setLoading(false);
          setPageView("otp_page");
        }
      })
      .catch(function (error) {
        if (error === false) {
          console.log("loginResponse [error]-->", error);
        }
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="ms-2">
        <img
          className="me-1 mb-1"
          width={100}
          height={30}
          src={hashching}
          alt="hashching"
        />
        <span>Broker</span>
        <span className="float-end">
          <Tooltip
            title={
              "Link your Hashching account to get access to reduced fees, higher commission limits, and other special offers."
            }
          >
            <span className="pointer mx-1">
              <HelpIcon className="mb-1" color="primary" fontSize="small" />
            </span>
          </Tooltip>
        </span>
      </div>
      <div className="ms-4">
        <div className="d-inline-flex center">
          {hashchingData.profile_pic && hashchingData.profile_pic.length ? (
            <div className="hashching_image center me-4 ms-2 mt-2">
              <img
                className="hashching_image"
                src={hashchingData.profile_pic}
                alt="profile"
              />
            </div>
          ) : (
            <div className="hashching_profile center me-4 ms-2 mt-2">
              {hashchingData.first_name[0]}
            </div>
          )}
          <div className="d-inline-flex mt-4">
            <div className="b-block">
              <div className="hashching_name">
                {hashchingData.first_name + " " + hashchingData.last_name}
              </div>
              <div className="verfiy_text b-block">Is this you?</div>
            </div>
            <span className="divider">|</span>
            <div className="hashching_number mt-2">
              ph:
              {hashchingData.mobile &&
                hashchingData.mobile.length &&
                MaskedPhoneNumber(hashchingData.mobile)}
            </div>
          </div>
        </div>
        <div className="my-4 mx-2">
          <button
            onClick={() => SubmitForm()}
            className="hashching_btn theme-btn py-2 px-4"
          >
            {loading ? (
              <Spinner className="mx-4" animation="border" size="sm" />
            ) : (
              "Yes, Verify my account"
            )}
          </button>
          <div
            onClick={() => setPageView("invalid_account")}
            className="hashching_btn2 pointer primary my-2"
          >
            No, this is not me
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VerifyAccount;
