import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Dropdown from "react-bootstrap/Dropdown";
import $ from "jquery";
import HeaderWrap from "./style";
import Popup from "reactjs-popup";
import "./menu.css";
import BrokerProfile from "./BrokerProfile";
import Brokerpay from "../../../../assets/images/logo/brokerpay-logo.svg";
import Avatar from "../../../../assets/images/logo/avatar.png";
import BurgerMenuButton from "../../../Reuseable/UIButton/AnimationButton";
import LogoutButton from "../../../Reuseable/UIButton/LogoutButton";
import { BarLoader } from "react-spinners";
import { css } from "@emotion/react";
import "bootstrap/dist/css/bootstrap.css";
import { useMedia } from "react-use";

const Header = ({
  handleToggle,
  showResposiveSideBar,
  setActiveNav,
  activeNav,
  setShow,
}) => {
  const darkmode = useSelector((state) => state.Layout.darkmode);
  const user = useSelector((state) => state.Auth.user);
  const menuList = useSelector((state) => state.Auth.menuList);
  const brokerdata = useSelector((state) => state.Tracking.brokerdata);

  const [open, setOpen] = useState(false);
  const [dash1, setDash1] = useState("");
  const [dash2, setDash2] = useState("");
  const [dash3, setDash3] = useState("");
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setdropdownOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
    setLoading(true);
  }, [dash1, dash2, dash3]);

  useEffect(() => {
    if (darkmode) {
      $("#darkTheme").prop("checked", true);
    } else {
      $("#lightTheme").prop("checked", true);
    }
  });

  const resourceBtn = useMedia("(min-width: 900px)");

  const toggleTooltip = () => {
    setOpen(!open);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const LoaderCss = css`
    overflow: none;
  `;

  return (
    <HeaderWrap>
      <nav className="navbar navbar-expand-md navbar-light bg-white shadow position-fixed w-100 z-index-navbar">
        <div className="position-relative">
          <div className="position-absolute barLoader">
            <BarLoader
              width={1900}
              height={3}
              css={LoaderCss}
              color="#1958a7"
              loading={loading}
            />
          </div>
        </div>

        <div className="container-fluid pe-0">
          {user &&
          user.userRole === "Broker" &&
          brokerdata.activationStatus !== "Onboarding" ? (
            <div className="menu-display">
              <div className="hide-menu">
                <button className="border-0 pt-1 btn" onClick={toggleTooltip}>
                  <BurgerMenuButton open={open} />
                </button>
                <Popup
                  id="popup-content1"
                  // ref={ref}
                  open={open}
                  position="bottom center"
                  // on="hover"
                  closeOnDocumentClick
                  mouseLeaveDelay={600}
                  mouseEnterDelay={0}
                  contentStyle={{
                    position: "absolute",
                    top: "60px",
                    right: "40px",
                    padding: "5px",
                    border: "none",
                  }}
                  onClose={closeModal}
                  arrow={true}
                >
                  {menuList ? (
                    <>
                      {menuList?.map((item) => {
                        return (
                          <div className="menupopup">
                            <Link
                              className="fw-bold blue-theme"
                              onClick={toggleTooltip}
                              to={item.path}
                            >
                              <div className="menu-item">
                                <img
                                  style={{
                                    marginRight: "4px",
                                    marginBottom: "4px",
                                  }}
                                  alt={item.name}
                                  src={item.icon}
                                />
                                {item.name}
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                </Popup>
              </div>
            </div>
          ) : null}

          <div className="navbar-item">
            <div className="d-inline-flex text-truncate">
              {user &&
              (user.userRole === "Admin" ||
                user.userRole === "Administrator") &&
              showResposiveSideBar ? (
                <div>
                  <MenuIcon
                    className="mx-2 ms-3"
                    style={{
                      marginTop: "25px",
                      cursor: "pointer",
                    }}
                    onClick={handleToggle}
                  />
                </div>
              ) : null}
              <NavLink className="btn" to={menuList[0]?.path}>
                <img
                  className="logoimg"
                  src={Brokerpay}
                  alt="menuIcon"
                  onClick={() => setActiveNav(menuList[0]?.name)}
                />
              </NavLink>
            </div>
          </div>

          <button
            className="ms-2 border-0 menu-icon navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <img src={menuIcon} width={29} height={29} alt="menuIcon"></img> */}
            {/* <BurgerMenuButton open={open} /> */}
          </button>
          {user && user.userRole === "Broker" ? (
            <div
              className="collapse navbar-collapse justify-content-start"
              id="navbarNavAltMarkup"
            >
              {menuList ? (
                <>
                  {menuList?.map((item) => {
                    return (
                      <ul className="navbar-nav">
                        <li
                          className="nav-item mx-2"
                          onClick={() => {
                            brokerdata.activationStatus === "Onboarding"
                              ? setActiveNav("dashboard")
                              : setActiveNav(item.name);
                          }}
                        >
                          <NavLink
                            className={
                              brokerdata.activationStatus === "Onboarding"
                                ? "fisrt_nav"
                                : activeNav === item.path
                                ? "primary nav-link"
                                : "nav-link"
                            }
                            to={
                              brokerdata.activationStatus === "Onboarding"
                                ? "dashboard"
                                : item.path
                            }
                          >
                            <img
                              className="head-icon"
                              style={{
                                marginRight: "4px",
                                marginBottom: "4px",
                              }}
                              alt={item.name}
                              src={item.icon}
                            />
                            <img
                              className="head-icon1"
                              style={{
                                marginRight: "4px",
                                marginBottom: "4px",
                              }}
                              alt={item.name}
                              src={item.activeicon}
                            />
                            {item.name}
                          </NavLink>
                        </li>
                      </ul>
                    );
                  })}
                </>
              ) : null}
            </div>
          ) : null}
          {user && resourceBtn &&
          user.userRole === "Broker" ? (
            <Link to='resource' className="theme-btn py-1 px-4">Resources</Link>
          ) : null}
          {user && user.userRole === "Broker" ? (
            <div className="nav-item">
              <Dropdown
                onToggle={() => setdropdownOpen(!dropdownOpen)}
                show={dropdownOpen}
                className="profile nav-link"
                to="#"
                type="button"
              >
                <Dropdown.Toggle>
                  <img
                     src={brokerdata.profile ? brokerdata.profile : Avatar}
                    height={35}
                    width={35}
                    className="avatar"
                    alt="ProfileAccount"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <BrokerProfile
                    Avatar={Avatar}
                    setdropdownOpen={setdropdownOpen}
                    setShow={setShow}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <div className="me-4">
              <LogoutButton />
            </div>
          )}
        </div>
      </nav>
    </HeaderWrap>
  );
};

export default Header;
