import Styled from "styled-components";
import slide1 from "../../../assets/images/slide1.svg";
import slide2 from "../../../assets/images/slide2.svg";

const LoginWrap = Styled.div`

        
        *,*::before,*::after {
            box-sizing: border-box;
        }

        .loginPageContent {
            overflow: auto;
             display: flex;
            justify-content: center;
            align-items: center;
        }
       
            .position{
                position:absolute;
                z-index:100;
            }
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.thanks-text{
    padding: 30px;
    padding-bottom: 50px;
    font-weight: 600;
}

.primary{
    color: #0094FF;
}
.signup-btn{
    width: 110px;
    height: 40px;
    background: #0094FF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    color: white;
    border: none;
}
.divider{
    font-size: 25px;
    font-weight: 200;
    margin: 0 10px;
}
.hashching_name{
    color: #303030;
    font-size: 25px;
    // font-weight: 600;
}
.hashching_profile{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: #0094FF;
    font-size: 34px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 30px;
   width: 90px;
}
.hashching_image{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    width: 100px;
    height: 100px;
}
.hashching_number{
    color: #303030;
}
.verfiy_text{
    color: #0094FF;
    font-size: 20px;
    font-weight: 600;
}
.hashching_btn{
    font-size: 17px;
}
.hashching_btn2{
font-weight: 600;
font-size: 17px;
}
.image_card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}
.invalid_value{
    border: 1px solid #FF0000 !important;
}

.sign-btn-group {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.yes-btn{
    margin: 10px;
}

.signup-card{
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-text{
    text-align: center;
    font: normal normal normal 22px/25px Roboto;
    letter-spacing: 0px;
    font-weight: 600;
}

.alert{
    margin:10px;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#000000;
    font-size:17px;
    padding-left:150px;
    padding-right:150px;

}
.col-form{
    padding-top: 15px !important;
}
.invalid_account_text{
    text-align: center !important;
    font-weight: bold;
}
.hashching-label {
    font-size: 14px !important;
    font-weight: bold;
}
@media (min-width:992px){
    .data_block{
        display:inline-flex;
    }
    }
@media (max-width:992px){
.data_divider{
  display: none;
}
}
@media (min-width:575px){
    .col-form{
        padding-right: 25px !important;
    }
    .reg-box{
        padding-left: 30px;
    }
}
@media (max-width:575px){
    .containerwidth {
        margin-top: 200px;
    }
   
}
.loginSubmitBtn{
    margin-bottom: 30px;
    margin-top: 10px;
}
        .container-width {
            // margin-top:130px;
            float: left !important;           
            width: 440px;
            background:tranparent;
            // box-shadow: 0px 0px 8px #0000004F;
            border-radius: 10px;
            opacity: 1;
            margin-left:0;
        }
        .loginPage-Content {
            display: flex;
            // justify-content: center;
            align-items: center;
        }
        .containerwidth {          
            width: 845px;
            background:tranparent;
            border-radius: 10px;
            opacity: 1;
        }
        .signup-container{
            width: 100%;
            height: 100vh;
        }
        .logo{
            width:130px;
        }
        .loginFormScreens {
            margin: 25px;
            border-radius: 10px;
        }
        .logoimg {
            max-height: 50px;
            padding-top: 0px;
        }
        .formScreenUser {
            color: #111247;
            font-weight: 600;
        }
        .formScreenTitle {
            color: #555;
            font-weight: 600;
        }
        .forgotLink {
            color:  ${({ theme }) => theme["orange-color"]};
            font-size: 14px;
            font-weight: 500;
            &:hover {
                color: ${({ theme }) => theme["orange-hover"]};
            }
            &:active {
                color: ${({ theme }) => theme["orange-hover"]};
            }
            &:focus {
                color: ${({ theme }) => theme["orange-hover"]};
            }
        }
        .loginFormScreens {
            .form-control {
                padding: 0px;
                font-size: 15px;
                border:none;
                border-bottom:1px solid #0094FF;
                border-radius:0px;
            }
            .reusable_select{
                padding: 0px;
                font-size: 15px;
                border:none;
                border-bottom:1px solid #0094FF;
                border-radius:0px;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAABWUlEQVR4nO2TbU7CQBCGewJNDPEDKWWnBY3Gg3kS252W6I30n4nxj6LgVxToTAshXgGzJFhqV8AKMTG8ySRNuvM+MzuzhrHSSn8m52ywBpKPaz4VfutV86mgvJRn6ge47+sg6RKQhyC5aXrtYl6IOI23hKRb5SWQr0tBZyMBSboYQZJo5YGZXruoctNedJ5UIenuC2goJD/ZfmjOC7H90FQ5GR+kxsxDIOmtWo9hFgRcKgukZ11+GSOROuy43ZIWhty2g47zHaSCbAlJL9k8erVOooo2yZK9bUC61yRFDvJBphOvWxXI3ex18aMqfOo1KJhuZiAptt3o8LMTL9wTkkMdpBp0do0frKhmQagHQXxk+bQPyJQthh/mhkxf1dEW9VVoZpnrSSSdITU0psOFQcYCjDbHL31pkDlgrXK9v2MsUgoGkm4mBt9cOGQSJpCuVKjvpUBW+v/6AGm8lMmKVC2rAAAAAElFTkSuQmCC');
            }
            .select_placeholder{
                opacity: 0.8 !important;
            }
          
        }
        form {
            label {
                color: ${({ theme }) => theme["gray-color"]};
                font-size:22px;
            }
        }
        .singleSlide {
            margin: 0;
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
            position: relative;
        }
        .slide1 {
            background-image: url(${slide1});
            height: 100vh;
        }
        .slide2 {
            background-image: url(${slide2});
            height: 100vh;
        }
        .slideOverlay {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: #4b58614d;
        }
        .loginScreenSlides {
            .slick-dots {
                position: absolute;
                bottom: 56px;
                display: block;
                padding: 0;
                margin: 0 auto;
                list-style: none;
                text-align: center;
                // right: -40%;
                bottom: 16px;
                li {
                    button {
                        
                        font-size: 0;
                        line-height: 0;
                        display: block;
                        width: 11px;
                        height: 11px;
                        padding: 5px;
                        cursor: pointer;
                        color: transparent;
                        border: 0;
                        outline: none;
                        // background: #a5abb1;
                        background: #fff;
                        border-radius: 50%;
                        &:before {
                            color: transparent !important;
                        }
                    }
                }
                li.slick-active {
                    button {
                        background: ${({ theme }) => theme["orange-color"]};
                    }
                }
            }
        }
        .loginScreenSlides.slick-dots {
            li.slick-active {
                button {
                    &:before {
                        color: transparent !important;
                    }
                }
            }
        }   

        @media only screen and (max-width:1508px){
                    .container-width {
                        // margin-top:140px;
                    }
            
        }
        @media only screen and (max-width:1208px){
           
                .container-width {
                    // margin-top:150px;
                }
        
    }
    @media only screen and (max-width:991px){
        
        @media only screen and (max-width:420px){
                .container-width {
                    // margin-top:110px;
                }
                .alert{
                    padding-left:20px;
                    padding-right:20px;
                }
        
    }
        @media only screen and (max-width:350px){
            .container-width {
            //    width: 300px !important;
            }
            .loginPageContent {
            //    min-width: 320px !important;               
            }
            .card{
                padding: 0px !important;
            }
            .loginFormScreens {
                margin: 10px !important;
            }
            
        }

        @media only screen and (max-width:800px) {
            .container-width {
            //    margin-top:50px;
            }
            .containerwidth {
                width:100%;
             }
            .loginPageContent {
                // min-width: 350px;
                max-height: 100vh;
                display: flex;
                align-items: center;
            }
            .loginFormScreens {
                margin: 20px;
            }
                
         }
        
        
        
`;

export default LoginWrap;
