import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import AlertNoInternet from './AlertNoInternet';
import './style.css';
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { isOnlineContext } from "../../../App";


const NoInternet = () => {
    const [show, setShow] = useState(false);

    const { online } = useContext(isOnlineContext);

    useEffect(() => {
        console.log("isOnline:", online)
        // if (online === false) {
        //     setShow(!show)
        // }
        online ? setShow(false) : setShow(true)
    }, [online])

    return (
        <>
            <div className='NoInternetAlert'>
                {show ? <AlertNoInternet /> : <></>}
            </div>

            <Outlet />
        </>
    )
}


export default NoInternet;