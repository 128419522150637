import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";


const LoginNavigation = ({ component, path }) => {

  const isLoggedIn = useSelector(state => state.Auth.isLoggedIn);
  const user = useSelector(state => state.Auth.user);

  return (
    isLoggedIn ?
      <>
        {user && ( user.userRole === 'Admin' || user && user.userRole === 'Administrator' ) ?
          <Navigate to="/home/overview" />
          :
          <Navigate to="/home/dashboard" />
        }
      </>
      :
      <Outlet />
  );
};

export default LoginNavigation;
