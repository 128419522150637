import Styled from "styled-components";

const UsersWrap = Styled.div`
.body{
 padding: 10px;
 font-size: 18px;
 font-weight: 600;
}
.user-container{
  background: white;
}
.notes-input{
  background-color: #F7F7F7 !important;
}
.ant-picker {
  z-index: 9999 !important;
}
.scroll-container-view{
  overflow-x : hidden !important;
}
.scroll-container-view::-webkit-scrollbar {
  background: #E8E8E8;
  height: 8px;
  width: 8px;
}
.scroll-container-view::-webkit-scrollbar-thumb {
  background-color: #0094FF;
  border-radius: 6px;
  border: 3px solid  #0094FF;
}
.del-contract{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 2px;
  float: right;
  padding: 5px;
  font-weight: 500;
}
.backcircle-arrow{
  transform: rotate(90deg);
}
.contract-header{
  background: #E4E4E4;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.contract-name{
  font-size:15px;
  font-weight: 500;
}
th, td {
  text-align: left !important; 
  padding: 10px;
  padding-left: 15px !important;
}
.edit{
  cursor: pointer;
  font-size: 13px;
  margin: 4px;
}
.limit-name{
  width: 80%;
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  background: transparent !important;
  border: none;
}
.limit-number{
  color: #FFFFFF !important;
  width: 80%;
  font-size: 13px;
}
 .back-link{
  display: inline-flex;
 }
 .modal-content{
  margin-top: 50px !important;
 }
.userdetails-card{
  background: white;
  color: black;
  height: 100%;
  padding: 10px;
  display: flex;
}
.navbar > .container{
  background: #E2F2FF;
  gap : 20px;
  width: 100%;
}
.message-box{
  font-size: 15px;
  font-weight: 400;
  padding: 5px;
}
.dataset{
gap: 7px;
}
.submit-btn{
  background: #0094FF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: white;
  border: none;
  width: 100%;
height: 40px;
}
.delete-btn{
  background: none;
  border: none;
}
.deleted-user{
  color:	rgb(0, 0, 0, 0.4);
}
.limit-input{
  background: transparent !important;
  border: none;
  padding: 2px;
 
}
.user-view-box{
  border: 2px solid #E6E6E6;
}
.limit-form {
  width: 70px;
  border-bottom: 2px solid #00C6FF !important;
}
.limit-form1{
  border-bottom: 2px solid #00C6FF !important;
}
.Linked-account{
  font-size: 15px;
  font-weight: 400;
}
.form-control{
  background: #00000015;
  border: none;
}
// .userdata-container{
//  height: 200px;
// }
.modal-nav{
 margin-right: 30px;
  margin-bottom: 10px;
}
.reactive{
  border: none;
  background: transparent;
  text-decoration: underline;
}
.userdata-profile{
  font-size: 15px;
}
.nav-link.active {
  color: #0094FF;      
  padding-bottom: 7px;
  border-bottom: 3px solid #0094FF;
  border-radius: 1px;
  transition: 0.1s easeinout;
}
.navbar-light, .navbar-nav, .nav-link.active {
  color: #0094FF;
}
.num-data{
  font-size: 20px;
}
.due-data{
  font-size: 10px;
  font-weight: 400;
 position: relative;
 bottom: 0px;
}
.user-icons{
  float: right;
  display: flex;
  gap: 8px;
}
.name{
  font-size: 27px;
  font-weight: 500;
}
.user-data{
  font-size: 13px;
  font-weight: 400;
}
.contact{
  display: flex;
  gap: 5px;
  position: relative;
  margin-top: 20px;
}
.link-card{
  padding: 16px !important;
}
.card_number{
  font-size: 20px;
}
.card_footer{
  margin-top: 12px;
}

 .table>tbody>tr:hover>*, .table-striped>tbody>tr:nth-of-type(odd)>* { 
    --bs-table-accent-bg: #E2F2FF;
    background: #E2F2FF;
}
 .tab-row{
   display: block;
   width: 100%;
 
 .tab-content{
  margin-top: 50px;
 }
   .nav-link{
      font-weight: 600;
      color: black !important;
      background: white;
      border: none;
      width: 204px;
   }
   .nav{
   .active{
    
      background: #E2F2FF 0% 0% no-repeat padding-box;
      border-radius: 5px 0px 0px 5px;
   }
  }
  }
  .modal-nav:active,
  .modal-nav:focus{

   border-bottom: 2px solid #0094FF;
}
 .tab-group{
   float: right;
   font-size: 18px;
   font-weight: 500;
 }
tr{
 td{
  font-size: 17px  !important;
   padding-top: 15px !important;
   padding-bottom: 15px !important;
   white-space: nowrap; 
 }
 th{
  font-weight: 700 !important;
  white-space: nowrap; 
 }
}
.icon_column{
  width: 20px;
}
thead{
  tr{
    background-color: #F8F7F7 !important;
    padding: 10px;
  }
}
.MuiBadge-dot{
  margin-right: -53px !important;
}
.search-title{
  font-size: 18px;
  font-weight: 500;
  margin-top: 5px;
}
.content-section{
  // height: 330px;
}
.action-column{
  width: 50%;
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: white !important;
}
.modal-content{
  margin-top: inherit !important;
}
.search-box{
// float: right;
width: 200px !important;
margin-bottom: 10px;
}
.search-deropdown{
  width: 200px !important;
  margin-bottom: 10px;
  }
.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon,
.css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon,
.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root{
  color: #0094FF !important;
  border:none;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
border: none;
}
@media (max-width:450px){
  font-size: 15px;
}
@media (max-width:890px){
  .MuiBadge-dot{
    margin-right: -28px !important;
  }
}
@media (min-width:1200px){
  .mobile-view{
    display: none;
  }
  .large-view{
    display: inline;
  }
}
@media (max-width:992px){
.search-container{
 float: none !important;
}
.submit-btn{
  width: 171px !important;
}

}
@media (max-width:1200px){
  .mobile-view{
    display: inline;
  }
  .large-view{
    display: none;
  }
}
@media  (max-width:890px){
  .tab-row{
   .nav-link{
    width: 150px !important;
 }
}

}
@media  (max-width:767px){
.card-row{
  margin-top: 10px;
  margin-bottom: 10px;
}
}
@media  (max-width:600px){
  .tab-row{
  .nav-link{
    width: 130px !important;
    font-size: 15px;
 }}
  tr{
    td{
  font-size: 13px !important;
  }
  th{
    font-size: 14px !important;
  }
}

}
@media  (min-width:519px){
  .dataset{
    display: inline-flex;
}
.time{
  float: right !important;
}
}
@media  (max-width:519px){
  .tab-content{
    margin-top: 100px !important;
   }
   .modal-nav{
    font-size: 13px !important;
    margin-right: 10px;
   }
   .dataset{
    display: block;
}
}
@media  (max-width:440px){
.search-box, .search-deropdown {
  width: 150px !important; 
}
.contact, .modal-contact{
  display: block;
}

.modal-divider{
  display: none ;
}
}
@media  (max-width:381px){
  .search-box, .search-deropdown {
    width: 120px !important; 
  }
 
  .navbar > .container{
    gap: 0px;
  }
}
@media  (max-width:335px){
  .tab-content{
    margin-top: 150px !important;
   }
}
`;



export default UsersWrap;


